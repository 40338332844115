import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs, Timestamp, getDoc, doc } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { ChevronLeft, ChevronRight, Clock, EuroIcon, Calendar, Briefcase, User, FileText } from 'lucide-react';
import AnnualEmployeeChart from './AnnualEmployeeChart';

// Composant Alert personnalisé
const Alert = ({ title, children, variant = 'default' }) => {
  const baseStyles = "p-4 mb-4 rounded-md";
  const variantStyles = {
    default: "bg-blue-100 text-blue-700",
    destructive: "bg-red-100 text-red-700",
  };

  return (
    <div className={`${baseStyles} ${variantStyles[variant]}`}>
      {title && <h3 className="font-bold mb-2">{title}</h3>}
      <div>{children}</div>
    </div>
  );
};


const EmployeeReports = ({ db, currentUser }) => {
  const [employees, setEmployees] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [currentDate, setCurrentDate] = useState(new Date());
  const [monthlyStats, setMonthlyStats] = useState(null);
  const [clientStats, setClientStats] = useState({});
  const [clients, setClients] = useState({});
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState(null);
  const [plannedHours, setPlannedHours] = useState(0);
  const [completedHours, setCompletedHours] = useState(0);
  const [clientContracts, setClientContracts] = useState({});
  const [workedDays, setWorkedDays] = useState(0);
  const [showAlert, setShowAlert] = useState(false);
  const [userPermissions, setUserPermissions] = useState({});

  const fetchUserPermissions = useCallback(async () => {
    if (!db || !currentUser) return;
    try {
      const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRoleId = userData.role;
        const roleDoc = await getDoc(doc(db, 'roles', userRoleId));
        if (roleDoc.exists()) {
          const roleData = roleDoc.data();
          setUserPermissions(roleData.permissions.reduce((acc, perm) => {
            acc[perm] = true;
            return acc;
          }, {}));
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des permissions de l'utilisateur:", error);
    }
  }, [db, currentUser]);

  useEffect(() => {
    fetchUserPermissions();
  }, [fetchUserPermissions]);

  const hasPermission = (permission) => !!userPermissions[permission];

  const canViewAllReports = hasPermission('write_employees');
  const canViewOwnReport = hasPermission('read_appointments');

  useEffect(() => {
    if (canViewOwnReport && !canViewAllReports) {
      // Charger automatiquement le rapport de l'utilisateur connecté
      handleEmployeeChange(currentUser.userId);
    }
  }, [canViewOwnReport, canViewAllReports, currentUser]);


  const getPageTitle = () => {
    if (canViewAllReports) {
      return "Rapport des collaborateurs";
    } else if (canViewOwnReport) {
      return "Vos statistiques";
    }
    return "Rapports"; // Titre par défaut si aucune permission
  };


  const fetchEmployees = useCallback(async () => {
    try {
      const employeesSnapshot = await getDocs(collection(db, 'employees'));
      const employeesList = employeesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
      setEmployees(employeesList);
      setIsLoading(false);
    } catch (error) {
      console.error("Error fetching employees:", error);
      setError("Erreur lors du chargement des employés");
      setIsLoading(false);
    }
  }, [db]);

  const fetchClients = useCallback(async () => {
    try {
      const clientsSnapshot = await getDocs(collection(db, "clients"));
      const clientsMap = {};
      clientsSnapshot.docs.forEach(doc => {
        clientsMap[doc.id] = { id: doc.id, ...doc.data() };
      });
      setClients(clientsMap);
    } catch (error) {
      console.error("Error fetching clients:", error);
      setError("Erreur lors du chargement des clients");
    }
  }, [db]);

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    const diff = (end - start) / (1000 * 60);
    const hours = Math.floor(diff / 60);
    const minutes = Math.round(diff % 60);
    return { hours, minutes };
  };

  const fetchClientContracts = useCallback(async () => {
    try {
      const contractsSnapshot = await getDocs(collection(db, "contracts"));
      const contractsMap = {};
      contractsSnapshot.docs.forEach(doc => {
        contractsMap[doc.id] = doc.data();
      });
      setClientContracts(contractsMap);
    } catch (error) {
      console.error("Error fetching client contracts:", error);
      setError("Erreur lors du chargement des contrats des clients");
    }
  }, [db]);

  const processAppointments = useCallback((appointments, startDate, endOfMonth, employee) => {
    let totalPlannedHours = 0;
    let totalCompletedHours = 0;
    let totalAmount = 0;
    let totalForecastAmount = 0;
    const clientStatsData = {};
    const now = new Date();
    const workedDaysSet = new Set();
    let showAlertFlag = false;


    appointments.forEach(appointment => {
      const duration = calculateDuration(appointment.startTime, appointment.endTime);
      const durationInHours = duration.hours + duration.minutes / 60;
      
      totalPlannedHours += durationInHours;
      
      const clientContract = clientContracts[appointment.clientId];
      let hourlyRate;

      if (employee.isAutoEntrepreneur && employee.hourlyRate) {
        hourlyRate = parseFloat(employee.hourlyRate);
      } else if (employee.isAutoEntrepreneur && !employee.hourlyRate) {
        showAlertFlag = true;
        hourlyRate = clientContract ? parseFloat(clientContract.hourlyRate) : 0;
      } else {
        hourlyRate = clientContract ? parseFloat(clientContract.hourlyRate) : 0;
      }

      totalForecastAmount += durationInHours * hourlyRate;

      if (appointment.date <= now) {
        totalCompletedHours += durationInHours;
        totalAmount += durationInHours * hourlyRate;
      }

      workedDaysSet.add(appointment.date.toDateString());

      if (!clientStatsData[appointment.clientId]) {
        clientStatsData[appointment.clientId] = { hours: 0, amount: 0, hourlyRate: hourlyRate };
      }
      clientStatsData[appointment.clientId].hours += durationInHours;
      clientStatsData[appointment.clientId].amount += durationInHours * hourlyRate;
    });

    const monthStats = {
      month: startDate.toLocaleString('default', { month: 'long', year: 'numeric' }),
      totalPlannedHours: totalPlannedHours,
      totalCompletedHours: totalCompletedHours,
      totalAmount: totalAmount,
      totalForecastAmount: totalForecastAmount
    };

    setMonthlyStats(monthStats);
    setClientStats(clientStatsData);
    setPlannedHours(totalPlannedHours);
    setCompletedHours(totalCompletedHours);
    setWorkedDays(workedDaysSet.size);
    setShowAlert(showAlertFlag);
  }, [clientContracts]);

  useEffect(() => {
    fetchEmployees();
    fetchClients();
    fetchClientContracts();
  }, [fetchEmployees, fetchClients, fetchClientContracts]);

  const fetchMonthData = useCallback(async (employee, date) => {
    if (!employee || !employee.id) return; // Vérification de l'existence de employee et de son id
  
    setIsLoading(true);
    setError(null);
    try {
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0, 23, 59, 59);
  
      startOfMonth.setHours(0, 0, 0, 0);
      endOfMonth.setHours(23, 59, 59, 999);
  
      const startTimestamp = Timestamp.fromDate(startOfMonth);
      const endTimestamp = Timestamp.fromDate(endOfMonth);
  
      console.log("Fetching data from", startOfMonth, "to", endOfMonth);
      console.log("Start Timestamp:", startTimestamp, "End Timestamp:", endTimestamp);
  
      const appointmentsQuery = query(
        collection(db, 'appointments'),
        where('employeeId', '==', employee.id), // Utilisation de employee.id au lieu de employee.userId
        where('date', '>=', startTimestamp),
        where('date', '<=', endTimestamp)
      );
  
      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const appointments = appointmentsSnapshot.docs.map(doc => {
        const data = doc.data();
        return {
          ...data,
          id: doc.id,
          date: data.date.toDate()
        };
      });
  
      console.log("Fetched appointments:", appointments);
  
      if (appointments.length === 0) {
        console.log("No appointments found for the selected period");
      }
  
      processAppointments(appointments, startOfMonth, endOfMonth, employee);
    } catch (error) {
      console.error("Error fetching month data:", error);
      setError("Erreur lors du chargement des données mensuelles: " + error.message);
    } finally {
      setIsLoading(false);
    }
  }, [db, processAppointments]);

  useEffect(() => {
    if (selectedEmployee) {
      fetchMonthData(selectedEmployee, currentDate);
    }
  }, [selectedEmployee, currentDate, fetchMonthData]);

  const formatHours = (hours) => {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours}h${minutes.toString().padStart(2, '0')}`;
  };

  const changeMonth = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + direction);
    setCurrentDate(newDate);
  };

  const handleEmployeeChange = (employeeId) => {
    if (canViewAllReports || (canViewOwnReport && employeeId === currentUser.userId)) {
      const employee = employees.find(emp => emp.id === employeeId);
      setSelectedEmployee(employee);
      setMonthlyStats(null);
      setClientStats({});
      fetchMonthData(employee, currentDate);
    } else {
      setError("Vous n'avez pas la permission de voir les rapports des autres employés.");
    }
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  const renderClientHoursPieChart = () => {
    const data = Object.entries(clientStats).map(([clientId, stats]) => ({
      name: clients[clientId] ? `${clients[clientId].firstName} ${clients[clientId].lastName}` : `Client ${clientId}`,
      value: stats.hours
    }));

    return (
      <ResponsiveContainer width="100%" height={300}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-full">
      <div className="animate-spin rounded-full h-32 w-32 border-b-2 border-gray-900"></div>
    </div>;
  }

  if (error) {
    return <div className="text-red-500">{error}</div>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6">{getPageTitle()}</h1>

      {showAlert && (
        <Alert variant="destructive" title="Attention">
          L'employé est marqué comme auto-entrepreneur mais aucun taux horaire n'est défini. 
          Le calcul a été effectué avec les taux horaires des clients.
        </Alert>
      )}

      {canViewAllReports && (
        <div className="mb-6">
          <select
            className="w-full p-2 border rounded"
            value={selectedEmployee ? selectedEmployee.userId : ''}
            onChange={(e) => handleEmployeeChange(e.target.value)}
          >
            <option value="">Sélectionnez un collaborateur</option>
            {employees.map(emp => (
              <option key={emp.id} value={emp.id}>{emp.firstName} {emp.lastName}</option>
            ))}
          </select>
        </div>
      )}

{selectedEmployee && monthlyStats && (
        <>
          <div className="mb-6 flex items-center justify-between">
            <button 
              onClick={() => changeMonth(-1)} 
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-200"
            >
              <ChevronLeft size={20} />
            </button>
            <span className="text-lg font-semibold">
              {currentDate.toLocaleString('default', { month: 'long', year: 'numeric' })}
            </span>
            <button 
              onClick={() => changeMonth(1)} 
              className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors duration-200"
            >
              <ChevronRight size={20} />
            </button>
          </div>
   
          <div className="grid grid-cols-1 md:grid-cols-2 gap-6 mb-6">
            <div className="bg-gradient-to-br from-blue-500 to-blue-600 p-6 rounded-xl shadow-lg text-white">
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <Calendar className="mr-2" size={24} />Rapport mensuel
              </h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-blue-100">Jours travaillés</span>
                  <span className="text-2xl font-bold">{workedDays}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-blue-100">Heures planifiées</span>
                  <span className="text-2xl font-bold">{formatHours(plannedHours)}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-blue-100">Heures effectuées</span>
                  <span className="text-2xl font-bold">{formatHours(completedHours)}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-blue-100">Heures restantes</span>
                  <span className="text-2xl font-bold">{formatHours(plannedHours - completedHours)}</span>
                </div>
              </div>
            </div>

            <div className="bg-gradient-to-br from-purple-500 to-purple-600 p-6 rounded-xl shadow-lg text-white">
              <h3 className="text-xl font-semibold mb-4 flex items-center">
                <EuroIcon className="mr-2" size={24} />Rapport financier
              </h3>
              <div className="space-y-4">
                <div className="flex justify-between items-center">
                  <span className="text-purple-100">Taux horaire</span>
                  <span className="text-2xl font-bold">{selectedEmployee.hourlyRate ? `${selectedEmployee.hourlyRate}€` : 'Variable'}</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-purple-100">Montant prévisionnel</span>
                  <span className="text-2xl font-bold">{monthlyStats.totalForecastAmount.toFixed(2)}€</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-purple-100">Montant réalisé</span>
                  <span className="text-2xl font-bold">{monthlyStats.totalAmount.toFixed(2)}€</span>
                </div>
                <div className="flex justify-between items-center">
                  <span className="text-purple-100">Différence</span>
                  <span className="text-2xl font-bold">{(monthlyStats.totalForecastAmount - monthlyStats.totalAmount).toFixed(2)}€</span>
                </div>
              </div>
            </div>
          </div>




          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
            
          </div>
          <div className="mt-6">
            <h3 className="text-xl font-semibold mb-2">Répartition des heures par client</h3>
            {renderClientHoursPieChart()}
          </div>
          <div className="mt-6">
        <h3 className="text-xl font-semibold mb-2">Statistiques par client</h3>
        <div className="overflow-x-auto">
          <table className="min-w-full bg-white">
            <thead>
              <tr>
                <th className="px-4 py-2 bg-gray-100 text-left">Client</th>
                <th className="px-4 py-2 bg-gray-100 text-left">Heures</th>
                <th className="px-4 py-2 bg-gray-100 text-left">Taux horaire</th>
                <th className="px-4 py-2 bg-gray-100 text-left">Montant</th>
              </tr>
            </thead>
            <tbody>
              {Object.entries(clientStats).map(([clientId, stats], index) => {
                const client = clients[clientId];
                return (
                  <tr key={clientId} className={index % 2 === 0 ? 'bg-gray-50' : ''}>
                    <td className="px-4 py-2">{client ? `${client.lastName} ${client.firstName}` : `Client ${clientId}`}</td>
                    <td className="px-4 py-2">{formatHours(stats.hours)}</td>
                    <td className="px-4 py-2">{typeof stats.hourlyRate === 'number' ? stats.hourlyRate.toFixed(2) : 'N/A'}€</td>
                    <td className="px-4 py-2">{stats.amount.toFixed(2)}€</td>
                  </tr>
                );
              })}
            </tbody>
          </table>
        </div>
      </div>

      {selectedEmployee && (
      <AnnualEmployeeChart db={db} employeeId={selectedEmployee.userId} />
    )}
        </>
      )}

        {!canViewAllReports && !selectedEmployee && (
        <div className="text-center text-gray-500 mt-10">
          <p>Veuillez sélectionner un employé pour afficher son rapport.</p>
        </div>
      )}

      {!canViewAllReports && !canViewOwnReport && (
        <div className="text-center text-gray-500 mt-10">
          <p>Vous n'avez pas les permissions nécessaires pour voir les rapports.</p>
        </div>
      )}

      {!selectedEmployee && canViewOwnReport && (
        <div className="text-center text-gray-500 mt-10">
          <p>Chargement de votre rapport...</p>
        </div>
      )}
    </div>
  );
};

export default EmployeeReports;