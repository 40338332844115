import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { User, Mail, Phone, MapPin, Calendar, Clock, FileText, AlertTriangle, Navigation } from 'lucide-react';

const TIME_SLOTS = [
  { id: 'morning', label: 'Matin', timeRange: '06:00 - 12:00' },
  { id: 'noon', label: 'Midi', timeRange: '12:00 - 14:00' },
  { id: 'afternoon', label: 'Après-midi', timeRange: '14:00 - 18:00' },
  { id: 'evening', label: 'Soir', timeRange: '18:00 - 22:00' },
];

const ClientSummary = ({ client, db, currentUser, hasPermission }) => {
  const [contract, setContract] = useState(null);
  const [appointments, setAppointments] = useState([]);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [totalHours, setTotalHours] = useState(0);
  const [appointmentsByMonth, setAppointmentsByMonth] = useState({});
  const [contractTasks, setContractTasks] = useState([]);
  const [tasksList, setTasksList] = useState([]);  // All available tasks
  const [showMapOptions, setShowMapOptions] = useState(false);
  const [timeSlotTasks, setTimeSlotTasks] = useState({
    morning: [],
    noon: [],
    afternoon: [],
    evening: [],
  });

  useEffect(() => {
    const fetchData = async () => {
      if (!db || !client || !client.id) {
        console.error("La base de données ou les informations du client ne sont pas disponibles");
        return;
      }

      try {
        const tasksSnapshot = await getDocs(collection(db, 'tasks'));
        const allTasks = tasksSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
        setTasksList(allTasks);

        const contractDoc = await getDoc(doc(db, 'contracts', client.id));
        if (contractDoc.exists()) {
          const contractData = contractDoc.data();
          setContract(contractData);
          setTimeSlotTasks(contractData.tasksPerPeriod || {});
        }

        const now = new Date();
        const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
        const endOfThreeMonths = new Date(now.getFullYear(), now.getMonth() + 3, 0);

        const appointmentsRef = collection(db, 'appointments');
        const appointmentsQuery = query(
          appointmentsRef,
          where('clientId', '==', client.id),
          where('date', '>=', startOfToday),
          where('date', '<=', endOfThreeMonths)
        );
        const appointmentsSnapshot = await getDocs(appointmentsQuery);
        const appointmentsData = appointmentsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setAppointments(appointmentsData);

        let total = 0;
        let next = null;
        const byMonth = {};

        appointmentsData.forEach(app => {
          const appDate = app.date.toDate();
          const [hours, minutes] = app.startTime.split(':').map(Number);
          const appDateTime = new Date(
            appDate.getFullYear(),
            appDate.getMonth(),
            appDate.getDate(),
            hours,
            minutes
          );

          const monthKey = `${appDate.getFullYear()}-${appDate.getMonth() + 1}`;
          if (!byMonth[monthKey]) byMonth[monthKey] = 0;
          byMonth[monthKey]++;

          const duration = calculateDuration(app.startTime, app.endTime);
          total += duration.hours + duration.minutes / 60;

          if (appDateTime >= now && (!next || appDateTime < next.dateTime)) {
            next = { ...app, dateTime: appDateTime };
          }
        });

        setTotalHours(total);
        setNextAppointment(next);
        setAppointmentsByMonth(byMonth);

      } catch (error) {
        console.error("Erreur lors de la récupération des données:", error);
      }
    };

    fetchData();
  }, [client, db]);


  const handleAddressClick = () => {
    if (/Android|webOS|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent)) {
      // C'est un appareil mobile
      setShowMapOptions(true);
    } else {
      // C'est un PC, ouvrir directement Google Maps
      window.open(`https://www.google.com/maps/search/?api=1&query=${encodeURIComponent(client.address)}`, '_blank');
    }
  };

  const openMaps = (app) => {
    const address = encodeURIComponent(client.address);
    let url;
    switch (app) {
      case 'google':
        url = `https://www.google.com/maps/search/?api=1&query=${address}`;
        break;
      case 'waze':
        url = `https://waze.com/ul?q=${address}`;
        break;
      default:
        url = `maps://?q=${address}`;
    }
    window.open(url, '_blank');
    setShowMapOptions(false);
  };


  const calculateDuration = (startTime, endTime) => {
    const [startHours, startMinutes] = startTime.split(':').map(Number);
    const [endHours, endMinutes] = endTime.split(':').map(Number);
    let durationMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
    if (durationMinutes < 0) durationMinutes += 24 * 60;
    return { hours: Math.floor(durationMinutes / 60), minutes: durationMinutes % 60 };
  };

  const formatDate = (date) => {
    return new Date(date).toLocaleDateString('fr-FR', {
      weekday: 'long',
      year: 'numeric',
      month: 'long',
      day: 'numeric'
    });
  };

  const formatTime = (time) => {
    return time;
  };

  const getMonthName = (monthNumber) => {
    const date = new Date();
    date.setMonth(monthNumber - 1);
    return date.toLocaleString('fr-FR', { month: 'long' });
  };

  const renderAppointmentsByMonth = () => {
    const now = new Date();
    const currentMonth = now.getMonth() + 1;
    const currentYear = now.getFullYear();

    return [0, 1, 2].map(offset => {
      const monthNumber = ((currentMonth - 1 + offset) % 12) + 1;
      const year = currentYear + Math.floor((currentMonth - 1 + offset) / 12);
      const monthKey = `${year}-${monthNumber}`;
      const count = appointmentsByMonth[monthKey] || 0;

      return (
        <div key={monthKey} className="bg-yellow-100 p-4 rounded-lg">
          <p className="font-semibold">{getMonthName(monthNumber)} {year}</p>
          <p className="text-2xl font-bold">{count}</p>
        </div>
      );
    });
  };

  const getTaskNames = (taskIds) => {
    return taskIds
      .map(id => tasksList.find(task => task.id === id))
      .filter(task => task)
      .map(task => task.name);
  };

  const renderTimeSlotTasks = (slot) => {
    const taskIds = timeSlotTasks[slot.id] || [];
    const taskNames = getTaskNames(taskIds);
  
    return (
      <div className="space-y-2">
        {taskNames.length > 0 ? (
          taskNames.map((taskName, index) => (
            <span
              key={index}
              className="inline-flex items-center bg-blue-100 text-blue-800 text-sm font-medium px-3 py-1 rounded-full"
            >
              {taskName}
            </span>
          ))
        ) : (
          <p className="text-gray-500 italic">Aucune tâche pour ce créneau</p>
        )}
      </div>
    );
  };
  

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
      date.getMonth() === today.getMonth() &&
      date.getFullYear() === today.getFullYear();
  };

  const formatTotalHours = (total) => {
    const hours = Math.floor(total);
    const minutes = Math.round((total - hours) * 60);
    return `${hours}h${minutes.toString().padStart(2, '0')} min`;
  };

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6">Résumé du client</h1>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
        <div>
          <h2 className="text-xl font-semibold mb-4">Informations personnelles</h2>
          <div className="space-y-2">
            <p className="flex items-center"><User className="mr-2" size={18} /> {client.firstName} {client.lastName}</p>
            <p className="flex items-center"><Mail className="mr-2" size={18} /> {client.email}</p>
            <p className="flex items-center"><Phone className="mr-2" size={18} /> {client.phoneNumber}</p>
            <div className="relative">
              <p className="flex items-center">
                <MapPin className="mr-2" size={18} /> 
                <span className="cursor-pointer text-blue-600 hover:text-blue-800" onClick={handleAddressClick}>
                  {client.address}
                </span>
                {client.addressNumber && (
                  <span className="ml-2">Apt. {client.addressNumber}</span>
                )}
              </p>
              {showMapOptions && (
                <div className="absolute z-10 mt-2 py-2 w-48 bg-white rounded-md shadow-xl">
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => openMaps('google')}>Google Maps</a>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => openMaps('apple')}>Apple Maps</a>
                  <a href="#" className="block px-4 py-2 text-sm text-gray-700 hover:bg-gray-100" onClick={() => openMaps('waze')}>Waze</a>
                </div>
              )}
            </div>
          </div>
        </div>
        {hasPermission('write_clients') && (
        <div>
          <h2 className="text-xl font-semibold mb-4">Détails du contrat</h2>
          {contract ? (
            <div className="space-y-2">
              <p className="flex items-center"><Calendar className="mr-2" size={18} /> Début: {formatDate(contract.startDate)}</p>
              {contract.isOpenEnded ? (
                <p className="flex items-center"><AlertTriangle className="mr-2" size={18} /> Contrat sans fin</p>
              ) : (
                <p className="flex items-center"><Calendar className="mr-2" size={18} /> Fin: {formatDate(contract.endDate)}</p>
              )}
              <p className="flex items-center"><Clock className="mr-2" size={18} /> {contract.hoursPerMonth} heures par mois</p>
              <p className="flex items-center"><FileText className="mr-2" size={18} /> Tarif horaire: {contract.hourlyRate}€</p>
            </div>
          ) : (
            <p>Aucun contrat trouvé</p>
          )}
        </div>
        )}
      </div>
        
      {hasPermission('write_clients') && (
      <div className="mt-6">
  <h2 className="text-xl font-semibold mb-4">Tâches par créneau horaire</h2>
  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
    {TIME_SLOTS.map((slot) => (
      <div key={slot.id} className="bg-blue-50 p-4 rounded-lg shadow">
        <div className="flex items-center mb-2">
          <Navigation className="mr-2 text-blue-600" size={18} />
          <h3 className="font-bold text-blue-600">{slot.label} ({slot.timeRange})</h3>
        </div>
        {renderTimeSlotTasks(slot)}
      </div>
    ))}
  </div>
  </div>
)}


      <div className="mt-6">
        <h2 className="text-xl font-semibold mb-4">Prochain rendez-vous</h2>
        {nextAppointment ? (
          <div className="bg-blue-100 p-4 rounded-lg">
            <p className="font-semibold">
              {isToday(nextAppointment.dateTime) ? "Aujourd'hui" : formatDate(nextAppointment.dateTime)}
            </p>
            <p>{formatTime(nextAppointment.startTime)} - {formatTime(nextAppointment.endTime)} - avec {nextAppointment.employeeName} </p>
          </div>
        ) : (
          <p>Aucun rendez-vous prévu</p>
        )}
      </div>

      
    </div>
  );
};

export default ClientSummary;
