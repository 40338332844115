import React, { useState, useEffect } from 'react';
import CustomModal from './CustomModal';

const AppointmentModal = ({ isOpen, onClose, appointment, employees, onSave, onDelete }) => {
  const [appointmentData, setAppointmentData] = useState({
    date: '',
    startTime: '',
    endTime: '',
    employeeId: '',
    isRecurring: false,
    recurrencePattern: ''
  });

  useEffect(() => {
    if (appointment) {
      setAppointmentData({
        ...appointment,
        date: appointment.date instanceof Date ? appointment.date.toISOString().split('T')[0] : appointment.date,
      });
    } else {
      setAppointmentData({
        date: new Date().toISOString().split('T')[0],
        startTime: '',
        endTime: '',
        employeeId: '',
        isRecurring: false,
        recurrencePattern: ''
      });
    }
  }, [appointment]);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAppointmentData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(appointmentData);
  };

  const handleDelete = () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce rendez-vous ?")) {
      onDelete(appointment.id);
    }
  };

  return (
    <CustomModal
      isOpen={isOpen}
      onClose={onClose}
      title={appointment ? "Modifier le rendez-vous" : "Ajouter un rendez-vous"}
    >
      <form onSubmit={handleSubmit} style={styles.form}>
        <div style={styles.formGroup}>
          <label htmlFor="date">Date</label>
          <input
            type="date"
            id="date"
            name="date"
            value={appointmentData.date}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="startTime">Heure de début</label>
          <input
            type="time"
            id="startTime"
            name="startTime"
            value={appointmentData.startTime}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="endTime">Heure de fin</label>
          <input
            type="time"
            id="endTime"
            name="endTime"
            value={appointmentData.endTime}
            onChange={handleChange}
            style={styles.input}
          />
        </div>
        <div style={styles.formGroup}>
          <label htmlFor="employeeId">Employé</label>
          <select
            id="employeeId"
            name="employeeId"
            value={appointmentData.employeeId}
            onChange={handleChange}
            style={styles.input}
          >
            <option value="">Sélectionner un employé</option>
            {employees.map(emp => (
              <option key={emp.userId} value={emp.userId}>
                {emp.name || `${emp.firstName} ${emp.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div style={styles.formGroup}>
          <label>
            <input
              type="checkbox"
              name="isRecurring"
              checked={appointmentData.isRecurring}
              onChange={handleChange}
            />
            Récurrent
          </label>
        </div>
        {appointmentData.isRecurring && (
          <div style={styles.formGroup}>
            <label htmlFor="recurrencePattern">Modèle de récurrence</label>
            <select
              id="recurrencePattern"
              name="recurrencePattern"
              value={appointmentData.recurrencePattern}
              onChange={handleChange}
              style={styles.input}
            >
              <option value="">Sélectionner un modèle</option>
              <option value="weekly">Hebdomadaire</option>
              <option value="biweekly">Bi-hebdomadaire</option>
              <option value="monthly">Mensuel</option>
            </select>
          </div>
        )}
        <div style={styles.buttonGroup}>
          <button type="submit" style={styles.saveButton}>
            {appointment ? "Mettre à jour" : "Ajouter"}
          </button>
          {appointment && (
            <button type="button" onClick={handleDelete} style={styles.deleteButton}>
              Supprimer
            </button>
          )}
        </div>
      </form>
    </CustomModal>
  );
};

const styles = {
  form: {
    display: 'flex',
    flexDirection: 'column',
    gap: '16px',
  },
  formGroup: {
    display: 'flex',
    flexDirection: 'column',
    gap: '8px',
  },
  input: {
    padding: '8px',
    border: '1px solid #ccc',
    borderRadius: '4px',
  },
  buttonGroup: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '16px',
  },
  saveButton: {
    padding: '8px 16px',
    backgroundColor: '#4CAF50',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
  deleteButton: {
    padding: '8px 16px',
    backgroundColor: '#f44336',
    color: 'white',
    border: 'none',
    borderRadius: '4px',
    cursor: 'pointer',
  },
};

export default AppointmentModal;