import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Check } from 'lucide-react';

const PlanningManagementSettings = ({ db, onSettingsUpdate = () => {} }) => {
  const [settings, setSettings] = useState({
    openingTime: '05:00',
    closingTime: '22:00',
    firstDayOfWeek: 1,
  });
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDoc = await getDoc(doc(db, 'settings', 'planningManagement'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setSettings(data);
      }
    };
    fetchSettings();
  }, [db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: name === 'firstDayOfWeek' ? parseInt(value, 10) : value
    }));
  };

  const saveSettings = async () => {
    setIsSaving(true);
    try {
      await setDoc(doc(db, 'settings', 'planningManagement'), settings);
      setSaveSuccess(true);
      if (typeof onSettingsUpdate === 'function') {
        onSettingsUpdate(settings);
      }
      setTimeout(() => setSaveSuccess(false), 2000);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des paramètres:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-teal-700 mb-6">Affichage du planning</h2>
      
      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Ouverture du planning</label>
        <input
          type="time"
          name="openingTime"
          value={settings.openingTime}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Fermeture du planning</label>
        <input
          type="time"
          name="closingTime"
          value={settings.closingTime}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        />
      </div>

      <div className="mb-4">
        <label className="block text-sm font-medium text-gray-700">Premier jour de la semaine</label>
        <select
          name="firstDayOfWeek"
          value={settings.firstDayOfWeek}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
        >
          <option value={0}>Dimanche</option>
          <option value={1}>Lundi</option>
          <option value={2}>Mardi</option>
          <option value={3}>Mercredi</option>
          <option value={4}>Jeudi</option>
          <option value={5}>Vendredi</option>
          <option value={6}>Samedi</option>
        </select>
      </div>

      <button
        onClick={saveSettings}
        disabled={isSaving}
        className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
      >
        {isSaving ? 'Sauvegarde en cours...' : 'Sauvegarder'}
      </button>

      {saveSuccess && (
        <div className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded flex items-center">
          <Check size={18} className="mr-2" />
          Sauvegardé
        </div>
      )}
    </div>
  );
};

export default PlanningManagementSettings;