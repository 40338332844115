import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { collection, query, where, orderBy, getDocs, getDoc, Timestamp, doc } from 'firebase/firestore';
import { Calendar, Clock, User, MapPin, CheckSquare, ArrowLeft } from 'lucide-react';

const OverviewPage = ({ db, currentUser }) => {
  const [appointments, setAppointments] = useState([]);
  const [nextAppointment, setNextAppointment] = useState(null);
  const [stats, setStats] = useState({ weekCount: 0, monthCount: 0 });
  const [tasks, setTasks] = useState([]);
  const [showScrollTop, setShowScrollTop] = useState(false);
  const navigate = useNavigate();

  const handleBack = () => {
    navigate('/dashboard'); // Assurez-vous que '/dashboard' est le bon chemin
  };

  useEffect(() => {
    const fetchData = async () => {
      if (!db || !currentUser) return;

      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);

      const appointmentsRef = collection(db, 'appointments');
      const appointmentsQuery = query(
        appointmentsRef,
        where('employeeId', '==', currentUser.uid),
        where('date', '>=', Timestamp.fromDate(startOfMonth)),
        where('date', '<=', Timestamp.fromDate(endOfMonth)),
        orderBy('date', 'asc'),
        orderBy('startTime', 'asc')
      );

      try {
        const appointmentsSnapshot = await getDocs(appointmentsQuery);
        const fetchedAppointments = await Promise.all(appointmentsSnapshot.docs.map(async (docSnapshot) => {
          const appointmentData = docSnapshot.data();
          const appointmentDate = appointmentData.date.toDate();
          
          let clientName = "Client inconnu";
          let clientAddress = "Adresse inconnue";

          if (appointmentData.clientId) {
            const clientDocRef = doc(db, 'clients', appointmentData.clientId);
            const clientDocSnapshot = await getDoc(clientDocRef);
            if (clientDocSnapshot.exists()) {
              const clientData = clientDocSnapshot.data();
              clientName = `${clientData.lastName} ${clientData.firstName}`;
              clientAddress = `${clientData.address} ${clientData.addressNumber || ''}`.trim();
            }
          }

          return {
            id: docSnapshot.id,
            ...appointmentData,
            date: appointmentDate,
            clientName,
            clientAddress
          };
        }));

        // Tri des rendez-vous par ordre chronologique
        const sortedAppointments = fetchedAppointments.sort((a, b) => {
          const dateA = new Date(a.date.setHours(0,0,0,0));
          const dateB = new Date(b.date.setHours(0,0,0,0));
          if (dateA.getTime() !== dateB.getTime()) {
            return dateA.getTime() - dateB.getTime();
          }
          return a.startTime.localeCompare(b.startTime);
        });

        setAppointments(sortedAppointments);

        // Set next appointment
        const upcomingAppointments = sortedAppointments.filter(appt => 
          appt.date > now || (appt.date.toDateString() === now.toDateString() && appt.startTime > now.toTimeString().slice(0, 5))
        );
        if (upcomingAppointments.length > 0) {
          setNextAppointment(upcomingAppointments[0]);
        }

        // Calculate stats
        const startOfWeek = new Date(now);
        startOfWeek.setDate(now.getDate() - now.getDay());
        startOfWeek.setHours(0, 0, 0, 0);

        const endOfWeek = new Date(startOfWeek);
        endOfWeek.setDate(endOfWeek.getDate() + 7);

        const weekCount = sortedAppointments.filter(appt => 
          appt.date >= startOfWeek && appt.date < endOfWeek
        ).length;

        const monthCount = sortedAppointments.filter(appt => 
          appt.date >= startOfMonth && appt.date <= endOfMonth
        ).length;

        setStats({ weekCount, monthCount });

      } catch (error) {
        console.error("Erreur lors de la récupération des rendez-vous:", error);
      }
    };

    fetchData();
  }, [db, currentUser]);

  const formatDate = (date) => {
    return date.toLocaleDateString('fr-FR', { weekday: 'short', day: 'numeric', month: 'short' });
  };

  const formatTime = (time) => {
    return time;
  };

  const calculateTimeRemaining = (appointment) => {
    if (!appointment) return '';
    const now = new Date();
    const appointmentDateTime = new Date(appointment.date);
    const [hours, minutes] = appointment.startTime.split(':');
    appointmentDateTime.setHours(parseInt(hours, 10), parseInt(minutes, 10));
    const diff = appointmentDateTime - now;
    const hours_remaining = Math.floor(diff / 3600000);
    const minutes_remaining = Math.floor((diff % 3600000) / 60000);
    return `${hours_remaining}h ${minutes_remaining}min`;
  };

  const getAppointmentsForDate = (date) => {
    const now = new Date();
    const startOfDay = new Date(date.getFullYear(), date.getMonth(), date.getDate());
    const endOfDay = new Date(startOfDay);
    endOfDay.setDate(endOfDay.getDate() + 1);
    
    return appointments.filter(appointment => {
      const appointmentDateTime = new Date(appointment.date);
      appointmentDateTime.setHours(
        parseInt(appointment.startTime.split(':')[0], 10),
        parseInt(appointment.startTime.split(':')[1], 10)
      );
      
      if (date.toDateString() === now.toDateString()) {
        // Pour aujourd'hui, ne renvoyer que les rendez-vous à venir
        return appointmentDateTime >= now && appointmentDateTime < endOfDay;
      } else {
        // Pour les autres jours, renvoyer tous les rendez-vous de la journée
        return appointment.date >= startOfDay && appointment.date < endOfDay;
      }
    }).sort((a, b) => a.startTime.localeCompare(b.startTime));
  };

  const todayAppointments = getAppointmentsForDate(new Date());
  const tomorrowDate = new Date();
  tomorrowDate.setDate(tomorrowDate.getDate() + 1);
  const tomorrowAppointments = getAppointmentsForDate(tomorrowDate);

  const AppointmentList = ({ appointments, title }) => (
    <div className="mb-6">
      <h3 className="text-xl font-semibold mb-3 text-blue-600">{title}</h3>
      {appointments.length > 0 ? (
        <div className="space-y-4">
          {appointments.map(appointment => (
            <div key={appointment.id} className="bg-white p-4 rounded-lg shadow">
              <div className="flex justify-between items-center mb-2">
                <span className="font-semibold text-gray-700">{formatTime(appointment.startTime)} - {formatTime(appointment.endTime)}</span>
                <span className="text-sm text-gray-500">{formatDate(appointment.date)}</span>
              </div>
              <div className="text-gray-800">{appointment.clientName}</div>
              <div className="text-sm text-gray-600">{appointment.clientAddress}</div>
            </div>
          ))}
        </div>
      ) : (
        <p className="text-gray-500">Aucun rendez-vous {title.toLowerCase()}</p>
      )}
    </div>
  );

  const isToday = (date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  };
  
  const isTomorrow = (date) => {
    const tomorrow = new Date(new Date().setDate(new Date().getDate() + 1));
    return date.getDate() === tomorrow.getDate() &&
           date.getMonth() === tomorrow.getMonth() &&
           date.getFullYear() === tomorrow.getFullYear();
  };


  useEffect(() => {
    const toggleVisibility = () => {
      if (window.pageYOffset > 300) {
        setShowScrollTop(true);
      } else {
        setShowScrollTop(false);
      }
    };
  
    window.addEventListener('scroll', toggleVisibility);
  
    return () => window.removeEventListener('scroll', toggleVisibility);
  }, []);

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth'
    });
  };


  return (
    <div className="p-4 md:p-6 bg-gray-50 relative">
      {/* Bouton de retour */}
      <button
        onClick={handleBack}
        className="absolute top-4 left-4 bg-white text-blue-500 p-2 rounded-full shadow-lg hover:bg-blue-50 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300"
        aria-label="Retour au tableau de bord"
      >
        <ArrowLeft size={24} />
      </button>

      <h1 className="text-2xl font-bold mb-6 text-gray-800 pl-12">Vue d'ensemble</h1>
         
      {/* Résumé de la journée */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6 border-l-4 border-blue-500">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Aujourd'hui, {formatDate(new Date())}</h2>
        <p className="text-lg text-gray-600 mb-3">Rendez-vous aujourd'hui : {todayAppointments.length}</p>
        {nextAppointment && (
          <div className="bg-blue-50 p-4 rounded-lg">
            <h3 className="font-semibold text-blue-800 mb-2">Prochain rendez-vous</h3>
            <p className="text-blue-700">{formatTime(nextAppointment.startTime)} avec {nextAppointment.clientName}</p>
            <p className="text-blue-600">Temps restant : {calculateTimeRemaining(nextAppointment)}</p>
          </div>
        )}
      </div>

      {/* Liste des rendez-vous à venir */}
<div className="bg-white p-6 rounded-lg shadow-md mb-6">
  <h2 className="text-2xl font-semibold mb-4 text-gray-700">Rendez-vous à venir</h2>
  
  <AppointmentList appointments={todayAppointments} title="Aujourd'hui" />
  <AppointmentList appointments={tomorrowAppointments} title="Demain" />
  
  {/* Rendez-vous des prochains jours */}
  <div className="mt-6">
    <h3 className="text-xl font-semibold mb-3 text-green-600">Prochains jours</h3>
    <AppointmentList 
      appointments={appointments.filter(app => 
        !isToday(app.date) && !isTomorrow(app.date)
      ).slice(0, 5)} 
      title="À venir"
    />
  </div>
</div>

      {/* Statistiques rapides */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6 border-l-4 border-green-500">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Statistiques</h2>
        <div className="grid grid-cols-2 gap-4">
          <div className="bg-green-50 p-4 rounded-lg">
            <p className="text-lg font-semibold text-green-800">Cette semaine</p>
            <p className="text-3xl font-bold text-green-600">{stats.weekCount}</p>
          </div>
          <div className="bg-blue-50 p-4 rounded-lg">
            <p className="text-lg font-semibold text-blue-800">Ce mois</p>
            <p className="text-3xl font-bold text-blue-600">{stats.monthCount}</p>
          </div>
        </div>
      </div>

      {/* Tâches et rappels */}
      <div className="bg-white p-6 rounded-lg shadow-md mb-6 border-l-4 border-yellow-500">
        <h2 className="text-2xl font-semibold mb-4 text-gray-700">Tâches à effectuer</h2>
        {tasks.length > 0 ? (
          <ul className="space-y-2">
            {tasks.map(task => (
              <li key={task.id} className="flex items-center bg-yellow-50 p-3 rounded-lg">
                <CheckSquare className="mr-3 text-yellow-600" size={20} />
                <div>
                  <p className="font-semibold text-gray-700">{task.description}</p>
                  <p className="text-sm text-gray-500">Échéance : {formatDate(task.dueDate.toDate())}</p>
                </div>
              </li>
            ))}
          </ul>
        ) : (
          <p className="text-gray-500">Aucune tâche en attente</p>
        )}
      </div>

      {/* Actions rapides */}
      <div className="flex space-x-4 mb-6">
        <button className="bg-blue-500 hover:bg-blue-600 text-white font-semibold px-6 py-3 rounded-lg transition duration-300 ease-in-out transform hover:scale-105">
          Ajouter un rendez-vous
        </button>
        <button className="bg-green-500 hover:bg-green-600 text-white font-semibold px-6 py-3 rounded-lg transition duration-300 ease-in-out transform hover:scale-105">
          Gérer les disponibilités
        </button>
      </div>
      {showScrollTop && (
        <button
          onClick={scrollToTop}
          className="fixed bottom-4 right-4 bg-blue-500 text-white p-2 rounded-full shadow-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 transition-all duration-300"
          aria-label="Retour en haut"
        >
          <svg xmlns="http://www.w3.org/2000/svg" className="h-6 w-6" fill="none" viewBox="0 0 24 24" stroke="currentColor">
            <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M5 10l7-7m0 0l7 7m-7-7v18" />
          </svg>
        </button>
      )}
    </div>
  );
};

export default OverviewPage;