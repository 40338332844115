import React, { useState } from 'react';
import { getAuth, signInWithEmailAndPassword } from 'firebase/auth';
import { useNavigate, Link } from 'react-router-dom';
import { doc, getDoc, updateDoc, serverTimestamp } from 'firebase/firestore';
import { getDatabase, ref, set } from 'firebase/database';
import { Mail, Lock } from 'lucide-react';
import AuthLayout from './AuthLayout';
import { getAuthErrorMessage } from './firebaseAuthErrors';
import { db } from '../firebase';
import usePresence from '../hooks/usePresence'; // Assurez-vous que le chemin est correct

function Login() {
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  usePresence(); 

  const handleSubmit = async (e) => {
    e.preventDefault();
    console.log("Début de la soumission du formulaire de connexion");
    setError(null);
    setIsLoading(true);
    try {
      console.log("Tentative de connexion avec:", email);
      const auth = getAuth();
      const userCredential = await signInWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("Utilisateur connecté:", user.uid);
  
      console.log("Récupération des données utilisateur depuis Firestore");
      const userDocRef = doc(db, 'employees', user.uid);
      const userDoc = await getDoc(userDocRef);
      if (userDoc.exists()) {
        const userData = userDoc.data();
        console.log("Données de l'utilisateur:", userData);
        
        // Mise à jour du champ lastLogin
        await updateDoc(userDocRef, {
          lastLogin: serverTimestamp()
        });
        console.log("Dernière connexion mise à jour dans Firestore");
        

        if (userData.needPasswordReset === true) {
          console.log("Réinitialisation du mot de passe nécessaire, tentative de redirection");
          setIsLoading(false);
          navigate('/reset-password');
          console.log("Navigation vers /reset-password effectuée");
          return;
        }
        if (userData.needEmailUpdate === true) {
          console.log("L'utilisateur doit mettre à jour son email");
          setIsLoading(false);
          navigate('/update-email');
          return;
        }
        
        console.log("Pas de réinitialisation nécessaire, navigation vers le tableau de bord");
      } else {
        console.log("Aucun document utilisateur trouvé dans Firestore");
      }
    
      console.log("Navigation vers le tableau de bord");
      navigate('/dashboard');
    } catch (error) {
      console.error("Erreur lors de la connexion:", error);
      setError(getAuthErrorMessage(error.code));
    } finally {
      setIsLoading(false);
      console.log("Fin du processus de connexion");
    }
  };


  return (
    <AuthLayout title="Connexion">
      <form className="auth-fade-in" onSubmit={handleSubmit}>
        <div className="auth-input-group">
          <label htmlFor="email" className="auth-label">
            Adresse e-mail
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="auth-input pl-10"
              placeholder="vous@exemple.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="auth-input-group">
          <label htmlFor="password" className="auth-label">
            Mot de passe
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="current-password"
              required
              className="auth-input pl-10"
              placeholder="••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        {error && <div className="auth-error">{error}</div>}

        <div className="flex items-center justify-between mb-6">
          <div className="flex items-center">
            <input
              id="remember-me"
              name="remember-me"
              type="checkbox"
              className="h-4 w-4 text-indigo-600 focus:ring-indigo-500 border-gray-300 rounded"
            />
            <label htmlFor="remember-me" className="ml-2 block text-sm text-gray-900">
              Se souvenir de moi
            </label>
          </div>

          <div className="text-sm">
            <Link to="/forgot-password" className="auth-link">
              Mot de passe oublié ?
            </Link>
          </div>
        </div>

        <button
          type="submit"
          disabled={isLoading}
          className="auth-button"
        >
          {isLoading ? 'Connexion en cours...' : 'Se connecter'}
        </button>
      </form>

      
    </AuthLayout>
  );
}

export default Login;