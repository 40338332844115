import React, { useState, useEffect, useRef } from 'react';
import { doc, getDoc, updateDoc, collection, getDocs, Timestamp } from 'firebase/firestore';
import { X, Clock, Calendar, User, Check, MapPin, Wifi, AlertTriangle } from 'lucide-react';
import { sha256 } from 'js-sha256';

const TIME_SLOTS = [
  { id: 'morning', label: 'Matin', timeRange: '04:00 - 12:00' },
  { id: 'noon', label: 'Midi', timeRange: '12:00 - 14:00' },
  { id: 'afternoon', label: 'Après-midi', timeRange: '14:00 - 17:30' },
  { id: 'evening', label: 'Soir', timeRange: '17:30 - 22:00' },
];

// Nouvelle icône Waze officielle
const WazeIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24" fill="currentColor">
      <path d="M12,2C6.477,2,2,6.477,2,12c0,5.523,4.477,10,10,10s10-4.477,10-10C22,6.477,17.523,2,12,2z M12,20c-4.418,0-8-3.582-8-8
        s3.582-8,8-8s8,3.582,8,8S16.418,20,12,20z M15.5,11c0.828,0,1.5-0.672,1.5-1.5S16.328,8,15.5,8S14,8.672,14,9.5
        S14.672,11,15.5,11z M8.5,11C9.328,11,10,10.328,10,9.5S9.328,8,8.5,8S7,8.672,7,9.5S7.672,11,8.5,11z M12,17.5
        c2.33,0,4.32-1.43,5.12-3.46c0.128-0.324-0.021-0.691-0.346-0.819c-0.324-0.128-0.691,0.021-0.819,0.346
        C15.318,14.988,13.758,16.1,12,16.1s-3.318-1.112-3.955-2.533c-0.128-0.325-0.495-0.474-0.819-0.346
        c-0.325,0.128-0.474,0.495-0.346,0.819C7.68,16.07,9.67,17.5,12,17.5z"/>
    </svg>
  );

  const GoogleMapsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <path d="M12 0C7.58 0 4 3.58 4 8c0 5.5 8 16 8 16s8-10.5 8-16c0-4.42-3.58-8-8-8zm0 12c-2.21 0-4-1.79-4-4s1.79-4 4-4 4 1.79 4 4-1.79 4-4 4z" fill="#34A853"/>
      <path d="M12 0C7.58 0 4 3.58 4 8c0 1.15 0.23 2.54 0.73 3.93L12 22s7.26-9.5 7.98-14H20c0-4.42-3.58-8-8-8z" fill="#FBBC04"/>
      <path d="M12 0C7.58 0 4 3.58 4 8c0 1.15 0.23 2.54 0.73 3.93L12 22V0z" fill="#4285F4"/>
      <path d="M12 0v12c2.21 0 4-1.79 4-4s-1.79-4-4-4z" fill="#1A73E8"/>
      <circle cx="12" cy="8" r="2.5" fill="#FFFFFF"/>
    </svg>
  );

  const AppleMapsIcon = () => (
    <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 24 24" width="24" height="24">
      <rect width="24" height="24" rx="5" fill="#FFFFFF"/>
      <path d="M2 7L8 3L22 7L16 11L2 7Z" fill="#83CE7A"/>
      <path d="M2 7L16 11L15 21L8 17L2 7Z" fill="#4CD964"/>
      <path d="M16 11L22 7L21 17L15 21L16 11Z" fill="#3EB35B"/>
      <rect x="6" y="6" width="12" height="12" fill="#007AFF"/>
      <path d="M12 7L14 11H10L12 7Z" fill="#FFFFFF"/>
      <path d="M3 12L5 14H7L6 16H4L3 12Z" fill="#FF9500"/>
      <path d="M21 12L19 14H17L18 16H20L21 12Z" fill="#FF3B30"/>
    </svg>
  );

const AppointmentDetailsModal = ({ appointment, onClose, db, onUpdate }) => {
  const [clientContract, setClientContract] = useState(null);
  const [clientInfo, setClientInfo] = useState(null);
  const [tasks, setTasks] = useState([]);
  const [completedTasks, setCompletedTasks] = useState(appointment.completedTasks || []);
  const modalRef = useRef();
  const [isNfcAvailable, setIsNfcAvailable] = useState(false);
  const [checkInStatus, setCheckInStatus] = useState(appointment.checkInStatus || null);
  const [isNfcReading, setIsNfcReading] = useState(false);
  const [nfcError, setNfcError] = useState(null);
  const abortControllerRef = useRef(null);
  


  useEffect(() => {
    // Vérifier si l'API Web NFC est disponible
    if ('NDEFReader' in window) {
      setIsNfcAvailable(true);
    }
  }, []);


  useEffect(() => {
    const fetchClientData = async () => {
      if (appointment.clientId) {
        const clientDoc = await getDoc(doc(db, 'clients', appointment.clientId));
        if (clientDoc.exists()) {
          setClientInfo(clientDoc.data());
        }
        const contractDoc = await getDoc(doc(db, 'contracts', appointment.clientId));
        if (contractDoc.exists()) {
          setClientContract(contractDoc.data());
        }
      }
    };

    const fetchTasks = async () => {
      const tasksCollection = collection(db, 'tasks');
      const taskSnapshot = await getDocs(tasksCollection);
      const taskList = taskSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
      setTasks(taskList);
    };

    fetchClientData();
    fetchTasks();

    const handleOutsideClick = (event) => {
      if (modalRef.current && !modalRef.current.contains(event.target)) {
        onClose();
      }
    };
    document.addEventListener('mousedown', handleOutsideClick);
    return () => {
      document.removeEventListener('mousedown', handleOutsideClick);
    };
  }, [appointment.clientId, db, onClose]);

  const getTimeSlot = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    const totalMinutes = hours * 60 + minutes;

    if (totalMinutes >= 4 * 60 && totalMinutes < 12 * 60) return 'morning';
    if (totalMinutes >= 12 * 60 && totalMinutes < 14 * 60) return 'noon';
    if (totalMinutes >= 14 * 60 && totalMinutes < 17 * 60 + 30) return 'afternoon';
    if (totalMinutes >= 17 * 60 + 30 && totalMinutes < 22 * 60) return 'evening';
    return 'night';
  };

  const timeSlot = getTimeSlot(appointment.startTime);

  const handleTaskToggle = async (taskId) => {
    const updatedCompletedTasks = completedTasks.includes(taskId)
      ? completedTasks.filter(id => id !== taskId)
      : [...completedTasks, taskId];

    try {
      const appointmentRef = doc(db, 'appointments', appointment.id);
      await updateDoc(appointmentRef, { completedTasks: updatedCompletedTasks });
      
      setCompletedTasks(updatedCompletedTasks);
      onUpdate({ ...appointment, completedTasks: updatedCompletedTasks });
    } catch (error) {
      console.error("Erreur lors de la mise à jour des tâches:", error);
      alert("Erreur lors de la mise à jour des tâches. Veuillez réessayer.");
    }
  };

  
  const handleSave = async () => {
    try {
      const appointmentRef = doc(db, 'appointments', appointment.id);
      await updateDoc(appointmentRef, { completedTasks });
      onUpdate({ ...appointment, completedTasks });
      onClose();
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rendez-vous:", error);
    }
  };

  const getNavigationLinks = (address) => {
    const encodedAddress = encodeURIComponent(address);
    return {
      waze: `https://www.waze.com/ul?q=${encodedAddress}&navigate=yes`,
      googleMaps: `https://www.google.com/maps/search/?api=1&query=${encodedAddress}`,
      appleMaps: `http://maps.apple.com/?address=${encodedAddress}`
    };
  };

  const [isWithinRange, setIsWithinRange] = useState(false);

  // Fonction pour calculer la distance entre deux points GPS
  const calculateDistance = (lat1, lon1, lat2, lon2) => {
    const R = 6371; // Rayon de la Terre en km
    const dLat = (lat2 - lat1) * Math.PI / 180;
    const dLon = (lon2 - lon1) * Math.PI / 180;
    const a = 
      Math.sin(dLat/2) * Math.sin(dLat/2) +
      Math.cos(lat1 * Math.PI / 180) * Math.cos(lat2 * Math.PI / 180) * 
      Math.sin(dLon/2) * Math.sin(dLon/2);
    const c = 2 * Math.atan2(Math.sqrt(a), Math.sqrt(1-a));
    const distance = R * c; // Distance en km
    return distance * 1000; // Convertir en mètres
  };
  const getCoordinatesFromAddress = async (address) => {
    const API_KEY = 'AIzaSyCxR28trRMeW_1AboemOYzJFyE7ZtET-u4'; // Remplacez par votre clé API Google Maps
    const encodedAddress = encodeURIComponent(address);
    const url = `https://maps.googleapis.com/maps/api/geocode/json?address=${encodedAddress}&key=${API_KEY}`;

    try {
      const response = await fetch(url);
      const data = await response.json();

      if (data.status === 'OK' && data.results.length > 0) {
        const { lat, lng } = data.results[0].geometry.location;
        return { lat, lng };
      } else {
        throw new Error('Adresse non trouvée');
      }
    } catch (error) {
      console.error('Erreur lors du géocodage:', error);
      throw error;
    }
  };


  const checkDistance = async () => {
    if (!clientInfo || !clientInfo.address) {
      console.error("Adresse du client non disponible");
      return;
    }

    try {
      // Obtenir les coordonnées GPS de l'adresse du client
      const clientCoords = await getCoordinatesFromAddress(clientInfo.address);
      
      // Obtenir la position actuelle de l'utilisateur
      const currentPosition = await getCurrentLocation();

      // Calculer la distance
      const distance = calculateDistance(
        currentPosition.lat, currentPosition.lng,
        clientCoords.lat, clientCoords.lng
      );

      setIsWithinRange(distance <= 100);
    } catch (error) {
      console.error("Erreur lors de la vérification de la distance:", error);
      setIsWithinRange(false);
    }
  };

  

  const getCurrentLocation = () => {
    return new Promise((resolve, reject) => {
      if ("geolocation" in navigator) {
        navigator.geolocation.getCurrentPosition(
          position => resolve({ lat: position.coords.latitude, lng: position.coords.longitude }),
          error => reject(error)
        );
      } else {
        reject(new Error("Géolocalisation non supportée"));
      }
    });
  };


  const verifyNfcData = (nfcData) => {
    try {
      const data = JSON.parse(nfcData);
      const currentDate = new Date();
      const expDate = new Date(data.exp);

      if (currentDate > expDate) {
        return { isValid: false, error: "Le tag NFC a expiré." };
      }

      const decodedAddress = atob(data.addr);
      if (decodedAddress !== clientInfo.address) {
        return { isValid: false, error: "L'adresse du tag ne correspond pas à celle du rendez-vous." };
      }

      const secretKey = "Son_of_the_Bitch!"; // À remplacer par une vraie clé secrète
      const calculatedHash = sha256(data.cid + secretKey + data.clientId).slice(0, 20);
      if (calculatedHash !== data.hash) {
        return { isValid: false, error: "Le hash de sécurité est invalide." };
      }

      if (data.clientId !== appointment.clientId) {
        return { isValid: false, error: "Le tag NFC ne correspond pas au client du rendez-vous." };
      }

      return { isValid: true, clientId: data.clientId };
    } catch (error) {
      console.error("Erreur lors de la vérification des données NFC:", error);
      return { isValid: false, error: "Erreur lors de la lecture du tag NFC." };
    }
  };


  const handleNfcCheckIn = async () => {
    if (!isNfcAvailable) {
      setNfcError("NFC n'est pas disponible sur cet appareil.");
      return;
    }
  
    setIsNfcReading(true);
    setNfcError(null);
  
    try {
      abortControllerRef.current = new AbortController();
      const ndef = new window.NDEFReader();
      await ndef.scan({ 
        signal: abortControllerRef.current.signal,
        preventDefaultBehavior: true
      });
  
      // Ajouter explicitement preventDefault ici
      ndef.addEventListener("reading", (event) => {
        event.preventDefault();  // Empêche le comportement par défaut
        
        const { message, serialNumber } = event;
        const decoder = new TextDecoder();
        let nfcData;
        for (const record of message.records) {
          if (record.recordType === "text") {
            nfcData = decoder.decode(record.data);
            break;
          }
        }
  
        if (!nfcData) {
          setNfcError("Aucune donnée valide trouvée sur le tag NFC.");
          setIsNfcReading(false);
          return;
        }
  
        const { isValid, error, clientId } = verifyNfcData(nfcData);
        
        if (isValid) {
          handleCheckIn('nfc', clientId);
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
        } else {
          setNfcError(error);
        }
        
        setIsNfcReading(false);
      }, { once: true });
  
      setTimeout(() => {
        if (isNfcReading) {
          if (abortControllerRef.current) {
            abortControllerRef.current.abort();
          }
          setIsNfcReading(false);
          setNfcError("Aucun tag NFC détecté. Veuillez réessayer.");
        }
      }, 30000);
  
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Erreur lors de la lecture NFC:", error);
        setNfcError("Erreur lors de la lecture NFC. Veuillez réessayer.");
      }
      setIsNfcReading(false);
    }
  };
  

  const handleCheckIn = async (method, nfcClientId = null) => {
    let checkInData = {
      checkedIn: true,
      checkInTime: Timestamp.now(),
      checkInMethod: method,
    };

    if (method === 'gps') {
      if (!isWithinRange) {
        setNfcError("Vous devez être à moins de 100 mètres du client pour pointer avec GPS.");
        return;
      }
      checkInData.checkInLocation = await getCurrentLocation();
    } else if (method === 'nfc') {
      if (nfcClientId !== appointment.clientId) {
        setNfcError("Le tag NFC ne correspond pas au client du rendez-vous.");
        return;
      }
      checkInData.nfcClientId = nfcClientId;
    }

    try {
      const appointmentRef = doc(db, 'appointments', appointment.id);
      await updateDoc(appointmentRef, { 
        checkInStatus: checkInData,
        status: 'in_progress'
      });
      setCheckInStatus(checkInData);
      onUpdate({ ...appointment, checkInStatus: checkInData, status: 'in_progress' });
      setIsNfcReading(false);
      setNfcError(null);
    } catch (error) {
      console.error("Erreur lors du pointage:", error);
      setNfcError("Erreur lors du pointage. Veuillez réessayer.");
    }
  };

  useEffect(() => {
    checkDistance();
  }, [clientInfo]);

  const handleCheckOut = async () => {
    const checkOutData = {
      ...checkInStatus,
      checkedOut: true,
      checkOutTime: Timestamp.now()
    };

    try {
      const appointmentRef = doc(db, 'appointments', appointment.id);
      await updateDoc(appointmentRef, { 
        checkInStatus: checkOutData,
        status: 'completed',
        completedTasks
      });
      setCheckInStatus(checkOutData);
      onUpdate({ ...appointment, checkInStatus: checkOutData, status: 'completed', completedTasks });
      onClose();
    } catch (error) {
      console.error("Erreur lors du check-out:", error);
      alert("Erreur lors du check-out. Veuillez réessayer.");
    }
  };

  useEffect(() => {
    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, []);
  
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div ref={modalRef} className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md relative">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        <h2 className="text-xl font-bold mb-4">Détails du rendez-vous</h2>
        <div className="space-y-2">
          <p className="flex items-center"><User className="mr-2" size={16} /> Client : {appointment.clientName}</p>
          <p className="flex items-center"><Calendar className="mr-2" size={16} /> Date : {appointment.date.toLocaleDateString()}</p>
          <p className="flex items-center"><Clock className="mr-2" size={16} /> Heure : {appointment.startTime} - {appointment.endTime}</p>
          <p className="flex items-center"><Clock className="mr-2" size={16} /> Créneau : {TIME_SLOTS.find(slot => slot.id === timeSlot)?.label || 'Non défini'}</p>
          {clientInfo && (
            <div className="mt-2">
              <p className="flex items-center">
                <MapPin className="mr-2" size={16} /> 
                Adresse : {clientInfo.address}
                {clientInfo.addressNumber && `, Apt ${clientInfo.addressNumber}`}
              </p>
              
              <div className="mt-2 flex space-x-4">
                {Object.entries(getNavigationLinks(clientInfo.address, clientInfo.addressNumber)).map(([key, url]) => (
                  <a
                    key={key}
                    href={url}
                    target="_blank"
                    rel="noopener noreferrer"
                    className="flex flex-col items-center text-blue-500 hover:text-blue-700"
                  >
                    {key === 'waze' ? <WazeIcon /> : key === 'googleMaps' ? <GoogleMapsIcon /> : <AppleMapsIcon />}
                    <span className="text-xs mt-1">{key === 'waze' ? 'Waze' : key === 'googleMaps' ? 'Google' : 'Apple'}</span>
                  </a>
                ))}
              </div>
            </div>
          )}
            {!checkInStatus?.checkedIn ? (
          <div className="mt-4 space-y-2">
            <button 
              onClick={() => handleCheckIn('gps')} 
              className={`w-full px-4 py-2 ${isWithinRange ? 'bg-blue-500 hover:bg-blue-600' : 'bg-gray-400 cursor-not-allowed'} text-white rounded flex items-center justify-center`}
              disabled={!isWithinRange}
            >
              <MapPin className="mr-2" size={18} />
              Pointer avec GPS
            </button>
            {!isWithinRange && (
              <p className="text-sm text-red-500">Vous devez être à moins de 100 mètres du client pour pointer avec GPS.</p>
            )}
            {isNfcAvailable && (
              <button 
                onClick={handleNfcCheckIn} 
                className={`w-full px-4 py-2 ${isNfcReading ? 'bg-yellow-500' : 'bg-green-500 hover:bg-green-600'} text-white rounded flex items-center justify-center`}
                disabled={isNfcReading}
              >
                <Wifi className="mr-2" size={18} />
                {isNfcReading ? 'Lecture NFC en cours...' : 'Pointer avec NFC'}
              </button>
            )}
            {nfcError && (
              <div className="mt-2 text-red-500 flex items-center">
                <AlertTriangle size={18} className="mr-2" />
                {nfcError}
              </div>
            )}
          </div>
        ) : !checkInStatus.checkedOut ? (
          <div className="mt-4">
            <button onClick={handleCheckOut} className="w-full px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 flex items-center justify-center">
              <Clock className="mr-2" size={18} />
              Terminer le rendez-vous
            </button>
          </div>
        ) : (
          <div className="mt-4 text-green-600 font-bold flex items-center justify-center">
            <Check className="mr-2" size={18} />
            Rendez-vous terminé
          </div>
        )}

        </div>
        {clientContract && clientContract.tasksPerPeriod && clientContract.tasksPerPeriod[timeSlot] && (
          <div className="mt-4">
            <h3 className="text-lg font-semibold mb-2">Tâches à effectuer</h3>
            <ul className="space-y-2">
              {clientContract.tasksPerPeriod[timeSlot].map((taskId) => {
                const task = tasks.find(t => t.id === taskId);
                return task ? (
                  <li key={taskId} className="flex items-center">
                    <input
                      type="checkbox"
                      id={`task-${taskId}`}
                      checked={completedTasks.includes(taskId)}
                      onChange={() => handleTaskToggle(taskId)}
                      className="mr-2"
                    />
                    <label htmlFor={`task-${taskId}`} className="flex-grow">{task.name}</label>
                    {completedTasks.includes(taskId) && <Check size={16} className="text-green-500" />}
                  </li>
                ) : null;
              })}
            </ul>
          </div>
        )}
        <div className="mt-6 flex justify-end space-x-2">
          <button onClick={onClose} className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300">
            Fermer
          </button>
        </div>
      </div>
    </div>
  );
};

export default AppointmentDetailsModal;