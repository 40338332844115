import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { collection, query, where, getDocs, doc, getDoc } from 'firebase/firestore';
import { EuroIcon, ChevronLeft, ChevronRight, Calendar, Clock } from 'lucide-react';

const AnnualChart = ({ client, db }) => {
  const [annualData, setAnnualData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [annualTotals, setAnnualTotals] = useState({
    plannedHours: 0,
    actualHours: 0,
    revenues: 0
  });

  useEffect(() => {
    const fetchAnnualData = async () => {
      const startOfYear = new Date(selectedYear, 0, 1);
      const endOfYear = new Date(selectedYear, 11, 31, 23, 59, 59);

      const contractDoc = await getDoc(doc(db, 'contracts', client.id));
      const contractData = contractDoc.exists() ? contractDoc.data() : null;
      const hourlyRate = contractData ? contractData.hourlyRate : 0;

      const appointmentsQuery = query(
        collection(db, 'appointments'),
        where('clientId', '==', client.id),
        where('date', '>=', startOfYear),
        where('date', '<=', endOfYear)
      );

      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const appointments = appointmentsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        date: doc.data().date.toDate()
      }));

      const monthlyData = Array(12).fill().map(() => ({ planned: 0, actual: 0, revenues: 0 }));
      let totalPlannedHours = 0;
      let totalActualHours = 0;
      let totalRevenues = 0;

      appointments.forEach(appointment => {
        const monthIndex = appointment.date.getMonth();
        const duration = calculateDuration(appointment.startTime, appointment.endTime);
        const revenue = duration * hourlyRate;
        
        monthlyData[monthIndex].planned += duration;
        totalPlannedHours += duration;

        if (appointment.date <= new Date()) {
          monthlyData[monthIndex].actual += duration;
          totalActualHours += duration;
        }

        monthlyData[monthIndex].revenues += revenue;
        totalRevenues += revenue;
      });

      const formattedData = monthlyData.map((data, index) => ({
        name: new Date(selectedYear, index).toLocaleString('default', { month: 'short' }),
        "Heures prévues": Number(data.planned.toFixed(2)),
        "Heures effectuées": Number(data.actual.toFixed(2)),
        "Revenus (€)": Number(data.revenues.toFixed(2))
      }));

      setAnnualData(formattedData);
      setAnnualTotals({
        plannedHours: totalPlannedHours,
        actualHours: totalActualHours,
        revenues: totalRevenues
      });
    };

    fetchAnnualData();
  }, [client.id, db, selectedYear]);

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    return (end - start) / (1000 * 60 * 60);
  };

  const changeYear = (increment) => {
    setSelectedYear(prevYear => prevYear + increment);
  };

  const formatHours = (hours) => {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours}h${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold">Aperçu annuel</h2>
        <div className="flex items-center">
          <button 
            onClick={() => changeYear(-1)} 
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 mr-2"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="text-lg font-semibold">{selectedYear}</span>
          <button 
            onClick={() => changeYear(1)} 
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 ml-2"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-blue-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Calendar className="mr-2" size={16} />Total annuel
          </h3>
          <p className="text-2xl font-bold text-blue-600">{formatHours(annualTotals.plannedHours)}</p>
          <p className="text-sm text-gray-600">Heures prévues</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Clock className="mr-2" size={16} />Heures effectuées
          </h3>
          <p className="text-2xl font-bold text-green-600">{formatHours(annualTotals.actualHours)}</p>
          <p className="text-sm text-gray-600">Sur {formatHours(annualTotals.plannedHours)} prévues</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <EuroIcon className="mr-2" size={16} />Revenus totaux
          </h3>
          <p className="text-2xl font-bold text-yellow-600">{annualTotals.revenues.toFixed(2)} €</p>
          <p className="text-sm text-gray-600">Pour l'année {selectedYear}</p>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={annualData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="Heures prévues" stroke="#8884d8" />
          <Line yAxisId="left" type="monotone" dataKey="Heures effectuées" stroke="#82ca9d" />
          <Line yAxisId="right" type="monotone" dataKey="Revenus (€)" stroke="#ffc658" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AnnualChart;