import React, { useState, useEffect } from 'react';
import { collection, query, where, orderBy, addDoc, onSnapshot, Timestamp, limit, deleteDoc, doc } from 'firebase/firestore';
import { Bold, Italic, Underline, List, ListOrdered, Link, Undo, Redo, ChevronDown, User, Trash2 } from 'lucide-react';

const ClientNotes = ({ db, client, currentUser, hasPermission }) => {
  const [notes, setNotes] = useState([]);
  const [newNote, setNewNote] = useState('');
  const [visibleNotes, setVisibleNotes] = useState(2);

  useEffect(() => {
    if (!client || !client.id || !hasPermission('write_clients')) return;

    const notesRef = collection(db, 'clientNotes');
    const q = query(
      notesRef,
      where('clientId', '==', client.id),
      orderBy('timestamp', 'desc'),
      limit(50)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedNotes = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setNotes(fetchedNotes);
    });

    return () => unsubscribe();
  }, [db, client, hasPermission]);

  const handleAddNote = async (e) => {
    e.preventDefault();
    if (newNote.trim() === '' || !hasPermission('write_clients')) return;

    try {
      const noteData = {
        clientId: client.id,
        content: newNote,
        employeeId: currentUser.userId,
        employeeName: `${currentUser.firstName} ${currentUser.lastName}`,
        timestamp: Timestamp.now()
      };

      await addDoc(collection(db, 'clientNotes'), noteData);
      setNewNote('');
    } catch (error) {
      console.error("Erreur lors de l'ajout de la note:", error);
    }
  };

  const handleDeleteNote = async (noteId) => {
    if (!hasPermission('write_clients')) return;

    try {
      await deleteDoc(doc(db, 'clientNotes', noteId));
      // La note sera automatiquement supprimée de l'état local grâce à l'écouteur onSnapshot
    } catch (error) {
      console.error("Erreur lors de la suppression de la note:", error);
    }
  };

  const handleShowMore = () => {
    setVisibleNotes(prev => prev + 2);
  };

  if (!hasPermission('write_clients')) {
    return null;
  }

  const displayedNotes = notes.slice(0, visibleNotes);

  return (
    <div className="bg-white rounded-lg shadow p-6">
      <h2 className="text-2xl font-semibold mb-4">Notes pour {client.firstName} {client.lastName}</h2>
      <div className="bg-blue-50 border-l-4 border-blue-400 p-4 mb-4">
        <p className="text-sm text-blue-700">Ces notes ne sont pas visibles aux salariés</p>
      </div>
      
      <div className="mb-6 space-y-4">
        {displayedNotes.map((note) => (
          <div key={note.id} className="bg-gray-50 rounded-lg p-4 shadow relative">
            <div className="flex items-center mb-2">
              <User size={20} className="text-gray-500 mr-2" />
              <p className="text-sm font-semibold text-gray-700">{note.employeeName}</p>
              <p className="text-xs text-gray-500 ml-auto">{note.timestamp.toLocaleString()}</p>
            </div>
            <p className="text-gray-800 pr-8">{note.content}</p>
            <button 
              onClick={() => handleDeleteNote(note.id)}
              className="absolute top-2 right-2 text-red-500 hover:text-red-700"
              title="Supprimer la note"
            >
              <Trash2 size={16} />
            </button>
          </div>
        ))}
      </div>
      
      {notes.length > visibleNotes && (
        <button 
          onClick={handleShowMore}
          className="flex items-center justify-center w-full py-2 mb-4 text-blue-600 bg-blue-100 rounded-md hover:bg-blue-200"
        >
          <ChevronDown size={20} className="mr-2" />
          Voir plus de notes
        </button>
      )}
      
      <form onSubmit={handleAddNote} className="bg-gray-100 rounded-lg p-4">
        <div className="mb-2 flex items-center space-x-2 text-gray-500">
          <button type="button"><Bold size={20} /></button>
          <button type="button"><Italic size={20} /></button>
          <button type="button"><Underline size={20} /></button>
          <button type="button"><List size={20} /></button>
          <button type="button"><ListOrdered size={20} /></button>
          <button type="button"><Link size={20} /></button>
          <button type="button"><Undo size={20} /></button>
          <button type="button"><Redo size={20} /></button>
        </div>
        <textarea
          value={newNote}
          onChange={(e) => setNewNote(e.target.value)}
          placeholder="Écrire un commentaire ou répondre ici."
          className="w-full p-2 border rounded-md bg-white"
          rows="4"
        />
        <div className="flex justify-end items-center mt-2">
          <button 
            type="submit" 
            className="bg-blue-500 text-white px-4 py-2 rounded-md hover:bg-blue-600"
          >
            Commenter
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientNotes;