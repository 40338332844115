import React, { useState } from 'react';
import { getAuth, createUserWithEmailAndPassword, updateProfile } from 'firebase/auth';
import { doc, setDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { useNavigate, Link } from 'react-router-dom';
import { User, Mail, Lock } from 'lucide-react';
import AuthLayout from './AuthLayout';
import { getAuthErrorMessage } from './firebaseAuthErrors';
import { db, auth } from '../firebase';

function Register() {
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [email, setEmail] = useState('');
  const [password, setPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      console.log("Début de l'inscription...");

      // Créer l'utilisateur dans Firebase Auth
      const userCredential = await createUserWithEmailAndPassword(auth, email, password);
      const user = userCredential.user;
      console.log("Utilisateur créé dans Auth:", user.uid);

      // Mettre à jour le profil de l'utilisateur avec son nom complet
      await updateProfile(user, { 
        displayName: `${lastName} ${firstName}`.trim() 
      });
      console.log("Profil utilisateur mis à jour");

      // Récupérer l'ID du rôle 'utilisateur'
      const rolesRef = collection(db, 'roles');
      const q = query(rolesRef, where("name", "==", "Utilisateur"));
      const querySnapshot = await getDocs(q);
      let userRoleId = null;
      if (!querySnapshot.empty) {
        userRoleId = querySnapshot.docs[0].id;
        console.log("ID du rôle utilisateur trouvé:", userRoleId);
      } else {
        console.log("Rôle 'Utilisateur' non trouvé dans la base de données");
      }

      // Créer l'employé dans Firestore
      const employeeData = {
        userId: user.uid,
        firstName,
        lastName,
        email,
        isArchived: false,
        roles: userRoleId ? [userRoleId] : [],
        createdAt: new Date()
      };
      console.log("Données de l'employé à créer:", employeeData);

      await setDoc(doc(db, 'employees', user.uid), employeeData);
      console.log("Employé créé dans Firestore");

      navigate('/dashboard');
    } catch (error) {
      console.error("Erreur d'inscription:", error);  
      setError(getAuthErrorMessage(error.code));
    } finally {
      setIsLoading(false);
    }
  };

    return (
    <AuthLayout title="Inscription">
      <form className="auth-fade-in" onSubmit={handleSubmit}>
        <div className="auth-input-group">
          <label htmlFor="name" className="auth-label">
            Nom
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <User className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="lastName"
              name="lastName"
              type="text"
              required
              className="auth-input pl-10"
              placeholder="Dupont"
              value={lastName}
              onChange={(e) => setLastName(e.target.value)}
            />
          </div>
        </div>

        <div className="auth-input-group">
          <label htmlFor="firstName" className="auth-label">
            Prénom
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <User className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="firstName"
              name="firstName"
              type="text"
              required
              className="auth-input pl-10"
              placeholder="Jean"
              value={firstName}
              onChange={(e) => setFirstName(e.target.value)}
            />
          </div>
        </div>
        
        
        <div className="auth-input-group">
          <label htmlFor="email" className="auth-label">
            Adresse e-mail
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="auth-input pl-10"
              placeholder="vous@exemple.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="auth-input-group">
          <label htmlFor="password" className="auth-label">
            Mot de passe
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="password"
              name="password"
              type="password"
              autoComplete="new-password"
              required
              className="auth-input pl-10"
              placeholder="••••••••"
              value={password}
              onChange={(e) => setPassword(e.target.value)}
            />
          </div>
        </div>

        {error && <div className="auth-error">{error}</div>}

        <button
          type="submit"
          disabled={isLoading}
          className="auth-button"
        >
          {isLoading ? 'Inscription en cours...' : 'S\'inscrire'}
        </button>
      </form>

      <div className="auth-separator">
        <span className="auth-separator-text">Déjà inscrit ?</span>
      </div>

      <div className="mt-6">
        <Link
          to="/login"
          className="auth-button auth-button-secondary"
        >
          Se connecter
        </Link>
      </div>
    </AuthLayout>
  );
}

export default Register;