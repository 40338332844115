import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, addDoc, updateDoc, doc, query, getDocs, Timestamp, getDoc, deleteDoc, writeBatch, where, setDoc } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { X, Clock, Calendar, User, ChevronLeft, ChevronRight, Plus, Copy, Search, Check, XCircle, AlertCircle } from 'lucide-react';
import ClientAutocomplete from './ClientAutocomplete';
import AppointmentDetailsModal from './AppointmentDetailsModal';
import EditAppointmentModal from './EditAppointmentModal'; // Nouvelle import



const MonthView = ({ db, currentDate, currentUser}) => {
  const [monthDates, setMonthDates] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(1); // Default to Monday (1)
  const [showEditModal, setShowEditModal] = useState(false);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [clients, setClients] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [filterText, setFilterText] = useState('');
  const [filterType, setFilterType] = useState('all'); // 'all', 'employee', or 'client'
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedUnassignedAppointment, setSelectedUnassignedAppointment] = useState(null);
  const [selectedInfoAppointment, setSelectedInfoAppointment] = useState(null);
  const [selectedEditAppointment, setSelectedEditAppointment] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDetailAppointment, setSelectedDetailAppointment] = useState(null);
  const [clickTimer, setClickTimer] = useState(null);
  const clickDelay = 200; // Délai en millisecondes pour détecter un double-clic
  const [userPermissions, setUserPermissions] = useState({
    readAppointments: false,
    writeAppointments: false,
    manageRoles: false
  });
  const [userRole, setUserRole] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminRoles, setAdminRoles] = useState([]);

// Fonction pour récupérer les permissions de l'utilisateur
const fetchUserPermissions = useCallback(async () => {
  if (!db || !currentUser) return;
  try {
    const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const userRoleId = userData.role;
      const roleDoc = await getDoc(doc(db, 'roles', userRoleId));
      if (roleDoc.exists()) {
        const roleData = roleDoc.data();
        setUserPermissions({
          readAppointments: roleData.permissions.includes('read_appointments'),
          writeAppointments: roleData.permissions.includes('write_appointments'),
          manageRoles: roleData.permissions.includes('manage_roles')
        });
      }
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des permissions de l'utilisateur:", error);
  }
}, [db, currentUser]);

useEffect(() => {
  fetchUserPermissions();
}, [fetchUserPermissions]);

// Fonction pour vérifier si l'utilisateur a une permission spécifique
const hasPermission = useCallback((permission) => userPermissions[permission], [userPermissions]);

const fetchUserRole = useCallback(async () => {
  if (!db || !currentUser) return;
  try {
    const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const userRoleId = userData.role;
      setUserRole(userRoleId);
      setIsAdmin(adminRoles.includes(userRoleId));
    }
  } catch (error) {
    console.error("Erreur lors de la récupération du rôle de l'utilisateur:", error);
  }
}, [db, currentUser, adminRoles]);

useEffect(() => {
  fetchUserRole();
}, [fetchUserRole]);
  
  const fetchPlanningSettings = useCallback(async () => {
    if (!db) return;
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'planningManagement'));
      if (settingsDoc.exists()) {
        const settings = settingsDoc.data();
        setFirstDayOfWeek(settings.firstDayOfWeek || 1);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des paramètres de planification:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchPlanningSettings();
  }, [fetchPlanningSettings]);

  const updateMonthDates = useCallback((date) => {
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    const dates = [];
    let start = (firstDay.getDay() - firstDayOfWeek + 7) % 7;

    for (let i = 0; i < start; i++) {
      dates.push(null);
    }

    for (let i = 1; i <= lastDay.getDate(); i++) {
      dates.push(new Date(year, month, i));
    }

    while (dates.length % 7 !== 0) {
      dates.push(null);
    }

    setMonthDates(dates);
  }, [firstDayOfWeek]);

  const fetchEmployees = useCallback(async () => {
    if (!db) return;
    try {
      const employeesRef = collection(db, 'employees');
      const q = query(employeesRef, where('isArchived', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map(doc => ({
        userId: doc.id,
        ...doc.data(),
        color: doc.data().color || '#808080'
      }));
      setEmployees(fetchedEmployees);
    } catch (error) {
      console.error("Erreur lors de la récupération des employés:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);

  const handleBookAppointment = async () => {
    if (!selectedUnassignedAppointment || !currentUser) return;

    try {
      const appointmentRef = doc(db, 'appointments', selectedUnassignedAppointment.id);
      await updateDoc(appointmentRef, {
        employeeId: currentUser.userId,
        status: 'reserve'
      });

      fetchAppointments();
      setShowBookingModal(false);
    } catch (error) {
      console.error("Erreur lors de la réservation du rendez-vous:", error);
    }
  };

  const fetchAppointments = useCallback(async () => {
    if (!db) return;
    try {
      const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
      startOfMonth.setHours(0, 0, 0, 0);
      const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
      endOfMonth.setHours(23, 59, 59, 999);
      
      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('date', '>=', Timestamp.fromDate(startOfMonth)),
        where('date', '<=', Timestamp.fromDate(endOfMonth))
      );
      const querySnapshot = await getDocs(q);
      const fetchedAppointments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate() || new Date(),
      }));
    
      const appointmentsWithClientInfo = await Promise.all(fetchedAppointments.map(async appointment => {
        const clientDoc = await getDoc(doc(db, 'clients', appointment.clientId));
        const clientData = clientDoc.data();
        return {
          ...appointment,
          clientName: clientData ? `${clientData.lastName} ${clientData.firstName}` : 'Client non spécifié',
          clientColor: clientData ? clientData.color : '#808080'
        };
      }));
    
      setAppointments(appointmentsWithClientInfo);
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  }, [db, currentDate]);

  // Fonction pour comparer les dates sans tenir compte des heures
  const isSameDay = (date1, date2) => {
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
  };

  useEffect(() => {
    updateMonthDates(currentDate);
    fetchEmployees();
    fetchAppointments();
  }, [currentDate, updateMonthDates, fetchEmployees, fetchAppointments]);

  useEffect(() => {
    const fetchClients = async () => {
      if (!db) return;
      try {
        const clientsSnapshot = await getDocs(collection(db, 'clients'));
        const fetchedClients = clientsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(fetchedClients);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients:", error);
      }
    };

    fetchClients();
  }, [db]);

  const appointmentsWithColors = useMemo(() => {
    return appointments.map(appointment => {
      const employee = employees.find(emp => emp.userId === appointment.employeeId);
      return {
        ...appointment,
        employeeColor: employee?.color || '#808080'
      };
    });
  }, [appointments, employees]);

  const handleCellClick = (date) => {
    if (date) {
      setSelectedDate(date);
      setShowAddModal(true);
    }
  };

  const handleAppointmentClick = (appointment, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (clickTimer) {
      // Double-clic détecté
      clearTimeout(clickTimer);
      setClickTimer(null);
      handleAppointmentDoubleClick(appointment, event);
    } else {
      // Définir un timer pour le simple clic
      const timer = setTimeout(() => {
        handleSingleClick(appointment);
        setClickTimer(null);
      }, clickDelay);
      setClickTimer(timer);
    }
  };

  const handleSingleClick = (appointment) => {
    if (!appointment.employeeId || appointment.status === 'libre') {
      setSelectedUnassignedAppointment(appointment);
      setShowBookingModal(true);
    } else if (appointment.status === 'reserve') {
      setSelectedInfoAppointment(appointment);
      setShowInfoModal(true);
    } else {
      setSelectedDetailAppointment(appointment);
      setShowDetailsModal(true);
    }
  };

  const handleAppointmentDoubleClick = (appointment, event) => {
    if (hasPermission('writeAppointments')) {
      event.preventDefault();
      setSelectedEditAppointment(appointment);
      setShowEditModal(true);
    }
  };

  const handleAppointmentUpdate = (updatedAppointment) => {
    setAppointments(prevAppointments =>
      prevAppointments.map(app =>
        app.id === updatedAppointment.id ? updatedAppointment : app
      )
    );
  };

  const handleAddAppointment = async (appointmentData) => {
    try {
      const newAppointmentRef = doc(collection(db, 'appointments'));
      await setDoc(newAppointmentRef, {
        ...appointmentData,
        date: Timestamp.fromDate(appointmentData.date)
      });
      
      fetchAppointments();
      setShowAddModal(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout du rendez-vous:", error);
    }
  };

  const handleUpdateAppointment = async (updatedAppointment) => {
    if (!hasPermission('writeAppointments')) {
      console.error("Tentative non autorisée de mise à jour de rendez-vous");
      return;
    }
    try {
      const appointmentRef = doc(db, 'appointments', updatedAppointment.id);
      await updateDoc(appointmentRef, {
        ...updatedAppointment,
        date: Timestamp.fromDate(updatedAppointment.date)
      });
  
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rendez-vous:", error);
    }
  };

  const handleDeleteAppointment = async (appointmentId) => {
    try {
      await deleteDoc(doc(db, 'appointments', appointmentId));
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la suppression du rendez-vous:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onDragEnd = useCallback(async (result) => {
    if (!result.destination) return;
  
    const { source, destination, draggableId } = result;
    
    const sourceDate = new Date(parseInt(source.droppableId));
    const destinationDate = new Date(parseInt(destination.droppableId));
    const appointment = appointmentsWithColors.find(app => app.id === draggableId);
    
    if (!appointment) return;
  
    const newDate = new Date(appointment.date);
    newDate.setFullYear(destinationDate.getFullYear());
    newDate.setMonth(destinationDate.getMonth());
    newDate.setDate(destinationDate.getDate());
  
    try {
      if (isCtrlPressed) {
        // Dupliquer le rendez-vous
        const newAppointment = {
          ...appointment,
          date: Timestamp.fromDate(newDate)
        };
        delete newAppointment.id;
        await addDoc(collection(db, 'appointments'), newAppointment);
      } else {
        // Déplacer le rendez-vous
        await updateDoc(doc(db, 'appointments', appointment.id), {
          date: Timestamp.fromDate(newDate)
        });
      }
      fetchAppointments();
    } catch (error) {
      console.error("Erreur lors de la manipulation du rendez-vous:", error);
    }
  }, [appointmentsWithColors, db, isCtrlPressed, fetchAppointments]);

  const getDayNames = () => {
    const dayNames = ['Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi', 'Dimanche'];
    return [...dayNames.slice(firstDayOfWeek - 1), ...dayNames.slice(0, firstDayOfWeek - 1)];
  };

  const appointmentsWithDetails = useMemo(() => {
    return appointments.map(appointment => {
      const employee = employees.find(emp => emp.userId === appointment.employeeId);
      return {
        ...appointment,
        employeeColor: employee?.color || '#808080',
        employeeName: employee ? (employee.name || `${employee.firstName} ${employee.lastName}`) : "Non attribué"
      };
    });
  }, [appointments, employees]);

  const sortAppointmentsByTime = (appointments) => {
    return appointments.sort((a, b) => {
      const aTime = a.startTime.split(':').map(Number);
      const bTime = b.startTime.split(':').map(Number);
      return (aTime[0] * 60 + aTime[1]) - (bTime[0] * 60 + bTime[1]);
    });
  };

  // Filtrer les rendez-vous en fonction des permissions de l'utilisateur
  const filteredAppointments = useMemo(() => {
    // Inclure tous les rendez-vous non assignés (plages à combler) et les rendez-vous de l'utilisateur actuel
    let appointments = appointmentsWithDetails.filter(app => 
      !app.employeeId || 
      app.status === 'libre' || 
      app.employeeId === currentUser.userId ||
      hasPermission('writeAppointments') || 
      isAdmin
    );
  
    // Appliquer le filtre de recherche
    if (filterText.trim() !== '') {
      const lowerCaseFilter = filterText.toLowerCase().trim();
      appointments = appointments.filter(app => 
        app.clientName.toLowerCase().includes(lowerCaseFilter) ||
        app.employeeName.toLowerCase().includes(lowerCaseFilter) ||
        app.startTime.includes(lowerCaseFilter) ||
        app.endTime.includes(lowerCaseFilter) ||
        app.date.toLocaleDateString('fr-FR').includes(lowerCaseFilter)
      );
    }
  
    return appointments;
  }, [appointmentsWithDetails, hasPermission, isAdmin, currentUser, filterText]);

  const getEmployeeName = useCallback((employeeId) => {
    const employee = employees.find(emp => emp.userId === employeeId);
    return employee ? (employee.name || `${employee.firstName} ${employee.lastName}`) : 'Employé non trouvé';
  }, [employees]);


  const renderAppointment = (appointment) => {
    let appointmentClass = 'pv-appointment';
    let backgroundColor, textColor, borderColor, statusText;
  
    if (!appointment.employeeId || appointment.status === 'libre') {
      appointmentClass += ' pv-appointment-libre';
      backgroundColor = '#FEE2E2'; // Rouge très clair
      textColor = '#991B1B'; // Rouge foncé pour le texte
      borderColor = '#EF4444'; // Rouge vif pour la bordure
      statusText = 'Plage à combler';
    } else if (appointment.status === 'reserve') {
      appointmentClass += ' pv-appointment-reserve';
      backgroundColor = '#FDE68A'; // Jaune doux
      textColor = '#92400E'; // Marron
      borderColor = '#FCD34D'; // Jaune légèrement plus foncé pour la bordure
      statusText = 'En attente de validation';
    } else {
      appointmentClass += ' pv-appointment-assigne';
      backgroundColor = lightenColor(appointment.clientColor || '#4B5563', 15);
      textColor = isLightColor(backgroundColor) ? '#1F2937' : '#F9FAFB';
      borderColor = appointment.clientColor || '#4B5563';
      statusText = '';
    }
  
    const employee = employees.find(emp => emp.userId === appointment.employeeId);
    const employeeName = employee ? (employee.name || `${employee.firstName} ${employee.lastName}`) : "Non attribué";
  
    return (
      <div
        className={`${appointmentClass} p-1 mb-1 rounded text-xs shadow-md`}
        style={{ 
          backgroundColor: backgroundColor,
          color: textColor,
          borderLeft: `4px solid ${borderColor}`
        }}
        onClick={(e) => handleAppointmentClick(appointment, e)}
      >
        <div className="font-bold truncate p-1">
          {!appointment.employeeId || appointment.status === 'libre' ? (
            <>
              <AlertCircle size={12} className="inline-block mr-1 text-red-600" />
              <span>Plage à combler</span>
            </>
          ) : (
            <>
              <User size={12} className="inline-block mr-1" />
              {appointment.clientName}
            </>
          )}
        </div>
        <div className="bg-white text-gray-800 p-1 mt-1 rounded">
          <Clock size={12} className="inline-block mr-1" />
          {`${appointment.startTime} - ${appointment.endTime}`}
        </div>
        {(hasPermission('writeAppointments') || isAdmin) && appointment.employeeId && (
          <div className="text-xs mt-1">
            <User size={12} className="inline-block mr-1" />
            {appointment.employeeName}
          </div>
        )}
        {statusText && <div className="text-xs mt-1 font-semibold">{statusText}</div>}
      </div>
    );
  };

  const handleApproveAppointment = async () => {
    if (!selectedInfoAppointment || !hasPermission('writeAppointments')) return;

    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'assigne'
      });

      // Mettre à jour l'état local
      setAppointments(prevAppointments => 
        prevAppointments.map(app => 
          app.id === selectedInfoAppointment.id ? {...app, status: 'assigne'} : app
        )
      );

      setShowInfoModal(false);
      
    } catch (error) {
      console.error("Erreur lors de l'approbation du rendez-vous:", error);
      alert("Une erreur s'est produite lors de l'approbation du rendez-vous");
    }
  };

  const handleRejectAppointment = async () => {
    if (!selectedInfoAppointment || !hasPermission('writeAppointments')) return;

    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'libre',
        employeeId: null
      });

      fetchAppointments();
      setShowInfoModal(false);
    } catch (error) {
      console.error("Erreur lors du refus du rendez-vous:", error);
      alert("Une erreur s'est produite lors du refus du rendez-vous");
    }
  };

  const handleCancelReservation = async () => {
    if (!selectedInfoAppointment || selectedInfoAppointment.employeeId !== currentUser.userId) return;

    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'libre',
        employeeId: null
      });

      fetchAppointments();
      setShowInfoModal(false);
    } catch (error) {
      console.error("Erreur lors de l'annulation de la réservation:", error);
      alert("Une erreur s'est produite lors de l'annulation de la réservation");
    }
  };


  return (
    <div className="month-view">
      <div className="filter-bar flex items-center justify-between mb-4">
        <div className="flex items-center space-x-2">
          <div className="relative">
            <input
              type="text"
              placeholder="Employé, Client, Heure"
              className="pl-8 pr-2 py-1 border-b border-gray-300 focus:border-blue-500 focus:outline-none text-sm bg-transparent"
              value={filterText}
              onChange={(e) => setFilterText(e.target.value)}
            />
            <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
          </div>
          
        </div>
        <div className="text-sm text-gray-500">
          {currentDate.toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}
        </div>
      </div>

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="month-grid grid grid-cols-7 gap-1 sticky-header">
          {getDayNames().map(day => (
            <div key={day} className="day-header font-bold text-center p-2 sticky-header">{day}</div>
          ))}
          {monthDates.map((date, index) => (
            <Droppable key={date ? date.getTime() : `empty-${index}`} droppableId={date ? date.getTime().toString() : `empty-${index}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`day-cell border p-1 ${date ? (date.getMonth() === currentDate.getMonth() ? 'bg-white' : 'bg-gray-100') : 'bg-gray-200'} 
                              ${snapshot.isDraggingOver ? 'bg-blue-100' : ''} min-h-[120px] relative`}
                  onClick={() => date && hasPermission('writeAppointments') && handleCellClick(date)}
                >
                  {date && (
                    <>
                      <div className={`date-number absolute top-1 left-1 ${date.getMonth() === currentDate.getMonth() ? 'text-gray-700' : 'text-gray-400'}`}>
                        {date.getDate()}
                      </div>
                      <div className="appointments-container mt-6">
                    {sortAppointmentsByTime(filteredAppointments.filter(app => isSameDay(app.date, date)))
                      .map((appointment, appIndex) => (
                        <Draggable 
                          key={appointment.id} 
                          draggableId={appointment.id} 
                          index={appIndex}
                          isDragDisabled={!hasPermission('writeAppointments')}
                        >
                          {(provided, snapshot) => (
                            <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`${snapshot.isDragging ? 'opacity-50' : ''} 
                                        ${hasPermission('writeAppointments') && isCtrlPressed ? 'cursor-copy' : 'cursor-move'}`}
                            style={provided.draggableProps.style}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAppointmentClick(appointment);
                            }}
                            onDoubleClick={(e) => handleAppointmentDoubleClick(appointment, e)}
                          >
                            {renderAppointment(appointment)}
                            {hasPermission('writeAppointments') && isCtrlPressed && snapshot.isDragging && (
                              <Copy size={12} className="absolute top-0 right-0" />
                            )}
                          </div>
                              )}
                            </Draggable>
                          ))}
                      </div>
                    </>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>

      {/* Modales conditionnelles basées sur les permissions */}
      {showAddModal && hasPermission('writeAppointments') && (
        <Modal onClose={() => setShowAddModal(false)}>
          <AddAppointmentModal
            date={selectedDate}
            employees={employees}
            clients={clients}
            onSave={handleAddAppointment}
            onClose={() => setShowAddModal(false)}
          />
        </Modal>
      )}

{showBookingModal && selectedUnassignedAppointment && (
      <Modal onClose={() => setShowBookingModal(false)}>
        <div className="booking-modal p-4">
          <h2 className="text-xl font-bold mb-4">Réserver ce rendez-vous</h2>
          <p>Voulez-vous réserver ce rendez-vous ?</p>
          <p>Date : {selectedUnassignedAppointment.date.toLocaleDateString()}</p>
          <p>Heure : {selectedUnassignedAppointment.startTime} - {selectedUnassignedAppointment.endTime}</p>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={() => setShowBookingModal(false)}
              className="pv-button-action pv-button-cancel-gray"
            >
              Annuler
            </button>
            <button
              onClick={handleBookAppointment}
              className="pv-button-action pv-button-book"
            >
              Réserver
            </button>
          </div>
        </div>
      </Modal>
      )}

{showInfoModal && selectedInfoAppointment && (
      <Modal onClose={() => setShowInfoModal(false)}>
        <div className="info-modal p-4">
          <h2 className="text-xl font-bold mb-4">Détails du rendez-vous</h2>
          <p>Client : {selectedInfoAppointment.clientName}</p>
          <p>Date : {selectedInfoAppointment.date.toLocaleDateString()}</p>
          <p>Heure : {selectedInfoAppointment.startTime} - {selectedInfoAppointment.endTime}</p>
          <p>Employé : {getEmployeeName(selectedInfoAppointment.employeeId)}</p>
          <p>Statut : {selectedInfoAppointment.status === 'reserve' ? 'En attente de validation' : selectedInfoAppointment.status}</p>
          
          <div className="mt-4 flex justify-end space-x-2">
            {selectedInfoAppointment.status === 'reserve' && (
              <>
                {hasPermission('writeAppointments') && (
                  <>
                    <button
                      onClick={handleApproveAppointment}
                      className="pv-button-action pv-button-approve"
                    >
                      <Check className="mr-1" size={12} />
                      Approuver
                    </button>
                    <button
                      onClick={handleRejectAppointment}
                      className="pv-button-action pv-button-reject"
                    >
                      <XCircle className="mr-1" size={12} />
                      Refuser
                    </button>
                  </>
                )}
                {selectedInfoAppointment.employeeId === currentUser.userId && (
                  <button
                    onClick={handleCancelReservation}
                    className="pv-button-action pv-button-cancel"
                  >
                    <X className="mr-1" size={12} />
                    Annuler
                  </button>
                )}
              </>
            )}
            <button
              onClick={() => setShowInfoModal(false)}
              className="pv-button-action pv-button-gray"
            >
              Fermer
            </button>
          </div>
        </div>
      </Modal>
      )}

{showEditModal && selectedEditAppointment && hasPermission('writeAppointments') && (
        <Modal onClose={() => setShowEditModal(false)}>
          <EditAppointmentModal
            appointment={selectedEditAppointment}
            employees={employees}
            clients={clients}
            onSave={handleUpdateAppointment}
            onClose={() => setShowEditModal(false)}
            onDelete={handleDeleteAppointment}
          />
        </Modal>
)}
{showDetailsModal && selectedDetailAppointment && (
        <AppointmentDetailsModal
          appointment={selectedDetailAppointment}
          onClose={() => setShowDetailsModal(false)}
          onUpdate={handleAppointmentUpdate}
          db={db}
        />
      )}
    </div>
  );
};

// Fonction utilitaire pour assombrir une couleur
const darkenColor = (color, percent) => {
  const num = parseInt(color.replace("#",""), 16),
    amt = Math.round(2.55 * percent),
    R = (num >> 16) - amt,
    G = (num >> 8 & 0x00FF) - amt,
    B = (num & 0x0000FF) - amt;
  return "#" + (0x1000000 + (R<255?R<1?0:R:255)*0x10000 + (G<255?G<1?0:G:255)*0x100 + (B<255?B<1?0:B:255)).toString(16).slice(1);
};

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

// Fonction utilitaire pour éclaircir une couleur
const lightenColor = (color, percent) => {
  const num = parseInt(color.replace("#",""), 16),
    amt = Math.round(2.55 * percent),
    R = Math.min((num >> 16) + amt, 255),
    G = Math.min((num >> 8 & 0x00FF) + amt, 255),
    B = Math.min((num & 0x0000FF) + amt, 255);
  return "#" + (0x1000000 + R*0x10000 + G*0x100 + B).toString(16).slice(1);
};

// Fonction utilitaire pour déterminer si une couleur est claire ou foncée
const isLightColor = (color) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness > 155;
};

const AddAppointmentModal = ({ date, employees, clients, onSave, onClose }) => {
  const [appointmentData, setAppointmentData] = useState({
    date: date,
    startTime: '',
    endTime: '',
    employeeId: '',
    clientId: '',
    clientName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData(prev => ({ ...prev, [name]: value }));
  };

  const handleClientChange = (clientId, clientName) => {
    setAppointmentData(prev => ({ ...prev, clientId, clientName }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(appointmentData);
  };

  return (
    <div className="modal bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
      <h2 className="text-2xl font-bold mb-4">Ajouter un rendez-vous</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Calendar className="text-gray-400" size={20} />
          <input 
            type="date" 
            name="date" 
            value={appointmentData.date.toISOString().split('T')[0]} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="text-gray-400" size={20} />
          <input 
            type="time" 
            name="startTime" 
            value={appointmentData.startTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span>à</span>
          <input 
            type="time" 
            name="endTime" 
            value={appointmentData.endTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <select 
            name="employeeId" 
            value={appointmentData.employeeId} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Sélectionner un employé</option>
            {employees.map(emp => (
              <option key={emp.userId} value={emp.userId}>
                {emp.name || `${emp.firstName} ${emp.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <ClientAutocomplete
            clients={clients}
            value={appointmentData.clientName}
            onChange={handleClientChange}
          />
        </div>
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            type="button" 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
};


export default MonthView;