import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, getDoc, query, where,} from 'firebase/firestore';
import { SketchPicker } from 'react-color'; // Import du color picker de react-color
import { User, Edit, Archive, Trash, Plus, Search, ExternalLink, FileText, ChevronDown, ChevronUp, Wifi } from 'lucide-react';
import ClientDetails from './ClientDetails';
import NFCModal from './NFCModal'; // Import du modal NFC

const extendedColorPalette = [
  // Ajout d'une palette plus grande
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581',
  '#FFD54F', '#7986CB', '#9575CD', '#4DB6AC', '#FFF176', '#FF8A65', '#A1887F',
  '#90A4AE', '#FFAB91', '#F48FB1', '#81C784', '#64B5F6', '#BA68C8', '#4DD0E1',
  '#DCE775', '#FFB74D', '#E57373', '#F06292', '#BA68C8', '#9575CD', '#7986CB',
  '#64B5F6', '#4FC3F7', '#4DD0E1', '#4DB6AC', '#81C784', '#AED581', '#DCE775',
  '#FFF176', '#FFD54F', '#FFB74D', '#FF8A65', '#A1887F', '#90A4AE', '#D1C4E9',
  '#B39DDB', '#9575CD', '#7E57C2', '#673AB7', '#5C6BC0', '#3F51B5', '#3949AB',
  '#303F9F', '#283593', '#1A237E', '#1976D2', '#1565C0', '#0D47A1', '#0277BD',
  '#01579B', '#00838F', '#006064', '#004D40', '#00695C', '#004C3F', '#00BFA5',
  '#00ACC1', '#0097A7', '#00838F', '#006064', '#FF6B6B', '#4ECDC4', '#45B7D1',
  '#FFD54F', '#7986CB', '#9575CD', '#4DB6AC', '#FFF176', '#FF8A65', '#A1887F',
  '#90A4AE', '#FFAB91', '#F48FB1', '#81C784', '#64B5F6', '#BA68C8', '#4DD0E1',
];

const ClientManagement = ({ db, setCurrentView, setSelectedClient: setSelectedClientFromProps, currentUser }) => {
  const [clients, setClients] = useState([]);
  const [selectedClient, setSelectedClient] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [filter, setFilter] = useState('active');
  const [searchTerm, setSearchTerm] = useState('');
  const [usedColors, setUsedColors] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [selectedClientForDetails, setSelectedClientForDetails] = useState(null);
  const [showHeader, setShowHeader] = useState(true);
  const [clientForNfc, setClientForNfc] = useState(null); // Le client à configurer avec NFC
  const [showNfcModal, setShowNfcModal] = useState(false); // État pour ouvrir/fermer le modal
  const [userPermissions, setUserPermissions] = useState({});



  const fetchUserPermissions = useCallback(async () => {
    if (!db || !currentUser) return;
    try {
      const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRoleId = userData.role;
        const roleDoc = await getDoc(doc(db, 'roles', userRoleId));
        if (roleDoc.exists()) {
          const roleData = roleDoc.data();
          setUserPermissions(roleData.permissions.reduce((acc, perm) => {
            acc[perm] = true;
            return acc;
          }, {}));
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des permissions de l'utilisateur:", error);
    }
  }, [db, currentUser]);

  useEffect(() => {
    fetchUserPermissions();
  }, [fetchUserPermissions]);

  const hasPermission = (permission) => !!userPermissions[permission];


  const canModifyClients = userPermissions.write_clients;
  

  const handleConfigureNFC = (e, client) => {
    e.stopPropagation();
    setClientForNfc(client);
    setShowNfcModal(true);
  };

  const handleNFCSubmit = async (nfcData) => {
    if (!clientForNfc) return;
    
    try {
      await updateDoc(doc(db, "clients", clientForNfc.id), { 
        nfcTag: nfcData,
      tagSerialNumber: nfcData.tagSerialNumber // Ajouter le numéro de série à la collection du client
    });
      console.log("Tag NFC configuré pour le client:", clientForNfc.id);
      setShowNfcModal(false);
      fetchClients(); // Rafraîchir la liste des clients
    } catch (error) {
      console.error("Erreur lors de la configuration du tag NFC:", error);
    }
  };
 
  const handleOpenClientDetails = (client) => {
    setSelectedClientFromProps(client);
    setCurrentView('clientSummary');
  };

  const handleCloseClientDetails = () => {
    setSelectedClientForDetails(null);
    setShowHeader(true); // Réaffiche l'en-tête à la fermeture des détails
    fetchClients(); // Pour s'assurer que les données du client sont à jour après la fermeture
  };

  const toggleHeader = () => {
    if (!selectedClientForDetails) {
      setShowHeader(!showHeader);
    }
  };

  const fetchClients = useCallback(async () => {
    setIsLoading(true);
    try {
      // Collection des clients
      const clientsCollection = collection(db, 'clients');
      const clientsSnapshot = await getDocs(clientsCollection);
      let clientsList = clientsSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data()
      }));
  
      // Si l'utilisateur n'a pas les droits de modification des clients
      if (!canModifyClients) {
        const today = new Date();
        const startOfMonth = new Date(today.getFullYear(), today.getMonth(), 1);
        const endOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0);
  
        // Récupération des rendez-vous pour le mois en cours
        const appointmentsRef = collection(db, 'appointments');
        const appointmentsQuery = query(
          appointmentsRef,
          where('employeeId', '==', currentUser.id),
          where('date', '>=', startOfMonth),
          where('date', '<=', endOfMonth)
        );
  
        const appointmentsSnapshot = await getDocs(appointmentsQuery);
        
        // Création d'un Set des IDs clients uniques
        const authorizedClientIds = new Set();
        appointmentsSnapshot.docs.forEach(doc => {
          const appointment = doc.data();
          if (appointment.clientId) {
            authorizedClientIds.add(appointment.clientId);
          }
        });
  
        // Filtrage de la liste des clients
        clientsList = clientsList.filter(client => authorizedClientIds.has(client.id));
      }
  
      // Mise à jour de l'état
      setClients(clientsList);
    } catch (error) {
      console.error("Erreur lors du chargement des clients:", error);
    } finally {
      setIsLoading(false);
    }
  }, [db, currentUser, canModifyClients]);

  useEffect(() => {
    fetchClients();
  }, [fetchClients]);

  useEffect(() => {
    const colors = clients.map(client => client.color);
    setUsedColors(colors);
  }, [clients]);

  const addClient = async (clientData) => {
    try {
      await addDoc(collection(db, "clients"), clientData);
      await fetchClients();
    } catch (err) {
      console.error("Erreur lors de l'ajout du client:", err);
    }
  };

  const updateClient = async (clientId, clientData) => {
    try {
      await updateDoc(doc(db, "clients", clientId), clientData);
      await fetchClients();
    } catch (err) {
      console.error("Erreur lors de la mise à jour du client:", err);
    }
  };

  const toggleArchiveClient = async (client) => {
    try {
      const newStatus = !client.isActive;
      await updateDoc(doc(db, "clients", client.id), { isActive: newStatus });
      
      // Mettre à jour l'état local immédiatement
      setClients(prevClients => 
        prevClients.map(c => 
          c.id === client.id ? {...c, isActive: newStatus} : c
        )
      );
    } catch (err) {
      console.error("Erreur lors de l'archivage/désarchivage du client:", err);
    }
  };

  
  const ClientForm = ({ client, onSave, onCancel }) => {
    const [clientData, setClientData] = useState(client || {
      firstName: '',
      lastName: '',
      email: '',
      phoneNumber: '',
      address: '',
      addressNumber: '',
      birthDate: '', // Ajout du champ birthDate
      isActive: true,
      color: ''
    });
  
    const [colorPickerVisible, setColorPickerVisible] = useState(false);

  // Filtrer les couleurs disponibles en excluant les couleurs déjà utilisées par d'autres clients
  const availableColors = extendedColorPalette.filter(color => !usedColors.includes(color));

  useEffect(() => {
    if (!clientData.color && availableColors.length > 0) {
      // Si aucune couleur n'est définie et des couleurs sont disponibles, en choisir une par défaut
      setClientData(prev => ({ ...prev, color: availableColors[0] }));
    }
  }, [availableColors, clientData.color]);

  const handleColorChange = (color) => {
    setClientData(prev => ({ ...prev, color: color.hex }));
  };

    const handleInputChange = (e) => {
      const { name, value } = e.target;
      setClientData(prev => ({ ...prev, [name]: value }));
    };
  
    const handleSave = async () => {
      if (client) {
        await updateClient(client.id, clientData);
      } else {
        await addClient(clientData);
      }
      onSave();
    };

    return (
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-teal-700 mb-6">
          {client ? 'Modifier le client' : 'Ajouter un client'}
        </h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Prénom</label>
              <input
                type="text"
                name="firstName"
                value={clientData.firstName}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Nom</label>
              <input
                type="text"
                name="lastName"
                value={clientData.lastName}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={clientData.email}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Numéro de téléphone</label>
              <input
                type="tel"
                name="phoneNumber"
                value={clientData.phoneNumber}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
          </div>
          <div>
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Date de naissance</label>
            <input
              type="date"
              name="birthDate"
              value={clientData.birthDate}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            />
          </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Adresse</label>
              <input
                type="text"
                name="address"
                value={clientData.address}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Numéro d'appartement</label>
              <input
                type="text"
                name="addressNumber"
                value={clientData.addressNumber}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Couleur</label>
            <div className="flex items-center mt-1">
              <div
                className="w-10 h-10 rounded-full mr-2"
                style={{ backgroundColor: clientData.color }}
                onClick={() => setColorPickerVisible(!colorPickerVisible)} // Toggle de la visibilité du sélecteur de couleurs
              ></div>
            </div>
            {/* Afficher le color picker seulement si disponible */}
            {colorPickerVisible && (
              <SketchPicker
                color={clientData.color}
                colors={availableColors} // Utilisation des couleurs disponibles filtrées
                onChange={handleColorChange}
              />
            )}
          </div>
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button onClick={onCancel} className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50">
            Annuler
          </button>
          <button onClick={handleSave} className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
            Sauvegarder
          </button>
        </div>
      </div>
    );
  };

  // Ajout de la fonction de calcul d'âge en haut du composant
  const calculateAge = (birthDate) => {
    if (!birthDate) return null;
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  const isLightColor = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness > 155;
  };

  const sortClients = (a, b) => {
    if (a.isActive !== b.isActive) {
      return a.isActive ? -1 : 1;
    }
    return `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`);
  };

  const filteredAndSortedClients = useMemo(() => {
    return clients
      .filter(client => 
        (filter === 'active' ? client.isActive : !client.isActive) &&
        (client.firstName.toLowerCase().includes(searchTerm.toLowerCase()) ||
         client.lastName.toLowerCase().includes(searchTerm.toLowerCase()) ||
         client.address.toLowerCase().includes(searchTerm.toLowerCase()))
      )
      .sort((a, b) => {
        if (a.isActive !== b.isActive) return a.isActive ? -1 : 1;
        return `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`);
      });
  }, [clients, filter, searchTerm]);

  if (isLoading) {
    return <div className="flex justify-center items-center h-full">Chargement...</div>;
  }
  

  return (
    <div className="bg-white p-6 rounded-lg shadow-md relative">
      {!selectedClientForDetails && (
        <button 
          onClick={toggleHeader}
          className="absolute top-2 right-2 p-2 bg-gray-200 rounded-full hover:bg-gray-300 transition-colors z-10"
          aria-label={showHeader ? "Masquer l'en-tête" : "Afficher l'en-tête"}
        >
          {showHeader ? <ChevronUp size={20} /> : <ChevronDown size={20} />}
        </button>
      )}

      <div className={`transition-all duration-300 ease-in-out ${showHeader && !selectedClientForDetails ? 'max-h-[1000px] opacity-100' : 'max-h-0 opacity-0 overflow-hidden'}`}>
        <h2 className="text-2xl font-bold mb-4">Gestion des bénéficiaires</h2>
        <div className="mb-6 flex justify-between items-center">
          <div>
            <button 
              className={`mr-4 ${filter === 'active' ? 'text-teal-700 font-bold' : 'text-gray-500'}`}
              onClick={() => setFilter('active')}
            >
              Actifs ({clients.filter(c => c.isActive).length})
            </button>
            <button 
              className={filter === 'archived' ? 'text-teal-700 font-bold' : 'text-gray-500'}
              onClick={() => setFilter('archived')}
            >
              Archivés ({clients.filter(c => !c.isActive).length})
            </button>
          </div>
          {canModifyClients && (
            <button 
            onClick={() => { setSelectedClient(null); setShowForm(true); }}
            className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            <Plus size={20} className="inline mr-2" />
            Ajouter un bénéficiaire
          </button>
          )}
        </div>
        
        <div className="mb-4 relative">
          <input
            type="text"
            placeholder="Rechercher un bénéficiaire..."
            value={searchTerm}
            onChange={(e) => setSearchTerm(e.target.value)}
            className="w-full p-2 pl-10 border rounded"
          />
          <Search className="absolute left-3 top-2.5 text-gray-400" size={20} />
        </div>
      </div>

      {!showForm && (
        <ul className="space-y-2">
          {filteredAndSortedClients.map(client => (
            <li 
            key={client.id} 
            className="bg-white p-4 rounded-lg shadow flex justify-between items-center cursor-pointer hover:bg-gray-50"
            onClick={() => handleOpenClientDetails(client)}
          >
            <div className="flex items-center flex-grow">
              <div 
                className="w-10 h-10 rounded-full mr-4 flex-shrink-0" 
                style={{ backgroundColor: client.color }}
              ></div>
              <div>
                <p className="font-semibold">
                  {client.lastName} {client.firstName}
                  {client.birthDate && (
                    <span className="text-xs text-gray-400 ml-2">
                      {calculateAge(client.birthDate)} ans
                    </span>
                  )}
                </p>
                <p className="text-sm text-gray-600">{client.email}</p>
                <p className="text-sm text-gray-600">{client.address} {client.addressNumber}</p>
              </div>
            </div>
              {canModifyClients && (
                <div className="flex items-center space-x-2" onClick={(e) => e.stopPropagation()}>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      setSelectedClient(client);
                      setShowForm(true);
                    }}
                    className="p-2 text-blue-500 hover:bg-blue-100 rounded-full transition-colors"
                    title="Modifier"
                  >
                    <Edit size={20} />
                  </button>
                  <button 
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleArchiveClient(client);
                    }}
                    className="p-2 text-yellow-500 hover:bg-yellow-100 rounded-full transition-colors"
                    title={client.isActive ? "Archiver" : "Désarchiver"}
                  >
                    <Archive size={20} />
                  </button>
                  <button 
                    onClick={(e) => handleConfigureNFC(e, client)}
                    className="p-2 text-green-500 hover:bg-green-100 rounded-full transition-colors"
                    title="Configurer un tag NFC"
                  >
                    <Wifi size={20} />
                  </button>
                </div>
              )}
            </li>
          ))}
        </ul>
      )}
      

      {/* Modal NFC */}
      {showNfcModal && canModifyClients && (
        <NFCModal 
          client={clientForNfc}
          onClose={() => setShowNfcModal(false)}
          onSubmit={handleNFCSubmit}
        />
      )}
      {showForm && canModifyClients && (
        <ClientForm 
          client={selectedClient}
          usedColors={clients.map(c => c.color)}
          onSave={(clientData) => {
            if (selectedClient) {
              updateClient(selectedClient.id, clientData);
            } else {
              addClient(clientData);
            }
            setShowForm(false);
            setSelectedClient(null);
            fetchClients();
          }}
          onCancel={() => {
            setShowForm(false);
            setSelectedClient(null);
          }}
        />
      )}
    </div>
  );
};

export default ClientManagement;