import React, { useState } from 'react';
import { getAuth, updatePassword } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { db } from '../firebase';
import AuthLayout from './AuthLayout';

function ResetPassword() {
  const [newPassword, setNewPassword] = useState('');
  const [confirmPassword, setConfirmPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    if (newPassword !== confirmPassword) {
      setError("Les mots de passe ne correspondent pas.");
      return;
    }
    setError(null);
    setIsLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      await updatePassword(user, newPassword);
      await updateDoc(doc(db, 'employees', user.uid), {
        needPasswordReset: false
      });
      navigate('/dashboard');
    } catch (error) {
      console.error("Erreur lors de la réinitialisation du mot de passe:", error);
      setError("Une erreur est survenue lors de la réinitialisation du mot de passe.");
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout title="Réinitialisation du mot de passe">
      <form className="auth-fade-in" onSubmit={handleSubmit}>
        <div className="auth-input-group">
          <label htmlFor="newPassword" className="auth-label">Nouveau mot de passe</label>
          <input
            type="password"
            id="newPassword"
            value={newPassword}
            onChange={(e) => setNewPassword(e.target.value)}
            required
            className="auth-input"
          />
        </div>
        <div className="auth-input-group">
          <label htmlFor="confirmPassword" className="auth-label">Confirmer le mot de passe</label>
          <input
            type="password"
            id="confirmPassword"
            value={confirmPassword}
            onChange={(e) => setConfirmPassword(e.target.value)}
            required
            className="auth-input"
          />
        </div>
        {error && <div className="auth-error">{error}</div>}
        <button type="submit" disabled={isLoading} className="auth-button">
          {isLoading ? 'Réinitialisation...' : 'Réinitialiser le mot de passe'}
        </button>
      </form>
    </AuthLayout>
  );
}

export default ResetPassword;