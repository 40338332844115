import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs, addDoc, updateDoc, Timestamp, doc, writeBatch } from 'firebase/firestore';
import { Plus, Calendar, Check, X, Filter, Search } from 'lucide-react';

// Composant FilterBar séparé
const FilterBar = ({ filters, setFilters, employees }) => (
  <div className="bg-white p-3 md:p-4 rounded-lg shadow mb-4">
    <div className="grid grid-cols-1 lg:grid-cols-4 gap-3 md:gap-4">
      <div className="col-span-1">
        <label className="block text-sm font-medium text-gray-700 mb-1">Statut</label>
        <select
          value={filters.status}
          onChange={(e) => setFilters(prev => ({ ...prev, status: e.target.value }))}
          className="w-full border rounded p-2.5 text-sm focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">Tous les statuts</option>
          <option value="pending">En attente</option>
          <option value="approved">Approuvé</option>
          <option value="rejected">Refusé</option>
        </select>
      </div>

      <div className="col-span-1">
        <label className="block text-sm font-medium text-gray-700 mb-1">Employé</label>
        <select
          value={filters.employeeId}
          onChange={(e) => setFilters(prev => ({ ...prev, employeeId: e.target.value }))}
          className="w-full border rounded p-2.5 text-sm focus:ring-2 focus:ring-blue-500"
        >
          <option value="all">Tous les employés</option>
          {employees.map(emp => (
            <option key={emp.id} value={emp.id}>
              {emp.firstName} {emp.lastName}
            </option>
          ))}
        </select>
      </div>

      <div className="col-span-1 lg:col-span-2">
        <label className="block text-sm font-medium text-gray-700 mb-1">Période</label>
        <div className="grid grid-cols-2 gap-2">
          <input
            type="date"
            value={filters.startDate}
            onChange={(e) => setFilters(prev => ({ ...prev, startDate: e.target.value }))}
            className="w-full border rounded p-2.5 text-sm focus:ring-2 focus:ring-blue-500"
          />
          <input
            type="date"
            value={filters.endDate}
            onChange={(e) => setFilters(prev => ({ ...prev, endDate: e.target.value }))}
            className="w-full border rounded p-2.5 text-sm focus:ring-2 focus:ring-blue-500"
          />
        </div>
      </div>
    </div>
    
    <div className="mt-3 md:mt-4">
      <div className="relative">
        <input
          type="text"
          value={filters.searchTerm}
          onChange={(e) => setFilters(prev => ({ ...prev, searchTerm: e.target.value }))}
          placeholder="Rechercher..."
          className="w-full border rounded p-2.5 pl-10 text-sm focus:ring-2 focus:ring-blue-500"
        />
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={18} />
      </div>
    </div>
  </div>
);

function LeaveManagement({ db, currentUser, hasPermission }) {
  const [leaves, setLeaves] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [viewMode, setViewMode] = useState('my-leaves');
  const [filters, setFilters] = useState({
    status: 'all',
    startDate: '',
    endDate: '',
    searchTerm: '',
    employeeId: 'all'
  });
  const [employees, setEmployees] = useState([]);
  const [newLeave, setNewLeave] = useState({
    startDate: '',
    endDate: '',
    reason: '',
    status: 'pending'
  });

  const isAdmin = hasPermission('write_appointments') && hasPermission('write_employees');

  useEffect(() => {
    if (isAdmin) {
      const fetchEmployees = async () => {
        const employeesSnapshot = await getDocs(collection(db, 'employees'));
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEmployees(employeesList);
      };
      fetchEmployees();
    }
  }, [isAdmin, db]);

  const fetchLeaves = useCallback(async () => {
    if (!db || !currentUser?.userId) return;

    const leavesRef = collection(db, 'leaves');
    let q = leavesRef;

    if (viewMode === 'my-leaves') {
      q = query(q, where('employeeId', '==', currentUser.userId));
    } else if (viewMode === 'all-leaves') {
      q = query(q, where('status', '==', 'pending'));
    }

    if (filters.status !== 'all') {
      q = query(q, where('status', '==', filters.status));
    }
    if (filters.employeeId !== 'all') {
      q = query(q, where('employeeId', '==', filters.employeeId));
    }

    const querySnapshot = await getDocs(q);
    let fetchedLeaves = querySnapshot.docs.map(doc => ({
      id: doc.id,
      ...doc.data(),
      startDate: doc.data().startDate.toDate(),
      endDate: doc.data().endDate.toDate()
    }));

    if (filters.startDate) {
      fetchedLeaves = fetchedLeaves.filter(leave => 
        leave.startDate >= new Date(filters.startDate)
      );
    }
    if (filters.endDate) {
      fetchedLeaves = fetchedLeaves.filter(leave => 
        leave.endDate <= new Date(filters.endDate)
      );
    }
    if (filters.searchTerm) {
      const searchLower = filters.searchTerm.toLowerCase();
      fetchedLeaves = fetchedLeaves.filter(leave => 
        leave.employeeName?.toLowerCase().includes(searchLower) ||
        leave.reason?.toLowerCase().includes(searchLower)
      );
    }

    setLeaves(fetchedLeaves);
  }, [db, currentUser, viewMode, filters]);

  useEffect(() => {
    fetchLeaves();
  }, [fetchLeaves]);

  const updateAppointmentsForLeave = async (employeeId, startDate, endDate) => {
    const appointmentsRef = collection(db, 'appointments');
    const q = query(
      appointmentsRef,
      where('employeeId', '==', employeeId),
      where('date', '>=', Timestamp.fromDate(startDate)),
      where('date', '<=', Timestamp.fromDate(endDate))
    );

    const querySnapshot = await getDocs(q);
    const batch = writeBatch(db);

    querySnapshot.docs.forEach((appointmentDoc) => {
      batch.update(doc(db, 'appointments', appointmentDoc.id), {
        employeeId: null,
        status: 'libre'
      });
    });

    await batch.commit();
  };

  const handleLeaveResponse = async (leaveId, employeeId, startDate, endDate, approved) => {
    try {
        const leaveRef = doc(db, 'leaves', leaveId);
        await updateDoc(leaveRef, {
            status: approved ? 'approved' : 'rejected',
            responseDate: Timestamp.now(),
            reviewedBy: {
                id: currentUser.userId,
                name: `${currentUser.firstName} ${currentUser.lastName}`,
                timestamp: Timestamp.now()
            }
        });

        if (approved) {
            await updateAppointmentsForLeave(employeeId, startDate, endDate);
        }

        await fetchLeaves();
    } catch (error) {
        console.error("Erreur lors du traitement de la demande:", error);
    }
};

const handleSubmit = async (e) => {
  e.preventDefault();
  try {
      // Conversion des dates en UTC
      const startDateTime = new Date(newLeave.startDate);
      startDateTime.setHours(0, 0, 0, 0);
      
      const endDateTime = new Date(newLeave.endDate);
      endDateTime.setHours(23, 59, 59, 999);

      const leaveData = {
          ...newLeave,
          employeeId: currentUser.userId,
          employeeName: `${currentUser.firstName} ${currentUser.lastName}`,
          startDate: Timestamp.fromDate(startDateTime),
          endDate: Timestamp.fromDate(endDateTime),
          status: 'pending',
          createdAt: Timestamp.now()
      };

      // Validation des dates
      const startTimestamp = startDateTime.getTime();
      const endTimestamp = endDateTime.getTime();
      if (startTimestamp > endTimestamp) {
          alert("La date de fin doit être égale ou postérieure à la date de début");
          return;
      }

      await addDoc(collection(db, 'leaves'), leaveData);
      setShowAddModal(false);
      setNewLeave({ startDate: '', endDate: '', reason: '', status: 'pending' });
      fetchLeaves();
  } catch (error) {
      console.error("Erreur lors de l'ajout du congé:", error);
  }
};

  return (
    <div className="w-full max-w-6xl mx-auto px-3 md:px-6 py-4 md:py-6">
      <div className="flex flex-col md:flex-row md:items-center justify-between gap-4 mb-6">
        <div className="flex flex-col md:flex-row md:items-center gap-4">
          <h1 className="text-xl md:text-2xl font-bold text-gray-800">Gestion des Congés</h1>
          {isAdmin && (
            <select
              value={viewMode}
              onChange={(e) => setViewMode(e.target.value)}
              className="w-full md:w-auto border rounded px-3 py-2 focus:ring-2 focus:ring-blue-500"
            >
              <option value="my-leaves">Mes congés</option>
              <option value="all-leaves">Demandes en attente</option>
              <option value="history">Historique complet</option>
            </select>
          )}
        </div>
        
        <button
          onClick={() => setShowAddModal(true)}
          className="w-full md:w-auto flex items-center justify-center px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 transition-colors"
        >
          <Plus size={20} className="mr-2" />
          Demander un congé
        </button>
      </div>

      {isAdmin && viewMode === 'history' && (
        <FilterBar 
          filters={filters} 
          setFilters={setFilters} 
          employees={employees}
        />
      )}

      <div className="bg-white rounded-lg shadow p-6">
        <h2 className="text-lg font-semibold mb-4">
          {viewMode === 'all-leaves' ? 'Demandes en attente' : 'Mes demandes de congés'}
        </h2>
        <div className="space-y-4">
        {leaves.map(leave => (
    <div key={leave.id} className={`p-4 rounded-lg border ${
        leave.status === 'pending' ? 'bg-yellow-50 border-yellow-200' :
        leave.status === 'approved' ? 'bg-green-50 border-green-200' :
        'bg-red-50 border-red-200'
    }`}>
        <div className="flex justify-between items-start">
            <div>
                <p className="font-medium mb-1">{leave.employeeName}</p>
                <span className="font-medium">
                    Du {leave.startDate.toLocaleDateString()} au {leave.endDate.toLocaleDateString()}
                </span>
                <p className="text-gray-600 mt-1">{leave.reason}</p>

                {/* Info de validation uniquement dans la vue historique */}
                {viewMode === 'history' && leave.status !== 'pending' && leave.reviewedBy && (
                    <div className="mt-2 text-sm text-gray-500 border-t pt-2">
                        <p>
                            {leave.status === 'approved' ? 'Approuvé' : 'Refusé'} par {leave.reviewedBy.name}
                            <br />
                            Le {leave.reviewedBy.timestamp.toDate().toLocaleDateString('fr-FR', {
                                day: '2-digit',
                                month: '2-digit',
                                year: 'numeric',
                                hour: '2-digit',
                                minute: '2-digit'
                            })}
                        </p>
                    </div>
                )}
            </div>

            <div className="flex items-center space-x-2">
                <span className={`px-3 py-1 rounded-full text-sm ${
                    leave.status === 'pending' ? 'bg-yellow-100 text-yellow-800' :
                    leave.status === 'approved' ? 'bg-green-100 text-green-800' :
                    'bg-red-100 text-red-800'
                }`}>
                    {leave.status === 'pending' ? 'En attente' :
                     leave.status === 'approved' ? 'Approuvé' : 'Refusé'}
                </span>
                {isAdmin && leave.status === 'pending' && viewMode === 'all-leaves' && (
                    <div className="flex space-x-1">
                        <button
                            onClick={() => handleLeaveResponse(leave.id, leave.employeeId, leave.startDate, leave.endDate, true)}
                            className="p-1 bg-green-100 text-green-600 rounded hover:bg-green-200"
                        >
                            <Check size={20} />
                        </button>
                        <button
                            onClick={() => handleLeaveResponse(leave.id, leave.employeeId, leave.startDate, leave.endDate, false)}
                            className="p-1 bg-red-100 text-red-600 rounded hover:bg-red-200"
                        >
                            <X size={20} />
                        </button>
                    </div>
                )}
            </div>
        </div>
    </div>
))}
          {leaves.length === 0 && (
            <p className="text-gray-500 text-center py-4">Aucune demande à afficher</p>
          )}
        </div>
      </div>

      {showAddModal && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex items-center justify-center z-50">
          <div className="bg-white rounded-lg p-6 w-full max-w-md">
            <h2 className="text-xl font-bold mb-4">Nouvelle demande de congé</h2>
            <form onSubmit={handleSubmit}>
              <div className="space-y-4">
              <div>
              <label className="block text-sm font-medium text-gray-700">
                  Date de début (incluse)
              </label>
              <input
                  type="date"
                  value={newLeave.startDate}
                  onChange={(e) => setNewLeave(prev => ({ ...prev, startDate: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  required
              />
          </div>

          <div>
              <label className="block text-sm font-medium text-gray-700">
                  Date de fin (incluse)
              </label>
              <input
                  type="date"
                  value={newLeave.endDate}
                  min={newLeave.startDate} // Empêche de sélectionner une date antérieure
                  onChange={(e) => setNewLeave(prev => ({ ...prev, endDate: e.target.value }))}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                  required
              />
              <p className="text-sm text-gray-500 mt-1">
                  La journée sera comptée jusqu'à sa fin
              </p>
          </div>

                <div>
                  <label className="block text-sm font-medium text-gray-700">Motif</label>
                  <textarea
                    value={newLeave.reason}
                    onChange={(e) => setNewLeave(prev => ({ ...prev, reason: e.target.value }))}
                    className="mt-1 block w-full rounded-md border-gray-300 shadow-sm"
                    rows="3"
                    required
                  />
                </div>

                <div className="flex justify-end space-x-2">
                  <button
                    type="button"
                    onClick={() => setShowAddModal(false)}
                    className="px-4 py-2 text-gray-700 bg-gray-100 rounded hover:bg-gray-200"
                  >
                    Annuler
                  </button>
                  <button
                    type="submit"
                    className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600"
                  >
                    Soumettre
                  </button>
                </div>
              </div>
            </form>
          </div>
        </div>
        )}
        </div>
      );
    }
    
    export default LeaveManagement;