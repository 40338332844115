import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, addDoc, updateDoc, doc, query, getDocs, Timestamp, getDoc, deleteDoc, writeBatch, where, setDoc } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { X, Clock, Calendar, User, ChevronLeft, ChevronRight, Plus, Copy, Search, AlertCircle , Shield, Check, Menu} from 'lucide-react';
import ClientAutocomplete from './ClientAutocomplete';
import AppointmentDetailsModal from './AppointmentDetailsModal';

// Hook personnalisé pour détecter la taille de l'écran
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    window.addEventListener("resize", handleResize);
    handleResize();
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const WeekViewMobile = ({ db, currentDate, currentUser, onDateChange }) => {
  const [selectedDay, setSelectedDay] = useState(new Date(currentDate));
  const [weekDates, setWeekDates] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [showEditModal, setShowEditModal] = useState(false);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [clients, setClients] = useState([]);
  const [showAddModal, setShowAddModal] = useState(false);
  const [filterText, setFilterText] = useState('');
  const [adminRoles, setAdminRoles] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [isAdmin, setIsAdmin] = useState(false);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedUnassignedAppointment, setSelectedUnassignedAppointment] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedInfoAppointment, setSelectedInfoAppointment] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [clickTimer, setClickTimer] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDetailAppointment, setSelectedDetailAppointment] = useState(null);
  const clickDelay = 200; // Délai en millisecondes pour détecter un double-clic
  const [showCompletedAppointments, setShowCompletedAppointments] = useState(false);
  const [userPermissions, setUserPermissions] = useState({
    readAppointments: false,
    writeAppointments: false,
    manageRoles: false
  });
  

  const getWeekDates = useCallback((date) => {
    const week = [];
    const current = new Date(date);
    current.setDate(current.getDate() - current.getDay() + (current.getDay() === 0 ? -6 : 1)); // Commence par lundi
    for (let i = 0; i < 7; i++) {
      week.push(new Date(current));
      current.setDate(current.getDate() + 1);
    }
    return week;
  }, []);

  useEffect(() => {
    const newSelectedDay = new Date(currentDate);
    setSelectedDay(newSelectedDay);
    setWeekDates(getWeekDates(newSelectedDay));
  }, [currentDate, getWeekDates]);

  const handleDaySelect = (date) => {
    setSelectedDay(date);
    onDateChange(date);
  };

  const navigateDay = (direction) => {
    const newDate = new Date(selectedDay);
    newDate.setDate(newDate.getDate() + direction);
    handleDaySelect(newDate);
  };

  const formatDay = (date) => {
    return date.toLocaleDateString('fr-FR', { weekday: 'short' }).charAt(0).toUpperCase();
  };

  const formatDayNumber = (date) => {
    return date.getDate();
  };

  const isCurrentDay = (date) => {
    return date.toDateString() === new Date().toDateString();
  };

  const isSelectedDay = (date) => {
    return date.toDateString() === selectedDay.toDateString();
  };

  const navigateWeek = (direction) => {
    const newDate = new Date(selectedDay);
    newDate.setDate(newDate.getDate() + direction * 7);
    setSelectedDay(newDate);
    onDateChange(newDate);
  };

   
  const { width } = useWindowSize();
  const isMobile = width < 768; // Considère les écrans de moins de 768px comme mobiles

  const visibleEmployees = useMemo(() => {
    if (isMobile) {
      return employees.filter(emp => emp.userId === currentUser.userId);
    } else if (isAdmin) {
      return employees;
    } else {
      return employees.filter(emp => emp.userId === currentUser.userId);
    }
  }, [employees, isAdmin, currentUser, isMobile]);


// Fonction pour récupérer les permissions de l'utilisateur
const fetchUserPermissions = useCallback(async () => {
  if (!db || !currentUser) return;
  try {
    const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
    if (userDoc.exists()) {
      const userData = userDoc.data();
      const userRoleId = userData.role;
      const roleDoc = await getDoc(doc(db, 'roles', userRoleId));
      if (roleDoc.exists()) {
        const roleData = roleDoc.data();
        setUserPermissions({
          readAppointments: roleData.permissions.includes('read_appointments'),
          writeAppointments: roleData.permissions.includes('write_appointments'),
          manageRoles: roleData.permissions.includes('manage_roles')
        });
      }
    }
  } catch (error) {
    console.error("Erreur lors de la récupération des permissions de l'utilisateur:", error);
  }
}, [db, currentUser]);

useEffect(() => {
  fetchUserPermissions();
}, [fetchUserPermissions]);


 // Fonction pour vérifier si l'utilisateur a une permission spécifique
 const hasPermission = (permission) => userPermissions[permission];

 
  const fetchUserRole = useCallback(async () => {
    if (!db || !currentUser) return;
    try {
      const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        // Vérification pour un seul rôle
const userRoleId = userData.role; // Changé de roles à role
        setUserRole(userRoleId);
        setIsAdmin(adminRoles.includes(userRoleId));       }
    } catch (error) {
      console.error("Erreur lors de la récupération du rôle de l'utilisateur:", error);
    }
  }, [db, currentUser, adminRoles]);



  useEffect(() => {
    if (adminRoles.length > 0) {
      fetchUserRole();
    }
  }, [fetchUserRole, adminRoles]);

  const fetchEmployees = useCallback(async () => {
    if (!db) return;
    try {
      const employeesRef = collection(db, 'employees');
      const q = query(employeesRef, where('isArchived', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map(doc => ({
        userId: doc.id,
        ...doc.data(),
        color: doc.data().color || '#808080',
        isAdmin: adminRoles.includes(doc.data().role) // Changé pour vérifier un seul rôle
      }));
      setEmployees(fetchedEmployees);
    } catch (error) {
      console.error("Erreur lors de la récupération des employés:", error);
    }
  }, [db, adminRoles]);

  useEffect(() => {
    if (adminRoles.length > 0) {
      fetchEmployees();
    }
  }, [fetchEmployees, adminRoles]);

  const renderEmployeeHeader = (employee) => {
    const isCurrentUser = employee.userId === currentUser.userId;
    const headerText = isCurrentUser ? "Mon planning" : (employee.name || `${employee.firstName} ${employee.lastName}`);
    
    return (
      <h3 className="font-bold text-center mb-4 text-lg flex items-center justify-center">
        {headerText}
        {employee.isAdmin && (
          <Shield className="ml-2 text-blue-500" size={20} title="Droits administratifs" />
        )}
      </h3>
    );
  };

  const filteredEmployees = useMemo(() => {
    if (isAdmin) {
      return employees;
    } else {
      return employees.filter(emp => emp.userId === currentUser.userId);
    }
  }, [employees, isAdmin, currentUser]);

  // Mise à jour de la fonction handleUnassignedAppointmentClick
  const handleUnassignedAppointmentClick = (appointment) => {
    if (appointment.status === 'libre' && (hasPermission('writeAppointments') || hasPermission('readAppointments'))) {
      setSelectedUnassignedAppointment(appointment);
      setShowBookingModal(true);
      setShowAddModal(false);
    } else if (appointment.status === 'reserve') {
      setSelectedInfoAppointment(appointment);
      setShowInfoModal(true);
    }
  };

  const getDayIndex = (date) => {
    return weekDates.findIndex(weekDate => 
      weekDate.toDateString() === date.toDateString()
    );
  };

  
  const handleBookAppointment = async () => {
    if (!selectedUnassignedAppointment || !currentUser) return;

    try {
      const appointmentRef = doc(db, 'appointments', selectedUnassignedAppointment.id);
      await updateDoc(appointmentRef, {
        employeeId: currentUser.userId,
        status: 'reserve'
      });

      fetchAppointments();
      setShowBookingModal(false);
    } catch (error) {
      console.error("Erreur lors de la réservation du rendez-vous:", error);
      alert("Une erreur s'est produite lors de la réservation du rendez-vous");
    }
  };


  const fetchAppointments = useCallback(async () => {
    if (!db || !currentUser) return;
    try {
      const startOfDay = new Date(currentDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(currentDate);
      endOfDay.setHours(23, 59, 59, 999);
  
      let appointmentsQuery = query(
        collection(db, 'appointments'),
        where('date', '>=', Timestamp.fromDate(startOfDay)),
        where('date', '<=', Timestamp.fromDate(endOfDay))
      );
  
      const querySnapshot = await getDocs(appointmentsQuery);
      const fetchedAppointments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate() || new Date(),
      }));
  
      const appointmentsWithClientInfo = await Promise.all(fetchedAppointments.map(async appointment => {
        const clientDoc = await getDoc(doc(db, 'clients', appointment.clientId));
        const clientData = clientDoc.data();
        return {
          ...appointment,
          clientName: clientData ? `${clientData.lastName} ${clientData.firstName}` : 'Client non spécifié',
          clientColor: clientData ? clientData.color : '#808080',
          status: appointment.status || 'libre'
        };
      }));
      setAppointments(appointmentsWithClientInfo);
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  }, [db, currentDate, currentUser]);

  useEffect(() => {
    fetchEmployees();
    fetchAppointments();
  }, [fetchEmployees, fetchAppointments, adminRoles, userRole]);
  

  useEffect(() => {
    const fetchClients = async () => {
      if (!db) return;
      try {
        const clientsRef = collection(db, 'clients');
        const q = query(clientsRef, where('isActive', '==', true));
        const clientsSnapshot = await getDocs(q);
        const fetchedClients = clientsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(fetchedClients);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients:", error);
      }
    };
    fetchClients();
  }, [db]);

    
  const filteredAppointments = useMemo(() => {
    return appointments.filter(appointment => {
      const searchText = filterText.toLowerCase();
      const matchesSearch = appointment.clientName.toLowerCase().includes(searchText) ||
             (appointment.employeeName || '').toLowerCase().includes(searchText) ||
             appointment.startTime.includes(searchText) ||
             appointment.endTime.includes(searchText);
      
      // Inclure les rendez-vous terminés si l'option est activée
      const showCompleted = showCompletedAppointments || appointment.status !== 'completed';
      
      return matchesSearch && showCompleted;
    });
  }, [appointments, filterText, showCompletedAppointments]);

  const groupedAppointments = useMemo(() => {
    const grouped = {};
    employees.forEach(employee => {
      grouped[employee.userId] = filteredAppointments.filter(app => 
        app.employeeId === employee.userId
      ).sort((a, b) => a.startTime.localeCompare(b.startTime));
    });
    return grouped;
  }, [filteredAppointments, employees]);

  const handleDragEnd = (result) => {
    // Implémentez la logique de drag and drop ici
  };

  const sortedUnassignedAppointments = useMemo(() => {
    return filteredAppointments
      .filter(app => !app.employeeId || app.status === 'libre' || app.status === 'reserve')
      .sort((a, b) => a.startTime.localeCompare(b.startTime));
  }, [filteredAppointments]);

  const groupedAssignedAppointments = useMemo(() => {
    const grouped = {};
    employees.forEach(employee => {
      grouped[employee.userId] = filteredAppointments
        .filter(app => app.employeeId === employee.userId && 
                       (app.status === 'assigne' || app.status === 'in_progress' || 
                        (app.status === 'completed' && showCompletedAppointments)))
        .sort((a, b) => a.startTime.localeCompare(b.startTime));
    });
    return grouped;
  }, [filteredAppointments, employees, showCompletedAppointments]);

  const handleAppointmentClick = (appointment, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (clickTimer) {
      // Double-clic détecté
      clearTimeout(clickTimer);
      setClickTimer(null);
      handleAppointmentDoubleClick(appointment);
    } else {
      // Définir un timer pour le simple clic
      const timer = setTimeout(() => {
        handleSingleClick(appointment);
        setClickTimer(null);
      }, clickDelay);
      setClickTimer(timer);
    }
  };

  const handleSingleClick = (appointment) => {
    if (appointment.status === 'libre') {
      setSelectedUnassignedAppointment(appointment);
      setShowBookingModal(true);
    } else if (appointment.status === 'reserve') {
      setSelectedInfoAppointment(appointment);
      setShowInfoModal(true);
    } else {
      setSelectedDetailAppointment(appointment);
      setShowDetailsModal(true);
    }
  };

  const handleAppointmentDoubleClick = (appointment) => {
    if (hasPermission('writeAppointments')) {
      setSelectedAppointment(appointment);
      setShowEditModal(true);
    }
  };

  
  const handleAppointmentUpdate = (updatedAppointment) => {
    setAppointments(prevAppointments =>
      prevAppointments.map(app =>
        app.id === updatedAppointment.id ? updatedAppointment : app
      )
    );
  };

  const handleAddAppointment = async (appointmentData) => {
    try {
      const newAppointmentRef = doc(collection(db, 'appointments'));
      await setDoc(newAppointmentRef, {
        ...appointmentData,
        date: Timestamp.fromDate(currentDate)
      });
      
      fetchAppointments();
      setShowAddModal(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout du rendez-vous:", error);
    }
  };


  const handleApproveAppointment = async () => {
    if (!selectedInfoAppointment || !hasPermission('writeAppointments')) return;

    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'assigne'
      });

      setAppointments(prevAppointments => 
        prevAppointments.map(app => 
          app.id === selectedInfoAppointment.id ? {...app, status: 'assigne'} : app
        )
      );

      setShowInfoModal(false);
      fetchAppointments();
    } catch (error) {
      console.error("Erreur lors de l'approbation du rendez-vous:", error);
      alert("Une erreur s'est produite lors de l'approbation du rendez-vous");
    }
  };

  const handleRejectAppointment = async () => {
    if (!selectedInfoAppointment || !hasPermission('writeAppointments')) return;

    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'libre',
        employeeId: null
      });

      fetchAppointments();
      setShowInfoModal(false);
    } catch (error) {
      console.error("Erreur lors du refus du rendez-vous:", error);
      alert("Une erreur s'est produite lors du refus du rendez-vous");
    }
  };

  const handleCancelReservation = async () => {
    if (!selectedInfoAppointment || selectedInfoAppointment.employeeId !== currentUser.userId) return;

    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'libre',
        employeeId: null
      });

      fetchAppointments();
      setShowInfoModal(false);
    } catch (error) {
      console.error("Erreur lors de l'annulation de la réservation:", error);
      alert("Une erreur s'est produite lors de l'annulation de la réservation");
    }
  };

  const handleUpdateAppointment = async (updatedAppointment) => {
    try {
      const appointmentRef = doc(db, 'appointments', updatedAppointment.id);
      await updateDoc(appointmentRef, {
        ...updatedAppointment,
        date: Timestamp.fromDate(currentDate)
      });
  
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rendez-vous:", error);
    }
  };

  const handleDeleteAppointment = async (appointmentId) => {
    try {
      await deleteDoc(doc(db, 'appointments', appointmentId));
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la suppression du rendez-vous:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onDragEnd = useCallback(async (result) => {
    if (!result.destination || !isAdmin) return;
  
    const { source, destination, draggableId } = result;
    
    const sourceEmployeeId = source.droppableId;
    const destEmployeeId = destination.droppableId;
    
    const appointment = appointments.find(app => app.id === draggableId);
    if (!appointment) return;
  
    try {
      const appointmentRef = doc(db, 'appointments', appointment.id);
      let updateData = {};

      if (destEmployeeId === 'unassigned') {
        // Déplacer vers les plages à combler
        updateData = {
          employeeId: null,
          status: 'libre'
        };
      } else if (sourceEmployeeId === 'unassigned' || sourceEmployeeId !== destEmployeeId) {
        // Déplacer vers un employé (depuis non assigné ou un autre employé)
        updateData = {
          employeeId: destEmployeeId,
          status: 'assigne'
        };
      }

      if (Object.keys(updateData).length > 0) {
        await updateDoc(appointmentRef, updateData);
        
        // Mettre à jour l'état local
        setAppointments(prevAppointments => 
          prevAppointments.map(app => 
            app.id === appointment.id ? { ...app, ...updateData } : app
          )
        );
      }
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rendez-vous:", error);
    }
  }, [appointments, db, isAdmin]);

  useEffect(() => {
    console.log("Filtered appointments:", filteredAppointments);
    console.log("Sorted unassigned appointments:", sortedUnassignedAppointments);
    console.log("Grouped assigned appointments:", groupedAssignedAppointments);
  }, [filteredAppointments, sortedUnassignedAppointments, groupedAssignedAppointments]);

  const getEmployeeName = useCallback((employeeId) => {
    const employee = employees.find(emp => emp.userId === employeeId);
    return employee ? (employee.name || `${employee.firstName} ${employee.lastName}`) : 'Employé non trouvé';
  }, [employees]);

  const renderAppointment = (appointment) => {
    let appointmentClass = 'pv-appointment pv-week-appointment';
    let backgroundColor, textColor, borderColor, statusText;
  
    switch(appointment.status) {
      case 'libre':
        appointmentClass += ' pv-appointment-libre';
        backgroundColor = '#FEE2E2';
        textColor = '#991B1B';
        borderColor = '#EF4444';
        statusText = 'Plage à combler';
        break;
      case 'reserve':
        appointmentClass += ' pv-appointment-reserve';
        backgroundColor = '#FDE68A';
        textColor = '#92400E';
        borderColor = '#FCD34D';
        statusText = 'En attente de validation';
        break;
      case 'in_progress':
        appointmentClass += ' pv-appointment-in-progress';
        backgroundColor = '#FFEDD5';
        textColor = '#9A3412';
        borderColor = '#F97316';
        statusText = 'En cours';
        break;
      case 'completed':
        appointmentClass += ' pv-appointment-completed';
        backgroundColor = 'rgba(156, 163, 175, 0.3)';
        textColor = '#4B5563';
        borderColor = '#9CA3AF';
        statusText = 'Terminé';
        break;
      default:
        appointmentClass += ' pv-appointment-assigne';
        backgroundColor = lightenColor(appointment.clientColor || '#4B5563', 15);
        textColor = isLightColor(backgroundColor) ? '#1F2937' : '#F9FAFB';
        borderColor = appointment.clientColor || '#4B5563';
        statusText = 'Assigné';
    }
  
    return (
      <div
        className={`${appointmentClass} p-1 mb-1 rounded text-xs shadow-md`}
        style={{ 
          backgroundColor: backgroundColor,
          color: textColor,
          borderLeft: `4px solid ${borderColor}`,
          opacity: appointment.status === 'completed' ? 0.7 : 1
        }}
        onClick={(e) => handleAppointmentClick(appointment, e)}
      >
        <div className="font-bold truncate p-1">
          <User size={12} className="inline-block mr-1" />
          {appointment.clientName || "Client non spécifié"}
        </div>
        <div className="bg-white text-gray-800 p-1 mt-1 rounded">
          <Clock size={12} className="inline-block mr-1" />
          {`${appointment.startTime} - ${appointment.endTime}`}
        </div>
        {hasPermission('writeAppointments') && appointment.employeeId && (
          <div className="text-xs mt-1">
            <User size={12} className="inline-block mr-1" />
            {getEmployeeName(appointment.employeeId)}
          </div>
        )}
        {statusText && <div className="text-xs mt-1 font-semibold">{statusText}</div>}
      </div>
    );
  };

  // Fonction utilitaire pour assombrir une couleur
  const darkenColor = (color, percent) => {
    const num = parseInt(color.replace("#",""), 16),
      amt = Math.round(2.55 * percent),
      R = Math.max((num >> 16) - amt, 0),
      G = Math.max((num >> 8 & 0x00FF) - amt, 0),
      B = Math.max((num & 0x0000FF) - amt, 0);
    return "#" + ((0x1000000 + (R * 0x10000) + (G * 0x100) + B).toString(16).slice(1));
  };

  // Fonction utilitaire pour déterminer si une couleur est claire ou foncée
  const isLightColor = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness > 155;
  };

  return (
    <div className="week-view flex flex-col h-full overflow-hidden">
        <div className="week-navigation bg-teal-600 p-4 text-white">
          <div className="flex justify-center items-center">
            <div className="flex space-x-4">
              {weekDates.map((date, index) => (
            <div
              key={index}
              className={`flex flex-col items-center cursor-pointer ${
                isSelectedDay(date) ? 'bg-teal-700' : ''
              } ${isCurrentDay(date) ? 'rounded-full' : ''} p-2`}
              onClick={() => handleDaySelect(date)}
            >
              <span className="text-xs">{formatDay(date)}</span>
              <span className={`text-lg ${isCurrentDay(date) ? 'bg-white text-teal-600 rounded-full w-8 h-8 flex items-center justify-center' : ''}`}>
                {formatDayNumber(date)}
              </span>
            </div>
              ))}
          </div>
        </div>
        


    </div>
    <div className="filter-options p-2 bg-gray-100">
        <label className="flex items-center space-x-2">
          <input
            type="checkbox"
            checked={showCompletedAppointments}
            onChange={(e) => setShowCompletedAppointments(e.target.checked)}
            className="form-checkbox h-4 w-4 text-teal-600"
          />
          <span className="text-sm text-gray-700">Afficher les rendez-vous terminés</span>
        </label>
      </div>

      {/* Contenu des rendez-vous */}
      <div className="content-wrapper flex-grow overflow-auto">
        <div className="p-4">
          <DragDropContext onDragEnd={onDragEnd}>
            {/* Section des rendez-vous non assignés */}
            {hasPermission('readAppointments') && sortedUnassignedAppointments.length > 0 && (
              <Droppable droppableId="unassigned" direction="horizontal">
                {(provided, snapshot) => (
                  <div
                    ref={provided.innerRef}
                    {...provided.droppableProps}
                    className="unassigned-appointments mb-6"
                  >
                    <h3 className="font-bold text-lg mb-2 flex items-center">
                      <AlertCircle className="mr-2 text-yellow-500" size={20} />
                      Plages à combler :
                    </h3>
                    <div className={`flex overflow-x-auto p-4 rounded-lg ${snapshot.isDraggingOver ? 'bg-blue-50' : 'bg-gray-50'}`}
                         style={{ minHeight: '100px' }}>
                      {sortedUnassignedAppointments.map((appointment, index) => (
                        <Draggable key={appointment.id} draggableId={appointment.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`mr-3 ${snapshot.isDragging ? 'opacity-50' : ''}`}
                              style={{
                                ...provided.draggableProps.style,
                                width: '200px',
                                flexShrink: 0
                              }}
                            >
                              {renderAppointment(appointment)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  </div>
                )}
              </Droppable>
            )}

          {/* Planning des employés */}
          <div className="employee-columns grid gap-4">
              {visibleEmployees.map(employee => (
                <Droppable key={employee.userId} droppableId={employee.userId}>
                  {(provided, snapshot) => (
                    <div
                      ref={provided.innerRef}
                      {...provided.droppableProps}
                      className={`employee-column p-4 rounded-lg ${snapshot.isDraggingOver ? 'bg-blue-50' : 'bg-gray-50'}`}
                    >
                      {renderEmployeeHeader(employee)}
                      {groupedAssignedAppointments[employee.userId]?.map((appointment, index) => (
                        <Draggable key={appointment.id} draggableId={appointment.id} index={index}>
                          {(provided, snapshot) => (
                            <div
                              ref={provided.innerRef}
                              {...provided.draggableProps}
                              {...provided.dragHandleProps}
                              className={`${snapshot.isDragging ? 'opacity-50' : ''}`}
                              style={provided.draggableProps.style}
                            >
                              {renderAppointment(appointment)}
                            </div>
                          )}
                        </Draggable>
                      ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </div>
          </DragDropContext>
        </div>
      </div>


      {/* Modal pour la réservation de rendez-vous non assignés */}
      {showBookingModal && selectedUnassignedAppointment && (
        <Modal onClose={() => setShowBookingModal(false)}>
          <div className="booking-modal p-4">
            <h2 className="text-xl font-bold mb-4">Réserver ce rendez-vous</h2>
            <p>Voulez-vous réserver ce rendez-vous ?</p>
            <p>Client : {selectedUnassignedAppointment.clientName || "Client non spécifié"}</p>
            <p>Date : {selectedUnassignedAppointment.date.toLocaleDateString()}</p>
            <p>Heure : {selectedUnassignedAppointment.startTime} - {selectedUnassignedAppointment.endTime}</p>
            <div className="mt-4 flex justify-end space-x-2">
              <button
                onClick={() => setShowBookingModal(false)}
                className="pv-button-action pv-button-cancel-gray"
              >
                Annuler
              </button>
              <button
                onClick={handleBookAppointment}
                className="pv-button-action pv-button-book"
              >
                Réserver
              </button>
            </div>
          </div>
        </Modal>
      )}

      {/* Modal d'information pour les rendez-vous en attente de validation */}
      {showInfoModal && selectedInfoAppointment && (
        <Modal onClose={() => setShowInfoModal(false)}>
          <div className="info-modal p-4">
            <h2 className="text-xl font-bold mb-4">Détails du rendez-vous</h2>
            <p>Client : {selectedInfoAppointment.clientName}</p>
            <p>Date : {selectedInfoAppointment.date.toLocaleDateString()}</p>
            <p>Heure : {selectedInfoAppointment.startTime} - {selectedInfoAppointment.endTime}</p>
            <p>Employé : {getEmployeeName(selectedInfoAppointment.employeeId)}</p>
            <p>Statut : {selectedInfoAppointment.status === 'reserve' ? 'En attente de validation' : selectedInfoAppointment.status}</p>
            
            <div className="mt-4 flex justify-end space-x-2">
              {selectedInfoAppointment.status === 'reserve' && (
                <>
                  {hasPermission('writeAppointments') && (
                    <>
                      <button
                        onClick={handleApproveAppointment}
                        className="pv-button-action pv-button-approve"
                      >
                        <Check className="mr-1" size={12} />
                        Approuver
                      </button>
                      <button
                        onClick={handleRejectAppointment}
                        className="pv-button-action pv-button-reject"
                      >
                        <X className="mr-1" size={12} />
                        Refuser
                      </button>
                    </>
                  )}
                  {selectedInfoAppointment.employeeId === currentUser.userId && (
                    <button
                      onClick={handleCancelReservation}
                      className="pv-button-action pv-button-cancel"
                    >
                      <X className="mr-1" size={12} />
                      Annuler
                    </button>
                  )}
                </>
              )}
              <button
                onClick={() => setShowInfoModal(false)}
                className="pv-button-action pv-button-gray"
              >
                Fermer
              </button>
            </div>
          </div>
        </Modal>
      )}

{showAddModal && (
        <Modal onClose={() => setShowAddModal(false)}>
          <AddAppointmentModal
            date={currentDate}
            employees={employees}
            clients={clients}
            onSave={handleAddAppointment}
            onClose={() => setShowAddModal(false)}
          />
        </Modal>
      )}

      {showEditModal && selectedAppointment && (
        <Modal onClose={() => setShowEditModal(false)}>
          <EditAppointmentModal
            appointment={selectedAppointment}
            employees={employees}
            clients={clients}
            onSave={handleUpdateAppointment}
            onClose={() => setShowEditModal(false)}
            onDelete={handleDeleteAppointment}
          />
        </Modal>
      )}

      {showDetailsModal && selectedDetailAppointment && (
        <AppointmentDetailsModal
          appointment={selectedDetailAppointment}
          onClose={() => setShowDetailsModal(false)}
          onUpdate={handleAppointmentUpdate}
          db={db}
        />
      )}
    </div>
  );
};

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

const AddAppointmentModal = ({ date, employees, clients, onSave, onClose }) => {
  const [appointmentData, setAppointmentData] = useState({
    date: date,
    startTime: '',
    endTime: '',
    employeeId: '',
    clientId: '',
    clientName: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData(prev => ({ ...prev, [name]: value }));
  };

  const handleClientChange = (clientId, clientName) => {
    setAppointmentData(prev => ({ ...prev, clientId, clientName }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(appointmentData);
  };

  return (
    <div className="modal bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
      <h2 className="text-2xl font-bold mb-4">Ajouter un rendez-vous</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Clock className="text-gray-400" size={20} />
          <input 
            type="time" 
            name="startTime" 
            value={appointmentData.startTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span>à</span>
          <input 
            type="time" 
            name="endTime" 
            value={appointmentData.endTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <select 
            name="employeeId" 
            value={appointmentData.employeeId} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Sélectionner un employé</option>
            {employees.map(emp => (
              <option key={emp.userId} value={emp.userId}>
                {emp.name || `${emp.firstName} ${emp.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <ClientAutocomplete
            clients={clients}
            value={appointmentData.clientName}
            onChange={handleClientChange}
          />
        </div>
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            type="button" 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
};

const EditAppointmentModal = ({ appointment, employees, clients, onSave, onClose, onDelete }) => {
  const [appointmentData, setAppointmentData] = useState({
    ...appointment,
    date: appointment.date instanceof Date 
      ? appointment.date 
      : new Date(appointment.date)
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData(prev => ({ ...prev, [name]: value }));
  };

  const handleClientChange = (clientId, clientName) => {
    setAppointmentData(prev => ({ 
      ...prev, 
      clientId: clientId, 
      clientName: clientName 
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(appointmentData);
  };

  return (
    <div className="modal max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4">Modifier le rendez-vous</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Clock className="text-gray-400" size={20} />
          <input 
            type="time" 
            name="startTime" 
            value={appointmentData.startTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span>à</span>
          <input 
            type="time" 
            name="endTime" 
            value={appointmentData.endTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <select 
            name="employeeId" 
            value={appointmentData.employeeId || 'unassigned'} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="unassigned">Non assigné</option>
            {employees.map(emp => (
              <option key={emp.userId} value={emp.userId}>
                {emp.name || `${emp.firstName} ${emp.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <ClientAutocomplete
            clients={clients}
            value={appointmentData.clientName || ''}
            onChange={handleClientChange}
          />
        </div>
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            type="button" 
            onClick={() => onDelete(appointmentData.id)}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Supprimer
          </button>
          <button 
            type="button" 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Mettre à jour
          </button>
        </div>
      </form>
    </div>
  );
};

// Fonction utilitaire pour éclaircir une couleur
const lightenColor = (color, percent) => {
  const num = parseInt(color.replace("#",""), 16),
    amt = Math.round(2.55 * percent),
    R = Math.min((num >> 16) + amt, 255),
    G = Math.min((num >> 8 & 0x00FF) + amt, 255),
    B = Math.min((num & 0x0000FF) + amt, 255);
  return "#" + (0x1000000 + R*0x10000 + G*0x100 + B).toString(16).slice(1);
};

// Fonction utilitaire pour déterminer si une couleur est claire ou foncée
const isLightColor = (color) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness > 155;
};

export default WeekViewMobile;