import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { db } from '../firebase';

function useSyncEmailWithFirestore() {
  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, async (user) => {
      if (user) {
        const uid = user.uid;
        const userDocRef = doc(db, 'employees', uid);

        try {
          // Mettre à jour Firestore avec la nouvelle adresse email si elle a changé
          await updateDoc(userDocRef, {
            email: user.email,
            //needEmailUpdate: false,
          });

          console.log(`Email synchronisé pour l'utilisateur ${uid} dans Firestore.`);
        } catch (error) {
          console.error("Erreur lors de la synchronisation de l'email dans Firestore:", error);
        }
      }
    });

    return () => unsubscribe();
  }, []);
}

export default useSyncEmailWithFirestore;
