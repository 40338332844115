import React, { useState } from 'react';
import { Calendar, Clock, User, RotateCcw } from 'lucide-react';

const ClientAddAppointmentModal = ({ date, employees, onSave, onClose, initialEmployeeId }) => {
  const [appointmentData, setAppointmentData] = useState(() => {
    const initialDate = new Date(date);
    initialDate.setMinutes(initialDate.getMinutes() - initialDate.getTimezoneOffset());
    return {
      date: initialDate,
      startTime: '',
      endTime: '',
      employeeId: initialEmployeeId || '',
      status: initialEmployeeId ? 'assigne' : 'libre',
      isRecurring: false,
      recurrenceBase: 'weekly',
      recurrenceFrequency: 1,
      recurrenceDay: [initialDate.getDay()],
      recurrenceMonthDay: initialDate.getDate(),
      recurrenceMonthType: 'dayOfMonth',
      recurrenceMonthWeek: Math.floor((initialDate.getDate() - 1) / 7) + 1,
      recurrenceMonthWeekday: initialDate.getDay(),
      recurrenceEndType: 'never',
      recurrenceEndAfter: 1,
      recurrenceEndDate: null,
      recurrenceInterval: 1,
      recurrenceMonthFrequency: 1,
    };
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAppointmentData(prev => {
      const newData = {
        ...prev,
        [name]: type === 'checkbox' ? checked : 
        (name === 'recurrenceInterval' || name === 'recurrenceFrequency') ? 
        Math.max(1, parseInt(value, 10) || 1) : value
      };
      
      if (name === 'employeeId') {
        newData.status = value ? 'assigne' : 'libre';
      }
      
      return newData;
    });
  };

  const handleDaySelection = (day) => {
    setAppointmentData(prev => ({
      ...prev,
      recurrenceDay: prev.recurrenceDay.includes(day)
        ? prev.recurrenceDay.filter(d => d !== day)
        : [...prev.recurrenceDay, day]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    const dateWithTime = new Date(appointmentData.date);
    dateWithTime.setUTCHours(2, 0, 0, 0);
    onSave({
      ...appointmentData,
      date: dateWithTime
    });
  };

  const getDayOfWeekName = (day) => {
    const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    return days[day];
  };

  const getWeekOfMonthName = (week) => {
    const weeks = ['premier', 'deuxième', 'troisième', 'quatrième', 'dernier'];
    return weeks[Math.min(week - 1, 4)];
  };

  return (
    <div className="modal">
      <h2 className="text-2xl font-bold mb-4">Ajouter un rendez-vous</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Calendar className="text-gray-400" size={20} />
          <input 
            type="date" 
            name="date" 
            value={appointmentData.date.toISOString().split('T')[0]} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="text-gray-400" size={20} />
          <input 
            type="time" 
            name="startTime" 
            value={appointmentData.startTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span>à</span>
          <input 
            type="time" 
            name="endTime" 
            value={appointmentData.endTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <select 
            name="employeeId" 
            value={appointmentData.employeeId} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Sélectionner un employé</option>
            {employees.map(emp => (
              <option key={emp.userId} value={emp.userId}>
                {emp.name || `${emp.firstName} ${emp.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <RotateCcw className="text-gray-400" size={20} />
          <label className="inline-flex items-center">
            <input 
              type="checkbox" 
              name="isRecurring" 
              checked={appointmentData.isRecurring} 
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2">Rendez-vous récurrent</span>
          </label>
        </div>
        {appointmentData.isRecurring && (
          <div className="space-y-4 ml-7">
            <div>
              <select 
                name="recurrenceBase" 
                value={appointmentData.recurrenceBase} 
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="daily">Journalière</option>
                <option value="weekly">Hebdomadaire</option>
                <option value="monthly">Mensuelle</option>
              </select>
            </div>

            {appointmentData.recurrenceBase === 'daily' && (
              <div className="flex items-center space-x-2">
                <span>Tous les</span>
                <input 
                  type="number" 
                  name="recurrenceInterval"
                  value={appointmentData.recurrenceInterval || 1} 
                  onChange={handleChange}
                  min="1"
                  className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span>jour(s)</span>
              </div>
            )}

            {appointmentData.recurrenceBase === 'weekly' && (
              <>
                <div className="flex items-center space-x-2">
                  <span>Tous les</span>
                  <input 
                    type="number" 
                    name="recurrenceFrequency" 
                    value={appointmentData.recurrenceFrequency} 
                    onChange={handleChange}
                    min="1"
                    className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span>semaine(s)</span>
                </div>
                <div className="flex flex-wrap gap-2">
                  {[0, 1, 2, 3, 4, 5, 6].map(day => (
                    <button
                      key={day}
                      type="button"
                      onClick={() => handleDaySelection(day)}
                      className={`px-3 py-1 rounded ${
                        appointmentData.recurrenceDay.includes(day)
                          ? 'bg-blue-500 text-white'
                          : 'bg-gray-200 text-gray-700'
                      }`}
                    >
                      {getDayOfWeekName(day).slice(0, 3)}
                    </button>
                  ))}
                </div>
              </>
            )}

            {appointmentData.recurrenceBase === 'monthly' && (
              <>
                <div>
                  <select
                    name="recurrenceMonthType"
                    value={appointmentData.recurrenceMonthType}
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="dayOfMonth">Le même jour du mois</option>
                    <option value="dayOfWeek">Le même jour de la semaine</option>
                  </select>
                </div>
                {appointmentData.recurrenceMonthType === 'dayOfMonth' ? (
                  <div>
                    Répéter le {appointmentData.recurrenceMonthDay} de chaque mois
                  </div>
                ) : (
                  <div>
                    Répéter le {getWeekOfMonthName(appointmentData.recurrenceMonthWeek)} {getDayOfWeekName(appointmentData.recurrenceMonthWeekday)} de chaque mois
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <span>Tous les</span>
                  <input 
                    type="number" 
                    name="recurrenceMonthFrequency" 
                    value={appointmentData.recurrenceMonthFrequency} 
                    onChange={handleChange}
                    min="1"
                    className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span>mois</span>
                </div>
              </>
            )}
            <div>
              <select
                name="recurrenceEndType"
                value={appointmentData.recurrenceEndType}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="never">Jamais</option>
                <option value="after">Après un nombre d'occurrences</option>
                <option value="on">À une date spécifique</option>
              </select>
            </div>
            {appointmentData.recurrenceEndType === 'after' && (
              <div className="flex items-center space-x-2">
                <span>Après</span>
                <input 
                  type="number" 
                  name="recurrenceEndAfter" 
                  value={appointmentData.recurrenceEndAfter} 
                  onChange={handleChange}
                  min="1"
                  className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span>occurrences</span>
              </div>
            )}
            {appointmentData.recurrenceEndType === 'on' && (
              <div>
                <input 
                  type="date" 
                  name="recurrenceEndDate" 
                  value={appointmentData.recurrenceEndDate || ''} 
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
          </div>
        )}
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            type="button" 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientAddAppointmentModal;