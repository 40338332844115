import React, { useState } from 'react';
import { Calendar, Clock, User } from 'lucide-react';
import ClientAutocomplete from './ClientAutocomplete';

const EditAppointmentModal = ({ appointment, employees, clients, onSave, onClose, onDelete }) => {
  const [appointmentData, setAppointmentData] = useState({
    ...appointment,
    date: appointment.date instanceof Date 
      ? appointment.date 
      : new Date(appointment.date)
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    if (name === 'date') {
      setAppointmentData(prev => ({ ...prev, [name]: new Date(value) }));
    } else if (name === 'employeeId') {
      setAppointmentData(prev => ({ ...prev, [name]: value === 'unassigned' ? null : value }));
    } else {
      setAppointmentData(prev => ({ ...prev, [name]: value }));
    }
  };

  const handleClientChange = (clientId, clientName) => {
    setAppointmentData(prev => ({ 
      ...prev, 
      clientId: clientId, 
      clientName: clientName 
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(appointmentData);
  };

  return (
    <div className="modal max-w-md mx-auto">
      <h2 className="text-2xl font-bold mb-4">Modifier le rendez-vous</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Calendar className="text-gray-400" size={20} />
          <input 
            type="date" 
            name="date" 
            value={appointmentData.date.toISOString().split('T')[0]} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="text-gray-400" size={20} />
          <input 
            type="time" 
            name="startTime" 
            value={appointmentData.startTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span>à</span>
          <input 
            type="time" 
            name="endTime" 
            value={appointmentData.endTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <select 
            name="employeeId" 
            value={appointmentData.employeeId || 'unassigned'} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="unassigned">Non assigné</option>
            {employees.map(emp => (
              <option key={emp.userId} value={emp.userId}>
                {emp.name || `${emp.firstName} ${emp.lastName}`}
              </option>
            ))}
          </select>
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <ClientAutocomplete
            clients={clients}
            value={appointmentData.clientName || ''}
            onChange={handleClientChange}
          />
        </div>
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            type="button" 
            onClick={() => onDelete(appointmentData.id)}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
          >
            Supprimer
          </button>
          <button 
            type="button" 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Mettre à jour
          </button>
        </div>
      </form>
    </div>
  );
};

export default EditAppointmentModal;