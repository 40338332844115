import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, addDoc, updateDoc, doc, query, where, getDocs, Timestamp, getDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { X, Clock,Calendar, User, ChevronLeft, ChevronRight, Plus, AlertTriangle, Copy , RotateCcw, AlertCircle, Check, Loader} from 'lucide-react';
import ClientAddAppointmentModal from './ClientAddAppointmentModal';
import ClientEditAppointmentModal from './ClientEditAppointmentModal';

const ClientWeekView = ({ db, client, currentDate, onClose }) => {
  const [weekDates, setWeekDates] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(1); // Default to Monday (1)
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [unassignedAppointments, setUnassignedAppointments] = useState([]);
  const [appointmentsWithColors, setAppointmentsWithColors] = useState([]);
  const [approvedLeaves, setApprovedLeaves] = useState([]);
  // Ajoutez un nouvel état pour stocker l'employé sélectionné
const [selectedEmployee, setSelectedEmployee] = useState(null);
  
  const isToday = useCallback((date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  }, []);

  const fetchPlanningSettings = useCallback(async () => {
    if (!db) return;
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'planningManagement'));
      if (settingsDoc.exists()) {
        const settings = settingsDoc.data();
        setFirstDayOfWeek(settings.firstDayOfWeek || 1); // Default to Monday if not set
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des paramètres de planification:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchPlanningSettings();
  }, [fetchPlanningSettings]);

  const updateWeekDates = useCallback((date) => {
    const weekStart = new Date(date);
    const day = weekStart.getDay();
    const diff = (day < firstDayOfWeek ? 7 : 0) + day - firstDayOfWeek;
    weekStart.setDate(weekStart.getDate() - diff);
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(weekStart);
      day.setDate(weekStart.getDate() + i);
      dates.push(day);
    }
    setWeekDates(dates);
  }, [firstDayOfWeek]);

  const fetchEmployees = useCallback(async () => {
    if (!db) return;
    try {
      const employeesRef = collection(db, 'employees');
      const q = query(employeesRef, where('isArchived', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map(doc => ({
        userId: doc.id,
        ...doc.data(),
        color: doc.data().color || '#808080' // Couleur par défaut si non définie
      }));
      setEmployees(fetchedEmployees);
      console.log("Employés récupérés:", fetchedEmployees);
    } catch (error) {
      console.error("Erreur lors de la récupération des employés:", error);
    }
  }, [db]);

  const sortAppointments = useCallback((apps) => {
    return apps.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA.getTime() !== dateB.getTime()) {
        return dateA.getTime() - dateB.getTime();
      }
      return a.startTime.localeCompare(b.startTime);
    });
  }, []);

  // Modifier la fonction fetchAppointments pour inclure les rendez-vous non assignés
  const fetchAppointments = useCallback(async () => {
    if (!db || !client.id) return;
    try {
      const appointmentsRef = collection(db, 'appointments');
      const q = query(appointmentsRef, where('clientId', '==', client.id));
      const querySnapshot = await getDocs(q);
      const fetchedAppointments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate() || new Date(),
      }));
      
      const sortedAppointments = sortAppointments(fetchedAppointments);
      
      setAppointments(sortedAppointments);
      
      const withColors = sortedAppointments.map(app => ({
        ...app,
        employeeColor: employees.find(emp => emp.userId === app.employeeId)?.color || '#808080'
      }));
      setAppointmentsWithColors(withColors);
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  }, [db, client.id, employees, sortAppointments]);

  useEffect(() => {
    fetchPlanningSettings();
  }, [fetchPlanningSettings]);


  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);


  useEffect(() => {
    if (db && client.id) {
      updateWeekDates(currentDate || new Date());
      fetchAppointments();
    }
  }, [db, client.id, currentDate, fetchEmployees, fetchAppointments, updateWeekDates]);

  

  useEffect(() => {
    // Mettre à jour les couleurs des rendez-vous lorsque les employés changent
    const updatedAppointments = appointments.map(appointment => {
      const employee = employees.find(emp => emp.userId === appointment.employeeId);
      return {
        ...appointment,
        employeeColor: employee?.color || '#808080'
      };
    });
    setAppointments(updatedAppointments);
  }, [employees]);


  const getEmployeeName = (employeeId) => {
    const employee = employees.find(emp => emp.userId === employeeId);
    if (employee) {
      return employee.name || `${employee.firstName} ${employee.lastName}`;
    } else {
      console.warn(`Employé non trouvé pour l'ID: ${employeeId}`);
      return "Employé non trouvé";
    }
  };

  const isSameDay = (date1, date2) => {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      console.error('Invalid date object:', { date1, date2 });
      return false;
    }
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
  };

  

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Date invalide';
    }
    return date.toLocaleDateString('fr-FR', { weekday: 'short', day: 'numeric', month: 'long' });
  };

  

  // Modifiez la fonction handleCellClick
const handleCellClick = (date, employeeId) => {
  if (employeeId && isEmployeeOnLeave(date, employeeId)) {
    return;
  }
  setSelectedDate(new Date(date));
  setSelectedEmployee(employeeId);
  setShowAddModal(true);
};

  const handleAppointmentClick = (appointment) => {
    console.log("Appointment clicked:", appointment);
    setSelectedAppointment(appointment);
    setIsEditModalOpen(true);
    console.log("isEditModalOpen set to true");
  };

  // Modification de la fonction handleAddAppointment
const handleAddAppointment = async (appointmentData) => {
  try {
    let appointmentsToAdd = [];
    if (appointmentData.isRecurring) {
      appointmentsToAdd = generateRecurringAppointments(appointmentData);
    } else {
      appointmentsToAdd = [{
        ...appointmentData,
        isRecurring: false,
        status: appointmentData.employeeId ? 'assigne' : 'libre'
      }];
    }

    const batch = writeBatch(db);
    
    appointmentsToAdd.forEach(appointment => {
      const newAppointmentRef = doc(collection(db, 'appointments'));
      batch.set(newAppointmentRef, {
        ...appointment,
        clientId: client.id,
        date: Timestamp.fromDate(appointment.date)
      });
    });

    await batch.commit();
    console.log(`Added ${appointmentsToAdd.length} appointment(s) successfully`);
    
    fetchAppointments();
    setShowAddModal(false);
  } catch (error) {
    console.error("Erreur lors de l'ajout du/des rendez-vous:", error);
  }
};

// Modification de la fonction generateRecurringAppointments
const generateRecurringAppointments = (appointmentData) => {
  console.log("Generating recurring appointments with data:", appointmentData);
  const appointments = [];
  let currentDate = new Date(appointmentData.date);
  const endDate = getRecurrenceEndDate(appointmentData);
  
  console.log("Start date:", currentDate);
  console.log("End date:", endDate);

  let occurrenceCount = 0;
  const maxOccurrences = appointmentData.recurrenceEndType === 'after' ? appointmentData.recurrenceEndAfter : Infinity;

  // Ajuster la condition de la boucle pour inclure la date de fin
  while (currentDate <= endDate && occurrenceCount < maxOccurrences) {
    if (appointmentData.recurrenceBase === 'daily') {
      appointments.push({
        ...appointmentData,
        date: new Date(currentDate),
        isRecurring: true
      });
      occurrenceCount++;
      currentDate.setDate(currentDate.getDate() + appointmentData.recurrenceInterval);
    } else if (appointmentData.recurrenceBase === 'weekly') {
      if (appointmentData.recurrenceDay.includes(currentDate.getDay())) {
        appointments.push({
          ...appointmentData,
          date: new Date(currentDate),
          isRecurring: true
        });
        occurrenceCount++;
      }
      currentDate.setDate(currentDate.getDate() + 1);
      if (currentDate.getDay() === 0) {
        currentDate.setDate(currentDate.getDate() + (7 * (appointmentData.recurrenceFrequency - 1)));
      }
    } else if (appointmentData.recurrenceBase === 'monthly') {
      if (shouldAddAppointment(currentDate, appointmentData)) {
        appointments.push({
          ...appointmentData,
          date: new Date(currentDate),
          isRecurring: true
        });
        occurrenceCount++;
      }
      currentDate = getNextRecurrenceDate(currentDate, appointmentData);
    }
  }

  console.log("Generated appointments:", appointments);
  return appointments;
};
  

  
const getRecurrenceEndDate = (appointmentData) => {
  if (appointmentData.recurrenceEndType === 'on' && appointmentData.recurrenceEndDate) {
    const endDate = new Date(appointmentData.recurrenceEndDate);
    // Assurez-vous que la date de fin inclut la fin de la journée
    endDate.setHours(23, 59, 59, 999);
    return endDate;
  } else if (appointmentData.recurrenceEndType === 'after') {
    const startDate = new Date(appointmentData.date);
    const endDate = new Date(startDate);
    if (appointmentData.recurrenceBase === 'daily') {
      endDate.setDate(startDate.getDate() + (appointmentData.recurrenceEndAfter - 1) * appointmentData.recurrenceInterval);
    } else if (appointmentData.recurrenceBase === 'weekly') {
      endDate.setDate(startDate.getDate() + (appointmentData.recurrenceEndAfter - 1) * 7 * appointmentData.recurrenceFrequency);
    } else if (appointmentData.recurrenceBase === 'monthly') {
      endDate.setMonth(startDate.getMonth() + (appointmentData.recurrenceEndAfter - 1) * appointmentData.recurrenceFrequency);
    }
    return endDate;
  } else {
    const startDate = new Date(appointmentData.date);
    return new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate());
  }
};


  // Ajout de la fonction getDateAfterOccurrences
const getDateAfterOccurrences = (startDate, appointmentData) => {
  let date = new Date(startDate);
  for (let i = 0; i < appointmentData.recurrenceEndAfter; i++) {
    date = getNextRecurrenceDate(date, appointmentData);
  }
  return date;
};
  
const shouldAddAppointment = (date, appointmentData) => {
  if (appointmentData.recurrenceBase === 'monthly') {
    if (appointmentData.recurrenceMonthType === 'dayOfWeek') {
      const weekOfMonth = Math.floor((date.getDate() - 1) / 7) + 1;
      return date.getDay() === parseInt(appointmentData.recurrenceMonthWeekday, 10) &&
             weekOfMonth === parseInt(appointmentData.recurrenceMonthWeek, 10);
    } else {
      return date.getDate() === parseInt(appointmentData.recurrenceMonthDay, 10);
    }
  }
  return true;
};


// Ajoutez une nouvelle fonction pour obtenir la prochaine date de récurrence journalière
const getNextDailyRecurrenceDate = (date, interval) => {
  const nextDate = new Date(date);
  nextDate.setDate(nextDate.getDate() + interval);
  return nextDate;
};


  // Modification de la fonction getNextRecurrenceDate
const getNextRecurrenceDate = (date, appointmentData) => {
  let newDate = new Date(date);
  const frequency = parseInt(appointmentData.recurrenceFrequency, 10);
  
  if (appointmentData.recurrenceBase === 'monthly') {
    newDate.setMonth(newDate.getMonth() + frequency);
    
    if (appointmentData.recurrenceMonthType === 'dayOfWeek') {
      const desiredDay = parseInt(appointmentData.recurrenceMonthWeekday, 10);
      const desiredWeek = parseInt(appointmentData.recurrenceMonthWeek, 10);
      
      // Aller au premier jour du mois
      newDate.setDate(1);
      
      // Trouver le premier jour de la semaine désirée
      while (newDate.getDay() !== desiredDay) {
        newDate.setDate(newDate.getDate() + 1);
      }
      
      // Avancer jusqu'à la semaine désirée
      newDate.setDate(newDate.getDate() + (desiredWeek - 1) * 7);
      
      // Si on dépasse le mois, retourner au dernier jour valide du mois précédent
      if (newDate.getMonth() > (date.getMonth() + frequency) % 12) {
        newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 0);
      }
    } else {
      // Pour 'dayOfMonth', ajuster au jour spécifique du mois
      newDate.setDate(parseInt(appointmentData.recurrenceMonthDay, 10));
    }
  } else if (appointmentData.recurrenceBase === 'weekly') {
    newDate.setDate(newDate.getDate() + (7 * frequency));
  }
  
  console.log("Next recurrence date:", newDate);
  return newDate;
};

  const handleUpdateAppointment = async (updatedAppointment) => {
    try {
      const appointmentRef = doc(db, 'appointments', updatedAppointment.id);
      const updateData = {
        ...updatedAppointment,
        date: Timestamp.fromDate(updatedAppointment.date),
        status: updatedAppointment.employeeId ? 'assigne' : 'libre'
      };
  
      await updateDoc(appointmentRef, updateData);
  
      // Mise à jour de l'état local
      setAppointments(prevAppointments => {
        const filteredAppointments = prevAppointments.filter(app => app.id !== updatedAppointment.id);
        if (updatedAppointment.employeeId && updatedAppointment.status === 'assigne') {
          return [...filteredAppointments, updatedAppointment];
        }
        return filteredAppointments;
      });

      setUnassignedAppointments(prevUnassigned => {
        const filteredUnassigned = prevUnassigned.filter(app => app.id !== updatedAppointment.id);
        if (!updatedAppointment.employeeId || updatedAppointment.status === 'libre') {
          return [...filteredUnassigned, updatedAppointment];
        }
        return filteredUnassigned;
      });

      // Mise à jour de appointmentsWithColors
      setAppointmentsWithColors(prevAppointments => {
        const filteredAppointments = prevAppointments.filter(app => app.id !== updatedAppointment.id);
        const employee = employees.find(emp => emp.userId === updatedAppointment.employeeId);
        const appointmentWithColor = {
          ...updatedAppointment,
          employeeColor: employee?.color || '#808080'
        };
        return [...filteredAppointments, appointmentWithColor];
      });
  
      setIsEditModalOpen(false);
      console.log("Rendez-vous mis à jour avec succès:", updatedAppointment);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rendez-vous:", error);
    }
  };

  const handleCloseEditModal = () => {
    setIsEditModalOpen(false);
    setSelectedAppointment(null);
  };


  const handleDeleteAppointment = async (appointmentId) => {
    try {
      await deleteDoc(doc(db, 'appointments', appointmentId));
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la suppression du rendez-vous:", error);
    }
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  // Modifier la fonction onDragEnd pour gérer les rendez-vous non assignés
  const onDragEnd = useCallback(async (result) => {
    if (!result.destination) return;
  
    const { source, destination, draggableId } = result;
    
    const [sourceEmployeeId, sourceDayIndex] = source.droppableId.split('-');
    const [destEmployeeId, destDayIndex] = result.destination.droppableId.split('-');
    
    let appointment = appointmentsWithColors.find(app => app.id === draggableId) ||
                      unassignedAppointments.find(app => app.id === draggableId);
    if (!appointment) return;
  
    const sourceDate = weekDates[parseInt(sourceDayIndex)];
    const destinationDate = weekDates[parseInt(destDayIndex)];
    const timeDifference = destinationDate.getTime() - sourceDate.getTime();
  
    const newDate = new Date(appointment.date.getTime() + timeDifference);
    const newEmployeeId = destEmployeeId === 'unassigned' ? null : destEmployeeId;
    const newStatus = destEmployeeId === 'unassigned' ? 'libre' : 'assigne';

    // Empêcher le déplacement vers une cellule de congé
  if (destEmployeeId !== 'unassigned' && isEmployeeOnLeave(destinationDate, destEmployeeId)) {
    return;
  }
  
    try {
      if (isCtrlPressed) {
        // Copier le rendez-vous
        const newAppointmentData = {
          ...appointment,
          date: Timestamp.fromDate(newDate),
          employeeId: newEmployeeId,
          status: newStatus
        };
        delete newAppointmentData.id; // Supprimer l'ancien ID
        const docRef = await addDoc(collection(db, 'appointments'), newAppointmentData);
        const newAppointment = { ...newAppointmentData, id: docRef.id, date: newDate };
  
        // Mettre à jour l'état local
        if (newEmployeeId) {
          setAppointmentsWithColors(prev => sortAppointments([...prev, newAppointment]));
        } else {
          setUnassignedAppointments(prev => sortAppointments([...prev, newAppointment]));
        }
      } else {
        // Déplacer le rendez-vous (code inchangé)
        await updateDoc(doc(db, 'appointments', appointment.id), {
          date: Timestamp.fromDate(newDate),
          employeeId: newEmployeeId,
          status: newStatus
        });
  
        const updatedAppointment = { ...appointment, date: newDate, employeeId: newEmployeeId, status: newStatus };
  
        // Mettre à jour l'état local
        setAppointmentsWithColors(prev => 
          sortAppointments(prev.filter(app => app.id !== appointment.id))
        );
        setUnassignedAppointments(prev => 
          sortAppointments(prev.filter(app => app.id !== appointment.id))
        );
  
        if (newEmployeeId) {
          setAppointmentsWithColors(prev => sortAppointments([...prev, updatedAppointment]));
        } else {
          setUnassignedAppointments(prev => sortAppointments([...prev, updatedAppointment]));
        }
      }
  
      // Rafraîchir les rendez-vous depuis Firestore
      fetchAppointments();
  
    } catch (error) {
      console.error("Erreur lors de la manipulation du rendez-vous:", error);
    }
  }, [appointmentsWithColors, unassignedAppointments, weekDates, db, isCtrlPressed, fetchAppointments]);

  const getAppointmentStyle = (appointment) => {
    let backgroundColor, borderColor, textColor, statusText;

    if (appointment.status === 'libre' && !appointment.employeeId) {
      backgroundColor = '#FF0000'; // Rouge
      borderColor = '#FF0000';
      textColor = '#FFFFFF';
      statusText = 'Plage à combler';
    } else if (appointment.employeeId && appointment.status !== 'assigne') {
      backgroundColor = '#FFA500'; // Orange
      borderColor = appointment.employeeColor || '#808080';
      textColor = '#000000';
      statusText = 'Validation requise';
    } else {
      backgroundColor = appointment.employeeColor || '#808080';
      borderColor = appointment.employeeColor || '#808080';
      textColor = isLightColor(appointment.employeeColor) ? '#000000' : '#FFFFFF';
      statusText = getEmployeeName(appointment.employeeId);
    }

    return { backgroundColor, borderColor, textColor, statusText };
  };

  // Ajouter cette fonction après les autres fetchs
const fetchApprovedLeaves = useCallback(async () => {
  if (!db) return;
  try {
    const leavesRef = collection(db, 'leaves');
    const q = query(
      leavesRef, 
      where('status', '==', 'approved')
    );
    const querySnapshot = await getDocs(q);
    const leaves = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      startDate: doc.data().startDate.toDate(),
      endDate: doc.data().endDate.toDate()
    }));
    setApprovedLeaves(leaves);
  } catch (error) {
    console.error("Erreur lors de la récupération des congés:", error);
  }
}, [db]);

useEffect(() => {
  fetchApprovedLeaves();
}, [fetchApprovedLeaves]);

const isEmployeeOnLeave = useCallback((date, employeeId) => {
  return approvedLeaves.some(leave => 
    leave.employeeId === employeeId &&
    date >= leave.startDate &&
    date <= leave.endDate
  );
}, [approvedLeaves]);



  const renderAppointments = (date, employeeId = null) => {
     // Si l'employé est en congé, affiche une indication
     if (employeeId && isEmployeeOnLeave(date, employeeId)) {
      return (
        <div className="leave-indicator">
          <div className="leave-content">
            <Clock className="leave-icon" size={18} />
            <span>En congé</span>
          </div>
        </div>
      );
    }
    return appointmentsWithColors
      .filter(app => isSameDay(new Date(app.date), date))
      .filter(app => {
        if (employeeId === null) {
          return app.status === 'libre' || app.status === 'reserve';
        } else {
          return app.employeeId === employeeId && (app.status === 'assigne' || app.status === 'completed');
        }
      })
      .sort((a, b) => a.startTime.localeCompare(b.startTime))
      .map((appointment, appIndex) => {
        let backgroundColor, borderColor, textColor, statusText, icon;

        switch(appointment.status) {
          case 'libre':
            backgroundColor = '#FEE2E2';
            borderColor = '#EF4444';
            textColor = '#991B1B';
            statusText = 'Plage à combler';
            icon = <AlertCircle size={12} className="inline-block mr-1 text-red-600" />;
            break;
          case 'reserve':
            backgroundColor = '#FDE68A';
            borderColor = '#F59E0B';
            textColor = '#92400E';
            statusText = 'En attente de validation';
            icon = <Loader size={12} className="inline-block mr-1 animate-spin" />;
            break;
          case 'assigne':
            backgroundColor = appointment.employeeColor || '#808080';
            borderColor = appointment.employeeColor || '#808080';
            textColor = isLightColor(backgroundColor) ? '#1F2937' : '#F9FAFB';
            statusText = getEmployeeName(appointment.employeeId);
            icon = <User size={12} className="inline-block mr-1" />;
            break;
          case 'completed':
            backgroundColor = 'rgba(156, 163, 175, 0.3)';
            borderColor = '#9CA3AF';
            textColor = '#4B5563';
            statusText = 'Terminé';
            icon = <Check size={12} className="inline-block mr-1 text-green-500" />;
            break;
          default:
            backgroundColor = '#E5E7EB';
            borderColor = '#9CA3AF';
            textColor = '#4B5563';
            statusText = 'Statut inconnu';
            icon = null;
        }

        return (
          <Draggable key={appointment.id} draggableId={appointment.id} index={appIndex}>
            {(provided, snapshot) => (
              <div
                ref={provided.innerRef}
                {...provided.draggableProps}
                {...provided.dragHandleProps}
                className={`pv-appointment ${snapshot.isDragging ? 'opacity-50' : ''} p-1 mb-1 rounded text-xs shadow-md`}
                style={{
                  ...provided.draggableProps.style,
                  backgroundColor: backgroundColor,
                  borderLeft: `4px solid ${borderColor}`,
                  color: textColor
                }}
                onClick={(e) => {
                  e.stopPropagation();
                  handleAppointmentClick(appointment);
                }}
              >
                <div className="font-bold truncate p-1">
                  {icon}
                  <span>{statusText}</span>
                </div>
                <div className="bg-white text-gray-800 p-1 mt-1 rounded">
                  <Clock size={12} className="inline-block mr-1" />
                  {`${appointment.startTime} - ${appointment.endTime}`}
                </div>
              </div>
            )}
          </Draggable>
        );
      });
  };


  return (
    <div className="pv-container h-full flex flex-col overflow-hidden">
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="flex-grow overflow-auto">
          <div className="pv-week-grid">
            {/* En-tête des jours */}
            <div className="pv-header"></div>
            {weekDates.map((date, index) => (
              <div 
                key={`header-${index}`} 
                className={`pv-header ${isToday(date) ? 'pv-today' : ''}`}
              >
                {formatDate(date)}
              </div>
            ))}

            {/* Ligne pour les plages à combler */}
            <div className="pv-employee-name pv-unassigned">
              Plages à combler
            </div>
            {weekDates.map((date, dayIndex) => (
              <Droppable key={`unassigned-${dayIndex}`} droppableId={`unassigned-${dayIndex}`}>
                {(provided, snapshot) => (
                  <div
                    {...provided.droppableProps}
                    ref={provided.innerRef}
                    className={`pv-day-cell pv-unassigned ${isToday(date) ? 'pv-today' : ''}`}
                    onClick={() => handleCellClick(date)}
                  >
                    {renderAppointments(date)}
                    {provided.placeholder}
                  </div>
                )}
              </Droppable>
            ))}

            {/* Lignes des employés */}
            {employees.map(employee => (
              <React.Fragment key={employee.userId}>
                <div className="pv-employee-name">
                  {employee.name || `${employee.firstName} ${employee.lastName}`}
                </div>
                {weekDates.map((date, dayIndex) => (
                  <Droppable key={`${employee.userId}-${dayIndex}`} droppableId={`${employee.userId}-${dayIndex}`}>
                    {(provided, snapshot) => (
                      <div
                        {...provided.droppableProps}
                        ref={provided.innerRef}
                        className={`pv-day-cell ${isToday(date) ? 'pv-today' : ''}`}
                        onClick={() => handleCellClick(date, employee.userId)}
                      >
                        {renderAppointments(date, employee.userId)}
                        {provided.placeholder}
                      </div>
                    )}
                  </Droppable>
                ))}
              </React.Fragment>
            ))}
          </div>
        </div>
      </DragDropContext>
  
      {showAddModal && (
  <Modal onClose={() => setShowAddModal(false)}>
    <ClientAddAppointmentModal
      date={selectedDate}
      employees={employees}
      onSave={handleAddAppointment}
      onClose={() => setShowAddModal(false)}
      initialEmployeeId={selectedEmployee}
    />
  </Modal>
      )}
  
  {isEditModalOpen && selectedAppointment && (
        <Modal onClose={handleCloseEditModal}>
          <ClientEditAppointmentModal
            appointment={selectedAppointment}
            employees={employees}
            onSave={handleUpdateAppointment}
            onDelete={handleDeleteAppointment}
            onClose={handleCloseEditModal}
          />
        </Modal>
      )}
    </div>
  );

};

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="pv-modal w-full max-w-md animate-fade-in-down">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
}; 


// Fonctions utilitaires
const lightenColor = (color, percent) => {
  const num = parseInt(color.replace("#",""), 16),
    amt = Math.round(2.55 * percent),
    R = Math.min((num >> 16) + amt, 255),
    G = Math.min((num >> 8 & 0x00FF) + amt, 255),
    B = Math.min((num & 0x0000FF) + amt, 255);
  return "#" + (0x1000000 + R*0x10000 + G*0x100 + B).toString(16).slice(1);
};

const isLightColor = (color) => {
  if (!color) return false; // ou true, selon ce qui est le plus approprié pour votre cas d'utilisation
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness > 155;
};

export default ClientWeekView;