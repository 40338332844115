import React, { useState, useEffect, useCallback } from 'react';
import { ChevronLeft, ChevronRight, Calendar } from 'lucide-react';
import ClientDayView from './ClientDayView';
import ClientWeekView from './ClientWeekView';
import ClientWeekViewMobile from './ClientWeekViewMobile';
import ClientMonthView from './ClientMonthView';

const ClientPlanning = ({ db, client, userPermissions }) => {
  const [currentView, setCurrentView] = useState('week');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  const goToToday = useCallback(() => {
    console.log("Going to today");
    setCurrentDate(new Date());
  }, []);

  const goToPrevious = useCallback(() => {
    console.log("Going to previous");
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      switch (currentView) {
        case 'day':
          newDate.setDate(newDate.getDate() - 1);
          break;
        case 'week':
          newDate.setDate(newDate.getDate() - 7);
          break;
        case 'month':
          newDate.setMonth(newDate.getMonth() - 1);
          break;
        default:
          break;
      }
      console.log("New date after going previous:", newDate);
      return newDate;
    });
  }, [currentView]);
  
  const goToNext = useCallback(() => {
    console.log("Going to next");
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate);
      switch (currentView) {
        case 'day':
          newDate.setDate(newDate.getDate() + 1);
          break;
        case 'week':
          newDate.setDate(newDate.getDate() + 7);
          break;
        case 'month':
          newDate.setMonth(newDate.getMonth() + 1);
          break;
        default:
          break;
      }
      console.log("New date after going next:", newDate);
      return newDate;
    });
  }, [currentView]);

  useEffect(() => {
    console.log("Current view changed to:", currentView);
    console.log("Current date is:", currentDate);
  }, [currentView, currentDate]);

  const formatDateRange = useCallback(() => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    switch (currentView) {
      case 'day':
        return currentDate.toLocaleDateString('fr-FR', options);
      case 'week':
        const weekStart = new Date(currentDate);
        const dayOfWeek = weekStart.getDay();
        const startOfWeek = new Date(weekStart);
        // Correction du calcul du début de semaine
        startOfWeek.setDate(weekStart.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
        const weekEnd = new Date(startOfWeek);
        weekEnd.setDate(startOfWeek.getDate() + 6);
        return `${startOfWeek.toLocaleDateString('fr-FR', options)} - ${weekEnd.toLocaleDateString('fr-FR', options)}`;
      case 'month':
        return currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
      default:
        return '';
    }
}, [currentView, currentDate]);

  const formatDate = useCallback((date) => {
    return date.toLocaleDateString('fr-FR', { weekday: 'short', day: 'numeric', month: 'long' });
  }, []);

  const formatTime = useCallback((time) => {
    return time;
  }, []);

  const renderMonthView = useCallback(() => {
    console.log("Rendering MonthView with date:", currentDate);
    return (
      <ClientMonthView
        key={currentDate.toISOString()} // Ajout d'une clé unique
        db={db}
        client={client}
        currentDate={currentDate}
        formatDate={formatDate}
        formatTime={formatTime}
        onClose={() => {}}
        onAppointmentClick={() => {}}
        onAddAppointment={() => {}}
        onUpdateAppointment={() => {}}
        onDeleteAppointment={() => {}}
      />
    );
  }, [currentDate, db, client, formatDate, formatTime]);

  return (
    <div className="pv-container">
      <h2 className="pv-title">{client.lastName} {client.firstName} </h2>
      <div className="pv-controls">
        <div className="pv-view-buttons">
          <button onClick={() => setCurrentView('day')} className={`pv-button ${currentView === 'day' ? 'pv-button-primary' : ''}`}>Jour</button>
          <button onClick={() => setCurrentView('week')} className={`pv-button ${currentView === 'week' ? 'pv-button-primary' : ''}`}>Semaine</button>
          <button onClick={() => setCurrentView('month')} className={`pv-button ${currentView === 'month' ? 'pv-button-primary' : ''}`}>Mois</button>
        </div>
        <div className="pv-date-navigation">
          <button onClick={goToPrevious} className="pv-button pv-button-icon"><ChevronLeft size={18} /></button>
          <span className="pv-date-range">{formatDateRange()}</span>
          <button onClick={goToNext} className="pv-button pv-button-icon"><ChevronRight size={18} /></button>
        </div>
        <button onClick={goToToday} className="pv-button pv-today-button">
          <Calendar size={16} />
          <span>Aujourd'hui</span>
        </button>
      </div>
      <div className="pv-view-container">
      {currentView === 'day' && (
          <ClientDayView
            db={db}
            client={client}
            currentDate={currentDate}
            formatDate={formatDate}
            formatTime={formatTime}
            userPermissions={userPermissions}
          />
        )}
        {currentView === 'week' && (
          isMobile ? (
            <ClientWeekViewMobile
              db={db}
              client={client}
              currentDate={currentDate}
              formatDate={formatDate}
              formatTime={formatTime}
              userPermissions={userPermissions}
            />
          ) : (
            <ClientWeekView
              db={db}
              client={client}
              currentDate={currentDate}
              formatDate={formatDate}
              formatTime={formatTime}
              userPermissions={userPermissions}
            />
          )
        )}
        {currentView === 'month' && renderMonthView()}
      </div>
    </div>
  );
};

export default ClientPlanning;