import { useRef, useEffect, useCallback } from 'react';
import { getAuth, signOut } from 'firebase/auth';
import { useNavigate } from 'react-router-dom';
import { getDatabase, ref, set, onDisconnect } from 'firebase/database';

const INACTIVITY_TIMEOUT = 60 * 60 * 1000; // 1 heure en millisecondes

const useInactivityTimer = () => {
  const timeoutRef = useRef(null);
  const navigate = useNavigate();
  const auth = getAuth();
  const db = getDatabase();

  const resetTimer = useCallback(() => {
    if (timeoutRef.current) {
      clearTimeout(timeoutRef.current);
    }
    timeoutRef.current = setTimeout(async () => {
      const user = auth.currentUser;
      if (user) {
        const userStatusRef = ref(db, `presence/${user.uid}`);
        // Mettre à jour le statut de l'utilisateur à 'offline' dans la Realtime Database
        await set(userStatusRef, {
          state: 'offline',
          last_changed: new Date().toISOString()
        });
        await signOut(auth);
        navigate('/login');
        alert("Vous avez été déconnecté en raison d'inactivité.");
      }
    }, INACTIVITY_TIMEOUT);
  }, [auth, navigate, db]);

  useEffect(() => {
    const user = auth.currentUser;
    if (user) {
      const userStatusRef = ref(db, `presence/${user.uid}`);
      // Mettre à jour le statut de l'utilisateur à 'online' lorsqu'il se connecte
      set(userStatusRef, {
        state: 'online',
        last_changed: new Date().toISOString()
      });
      // Configurer le changement de statut à 'offline' lors de la déconnexion
      onDisconnect(userStatusRef).set({
        state: 'offline',
        last_changed: new Date().toISOString()
      });
    }

    resetTimer();

    // Ajouter des écouteurs d'événements pour réinitialiser le timer
    const events = ['mousedown', 'keydown', 'touchstart', 'scroll'];
    events.forEach(event => {
      window.addEventListener(event, resetTimer);
    });

    return () => {
      if (timeoutRef.current) {
        clearTimeout(timeoutRef.current);
      }
      // Nettoyer les écouteurs d'événements
      events.forEach(event => {
        window.removeEventListener(event, resetTimer);
      });
    };
  }, [resetTimer, auth, db]);

  return resetTimer;
};

export default useInactivityTimer;