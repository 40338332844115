import React from 'react';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { EuroIcon } from 'lucide-react';

const RevenueDisplay = ({ monthlyStats }) => {
  return (
    <div className="mt-6">
      <h3 className="text-xl font-semibold mb-2 flex items-center">
        <EuroIcon className="mr-2" size={20} />
        Revenus mensuels
      </h3>
      <div className="bg-white p-4 rounded-lg shadow">
        <ResponsiveContainer width="100%" height={200}>
          <BarChart data={monthlyStats}>
            <CartesianGrid strokeDasharray="3 3" />
            <XAxis dataKey="name" />
            <YAxis />
            <Tooltip />
            <Legend />
            <Bar dataKey="amount" fill="#22C55E" name="Revenus (€)" />
          </BarChart>
        </ResponsiveContainer>
      </div>
      <div className="mt-4 text-right">
        <p className="text-lg font-semibold">
          Total des revenus : {monthlyStats.reduce((sum, month) => sum + month.amount, 0).toFixed(2)}€
        </p>
      </div>
    </div>
  );
};

export default RevenueDisplay;