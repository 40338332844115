export const getAuthErrorMessage = (errorCode) => {
    switch (errorCode) {
      // Erreurs générales
      case 'auth/app-deleted':
        return "L'application a été supprimée.";
      case 'auth/app-not-authorized':
        return "L'application n'est pas autorisée.";
      case 'auth/argument-error':
        return "Erreur d'argument dans la requête d'authentification.";
      case 'auth/invalid-api-key':
        return "La clé API fournie est invalide.";
      case 'auth/invalid-user-token':
        return "Le jeton de l'utilisateur n'est plus valide. Veuillez vous reconnecter.";
      case 'auth/network-request-failed':
        return "Une erreur de réseau s'est produite. Veuillez vérifier votre connexion.";
      case 'auth/operation-not-allowed':
        return "Cette opération n'est pas autorisée.";
      case 'auth/requires-recent-login':
        return "Cette opération nécessite une connexion récente. Veuillez vous reconnecter.";
      case 'auth/too-many-requests':
        return "Trop de tentatives. Veuillez réessayer plus tard.";
      case 'auth/unauthorized-domain':
        return "Le domaine de l'application n'est pas autorisé.";
      case 'auth/user-disabled':
        return "Ce compte utilisateur a été désactivé.";
  
      // Erreurs spécifiques à la création de compte
      case 'auth/email-already-in-use':
        return "Cette adresse e-mail est déjà utilisée par un autre compte.";
      case 'auth/invalid-email':
        return "L'adresse e-mail fournie n'est pas valide.";
      case 'auth/weak-password':
        return "Le mot de passe est trop faible. Il doit contenir au moins 6 caractères.";
  
      // Erreurs spécifiques à la connexion
      case 'auth/user-not-found':
        return "Aucun compte ne correspond à cette adresse e-mail.";
      case 'auth/wrong-password':
        return "Le mot de passe est incorrect.";
      case 'auth/invalid-login-credentials':
        return "Les identifiants de connexion sont invalides.";
  
      // Erreurs spécifiques à la réinitialisation du mot de passe
      case 'auth/expired-action-code':
        return "Le code de réinitialisation du mot de passe a expiré.";
      case 'auth/invalid-action-code':
        return "Le code de réinitialisation du mot de passe est invalide.";
      case 'auth/user-mismatch':
        return "L'utilisateur ne correspond pas à la demande de réinitialisation.";
  
      // Erreur par défaut
      default:
        return "Une erreur s'est produite. Veuillez réessayer.";
    }
  };