import React, { useState } from 'react';
import { getAuth, sendPasswordResetEmail } from 'firebase/auth';
import { Link } from 'react-router-dom';
import { RefreshCw, Mail } from 'lucide-react';
import { getAuthErrorMessage } from './firebaseAuthErrors';
import AuthLayout from './AuthLayout';

function ForgotPassword() {
  const [email, setEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(false);
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setSuccess(false);
    setIsLoading(true);
    try {
      const auth = getAuth();
      await sendPasswordResetEmail(auth, email);
      setSuccess(true);
    } catch (error) {
      console.error("Erreur de réinitialisation du mot de passe:", error);
      setError(getAuthErrorMessage(error.code));
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <AuthLayout title="Réinitialisation du mot de passe">
      <form className="auth-fade-in" onSubmit={handleSubmit}>
        <div className="auth-input-group">
          <label htmlFor="email" className="auth-label">
            Adresse e-mail
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="email"
              name="email"
              type="email"
              autoComplete="email"
              required
              className="auth-input pl-10"
              placeholder="vous@exemple.com"
              value={email}
              onChange={(e) => setEmail(e.target.value)}
            />
          </div>
        </div>

        {error && <div className="auth-error">{error}</div>}
        {success && <div className="auth-success">Un e-mail de réinitialisation a été envoyé à votre adresse.</div>}

        <button
          type="submit"
          disabled={isLoading}
          className="auth-button"
        >
          {isLoading ? 'Envoi en cours...' : 'Réinitialiser le mot de passe'}
        </button>
      </form>

      <div className="auth-separator">
        <span className="auth-separator-text">Ou</span>
      </div>

      <div className="mt-6">
        <Link
          to="/login"
          className="auth-button auth-button-secondary"
        >
          Retour à la connexion
        </Link>
      </div>
    </AuthLayout>
  );
}

export default ForgotPassword;