import React, { useState, useEffect, useRef, useCallback } from 'react';
import { Bell, Settings, HelpCircle, Menu, User, LogOut, Clock } from 'lucide-react';
import { motion } from 'framer-motion';
import { getAuth, signOut } from 'firebase/auth';
import { getDatabase, ref, set } from 'firebase/database';
import { useNavigate } from 'react-router-dom';
import Sidebar from './Sidebar';
import Dashboard from './Dashboard';
import Planning from './Planning';
import EmployeeManagement from './EmployeeManagement';
import ClientManagement from './ClientManagement';
import GeneralSettings from './GeneralSettings';
import EmployeeReports from './EmployeeReports';
import RolesPositionsManagement from './RolesPositionsManagement';
import PlanningManagementSettings from './PlanningManagementSettings';
import { getFirestore, doc, getDoc, updateDoc, serverTimestamp, collection, query, where, getDocs, addDoc, deleteDoc, orderBy } from 'firebase/firestore';
import { db, storage, auth } from '../firebase';
import ClientDetails from './ClientDetails';
import ClientDiscussion from './ClientDiscussion';
import ClientNotes from './ClientNotes';
import ClientContract from './ClientContract';
import ClientReport from './ClientReport';
import ClientPlanning from './ClientPlanning';
import ClientSummary from './ClientSummary';
import LeaveManagement from './LeaveManagement';  // Ajoutez cet import en haut du fichier
import useSyncEmailWithFirestore from '../hooks/useSyncEmailWithFirestore';
import TaskManager from './TaskManager';
import usePresence from '../hooks/usePresence'; // Assurez-vous que le chemin est correct
import useInactivityTimer from '../hooks/useInactivityTimer'; // Importer le nouveau hook
import { setupForcedLogout, checkAndUpdateUserStatus } from '../hooks/setupForcedLogout'; 
import { SpeedInsights } from "@vercel/speed-insights/react"


const MainLayout = () => {
  const [currentView, setCurrentView] = useState('dashboard');
  const [selectedClient, setSelectedClient] = useState(null);
  const [availableColors, setAvailableColors] = useState([]);
  const [generalSettings, setGeneralSettings] = useState({
    organizationName: 'AgenTime',
  });
  const [isSidebarOpen, setIsSidebarOpen] = useState(false);
  const sidebarRef = useRef(null);
  const [isProfileMenuOpen, setIsProfileMenuOpen] = useState(false);
  const [employeeData, setEmployeeData] = useState(null);
  const profileMenuRef = useRef(null);
  const navigate = useNavigate();
  const [lastLoginTime, setLastLoginTime] = useState(null);
  const [userPermissions, setUserPermissions] = useState({});
  const [notifications, setNotifications] = useState([]);
  const [isNotificationsOpen, setIsNotificationsOpen] = useState(false);

  const notificationsRef = useRef(null);
  const resetInactivityTimer = useInactivityTimer(); // Utiliser le nouveau hook
  
  usePresence();
  useSyncEmailWithFirestore(); 

  useEffect(() => {
    const auth = getAuth();
    const rtdb = getDatabase();
    
    const unsubscribe = auth.onAuthStateChanged(async (user) => {
      if (user) {
        try {
          const employeeDoc = await getDoc(doc(db, 'employees', user.uid));
          if (employeeDoc.exists()) {
            const data = { id: employeeDoc.id, ...employeeDoc.data() };
            
            // Mise à jour simple du lastLogin
            await updateDoc(doc(db, 'employees', user.uid), {
              lastLogin: serverTimestamp()
            });
            
            setEmployeeData(data);
            setLastLoginTime(data.lastLogin);
    
            const roleDoc = await getDoc(doc(db, 'roles', data.role));
            if (roleDoc.exists()) {
              setUserPermissions(roleDoc.data().permissions.reduce((acc, perm) => {
                acc[perm] = true;
                return acc;
              }, {}));
            }
          } else {
            await auth.signOut();
            navigate('/login');
          }
        } catch (error) {
          console.error("Erreur:", error);
          await auth.signOut();
          navigate('/login');
        }
      } else {
        setEmployeeData(null);
        setUserPermissions({});
        setLastLoginTime(null);
        navigate('/login');
      }
    });
  
    return () => unsubscribe();
  }, [navigate]);
  

  const hasPermission = useCallback((permission) => {
    return !!userPermissions[permission];
  }, [userPermissions]);

  const formatLastLoginTime = (timestamp) => {
    if (!timestamp) return 'Première connexion';
    try {
      // Vérifier si timestamp est un objet Firestore Timestamp
      if (timestamp?.toDate) {
        return timestamp.toDate().toLocaleString('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      }
      // Si c'est déjà une date
      if (timestamp instanceof Date) {
        return timestamp.toLocaleString('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      }
      // Si c'est un timestamp numérique
      if (typeof timestamp === 'number') {
        return new Date(timestamp).toLocaleString('fr-FR', {
          year: 'numeric',
          month: 'long',
          day: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      }
      return 'Date non valide';
    } catch (error) {
      console.error("Erreur lors du formatage de la date:", error);
      return 'Date non valide';
    }
  };

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (profileMenuRef.current && !profileMenuRef.current.contains(event.target)) {
        setIsProfileMenuOpen(false);
      }
      if (notificationsRef.current && !notificationsRef.current.contains(event.target)) {
        setIsNotificationsOpen(false);
      }
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setIsSidebarOpen(false);
      }
    };
  
    document.addEventListener('mousedown', handleClickOutside);
    
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

const handleUserActivity = useCallback(() => {
    resetInactivityTimer();
  }, [resetInactivityTimer]);

  useEffect(() => {
    // Ajouter les écouteurs d'événements une seule fois
    window.addEventListener('mousemove', handleUserActivity);
    window.addEventListener('keydown', handleUserActivity);
    window.addEventListener('mousedown', handleUserActivity);
    window.addEventListener('touchstart', handleUserActivity);

    return () => {
      // Nettoyer les écouteurs d'événements
      window.removeEventListener('mousemove', handleUserActivity);
      window.removeEventListener('keydown', handleUserActivity);
      window.removeEventListener('mousedown', handleUserActivity);
      window.removeEventListener('touchstart', handleUserActivity);
    };
  }, [handleUserActivity]);
  

  const handleLogout = async () => {
    const auth = getAuth();
    const db = getDatabase();
    
  
    try {
      const user = auth.currentUser;
      if (user) {
        // Mettre à jour le statut de présence à false
        const userStatusRef = ref(db, `presence/${user.uid}`);
        await set(userStatusRef, false);
      }
  
      // Déconnecter l'utilisateur
      await signOut(auth);
      
      // Rediriger vers la page de connexion
      navigate('/login');
    } catch (error) {
      console.error("Erreur lors de la déconnexion:", error);
      // Vous pouvez ajouter ici une gestion d'erreur plus détaillée si nécessaire
    }
  };

  useEffect(() => {
    const fetchGeneralSettings = async () => {
      try {
        const settingsDoc = await getDoc(doc(db, 'settings', 'general'));
        if (settingsDoc.exists()) {
          const settingsData = settingsDoc.data();
          setGeneralSettings(settingsData);
        }
      } catch (error) {
        console.error("Erreur lors du chargement des paramètres généraux:", error);
      }
    };

    fetchGeneralSettings();
  }, []);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSelectedClient(prev => ({ ...prev, [name]: value }));
  };

  const handleSaveClient = async () => {
    // Logique pour sauvegarder le client
    // Vous devrez implémenter cette fonction
  };

  const renderContent = () => {
    console.log("%c[MainLayout] Rendering content, currentView:", "color: #007bff; font-weight: bold;", currentView);
    console.log("%c[MainLayout] Passing currentUser to child:", "color: #007bff; font-weight: bold;", employeeData);
  const components = {
      dashboard: (props) => <Dashboard {...props} />,
      planning: (props) => <Planning {...props} currentUser={employeeData} />,
      presence: (props) => <LeaveManagement {...props} db={db} currentUser={employeeData} hasPermission={hasPermission} />,
      employeeList: EmployeeManagement,
      clientList: (props) => (
        <ClientManagement
          {...props}
          db={db}
          setCurrentView={setCurrentView}
          setSelectedClient={setSelectedClient}
        />
      ),
      clientSummary: () => (
        <ClientSummary
          client={selectedClient}
          db={db}
          currentUser={employeeData}
          handleInputChange={handleInputChange}
          handleSave={handleSaveClient}
          availableColors={availableColors}
          hasPermission={hasPermission}
        />
      ),
      clientDiscussion: (props) => <ClientDiscussion {...props} client={selectedClient} />,
      clientNotes: () => (
        <ClientNotes 
          db={db} 
          client={selectedClient} 
          currentUser={employeeData} 
          hasPermission={hasPermission}
        />
      ),
      clientContract: (props) => <ClientContract {...props} client={selectedClient} />,
      clientReport: () => (
        <ClientReport
          client={selectedClient}
          db={db}
          // Ajoutez d'autres props nécessaires pour ClientReport
        />
      ),
      clientPlanning: () => (
        <ClientPlanning
          db={db}
          client={selectedClient}
          userPermissions={userPermissions}
        />
      ),
      generalSettings: GeneralSettings,
      employeeReports: EmployeeReports,
      rolesPositions: RolesPositionsManagement,
      planningsettings: PlanningManagementSettings,
      TaskManager: (props) => <TaskManager {...props} db={db} />,
    };

    const Component = components[currentView] || Dashboard;

    return <Component 
      db={db} 
      storage={storage} 
      onSettingsUpdate={setGeneralSettings}
      setCurrentView={setCurrentView}
      setSelectedClient={setSelectedClient}
      currentUser={employeeData}  // Ajoutez cette ligne
    />;
  };

  const toggleSidebar = () => {
    setIsSidebarOpen(!isSidebarOpen);
  };

  const handleClickOutside = (event) => {
    if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
      setIsSidebarOpen(false);
    }
  };

  useEffect(() => {
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const getDisplayName = () => {
    if (!employeeData) return 'Chargement...';
    
    // Pour les écrans larges, retourner le nom complet
    const fullName = `${employeeData.firstName} ${employeeData.lastName}`;
    
    // Pour les petits écrans, retourner les initiales ou le prénom
    const initials = `${employeeData.firstName[0]}${employeeData.lastName[0]}`;
    
    return (
      <>
        <span className="hidden md:inline">{fullName}</span>
        <span className="md:hidden">{initials}</span>
      </>
    );
  };

  useEffect(() => {
    console.log("%c[MainLayout] currentUser updated:", "color: #007bff; font-weight: bold;", employeeData);
  }, [employeeData]);

  const fetchNotifications = useCallback(async () => {
    if (!employeeData?.id) return;
  
    try {
      const now = new Date();
      const tomorrow = new Date(now);
      tomorrow.setDate(tomorrow.getDate() + 1);
      
      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('employeeId', '==', employeeData.id),
        where('date', '>=', now),
        where('date', '<=', tomorrow),
        orderBy('date', 'asc')
      );
  
      const querySnapshot = await getDocs(q);
      const newNotifications = querySnapshot.docs.map(doc => {
        const data = doc.data();
        const date = data.date?.toDate() || new Date();
        return {
          id: doc.id,
          clientId: data.clientId || '',
          date: date,
          message: `Rendez-vous avec ${data.clientName || 'Client'} à ${date.toLocaleTimeString([], {hour: '2-digit', minute:'2-digit'})}`
        };
      }).filter(notif => notif.clientId && notif.date); // Filtrer les notifications invalides
  
      setNotifications(newNotifications);
    } catch (error) {
      console.error("Erreur lors de la récupération des notifications:", error);
      setNotifications([]);
    }
  }, [db, employeeData]);

  useEffect(() => {
    if (employeeData?.id) {
      fetchNotifications();
    }
  }, [fetchNotifications, employeeData]);
  
  return (
    <div className="flex flex-col h-screen bg-gray-100 overflow-hidden">
      <header className="bg-white border-b shadow-sm z-10">
        <div className="flex justify-between items-center p-4">
          <div className="flex items-center">
            <button 
              className="mr-4 text-gray-600 hover:text-blue-600 transition-colors md:hidden"
              onClick={toggleSidebar}
            >
              <Menu size={24} />
            </button>
            <h1 className="text-xl md:text-2xl font-bold text-blue-600 truncate">{generalSettings.organizationName}</h1>
          </div>
          <div className="flex items-center space-x-4">
            <button className="text-gray-600 hover:text-blue-600 transition-colors">
              <HelpCircle size={24} />
            </button>
            <div className="relative" ref={notificationsRef}>
  <button 
    className="text-gray-600 hover:text-blue-600 transition-colors"
    onClick={() => setIsNotificationsOpen(!isNotificationsOpen)}
  >
    <Bell size={24} />
    {notifications.length > 0 && (
      <span className="absolute top-0 right-0 inline-flex items-center justify-center px-2 py-1 text-xs font-bold leading-none text-red-100 transform translate-x-1/2 -translate-y-1/2 bg-red-600 rounded-full">
        {notifications.length}
      </span>
    )}
  </button>
  {isNotificationsOpen && (
    <div className="absolute right-0 mt-2 w-80 bg-white rounded-lg shadow-xl overflow-hidden z-10">
   
      <div className="px-4 py-2 bg-gray-100 border-b font-semibold">
        Notifications
      </div>
      <div className="max-h-64 overflow-y-auto">
        {notifications.length === 0 ? (
          <p className="px-4 py-2 text-gray-500">Aucune notification</p>
        ) : (
          notifications.map(notif => (
            <div key={notif.id} className="px-4 py-2 hover:bg-gray-100 border-b">
              <p className="text-sm">{notif.message}</p>
            </div>
          ))
        )}
      </div>
    </div>
  )}
</div>
            <div className="relative" ref={profileMenuRef}>
              <button 
                className="flex items-center text-gray-600 hover:text-blue-600 transition-colors"
                onClick={() => setIsProfileMenuOpen(!isProfileMenuOpen)}
              >
                {employeeData?.photoURL ? (
                  <img src={employeeData.photoURL} alt="Profile" className="w-8 h-8 rounded-full mr-2" />
                ) : (
                  <User size={24} className="mr-2" />
                )}
                <span className="ml-2 font-semibold">{getDisplayName()}</span>
              </button>

              {isProfileMenuOpen && (
                <div className="absolute right-0 mt-2 w-64 bg-white rounded-lg shadow-xl overflow-hidden z-10">
                  <div className="px-4 py-3 text-sm text-gray-700 border-b">                 
                    <p className="text-gray-500 text-sm mt-1">Dernière connexion :</p>
                    <p className="flex items-center text-sm text-gray-700 mt-1">
                      <Clock size={16} className="mr-2" />
                      <span>{formatLastLoginTime(lastLoginTime)}</span>
                    </p>
                  </div>

                  <div className="py-2">
                    <a 
                      href="/profile" 
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-blue-600 transition-colors"
                    >
                      Profil
                    </a>
                    <a 
                      href="/settings" 
                      className="block px-4 py-2 text-gray-700 hover:bg-gray-100 hover:text-blue-600 transition-colors"
                    >
                      Paramètres
                    </a>
                  </div>

                  <div className="border-t">
                    <button 
                      onClick={handleLogout}
                      className="w-full text-left px-4 py-2 text-gray-700 hover:bg-red-100 hover:text-red-600 transition-colors"
                    >
                      Déconnexion
                    </button>
                  </div>
                </div>
              )}
            </div>
          </div>
        </div>
      </header>
      <div className="flex flex-1 overflow-hidden">
        <div ref={sidebarRef}>
          <Sidebar
            setCurrentView={setCurrentView}
            currentView={currentView}
            isOpen={isSidebarOpen}
            toggleSidebar={toggleSidebar}
            selectedClient={selectedClient}
            setSelectedClient={setSelectedClient}
            currentUser={employeeData}
            hasPermission={hasPermission}
          />
        </div>
        <motion.main
          className="flex-1 overflow-hidden p-4 md:p-6 bg-white m-2 md:m-4 rounded-lg shadow-sm"
          initial={{ opacity: 0, y: 20 }}
          animate={{ opacity: 1, y: 0 }}
          transition={{ duration: 0.3 }}
        >
          <div className="h-full overflow-auto">
            {renderContent()}
          </div>
        </motion.main>
      </div>
    </div>
  );
};

export default MainLayout;