import React, { useState, useEffect } from 'react';
import { CreditCard, TrendingUp, TrendingDown, Calendar } from 'lucide-react';
import { collection, query, where, getDocs, Timestamp, doc, getDoc } from 'firebase/firestore';

const FinancialSummaryWidget = ({ db }) => {
  const [financialData, setFinancialData] = useState({
    currentMonthRevenue: 0,
    previousMonthRevenue: 0,
    yearToDateRevenue: 0,
    lastYearRevenue: 0
  });
  const [isLoading, setIsLoading] = useState(true);

  useEffect(() => {
    const fetchFinancialData = async () => {
      setIsLoading(true);
      try {
        const now = new Date();
        const currentMonthStart = new Date(now.getFullYear(), now.getMonth(), 1);
        const previousMonthStart = new Date(now.getFullYear(), now.getMonth() - 1, 1);
        const yearStart = new Date(now.getFullYear(), 0, 1);
        const lastYearStart = new Date(now.getFullYear() - 1, 0, 1);
        const lastYearEnd = new Date(now.getFullYear() - 1, 11, 31, 23, 59, 59);

        const appointmentsRef = collection(db, 'appointments');
        
        const [currentMonthSnapshot, previousMonthSnapshot, yearToDateSnapshot, lastYearSnapshot] = await Promise.all([
          getDocs(query(appointmentsRef, where('date', '>=', Timestamp.fromDate(currentMonthStart)))),
          getDocs(query(appointmentsRef, where('date', '>=', Timestamp.fromDate(previousMonthStart)), where('date', '<', Timestamp.fromDate(currentMonthStart)))),
          getDocs(query(appointmentsRef, where('date', '>=', Timestamp.fromDate(yearStart)))),
          getDocs(query(appointmentsRef, where('date', '>=', Timestamp.fromDate(lastYearStart)), where('date', '<=', Timestamp.fromDate(lastYearEnd))))
        ]);

        const calculateRevenue = async (snapshot) => {
          let total = 0;
          for (const doc of snapshot.docs) {
            const data = doc.data();
            const contractDoc = await getDoc(doc(db, 'contracts', data.clientId));
            if (contractDoc.exists()) {
              const contractData = contractDoc.data();
              const hourlyRate = contractData.hourlyRate || 0;
              const duration = calculateDuration(data.startTime, data.endTime);
              total += duration * hourlyRate;
            }
          }
          return total;
        };

        const [currentMonthRevenue, previousMonthRevenue, yearToDateRevenue, lastYearRevenue] = await Promise.all([
          calculateRevenue(currentMonthSnapshot),
          calculateRevenue(previousMonthSnapshot),
          calculateRevenue(yearToDateSnapshot),
          calculateRevenue(lastYearSnapshot)
        ]);

        console.log('Current Month Revenue:', currentMonthRevenue);
        console.log('Previous Month Revenue:', previousMonthRevenue);
        console.log('Year to Date Revenue:', yearToDateRevenue);
        console.log('Last Year Revenue:', lastYearRevenue);

        setFinancialData({
          currentMonthRevenue,
          previousMonthRevenue,
          yearToDateRevenue,
          lastYearRevenue
        });
      } catch (error) {
        console.error("Erreur lors de la récupération des données financières:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchFinancialData();
  }, [db]);

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    return (end - start) / (1000 * 60 * 60); // durée en heures
  };

  if (isLoading) {
    return <div>Chargement des données financières...</div>;
  }

  const revenueChange = financialData.currentMonthRevenue - financialData.previousMonthRevenue;
  const revenueChangePercentage = financialData.previousMonthRevenue !== 0
    ? (revenueChange / financialData.previousMonthRevenue) * 100
    : 100;

  return (
    <div className="bg-white p-4 rounded-lg shadow">
      <div className="flex justify-between items-center mb-4">
        <h3 className="text-lg font-semibold">Résumé financier</h3>
        <CreditCard className="text-purple-500" />
      </div>
      <div className="space-y-3">
        <div className="flex justify-between items-center">
          <span className="text-gray-600">CA du mois</span>
          <span className="text-lg font-semibold text-green-600">{financialData.currentMonthRevenue.toFixed(2)} €</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-600">Variation / mois précédent</span>
          <div className="flex items-center">
            {revenueChange >= 0 ? (
              <TrendingUp className="text-green-500 mr-1" size={16} />
            ) : (
              <TrendingDown className="text-red-500 mr-1" size={16} />
            )}
            <span className={`font-semibold ${revenueChange >= 0 ? 'text-green-600' : 'text-red-600'}`}>
              {revenueChangePercentage.toFixed(1)}%
            </span>
          </div>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-600">CA année en cours</span>
          <span className="font-semibold text-blue-600">{financialData.yearToDateRevenue.toFixed(2)} €</span>
        </div>
        <div className="flex justify-between items-center">
          <span className="text-gray-600">CA année précédente</span>
          <span className="font-semibold text-gray-600">{financialData.lastYearRevenue.toFixed(2)} €</span>
        </div>
      </div>
      <div className="mt-4 text-sm text-gray-500 flex items-center">
        <Calendar size={14} className="mr-1" />
        <span>Mise à jour: {new Date().toLocaleDateString()}</span>
      </div>
    </div>
  );
};

export default FinancialSummaryWidget;