import React, { useState } from 'react';
import { Calendar, Clock, User } from 'lucide-react';

const ClientEditAppointmentModal = ({ appointment, employees, onSave, onClose, onDelete }) => {
  const [appointmentData, setAppointmentData] = useState(() => {
    const date = appointment.date instanceof Date && !isNaN(appointment.date)
      ? appointment.date
      : new Date();
    return {
      ...appointment,
      date: date
    };
  });

  const [isSaving, setIsSaving] = useState(false);
  const [isDeleting, setIsDeleting] = useState(false);

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAppointmentData(prev => {
      const newData = { ...prev };
      if (name === 'date') {
        const newDate = new Date(value);
        newData[name] = isNaN(newDate.getTime()) ? prev.date : newDate;
      } else if (name === 'employeeId') {
        newData[name] = value === 'unassigned' ? null : value;
        newData.status = value === 'unassigned' ? 'libre' : 'assigne';
      } else {
        newData[name] = type === 'checkbox' ? checked : value;
      }
      return newData;
    });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    setIsSaving(true);
    try {
      await onSave(appointmentData);
      onClose();
    } catch (error) {
      console.error("Erreur lors de la sauvegarde du rendez-vous:", error);
    } finally {
      setIsSaving(false);
    }
  };

  const handleDelete = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir supprimer ce rendez-vous ?")) {
      setIsDeleting(true);
      try {
        await onDelete(appointmentData.id);
        onClose();
      } catch (error) {
        console.error("Erreur lors de la suppression du rendez-vous:", error);
      } finally {
        setIsDeleting(false);
      }
    }
  };

  const getDayOfWeekName = (day) => {
    const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    return days[day];
  };

  const getWeekOfMonthName = (week) => {
    const weeks = ['premier', 'deuxième', 'troisième', 'quatrième', 'dernier'];
    return weeks[Math.min(week - 1, 4)];
  };

  return (
    <div className="modal">
      <h2 className="text-2xl font-bold mb-4">Modifier le rendez-vous client</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Calendar className="text-gray-400" size={20} />
          <input 
            type="date" 
            name="date" 
            value={appointmentData.date instanceof Date && !isNaN(appointmentData.date) 
              ? appointmentData.date.toISOString().split('T')[0] 
              : ''}
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="text-gray-400" size={20} />
          <input 
            type="time" 
            name="startTime" 
            value={appointmentData.startTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span>à</span>
          <input 
            type="time" 
            name="endTime" 
            value={appointmentData.endTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <select 
            name="employeeId" 
            value={appointmentData.employeeId || 'unassigned'} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="unassigned">Non assigné</option>
            {employees.map(emp => (
              <option key={emp.userId} value={emp.userId}>
                {emp.name || `${emp.firstName} ${emp.lastName}`}
              </option>
            ))}
          </select>
        </div>
        
        {appointmentData.isRecurring && (
          <div className="space-y-4 ml-7">
            <div>
              <select 
                name="recurrenceBase" 
                value={appointmentData.recurrenceBase} 
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="daily">Journalière</option>
                <option value="weekly">Hebdomadaire</option>
                <option value="monthly">Mensuelle</option>
              </select>
            </div>
            {appointmentData.recurrenceBase === 'daily' && (
                <div className="flex items-center space-x-2">
                  <span>Tous les</span>
                  <input 
                    type="number" 
                    name="recurrenceInterval"
                    value={appointmentData.recurrenceInterval || 1} 
                    onChange={handleChange}
                    min="1"
                    className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span>jour(s)</span>
                </div>
              )}

              {appointmentData.recurrenceBase === 'weekly' && (
                <>
                  
                  <div className="flex items-center space-x-2">
                    <span>Tous les</span>
                    <input 
                      type="number" 
                      name="recurrenceFrequency" 
                      value={appointmentData.recurrenceFrequency} 
                      onChange={handleChange}
                      min="1"
                      className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <span>semaine(s)</span>
                  </div>
                </>
              )}

              {appointmentData.recurrenceBase === 'monthly' && (
              <>
                <div>
                  <select
                    name="recurrenceMonthType"
                    value={appointmentData.recurrenceMonthType}
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="dayOfMonth">Le même jour du mois</option>
                    <option value="dayOfWeek">Le même jour de la semaine</option>
                  </select>
                </div>
                {appointmentData.recurrenceMonthType === 'dayOfMonth' ? (
                  <div>
                    Répéter le {appointmentData.recurrenceMonthDay} de chaque mois
                  </div>
                ) : (
                  <div>
                    Répéter le {getWeekOfMonthName(appointmentData.recurrenceMonthWeek)} {getDayOfWeekName(appointmentData.recurrenceMonthWeekday)} de chaque mois
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <span>Tous les</span>
                  <input 
                    type="number" 
                    name="recurrenceMonthFrequency" 
                    value={appointmentData.recurrenceMonthFrequency} 
                    onChange={handleChange}
                    min="1"
                    className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span>mois</span>
                </div>
              </>
            )}
            <div>
              <select
                name="recurrenceEndType"
                value={appointmentData.recurrenceEndType}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="never">Jamais</option>
                <option value="after">Après un nombre d'occurrences</option>
                <option value="on">À une date spécifique</option>
              </select>
            </div>
            {appointmentData.recurrenceEndType === 'after' && (
              <div className="flex items-center space-x-2">
                <span>Après</span>
                <input 
                  type="number" 
                  name="recurrenceEndAfter" 
                  value={appointmentData.recurrenceEndAfter} 
                  onChange={handleChange}
                  min="1"
                  className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span>occurrences</span>
              </div>
            )}
            {appointmentData.recurrenceEndType === 'on' && (
              <div>
                <input 
                  type="date" 
                  name="recurrenceEndDate" 
                  value={appointmentData.recurrenceEndDate || ''} 
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
          </div>
        )}
        
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            type="button" 
            onClick={handleDelete}
            className="px-4 py-2 bg-red-500 text-white rounded hover:bg-red-600 focus:outline-none focus:ring-2 focus:ring-red-500"
            disabled={isDeleting || isSaving}
          >
            {isDeleting ? 'Suppression...' : 'Supprimer'}
          </button>
          <button 
            type="button" 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
            disabled={isSaving}
          >
            Annuler
          </button>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            disabled={isSaving}
          >
            {isSaving ? 'Mise à jour...' : 'Mettre à jour'}
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientEditAppointmentModal;