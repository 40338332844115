import React, { useState, useEffect } from 'react';
import { ChevronLeft, ChevronRight, Calendar } from 'lucide-react';
import DayView from './DayView';
import WeekView from './WeekView';
import MonthView from './MonthView';
import WeekViewMobile from './WeekViewMobile'; // Nouvelle importation

const Planning = ({ db, currentUser }) => {
  console.log("%c[Planning] Received props:", "color: #ffc107; font-weight: bold;", { currentUser });
  const [currentView, setCurrentView] = useState('day');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [adminRoleIds, setAdminRoleIds] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);


  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  useEffect(() => {
    if (isMobile && currentView === 'month') {
      setCurrentView('week');
    }
  }, [isMobile, currentView]);

  const goToToday = () => {
    setCurrentDate(new Date());
  };

  const goToPrevious = () => {
    const newDate = new Date(currentDate);
    switch (currentView) {
      case 'day':
        newDate.setDate(newDate.getDate() - 1);
        break;
      case 'week':
        newDate.setDate(newDate.getDate() - 7);
        break;
      case 'month':
        newDate.setMonth(newDate.getMonth() - 1);
        break;
      default:
        break;
    }
    setCurrentDate(newDate);
  };
  
  const goToNext = () => {
    const newDate = new Date(currentDate);
    switch (currentView) {
      case 'day':
        newDate.setDate(newDate.getDate() + 1);
        break;
      case 'week':
        newDate.setDate(newDate.getDate() + 7);
        break;
      case 'month':
        newDate.setMonth(newDate.getMonth() + 1);
        break;
      default:
        break;
    }
    setCurrentDate(newDate);
  };

  const formatDateRange = () => {
    const options = { weekday: 'short', year: 'numeric', month: 'short', day: 'numeric' };
    
    const getWeekNumber = (date) => {
      const d = new Date(Date.UTC(date.getFullYear(), date.getMonth(), date.getDate()));
      const dayNum = d.getUTCDay() || 7;
      d.setUTCDate(d.getUTCDate() + 4 - dayNum);
      const yearStart = new Date(Date.UTC(d.getUTCFullYear(),0,1));
      return Math.ceil((((d - yearStart) / 86400000) + 1)/7);
    };
  
    switch (currentView) {
      case 'day':
        const weekNum = getWeekNumber(currentDate);
        return `${currentDate.toLocaleDateString('fr-FR', options)} | S${weekNum}`;
      case 'week':
        const weekStart = new Date(currentDate);
        const dayOfWeek = weekStart.getDay();
        const startOfWeek = new Date(weekStart);
        startOfWeek.setDate(weekStart.getDate() - (dayOfWeek === 0 ? 6 : dayOfWeek - 1));
        const weekEnd = new Date(startOfWeek);
        weekEnd.setDate(startOfWeek.getDate() + 6);
        const weekNumStart = getWeekNumber(startOfWeek);
        return `${startOfWeek.getDate()} - ${weekEnd.getDate()} ${weekEnd.toLocaleDateString('fr-FR', { month: 'short' })} ${weekEnd.getFullYear()} | S${weekNumStart}`;
      case 'month':
        return currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
      default:
        return '';
    }
  };

  useEffect(() => {
    console.log("%c[Planning] Current view changed:", "color: #ffc107; font-weight: bold;", currentView);
  }, [currentView]);

  return (
    <div className="pv-container">
      <div className="pv-controls flex flex-col md:flex-row justify-between items-center">
        <div className="pv-view-buttons flex justify-center w-full md:w-auto mb-2 md:mb-0">
          <button onClick={() => setCurrentView('day')} className={`pv-button ${currentView === 'day' ? 'pv-button-primary' : ''}`}>Jour</button>
          <button onClick={() => setCurrentView('week')} className={`pv-button ${currentView === 'week' ? 'pv-button-primary' : ''}`}>Semaine</button>
          <button onClick={() => setCurrentView('month')} className={`pv-button hidden md:inline-block ${currentView === 'month' ? 'pv-button-primary' : ''}`}>Mois</button>
          <button onClick={goToToday} className="pv-button pv-today-button ml-2">
            <Calendar size={16} className="mr-1" />
            <span className="hidden md:inline">Aujourd'hui</span>
          </button>
        </div>
        <div className="pv-date-navigation flex justify-center items-center w-full md:w-auto">
          <button onClick={goToPrevious} className="pv-button pv-button-icon"><ChevronLeft size={18} /></button>
          <span className="pv-date-range mx-2">{formatDateRange()}</span>
          <button onClick={goToNext} className="pv-button pv-button-icon"><ChevronRight size={18} /></button>
        </div>
      </div>
      <div className="pv-view-container" style={{ height: 'calc(100vh - 100px)', overflowY: 'auto' }}>
        {currentView === 'day' && <DayView db={db} currentDate={currentDate} currentUser={currentUser} />}
        {currentView === 'week' && (
          isMobile ? (
            <WeekViewMobile db={db} currentDate={currentDate} currentUser={currentUser} onDateChange={setCurrentDate} />
          ) : (
            <WeekView db={db} currentDate={currentDate} currentUser={currentUser} onDateChange={setCurrentDate} />
          )
        )}
        {currentView === 'month' && !isMobile && <MonthView db={db} currentDate={currentDate} currentUser={currentUser} />}
      </div>
    </div>
  );
};

export default Planning;