import React, { useState } from 'react';
import { getAuth, verifyBeforeUpdateEmail, reauthenticateWithCredential, EmailAuthProvider } from 'firebase/auth';
import { doc, updateDoc } from 'firebase/firestore';
import { useNavigate } from 'react-router-dom';
import { Mail, Lock } from 'lucide-react';
import AuthLayout from './AuthLayout';
import { db } from '../firebase';

function UpdateEmail() {
  const [newEmail, setNewEmail] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [error, setError] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const [verificationSent, setVerificationSent] = useState(false);
  const navigate = useNavigate();

  const handleSubmit = async (e) => {
    e.preventDefault();
    setError(null);
    setIsLoading(true);
    try {
      const auth = getAuth();
      const user = auth.currentUser;

      // Réauthentification de l'utilisateur
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);

      // Envoi de l'e-mail de vérification
      await verifyBeforeUpdateEmail(user, newEmail);

      setVerificationSent(true);

      // Mettre à jour le champ `needEmailUpdate` dans Firestore
      await updateDoc(doc(db, 'employees', user.uid), {
        needEmailUpdate: false,
      });

    } catch (error) {
      console.error("Erreur lors de la mise à jour de l'email:", error);
      switch (error.code) {
        case 'auth/requires-recent-login':
          setError("Pour des raisons de sécurité, veuillez vous reconnecter avant de modifier votre e-mail.");
          break;
        case 'auth/invalid-email':
          setError("L'adresse e-mail fournie n'est pas valide.");
          break;
        case 'auth/email-already-in-use':
          setError("Cette adresse e-mail est déjà utilisée par un autre compte.");
          break;
        case 'auth/wrong-password':
          setError("Le mot de passe actuel est incorrect.");
          break;
        default:
          setError("Une erreur est survenue lors de la mise à jour de l'email. Veuillez réessayer.");
      }
    } finally {
      setIsLoading(false);
    }
  };

  if (verificationSent) {
    return (
      <AuthLayout title="Vérification de l'email">
        <div className="auth-fade-in">
          <p>Un e-mail de vérification a été envoyé à {newEmail}.</p>
          <p>Veuillez vérifier votre nouvelle adresse e-mail en cliquant sur le lien dans l'e-mail, puis reconnectez-vous.</p>
          <button
            onClick={() => navigate('/login')}
            className="auth-button mt-4"
          >
            Retour à la connexion
          </button>
        </div>
      </AuthLayout>
    );
  }

  return (
    <AuthLayout title="Mise à jour de l'adresse email">
      <form className="auth-fade-in" onSubmit={handleSubmit}>
        <div className="auth-input-group">
          <label htmlFor="newEmail" className="auth-label">
            Nouvelle adresse email
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Mail className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="newEmail"
              name="newEmail"
              type="email"
              required
              className="auth-input pl-10"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
            />
          </div>
        </div>

        <div className="auth-input-group">
          <label htmlFor="currentPassword" className="auth-label">
            Mot de passe actuel
          </label>
          <div className="relative">
            <div className="absolute inset-y-0 left-0 pl-3 flex items-center pointer-events-none">
              <Lock className="h-5 w-5 text-gray-400" aria-hidden="true" />
            </div>
            <input
              id="currentPassword"
              name="currentPassword"
              type="password"
              required
              className="auth-input pl-10"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
            />
          </div>
        </div>

        {error && <div className="auth-error">{error}</div>}

        <button
          type="submit"
          disabled={isLoading}
          className="auth-button"
        >
          {isLoading ? 'Envoi de la vérification...' : 'Mettre à jour l\'email'}
        </button>
      </form>
    </AuthLayout>
  );
}

export default UpdateEmail;
