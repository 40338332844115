import React, { useState, useEffect} from 'react';
import { doc, updateDoc, collection, query, where, getDocs, addDoc, deleteDoc, Timestamp } from 'firebase/firestore';
import { Calendar, ChevronLeft, ChevronRight, Archive, RefreshCw, User, Mail, Phone, MapPin, Edit, Save, File, FileText } from 'lucide-react';
import ClientWeekView from './ClientWeekView';
import ClientMonthView from './ClientMonthView';
import ClientContract from './ClientContract';
import ClientReport from './ClientReport';
import AppointmentModal from './AppointmentModal';
import AddAppointmentForm from './AppointmentForm';
import ResumeClient from './ClientResume';
import ClientSummary from './ClientSummary';
import ClientDiscussion from './ClientDiscussion';


// Fonctions utilitaires
const adjustForTimezone = (date) => {
  return new Date(date.getTime() + date.getTimezoneOffset() * 60000);
};

const colorPalette = [
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581',
  '#FFD54F', '#7986CB', '#9575CD', '#4DB6AC', '#FFF176', '#FF8A65', '#A1887F',
  '#90A4AE', '#FFAB91', '#F48FB1', '#81C784', '#64B5F6', '#BA68C8', '#4DD0E1',
  '#DCE775', '#FFB74D', '#E57373', '#F06292', '#BA68C8', '#9575CD', '#7986CB',
  '#64B5F6', '#4FC3F7', '#4DD0E1', '#4DB6AC', '#81C784', '#AED581', '#DCE775',
  '#FFF176', '#FFD54F', '#FFB74D', '#FF8A65', '#A1887F', '#90A4AE', '#D1C4E9',
  '#B39DDB', '#9575CD', '#7E57C2', '#673AB7', '#5C6BC0', '#3F51B5', '#3949AB',
  '#303F9F', '#283593', '#1A237E', '#1976D2', '#1565C0', '#0D47A1', '#0277BD',
  '#01579B', '#00838F', '#006064', '#004D40', '#00695C', '#004C3F', '#00BFA5',
  '#00ACC1', '#0097A7', '#00838F', '#006064'
];



const isLightColor = (color) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness > 155;
};

const ClientDetails = ({ 
  client, 
  onClose, 
  onUpdate, 
  db, 
  usedColors, 
  generalSettings,
  planningManagementSettings, // Ajoutez ceci aux props
  formatDateForDisplay,
  currentUser,
  employeeAppointments,
  addNotification
}) => {
  const [clientData, setClientData] = useState(client);
  const [selectedDate, setSelectedDate] = useState(null);
  const [showAppointmentForm, setShowAppointmentForm] = useState(false);
  const [activeTab, setActiveTab] = useState('summary');
  const [viewType, setViewType] = useState('week');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [appointments, setAppointments] = useState({});
  const [employees, setEmployees] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [isAddFormOpen, setIsAddFormOpen] = useState(false);
 


  useEffect(() => {
    fetchAppointments();
    fetchEmployees();
  }, [client]);

  useEffect(() => {
    console.log("selectedAppointment mis à jour:", selectedAppointment);
  }, [selectedAppointment]);

  const fetchAppointments = async () => {
    const appointmentsRef = collection(db, 'appointments');
    const q = query(appointmentsRef, where('clientId', '==', client.id));
    const querySnapshot = await getDocs(q);
    const fetchedAppointments = {};
    querySnapshot.forEach((doc) => {
      const appointment = doc.data();
      const dateString = appointment.date.toDate().toISOString().split('T')[0];
      if (!fetchedAppointments[dateString]) {
        fetchedAppointments[dateString] = [];
      }
      fetchedAppointments[dateString].push({ id: doc.id, ...appointment });
    });
    setAppointments(fetchedAppointments);
  };

  const fetchEmployees = async () => {
    const employeesRef = collection(db, 'employees');
    const querySnapshot = await getDocs(employeesRef);
    const fetchedEmployees = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setEmployees(fetchedEmployees);
  };

  const availableColors = colorPalette.filter(color => !usedColors.includes(color) || color === client.color);


  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setClientData(prev => ({ ...prev, [name]: value }));
  };

  const handleSave = async () => {
    await updateDoc(doc(db, "clients", client.id), clientData);
    await onUpdate(clientData);
    onClose();
  };

  const handleAddAppointment = (date) => {
    const newDate = new Date(date);
    newDate.setHours(12, 0, 0, 0);
    setSelectedDate(newDate);
    setShowAppointmentForm(true);
    setIsAddFormOpen(true);
  };

  const addAppointment = async (newAppointment) => {
    try {
      const appointmentRef = await addDoc(collection(db, 'appointments'), {
        ...newAppointment,
        clientId: clientData.id,
      });
      console.log('New appointment added with ID: ', appointmentRef.id);
      await fetchAppointments();
      setShowAppointmentForm(false);
    } catch (error) {
      console.error('Error adding appointment: ', error);
    }
  };

  const onUpdateAppointment = async (updatedAppointment) => {
    try {
      const appointmentRef = doc(db, 'appointments', updatedAppointment.id);
      await updateDoc(appointmentRef, updatedAppointment);
      console.log('Appointment updated: ', updatedAppointment.id);
      await fetchAppointments();
    } catch (error) {
      console.error('Error updating appointment: ', error);
    }
  };

  const formatDate = (date) => {
    return new Intl.DateTimeFormat('fr-FR', { 
      weekday: 'short', 
      day: 'numeric', 
      month: 'long' 
    }).format(date);
  };

  const formatTime = (time) => {
    return new Intl.DateTimeFormat('fr-FR', {
      hour: '2-digit',
      minute: '2-digit'
    }).format(new Date(`2000-01-01T${time}`));
  };

  const changeDate = (amount, view) => {
    const newDate = new Date(currentDate);
    if (view === 'week') {
      newDate.setDate(newDate.getDate() + amount * 7);
    } else {
      newDate.setMonth(newDate.getMonth() + amount);
    }
    setCurrentDate(newDate);
  };

  const toggleClientStatus = async () => {
    const newStatus = !clientData.isActive;
    try {
      await updateDoc(doc(db, "clients", client.id), { isActive: newStatus });
      setClientData(prevData => ({ ...prevData, isActive: newStatus }));
      onUpdate(); // Ceci devrait déclencher un re-rendu du composant parent
    } catch (error) {
      console.error("Erreur lors du changement de statut du client:", error);
    }
  };

  const handleSaveAppointment = async (newAppointment) => {
    try {
      await addDoc(collection(db, "appointments"), {
        ...newAppointment,
        clientId: client.id,
        date: Timestamp.fromDate(new Date(newAppointment.date))
      });
      setIsAddFormOpen(false);
      await fetchAppointments(); // Rafraîchir la liste des rendez-vous
    } catch (error) {
      console.error("Erreur lors de l'ajout du rendez-vous:", error);
    }
  };
  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
    setIsModalOpen(true);
  };
  
  const handleCloseModal = () => {
    setIsModalOpen(false);
    setSelectedAppointment(null);
  };

  const handleCloseAddForm = () => {
    setIsAddFormOpen(false);
    setSelectedDate(null);
  };
  
  const handleDeleteAppointment = async (appointmentId) => {
    try {
      await deleteDoc(doc(db, "appointments", appointmentId));
      setIsModalOpen(false);
      setSelectedAppointment(null);
      await fetchAppointments(); // Rafraîchir la liste des rendez-vous
    } catch (error) {
      console.error("Erreur lors de la suppression du rendez-vous:", error);
    }
  };

  const getStartOfWeek = (date) => {
    const d = new Date(date);
    const day = d.getDay();
    const diff = d.getDate() - day + (day === 0 ? -6 : 1);
    return new Date(d.setDate(diff));
  };

  return (
    <div className="flex h-full bg-gray-100">
      <div className="w-64 bg-white p-4 border-r">
        <button onClick={onClose} className="mb-4 text-teal-600 hover:text-teal-800">
          &lt; Retour
        </button>
        <div className="text-center mb-4">
          <div className="w-16 h-16 bg-teal-100 rounded-full mx-auto mb-2 flex items-center justify-center">
            <span className="text-teal-600 font-bold">{clientData.firstName?.charAt(0)}{clientData.lastName?.charAt(0)}</span>
          </div>
          <h2 className="text-xl font-bold text-teal-700">{clientData.firstName} {clientData.lastName}</h2>
          <p className="text-gray-600">{clientData.addressNumber}</p>
        </div>
          <nav>
            <button 
              onClick={() => setActiveTab('summary')}
              className={`w-full text-left py-2 px-4 rounded ${activeTab === 'summary' ? 'bg-teal-100 text-teal-700' : 'hover:bg-gray-100'}`}
            >
              Sommaire
            </button>
            <button 
              onClick={() => setActiveTab('discussion')}
              className={`w-full text-left py-2 px-4 rounded ${activeTab === 'discussion' ? 'bg-teal-100 text-teal-700' : 'hover:bg-gray-100'}`}
            >
              Discussion
            </button>
            <button 
              onClick={() => setActiveTab('notes')}
              className={`w-full text-left py-2 px-4 rounded ${activeTab === 'notes' ? 'bg-teal-100 text-teal-700' : 'hover:bg-gray-100'}`}
            >
              Notes
            </button>
            <button 
              onClick={() => setActiveTab('contrat')}
              className={`w-full text-left py-2 px-4 rounded ${activeTab === 'contrat' ? 'bg-teal-100 text-teal-700' : 'hover:bg-gray-100'}`}
            >
              Contrat
            </button>
            <button 
              onClick={() => setActiveTab('rapport')}
              className={`w-full text-left py-2 px-4 rounded ${activeTab === 'rapport' ? 'bg-teal-100 text-teal-700' : 'hover:bg-gray-100'}`}
            >
              Rapport
            </button>
            <button 
              onClick={() => setActiveTab('planning')}
              className={`w-full text-left py-2 px-4 rounded ${activeTab === 'planning' ? 'bg-teal-100 text-teal-700' : 'hover:bg-gray-100'}`}
            >
              Planning
            </button>
            </nav>
            <button 
              onClick={toggleClientStatus}
              className={`w-full mt-4 py-2 px-4 rounded flex items-center justify-center ${
                clientData.isActive 
                  ? 'bg-red-100 text-red-700 hover:bg-red-200' 
                  : 'bg-green-100 text-green-700 hover:bg-green-200'
              }`}
            >
              {clientData.isActive ? (
                <>
                  <Archive size={18} className="mr-2" />
                  Archiver cette ressource
                </>
              ) : (
                <>
                  <RefreshCw size={18} className="mr-2" />
                  Réactiver cette ressource
                </>
              )}
            </button>
      </div>
      <div className="flex-1 p-6 overflow-auto">
          {activeTab === 'summary' && (
            <ClientSummary client={clientData} db={db} />
          )}
          {activeTab === 'discussion' && (
          <div className="h-full">
            <h2 className="text-2xl font-bold mb-4">Discussion</h2>
            <ClientDiscussion 
              db={db}
              client={clientData}
              currentUser={currentUser}
              employeeAppointments={employeeAppointments}
              addNotification={addNotification}
            
            />
          </div>
        )}
          {activeTab === 'notes' && (
            <div>
              <h2 className="text-2xl font-bold mb-4">Notes</h2>
              {/* Implémenter la fonctionnalité de notes ici */}
            </div>
          )}
          {activeTab === 'contrat' && (
              <ClientContract client={clientData} db={db} />
            )}
            {activeTab === 'rapport' && (
              <ClientReport client={clientData} db={db} />
            )}
          {activeTab === 'planning' && (
        <div>
          <h2 className="text-2xl font-bold mb-4">Planning</h2>
          <div className="flex justify-between items-center mb-4">
          
            <select 
              value={viewType} 
              onChange={(e) => setViewType(e.target.value)}
              className="bg-white border rounded px-5 py-1"
            >
              <option value="week">Semaine</option>
              <option value="month">Mois</option>
            </select>
            
          </div>
          {viewType === 'week' ? (
            <ClientWeekView
              db={db}
              startOfWeek={getStartOfWeek(currentDate)}
              client={clientData}
              appointments={appointments}
              employees={employees}
              generalSettings={generalSettings}
              formatDate={formatDate}
              formatTime={formatTime}
              onAppointmentClick={handleAppointmentClick}
              changeDate={(amount) => changeDate(amount, 'week')}
              isLightColor={isLightColor}
              onAddAppointment={handleAddAppointment}
              onUpdateAppointment={onUpdateAppointment}
              onDeleteAppointment={handleDeleteAppointment}
              onHandleAddAppointment={handleAddAppointment}
              formatDateForDisplay={formatDateForDisplay}
              adjustForTimezone={adjustForTimezone}
            />
          ) : (
            <ClientMonthView
            db={db}
            client={clientData}
            currentDate={currentDate}
            appointments={appointments}
            employees={employees}
            generalSettings={generalSettings}
            planningManagementSettings={planningManagementSettings} // Ajout de cette ligne
            formatDate={formatDate}
            formatTime={formatTime}
            onAppointmentClick={handleAppointmentClick}
            changeDate={(amount) => changeDate(amount, 'month')}
            isLightColor={isLightColor}
            onAddAppointment={handleAddAppointment}
            adjustForTimezone={adjustForTimezone}
            formatDateForDisplay={formatDateForDisplay}
              
            />
          )}
          {isModalOpen && selectedAppointment && (
            <AppointmentModal
              appointment={selectedAppointment}
              onClose={handleCloseModal}
              onDelete={handleDeleteAppointment}
              formatDate={formatDate}
              formatTime={formatTime}
            />
          )}
        </div>
      )}
    </div>
    
    </div>
  );
};

export default ClientDetails;