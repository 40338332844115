import React, { useState, useEffect, useCallback } from 'react';
import { collection, query, where, getDocs, Timestamp, doc, updateDoc, deleteDoc, addDoc, getDoc } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { X, Clock, User, Plus, RotateCcw, Calendar, Check, Loader  } from 'lucide-react';


// Fonction utilitaire pour comparer deux dates
const isSameDay = (date1, date2) => {
  if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
    const d1 = new Date(date1);
    const d2 = new Date(date2);
    return d1.getFullYear() === d2.getFullYear() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getDate() === d2.getDate();
  }
  return date1.getFullYear() === date2.getFullYear() &&
         date1.getMonth() === date2.getMonth() &&
         date1.getDate() === date2.getDate();
};

const ClientDayView = ({ db, client, currentDate, formatDate, formatTime, userPermissions }) => {
  const [appointments, setAppointments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [refreshTrigger, setRefreshTrigger] = useState(0);
  const [approvedLeaves, setApprovedLeaves] = useState([]);
  const [settings, setSettings] = useState({
    openingTime: '08:00',
    closingTime: '18:00',
  });

  const fetchEmployees = useCallback(async () => {
    if (!db) return;
    try {
      const employeesRef = collection(db, 'employees');
      const q = query(employeesRef, where('isArchived', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map(doc => ({
        userId: doc.id,
        ...doc.data(),
        color: doc.data().color || '#808080'
      }));
      setEmployees(fetchedEmployees);
    } catch (error) {
      console.error("Erreur lors de la récupération des employés:", error);
    }
  }, [db]);

  const fetchAppointments = useCallback(async () => {
    if (!db || !client.id) return;
    try {
      const startOfDay = new Date(currentDate);
      startOfDay.setHours(0, 0, 0, 0);
      const endOfDay = new Date(currentDate);
      endOfDay.setHours(23, 59, 59, 999);

      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('clientId', '==', client.id),
        where('date', '>=', Timestamp.fromDate(startOfDay)),
        where('date', '<=', Timestamp.fromDate(endOfDay))
      );
      const querySnapshot = await getDocs(q);
      const fetchedAppointments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate() || new Date(),
      }));
      setAppointments(fetchedAppointments);
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  }, [db, client.id, currentDate]);

  useEffect(() => {
    fetchEmployees();
    fetchAppointments();
  }, [fetchEmployees, fetchAppointments, refreshTrigger]);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDoc = await getDoc(doc(db, 'settings', 'planningManagement'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setSettings({
          openingTime: data.openingTime || '08:00',
          closingTime: data.closingTime || '18:00',
        });
      }
    };
    fetchSettings();
  }, [db]);

  const canEditAppointments = userPermissions.write_appointments;

  const handleAppointmentClick = (appointment) => {
    // Simple clic ne fait rien maintenant
  };

  const handleAppointmentDoubleClick = (appointment) => {
    if (canEditAppointments) {
      setSelectedAppointment(appointment);
      setIsModalOpen(true);
    }
  };

  const handleAddAppointment = async (appointmentData) => {
    if (!canEditAppointments) return;
    try {
      const appointmentDate = new Date(currentDate);
      appointmentDate.setUTCHours(2, 0, 0, 0);
      const status = appointmentData.employeeId ? 'assigne' : 'libre';
      await addDoc(collection(db, 'appointments'), {
        ...appointmentData,
        date: Timestamp.fromDate(appointmentDate),
        clientId: client.id,
        status: status
      });
      setIsModalOpen(false);
      setRefreshTrigger(prev => prev + 1);
    } catch (error) {
      console.error("Erreur lors de l'ajout du rendez-vous:", error);
    }
  };

  const handleUpdateAppointment = async (updatedAppointment) => {
    if (!canEditAppointments) return;
    try {
      const appointmentRef = doc(db, 'appointments', updatedAppointment.id);
      const status = updatedAppointment.employeeId ? 'assigne' : 'libre';
      await updateDoc(appointmentRef, {
        ...updatedAppointment,
        status: status
      });
      setIsModalOpen(false);
      setRefreshTrigger(prev => prev + 1);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rendez-vous:", error);
    }
  };

  const handleDeleteAppointment = async (appointmentId) => {
    if (!canEditAppointments) return;
    try {
      await deleteDoc(doc(db, 'appointments', appointmentId));
      setIsModalOpen(false);
      setRefreshTrigger(prev => prev + 1);
    } catch (error) {
      console.error("Erreur lors de la suppression du rendez-vous:", error);
    }
  };


  // Ajoutons la fonction pour récupérer les congés approuvés
const fetchApprovedLeaves = useCallback(async () => {
  if (!db) return;
  try {
    const leavesRef = collection(db, 'leaves');
    const q = query(
      leavesRef, 
      where('status', '==', 'approved')
    );
    const querySnapshot = await getDocs(q);
    const leaves = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      startDate: doc.data().startDate.toDate(),
      endDate: doc.data().endDate.toDate()
    }));
    setApprovedLeaves(leaves);
  } catch (error) {
    console.error("Erreur lors de la récupération des congés:", error);
  }
}, [db]);

// Ajoutons l'appel au useEffect existant
useEffect(() => {
  fetchEmployees();
  fetchAppointments();
  fetchApprovedLeaves(); // Ajout de cette ligne
}, [fetchEmployees, fetchAppointments, fetchApprovedLeaves, refreshTrigger]);

// Fonction pour vérifier si un employé est en congé à une date donnée
const isEmployeeOnLeave = useCallback((employeeId) => {
  if (!approvedLeaves.length) return false;
  
  const today = new Date(currentDate);
  today.setHours(0, 0, 0, 0); // Normaliser l'heure à minuit
  
  return approvedLeaves.some(leave => {
    const startDate = new Date(leave.startDate);
    const endDate = new Date(leave.endDate);
    startDate.setHours(0, 0, 0, 0);
    endDate.setHours(23, 59, 59, 999);
    
    return leave.employeeId === employeeId && 
           today >= startDate && 
           today <= endDate;
  });
}, [approvedLeaves, currentDate]);


 const onDragEnd = useCallback(async (result) => {
    if (!canEditAppointments || !result.destination) return;
    
    const { source, destination, draggableId } = result;
    
    // Ensure the draggable appointment exists
    const appointment = appointments.find(app => app.id === draggableId);
    if (!appointment) return;
    
    // Check if the droppableId corresponds to "unassigned" or an employee ID
    const newEmployeeId = destination.droppableId === 'unassigned' ? null : destination.droppableId;
    const newStatus = destination.droppableId === 'unassigned' ? 'libre' : 'assigne';

    try {
      // Update Firestore with new employeeId and status
      await updateDoc(doc(db, 'appointments', appointment.id), {
        employeeId: newEmployeeId,
        status: newStatus,
      });
  
      // Update local state
      setAppointments(prevAppointments => 
        prevAppointments.map(app => 
          app.id === appointment.id 
            ? { ...app, employeeId: newEmployeeId, status: newStatus }
            : app
        )
      );
  
      // Trigger a refresh
      setRefreshTrigger(prev => prev + 1);
    } catch (error) {
      console.error("Erreur lors de la manipulation du rendez-vous:", error);
    }
  }, [appointments, db, canEditAppointments, setRefreshTrigger]);

  
  const AddAppointmentModal = ({ date, employees, onSave, onClose, initialEmployeeId }) => {
    const [appointmentData, setAppointmentData] = useState(() => {
      const initialDate = new Date(date);
      initialDate.setUTCHours(2, 0, 0, 0); // Set the time to 02:00:00 UTC+2
      return {
        date: initialDate,
        startTime: '',
        endTime: '',
        employeeId: initialEmployeeId || '',
        status: initialEmployeeId ? 'assigne' : 'libre',
        isRecurring: false,
        recurrenceBase: 'weekly',
        recurrenceFrequency: 1,
        recurrenceDay: [initialDate.getDay()],
        recurrenceMonthDay: initialDate.getDate(),
        recurrenceMonthType: 'dayOfMonth', // 'dayOfMonth' ou 'dayOfWeek'
        recurrenceMonthWeek: Math.floor((initialDate.getDate() - 1) / 7) + 1,
        recurrenceMonthWeekday: initialDate.getDay(),
        recurrenceEndType: 'never',
        recurrenceEndAfter: 1,
        recurrenceEndDate: null,
        recurrenceInterval: 1,
        recurrenceMonthFrequency: 1,
      };
    });
  
    const handleChange = (e) => {
      const { name, value, type, checked } = e.target;
      setAppointmentData(prev => {
        const newData = {
          ...prev,
          [name]: type === 'checkbox' ? checked : 
          (name === 'recurrenceInterval' || name === 'recurrenceFrequency') ? 
          Math.max(1, parseInt(value, 10) || 1) : value
        };
        
        // Mettre à jour le statut en fonction de l'employeeId
        if (name === 'employeeId') {
          newData.status = value ? 'assigne' : 'libre';
        }
        
        return newData;
      });
    };
  
    const handleDaySelection = (day) => {
      setAppointmentData(prev => ({
        ...prev,
        recurrenceDay: prev.recurrenceDay.includes(day)
          ? prev.recurrenceDay.filter(d => d !== day)
          : [...prev.recurrenceDay, day]
      }));
    };
  
    const handleSubmit = (e) => {
      e.preventDefault();
      const dateWithTime = new Date(appointmentData.date);
      dateWithTime.setUTCHours(2, 0, 0, 0); // Set the time to 02:00:00 UTC+2
      console.log("Submitting appointment data:", appointmentData);
      onSave({
        ...appointmentData,
        date: dateWithTime,
        status: appointmentData.employeeId ? 'assigne' : 'libre'
      });
    };
  
  
  
    const getDayOfWeekName = (day) => {
      const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
      return days[day];
    };
  
    const getWeekOfMonthName = (week) => {
      const weeks = ['premier', 'deuxième', 'troisième', 'quatrième', 'dernier'];
      return weeks[Math.min(week - 1, 4)];
    };
    
      return (
      <div className="modal">
        <h2 className="text-2xl font-bold mb-4">Ajouter un rendez-vous</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-2">
            <Calendar className="text-gray-400" size={20} />
            <input 
              type="date" 
              name="date" 
              value={appointmentData.date.toISOString().split('T')[0]} 
              onChange={handleChange}
              className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-center space-x-2">
            <Clock className="text-gray-400" size={20} />
            <input 
              type="time" 
              name="startTime" 
              value={appointmentData.startTime} 
              onChange={handleChange}
              className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
            <span>à</span>
            <input 
              type="time" 
              name="endTime" 
              value={appointmentData.endTime} 
              onChange={handleChange}
              className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            />
          </div>
          <div className="flex items-center space-x-2">
            <User className="text-gray-400" size={20} />
            <select 
              name="employeeId" 
              value={appointmentData.employeeId} 
              onChange={handleChange}
              className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              <option value="">Sélectionner un employé</option>
              {employees.map(emp => (
                <option key={emp.userId} value={emp.userId}>
                  {emp.name || `${emp.firstName} ${emp.lastName}`}
                </option>
              ))}
            </select>
          </div>
          <div className="flex items-center space-x-2">
            <RotateCcw className="text-gray-400" size={20} />
            <label className="inline-flex items-center">
              <input 
                type="checkbox" 
                name="isRecurring" 
                checked={appointmentData.isRecurring} 
                onChange={handleChange}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
              <span className="ml-2">Rendez-vous récurrent</span>
            </label>
          </div>
          {appointmentData.isRecurring && (
              <div className="space-y-4 ml-7">
                <div>
                  <select 
                    name="recurrenceBase" 
                    value={appointmentData.recurrenceBase} 
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="daily">Journalière</option>
                    <option value="weekly">Hebdomadaire</option>
                    <option value="monthly">Mensuelle</option>
                  </select>
                </div>
  
                {appointmentData.recurrenceBase === 'daily' && (
                  <div className="flex items-center space-x-2">
                    <span>Tous les</span>
                    <input 
                      type="number" 
                      name="recurrenceInterval"
                      value={appointmentData.recurrenceInterval || 1} 
                      onChange={handleChange}
                      min="1"
                      className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <span>jour(s)</span>
                  </div>
                )}
  
                {appointmentData.recurrenceBase === 'weekly' && (
                  <>
                    
                    <div className="flex items-center space-x-2">
                      <span>Tous les</span>
                      <input 
                        type="number" 
                        name="recurrenceFrequency" 
                        value={appointmentData.recurrenceFrequency} 
                        onChange={handleChange}
                        min="1"
                        className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                      />
                      <span>semaine(s)</span>
                    </div>
                  </>
                )}
  
                {appointmentData.recurrenceBase === 'monthly' && (
                <>
                  <div>
                    <select
                      name="recurrenceMonthType"
                      value={appointmentData.recurrenceMonthType}
                      onChange={handleChange}
                      className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    >
                      <option value="dayOfMonth">Le même jour du mois</option>
                      <option value="dayOfWeek">Le même jour de la semaine</option>
                    </select>
                  </div>
                  {appointmentData.recurrenceMonthType === 'dayOfMonth' ? (
                    <div>
                      Répéter le {appointmentData.recurrenceMonthDay} de chaque mois
                    </div>
                  ) : (
                    <div>
                      Répéter le {getWeekOfMonthName(appointmentData.recurrenceMonthWeek)} {getDayOfWeekName(appointmentData.recurrenceMonthWeekday)} de chaque mois
                    </div>
                  )}
                  <div className="flex items-center space-x-2">
                    <span>Tous les</span>
                    <input 
                      type="number" 
                      name="recurrenceMonthFrequency" 
                      value={appointmentData.recurrenceMonthFrequency} 
                      onChange={handleChange}
                      min="1"
                      className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <span>mois</span>
                  </div>
                </>
              )}
              <div>
                <select
                  name="recurrenceEndType"
                  value={appointmentData.recurrenceEndType}
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="never">Jamais</option>
                  <option value="after">Après un nombre d'occurrences</option>
                  <option value="on">À une date spécifique</option>
                </select>
              </div>
              {appointmentData.recurrenceEndType === 'after' && (
                <div className="flex items-center space-x-2">
                  <span>Après</span>
                  <input 
                    type="number" 
                    name="recurrenceEndAfter" 
                    value={appointmentData.recurrenceEndAfter} 
                    onChange={handleChange}
                    min="1"
                    className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span>occurrences</span>
                </div>
              )}
              {appointmentData.recurrenceEndType === 'on' && (
                <div>
                  <input 
                    type="date" 
                    name="recurrenceEndDate" 
                    value={appointmentData.recurrenceEndDate || ''} 
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                </div>
              )}
            </div>
          )}
          <div className="flex justify-end space-x-2 mt-6">
            <button 
              type="button" 
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
            >
              Annuler
            </button>
            <button 
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
            >
              Ajouter
            </button>
          </div>
        </form>
      </div>
    );
  };


  const timeToMinutes = (time) => {
    const [hours, minutes] = time.split(':').map(Number);
    return hours * 60 + minutes;
  };

  const openingMinutes = timeToMinutes(settings.openingTime);
  const closingMinutes = timeToMinutes(settings.closingTime);
  const totalMinutes = closingMinutes - openingMinutes;

  const calculatePosition = (time) => {
    const minutes = timeToMinutes(time) - openingMinutes;
    return (minutes / totalMinutes) * 100;
  };

  const calculateWidth = (start, end) => {
    const startMinutes = timeToMinutes(start) - openingMinutes;
    const endMinutes = timeToMinutes(end) - openingMinutes;
    return ((endMinutes - startMinutes) / totalMinutes) * 100;
  };

  const generateTimeSlots = () => {
    const slots = [];
    let currentTime = new Date(`2000-01-01T${settings.openingTime}`);
    const endTime = new Date(`2000-01-01T${settings.closingTime}`);
    
    while (currentTime < endTime) {
      slots.push(currentTime.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' }));
      currentTime.setMinutes(currentTime.getMinutes() + 15);  // Incrément de 15 minutes
    }
    return slots;
  };

  const timeSlots = generateTimeSlots();

  const sortAppointments = (apps) => {
    return apps.sort((a, b) => a.startTime.localeCompare(b.startTime));
  };

  const groupAppointmentsByEmployee = useCallback(() => {
    const grouped = {};
    employees.forEach(emp => {
      grouped[emp.userId] = sortAppointments(
        appointments.filter(app => app.employeeId === emp.userId)
      );
    });
    return grouped;
  }, [appointments, employees]);

  const renderAppointments = (employeeAppointments, isUnassigned = false) => {
    return employeeAppointments.map((appointment, index) => (
      <Draggable
        key={appointment.id}
        draggableId={appointment.id}
        index={index}
        isDragDisabled={!canEditAppointments}
      >
        {(provided, snapshot) => (
          <div
            ref={provided.innerRef}
            {...provided.draggableProps}
            {...provided.dragHandleProps}
            className={`cdv-appointment ${isUnassigned ? 'cdv-unassigned' : ''} ${getStatusClass(appointment.status)}`}
            onClick={() => handleAppointmentClick(appointment)}
            onDoubleClick={() => handleAppointmentDoubleClick(appointment)}
          >
            <div className="cdv-appointment-time">
              <Clock size={14} className="cdv-icon" />
              {`${formatTime(appointment.startTime)} - ${formatTime(appointment.endTime)}`}
            </div>
            <div className="cdv-appointment-details">
              {appointment.details || "Pas de détails"}
            </div>
            <div className="cdv-appointment-status">
              {renderStatusIcon(appointment.status)}
              {getStatusText(appointment.status)}
            </div>
          </div>
        )}
      </Draggable>
    ));
  };

  const getStatusClass = (status) => {
    switch (status) {
      case 'in_progress':
        return 'cdv-in-progress';
      case 'completed':
        return 'cdv-completed';
      case 'assigne':
        return 'cdv-assigned';
      case 'libre':
        return 'cdv-unassigned';
      default:
        return '';
    }
  };

  const getStatusText = (status) => {
    switch (status) {
      case 'in_progress':
        return 'En cours';
      case 'completed':
        return 'Terminé';
      case 'assigne':
        return '';
      case 'libre':
        return 'À combler';
      default:
        return status;
    }
  };

  const renderStatusIcon = (status) => {
    switch (status) {
      case 'in_progress':
        return <Loader size={14} className="cdv-icon animate-spin" />;
      case 'completed':
        return <Check size={14} className="cdv-icon text-green-500" />;
      default:
        return null;
    }
  };

  const unassignedAppointments = appointments.filter(app => !app.employeeId || app.status === 'libre');

  return (
    <DragDropContext onDragEnd={onDragEnd}>
      <div className="cdv-container">
        
        <div className="cdv-employee-list">
          {/* Ligne pour les plages à combler */}
          <Droppable key="unassigned-droppable" droppableId="unassigned">
            {(provided) => (
              <div
                ref={provided.innerRef}
                {...provided.droppableProps}
                className="cdv-employee-row cdv-unassigned-row"
              >
                <div className="cdv-employee-name cdv-unassigned-name">
                  Plage à combler
                </div>
                <div className="cdv-appointments">
                  {renderAppointments(unassignedAppointments, true)}
                  {provided.placeholder}
                </div>
              </div>
            )}
          </Droppable>
  
          {/* Lignes pour les employés */}
          {employees.map(employee => (
  <Droppable key={employee.userId} droppableId={employee.userId}>
    {(provided) => (
      <div
        ref={provided.innerRef}
        {...provided.droppableProps}
        className="cdv-employee-row"
      >
        <div className="cdv-employee-name" style={{ borderLeftColor: employee.color }}>
          {employee.name || `${employee.firstName} ${employee.lastName}`}
          {isEmployeeOnLeave(employee.userId) && (
            <div className="leave-indicator ml-2 px-2 py-1 bg-gray-100 rounded text-sm flex items-center">
              <Clock className="w-4 h-4 mr-1 text-gray-500" />
              <span className="text-gray-600">En congé</span>
            </div>
          )}
        </div>
        <div className="cdv-appointments">
          {isEmployeeOnLeave(employee.userId) ? (
            <div className="leave-block w-full h-full bg-gray-100 flex items-center justify-center">
              <span className="text-gray-500">En congé aujourd'hui</span>
            </div>
          ) : (
            <>
              {renderAppointments(groupAppointmentsByEmployee()[employee.userId] || [])}
              {provided.placeholder}
            </>
          )}
        </div>
      </div>
    )}
  </Droppable>
))}
        </div>
  
        {isModalOpen && (
          <AppointmentModal
            appointment={selectedAppointment}
            employees={employees}
            onSave={selectedAppointment ? handleUpdateAppointment : handleAddAppointment}
            onDelete={handleDeleteAppointment}
            onClose={() => setIsModalOpen(false)}
            client={client}
            currentDate={currentDate}
          />
        )}
        {canEditAppointments && (
          <button
            onClick={() => {
              setSelectedAppointment(null);
              setIsModalOpen(true);
            }}
            className="cdv-add-button"
          >
            <Plus size={24} />
          </button>
        )}
      </div>
    </DragDropContext>
  );
};

// Déplacez AppointmentModal ici, en dehors de ClientDayView
const AppointmentModal = ({ appointment, employees, onSave, onDelete, onClose, client }) => {
  const [appointmentData, setAppointmentData] = useState(appointment || {
    startTime: '',
    endTime: '',
    employeeId: '',
    status: 'libre'
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setAppointmentData(prev => ({
      ...prev,
      [name]: value,
      status: name === 'employeeId' ? (value ? 'assigne' : 'libre') : prev.status
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(appointmentData);
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
        <h2 className="text-2xl font-bold mb-4">{appointment ? 'Modifier' : 'Ajouter'} un rendez-vous</h2>
        <form onSubmit={handleSubmit} className="space-y-4">
          <div className="flex items-center space-x-2">
            <Clock className="text-gray-400" size={20} />
            <input 
              type="time" 
              name="startTime" 
              value={appointmentData.startTime} 
              onChange={handleChange}
              className="flex-grow p-2 border rounded"
            />
            <span>à</span>
            <input 
              type="time" 
              name="endTime" 
              value={appointmentData.endTime} 
              onChange={handleChange}
              className="flex-grow p-2 border rounded"
            />
          </div>
          <div className="flex items-center space-x-2">
            <User className="text-gray-400" size={20} />
            <select 
              name="employeeId" 
              value={appointmentData.employeeId} 
              onChange={handleChange}
              className="flex-grow p-2 border rounded"
            >
              <option value="">Sélectionner un employé</option>
              {employees.map(emp => (
                <option key={emp.userId} value={emp.userId}>
                  {emp.name || `${emp.firstName} ${emp.lastName}`}
                </option>
              ))}
            </select>
          </div>
          <div className="flex justify-end space-x-2 mt-6">
            {appointment && (
              <button 
                type="button" 
                onClick={() => onDelete(appointment.id)}
                className="px-4 py-2 bg-red-500 text-white rounded"
              >
                Supprimer
              </button>
            )}
            <button 
              type="button" 
              onClick={onClose}
              className="px-4 py-2 bg-gray-200 text-gray-800 rounded"
            >
              Annuler
            </button>
            <button 
              type="submit"
              className="px-4 py-2 bg-blue-500 text-white rounded"
            >
              {appointment ? 'Mettre à jour' : 'Ajouter'}
            </button>
          </div>
        </form>
      </div>
    </div>
  );
};

export default ClientDayView;