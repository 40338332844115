import React, { useState, useEffect, useRef } from 'react';
import { Calendar, Users, UserPlus, Briefcase, Filter, Settings, Home, Clock, FileText, ChevronRight, X, Clipboard } from 'lucide-react';

const SidebarItem = ({ icon: Icon, label, onClick, isActive, hasSubmenu, children, isOpen, toggleSubmenu }) => {
  return (
    <div>
      <div
        className={`flex items-center justify-between py-3 px-4 cursor-pointer hover:bg-gray-100 transition-colors duration-200 ${isActive ? 'bg-gray-200' : ''}`}
        onClick={hasSubmenu && label !== 'Clients' ? toggleSubmenu : onClick}
      >
        <div className="flex items-center">
          <Icon size={20} className="text-gray-600 mr-3" />
          <span className="text-sm">{label}</span>
        </div>
        {hasSubmenu && (
          <ChevronRight
            size={16}
            className={`text-gray-400 transform transition-transform ${isOpen ? 'rotate-90' : ''}`}
            onClick={(e) => {
              e.stopPropagation();
              toggleSubmenu();
            }}
          />
        )}
      </div>
      {isOpen && children && (
        <div className="pl-8 bg-gray-50">
          {children}
        </div>
      )}
    </div>
  );
};

const Sidebar = ({ setCurrentView, currentView, isOpen, toggleSidebar, selectedClient, setSelectedClient, currentUser, hasPermission, employeeAppointments, addNotification, db }) => {
  console.log("%c[Sidebar] Received props:", "color: #28a745; font-weight: bold;", { currentView, isOpen, selectedClient, currentUser });
  const [showRHSubmenu, setShowRHSubmenu] = useState(false);
  const [showSettingsSubmenu, setShowSettingsSubmenu] = useState(false);
  const [isClientSubmenuOpen, setIsClientSubmenuOpen] = useState(false);
  const sidebarRef = useRef(null);

  useEffect(() => {
    if (selectedClient) {
      setIsClientSubmenuOpen(true);
    } else {
      setIsClientSubmenuOpen(false);
    }
  }, [selectedClient]);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (sidebarRef.current && !sidebarRef.current.contains(event.target)) {
        setShowRHSubmenu(false);
        setShowSettingsSubmenu(false);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleViewChange = (view) => {
    console.log("%c[Sidebar] Changing view to:", "color: #28a745; font-weight: bold;", view);
    setCurrentView(view);
    if (view === 'clientList') {
      setSelectedClient(null);
    }
    if (window.innerWidth < 768) {
      toggleSidebar();
    }
  };

  const isRHActive = ['employeeList', 'employeeReports'].includes(currentView);
  const isSettingsActive = ['generalSettings', 'rolesPositions', 'planningsettings', 'TaskManager'].includes(currentView);
  const isClientActive = ['clientList', 'clientSummary', 'clientDiscussion', 'clientNotes', 'clientContract', 'clientReport', 'clientPlanning'].includes(currentView);

  return (
    <aside ref={sidebarRef} className={`fixed inset-y-0 left-0 z-30 w-64 bg-white border-r transform transition-all duration-300 ease-in-out md:relative md:translate-x-0 ${isOpen ? 'translate-x-0' : '-translate-x-full'}`}>
      <div className="flex justify-between items-center p-4 border-b">
        <h2 className="font-semibold">Menu</h2>
        <button onClick={toggleSidebar} className="text-gray-500 hover:text-gray-700 md:hidden">
          <X size={24} />
        </button>
      </div>
      <nav className="h-full overflow-y-auto py-4">
        <SidebarItem icon={Home} label="Accueil" onClick={() => handleViewChange('dashboard')} isActive={currentView === 'dashboard'} />
        {hasPermission('read_appointments') && (
          <SidebarItem 
            icon={Calendar} 
            label="Planning" 
            onClick={() => handleViewChange('planning')} 
            isActive={currentView === 'planning'} 
          />
        )}
        {hasPermission('read_appointments') && (
          <SidebarItem 
            icon={Clock} 
            label="Présences" 
            onClick={() => handleViewChange('presence')} 
            isActive={currentView === 'presence'} 
          />
        )}
        {(hasPermission('read_employees') || hasPermission('write_employees')) && (
          <SidebarItem
            icon={Briefcase}
            label="RH"
            onClick={() => setShowRHSubmenu(!showRHSubmenu)}
            isActive={isRHActive}
            hasSubmenu
            isOpen={showRHSubmenu}
            toggleSubmenu={() => setShowRHSubmenu(!showRHSubmenu)}
          >
            <SidebarItem icon={Users} label="Collaborateurs" onClick={() => handleViewChange('employeeList')} isActive={currentView === 'employeeList'} />
            <SidebarItem icon={FileText} label="Rapports" onClick={() => handleViewChange('employeeReports')} isActive={currentView === 'employeeReports'} />
          </SidebarItem>
        )}
        {hasPermission('read_clients') && (
          <SidebarItem
            icon={UserPlus}
            label="Clients"
            onClick={() => handleViewChange('clientList')}
            isActive={isClientActive}
            hasSubmenu={true}
            isOpen={isClientSubmenuOpen}
            toggleSubmenu={() => setIsClientSubmenuOpen(!isClientSubmenuOpen)}
          >
            {selectedClient && (
              <>
                <SidebarItem icon={FileText} label="Sommaire" onClick={() => handleViewChange('clientSummary')} isActive={currentView === 'clientSummary'} />
                <SidebarItem 
                icon={Users} 
                label="Discussion" 
                onClick={() => handleViewChange('clientDiscussion')} 
                isActive={currentView === 'clientDiscussion'} 
              />
              
                {hasPermission('write_clients') && (
                  <>
                  <SidebarItem icon={FileText} label="Notes" onClick={() => handleViewChange('clientNotes')} isActive={currentView === 'clientNotes'} />  
                    <SidebarItem icon={FileText} label="Contrat" onClick={() => handleViewChange('clientContract')} isActive={currentView === 'clientContract'} />
                    <SidebarItem icon={FileText} label="Rapport" onClick={() => handleViewChange('clientReport')} isActive={currentView === 'clientReport'} />
                    <SidebarItem icon={Calendar} label="Planning" onClick={() => handleViewChange('clientPlanning')} isActive={currentView === 'clientPlanning'} />   </>
                )}
              </>
            )}
          </SidebarItem>
        )}
        {hasPermission('read_appointments') && (
          <SidebarItem icon={FileText} label="Demandes" onClick={() => handleViewChange('requests')} isActive={currentView === 'requests'} />
        )}
        {hasPermission('read_appointments') && (
          <SidebarItem icon={Filter} label="Filtres" onClick={() => handleViewChange('filters')} isActive={currentView === 'filters'} />
        )}
        {(hasPermission('write_settings') || hasPermission('write_roles') || hasPermission('write_positions') || hasPermission('write_tasks')) && (
          <SidebarItem
            icon={Settings}
            label="Paramètres"
            onClick={() => setShowSettingsSubmenu(!showSettingsSubmenu)}
            isActive={isSettingsActive}
            hasSubmenu
            isOpen={showSettingsSubmenu}
            toggleSubmenu={() => setShowSettingsSubmenu(!showSettingsSubmenu)}
          >
            {hasPermission('write_settings') && (
              <SidebarItem icon={Settings} label="Général" onClick={() => handleViewChange('generalSettings')} isActive={currentView === 'generalSettings'} />
            )}
            {(hasPermission('write_roles') || hasPermission('write_positions')) && (
              <SidebarItem icon={Users} label="Rôles et Positions" onClick={() => handleViewChange('rolesPositions')} isActive={currentView === 'rolesPositions'} />
            )}
            {hasPermission('write_tasks') && (
              <SidebarItem icon={Clipboard} label="Gestion des taches" onClick={() => handleViewChange('TaskManager')} isActive={currentView === 'TaskManager'} />
            )}
            {hasPermission('write_appointments') && (
              <SidebarItem icon={Calendar} label="Gestion des plannings" onClick={() => handleViewChange('planningsettings')} isActive={currentView === 'planningsettings'} />
            )}
          </SidebarItem>
        )}
      </nav>
    </aside>
  );
};

export default Sidebar;