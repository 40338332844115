import React, { useState, useEffect, useCallback, useRef } from 'react';
import { collection, addDoc, query, where, orderBy, onSnapshot, Timestamp, getDocs, limit } from 'firebase/firestore';
import { User, Send, Clock } from 'lucide-react';

const ClientDiscussion = ({ db, client, currentUser, employeeAppointments, addNotification }) => {
  const [messages, setMessages] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [newMessage, setNewMessage] = useState('');
  const messagesEndRef = useRef(null);

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: "smooth" });
  };

  useEffect(scrollToBottom, [messages]);

  useEffect(() => {
    const fetchEmployees = async () => {
      try {
        const employeesRef = collection(db, 'employees');
        const employeesSnapshot = await getDocs(employeesRef);
        const employeesList = employeesSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setEmployees(employeesList);
      } catch (error) {
        console.error("Erreur lors de la récupération des employés:", error);
      }
    };

    fetchEmployees();
  }, [db]);

  const isClientInAppointments = useCallback(() => {
    return employeeAppointments.some(appointment => appointment.clientId === client.id);
  }, [employeeAppointments, client]);

  useEffect(() => {
    if (!client || !client.id) return;

    const messagesRef = collection(db, 'clientMessages');
    const q = query(
      messagesRef,
      where('clientId', '==', client.id),
      orderBy('timestamp', 'desc'),
      limit(1)
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      snapshot.docChanges().forEach((change) => {
        if (change.type === "added") {
          const newMessage = change.doc.data();
          if (newMessage.userId !== currentUser.userId && isClientInAppointments()) {
            addNotification({
              id: change.doc.id,
              message: `Nouveau message de ${newMessage.userName} pour le client ${client.firstName} ${client.lastName}`,
              timestamp: newMessage.timestamp
            });
          }
        }
      });
    });

    return () => unsubscribe();
  }, [db, client, currentUser, isClientInAppointments, addNotification]);
  useEffect(() => {
    if (!client || !client.id) {
      console.error('Client or client ID is undefined');
      return;
    }

    const messagesRef = collection(db, 'clientMessages');
    const q = query(
      messagesRef,
      where('clientId', '==', client.id),
      orderBy('timestamp', 'asc')
    );

    const unsubscribe = onSnapshot(q, (snapshot) => {
      const fetchedMessages = snapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        timestamp: doc.data().timestamp?.toDate()
      }));
      setMessages(fetchedMessages);
    }, (error) => {
      console.error("Erreur lors de l'écoute des messages:", error);
    });

    return () => unsubscribe();
  }, [db, client]);

  const handleSendMessage = async (e) => {
    e.preventDefault();
    if (newMessage.trim() === '') return;

    try {
      const userName = `${currentUser.firstName} ${currentUser.lastName || ''}`.trim();
      const messageData = {
        clientId: client.id,
        userId: currentUser.userId,
        userName: userName,
        content: newMessage,
        timestamp: Timestamp.now()
      };

      await addDoc(collection(db, 'clientMessages'), messageData);
      setNewMessage('');
    } catch (error) {
      console.error("Erreur lors de l'envoi du message:", error);
    }
  };

  const getEmployeeColor = (userId) => {
    const employee = employees.find(emp => emp.id === userId);
    return employee?.color || '#808080'; // Couleur par défaut si non trouvée
  };

  const formatMessageDate = (timestamp) => {
    if (!(timestamp instanceof Date)) return '';
    const today = new Date();
    const messageDate = timestamp;

    if (messageDate.toDateString() === today.toDateString()) {
      return messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    } else {
      return messageDate.toLocaleDateString([], { year: 'numeric', month: 'short', day: 'numeric' }) + ' ' +
             messageDate.toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' });
    }
  };

  const renderMessages = () => {
    let currentDate = null;
    return messages.map((message, index) => {
      const isCurrentUser = message.userId === currentUser.userId;
      const bgColor = getEmployeeColor(message.userId);
      const textColor = isCurrentUser ? 'text-white' : 'text-gray-800';
      
      let showDate = false;
      if (message.timestamp && (!currentDate || message.timestamp.toDateString() !== currentDate.toDateString())) {
        showDate = true;
        currentDate = message.timestamp;
      }

      return (
        <React.Fragment key={message.id}>
          {showDate && (
            <div className="text-center my-4">
              <span className="bg-gray-200 text-gray-600 px-2 py-1 rounded-full text-xs">
                {message.timestamp.toLocaleDateString([], { weekday: 'long', year: 'numeric', month: 'long', day: 'numeric' })}
              </span>
            </div>
          )}
          <div className={`flex ${isCurrentUser ? 'justify-end' : 'justify-start'} mb-4`}>
            <div className={`max-w-xs lg:max-w-md px-4 py-2 rounded-lg shadow-md ${isCurrentUser ? 'bg-blue-500' : 'bg-white'}`} style={{ backgroundColor: isCurrentUser ? bgColor : 'white' }}>
              <div className="flex items-center mb-1">
                <User className="w-5 h-5 mr-2" style={{ color: isCurrentUser ? 'white' : bgColor }} />
                <span className={`font-semibold text-sm ${textColor}`}>{message.userName}</span>
              </div>
              <p className={`${textColor} mb-1`}>{message.content}</p>
              <div className="flex items-center justify-end">
                <Clock className="w-3 h-3 mr-1" style={{ color: isCurrentUser ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.5)' }} />
                <span className="text-xs" style={{ color: isCurrentUser ? 'rgba(255,255,255,0.7)' : 'rgba(0,0,0,0.5)' }}>
                  {formatMessageDate(message.timestamp)}
                </span>
              </div>
            </div>
          </div>
        </React.Fragment>
      );
    });
  };

  return (
    <div className="flex flex-col h-full bg-gray-100 rounded-lg shadow">
        {/* Ajout d'un en-tête pour indiquer le client */}
      <div className="bg-white p-4 border-b shadow-sm">
        <h2 className="text-xl font-semibold text-gray-800">
          Discussion pour {client.firstName} {client.lastName}
        </h2>
        {client.email && (
          <p className="text-sm text-gray-600">{client.email}</p>
        )}
      </div>
      <div className="flex-grow overflow-y-auto p-4 space-y-4">
        {messages.length === 0 ? (
          <p className="text-center text-gray-500">Aucun message pour le moment.</p>
        ) : (
          renderMessages()
        )}
        <div ref={messagesEndRef} />
      </div>
      <form onSubmit={handleSendMessage} className="bg-white p-4 border-t shadow-inner">
        <div className="flex items-center">
          <input
            type="text"
            value={newMessage}
            onChange={(e) => setNewMessage(e.target.value)}
            placeholder="Tapez votre message..."
            className="flex-grow px-4 py-3 border rounded-l-lg focus:outline-none focus:ring-2 focus:ring-blue-500 shadow-sm"
          />
          <button
            type="submit"
            className="bg-blue-500 text-white px-6 py-3 rounded-r-lg hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 transition duration-150 ease-in-out shadow-sm"
          >
            <Send className="w-6 h-6" />
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientDiscussion;