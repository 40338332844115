import React, { useState, useEffect, useCallback } from 'react';
import { collection, addDoc, updateDoc, deleteDoc, doc, getDocs, getDoc } from 'firebase/firestore';
import { Edit, Trash2 } from 'lucide-react';

const RolesPositionsManagement = ({ db, currentUser }) => {
  const [roles, setRoles] = useState([]);
  const [positions, setPositions] = useState([]);
  const [newRole, setNewRole] = useState({ name: '', permissions: [] });
  const [newPosition, setNewPosition] = useState('');
  const [editingRole, setEditingRole] = useState(null);
  const [editingPosition, setEditingPosition] = useState(null);
  const [userPermissions, setUserPermissions] = useState({});
  const [availablePermissions] = useState([
    'read_appointments', 'write_appointments',
    'read_clients', 'write_clients',
    'read_contracts', 'write_contracts',
    'read_employees', 'write_employees',
    'read_positions', 'write_positions',
    'read_roles', 'write_roles',
    'read_settings', 'write_settings',
    'read_taskCategories', 'write_taskCategories',
    'read_tasks', 'write_tasks',
    'manage_roles', 'manage_positions'
  ]);

  const fetchUserPermissions = useCallback(async () => {
    if (!db || !currentUser) return;
    try {
      const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRoleId = userData.role;
        const roleDoc = await getDoc(doc(db, 'roles', userRoleId));
        if (roleDoc.exists()) {
          const roleData = roleDoc.data();
          setUserPermissions(roleData.permissions.reduce((acc, perm) => {
            acc[perm] = true;
            return acc;
          }, {}));
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des permissions de l'utilisateur:", error);
    }
  }, [db, currentUser]);

  useEffect(() => {
    fetchUserPermissions();
  }, [fetchUserPermissions]);

  useEffect(() => {
    if (userPermissions.manage_roles || userPermissions.manage_positions) {
      fetchRolesAndPositions();
    }
  }, [userPermissions]);

  const hasPermission = (permission) => !!userPermissions[permission];

  const fetchRolesAndPositions = async () => {
    if (hasPermission('manage_roles')) {
      const rolesSnapshot = await getDocs(collection(db, "roles"));
      setRoles(rolesSnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        permissions: doc.data().permissions || []
      })));
    }

    if (hasPermission('manage_positions')) {
      const positionsSnapshot = await getDocs(collection(db, "positions"));
      setPositions(positionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
    }
  };

  const handleAddRole = async () => {
    if (newRole.name.trim() && hasPermission('manage_roles')) {
      await addDoc(collection(db, "roles"), {
        name: newRole.name.trim(),
        permissions: newRole.permissions
      });
      setNewRole({ name: '', permissions: [] });
      fetchRolesAndPositions();
    }
  };

  const handleAddPosition = async () => {
    if (newPosition.trim() && hasPermission('manage_positions')) {
      await addDoc(collection(db, "positions"), {
        name: newPosition.trim(),
        isMultipleAllowed: false
      });
      setNewPosition('');
      fetchRolesAndPositions();
    }
  };

  const handleEditRole = async (id, updatedRole) => {
    if (hasPermission('manage_roles')) {
      await updateDoc(doc(db, "roles", id), updatedRole);
      setEditingRole(null);
      fetchRolesAndPositions();
    }
  };

  const handleEditPosition = async (id, newName, isMultipleAllowed) => {
    if (hasPermission('manage_positions')) {
      await updateDoc(doc(db, "positions", id), {
        name: newName,
        isMultipleAllowed: isMultipleAllowed
      });
      setEditingPosition(null);
      fetchRolesAndPositions();
    }
  };

  const handleDeleteRole = async (id) => {
    if (hasPermission('manage_roles')) {
      await deleteDoc(doc(db, "roles", id));
      fetchRolesAndPositions();
    }
  };

  const handleDeletePosition = async (id) => {
    if (hasPermission('manage_positions')) {
      await deleteDoc(doc(db, "positions", id));
      fetchRolesAndPositions();
    }
  };

  const togglePermission = (permission) => {
    setNewRole(prev => ({
      ...prev,
      permissions: prev.permissions.includes(permission)
        ? prev.permissions.filter(p => p !== permission)
        : [...prev.permissions, permission]
    }));
  };

  const toggleEditingPermission = (role, permission) => {
    const updatedRoles = roles.map(r => {
      if (r.id === role.id) {
        const updatedPermissions = r.permissions.includes(permission)
          ? r.permissions.filter(p => p !== permission)
          : [...r.permissions, permission];
        return { ...r, permissions: updatedPermissions };
      }
      return r;
    });
    setRoles(updatedRoles);
  };

  const toggleMultipleAllowed = async (position) => {
    if (hasPermission('manage_positions')) {
      await updateDoc(doc(db, "positions", position.id), {
        isMultipleAllowed: !position.isMultipleAllowed
      });
      fetchRolesAndPositions();
    }
  };

  if (!hasPermission('manage_roles') && !hasPermission('manage_positions')) {
    return <p>Vous n'avez pas les permissions nécessaires pour accéder à cette page.</p>;
  }

  return (
    <div className="p-4">
      {hasPermission('manage_roles') && (
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-3">Gestion des Rôles</h2>
          <div className="flex space-x-2 mb-2">
            <input
              type="text"
              value={newRole.name}
              onChange={(e) => setNewRole(prev => ({ ...prev, name: e.target.value }))}
              placeholder="Nouveau rôle"
              className="p-2 border rounded flex-grow text-sm"
            />
            <button onClick={handleAddRole} className="px-3 py-2 bg-blue-500 text-white text-sm rounded">
              Ajouter
            </button>
          </div>

          <div className="flex flex-wrap gap-2 mb-4">
            {availablePermissions.map(permission => (
              <label key={permission} className="flex items-center">
                <input
                  type="checkbox"
                  checked={newRole.permissions.includes(permission)}
                  onChange={() => togglePermission(permission)}
                  className="mr-1"
                />
                <span className="text-sm">{permission}</span>
              </label>
            ))}
          </div>

          <ul className="space-y-1">
            {roles.map((role) => (
              <li key={role.id} className="p-2 border rounded flex justify-between items-center">
                {editingRole === role.id ? (
                  <>
                    <input
                      type="text"
                      value={role.name}
                      onChange={(e) => {
                        const updatedRoles = roles.map(r =>
                          r.id === role.id ? { ...r, name: e.target.value } : r
                        );
                        setRoles(updatedRoles);
                      }}
                      className="p-1 border rounded flex-grow text-sm mr-2"
                    />
                    <div className="flex flex-wrap gap-2 mb-2">
                      {availablePermissions.map(permission => (
                        <label key={permission} className="flex items-center">
                          <input
                            type="checkbox"
                            checked={role.permissions.includes(permission)}
                            onChange={() => toggleEditingPermission(role, permission)}
                            className="mr-1"
                          />
                          <span className="text-sm">{permission}</span>
                        </label>
                      ))}
                    </div>
                    <button onClick={() => handleEditRole(role.id, role)} className="px-3 py-2 bg-green-500 text-white text-sm rounded">
                      Sauvegarder
                    </button>
                    <button onClick={() => setEditingRole(null)} className="px-3 py-2 bg-gray-500 text-white text-sm rounded">
                      Annuler
                    </button>
                  </>
                ) : (
                  <>
                    <span className="text-sm">{role.name}</span>
                    <div className="flex space-x-2">
                      <button onClick={() => setEditingRole(role.id)} className="p-1 bg-yellow-500 text-white text-xs rounded">
                        <Edit size={16} />
                      </button>
                      <button onClick={() => handleDeleteRole(role.id)} className="p-1 bg-red-500 text-white text-xs rounded">
                        <Trash2 size={16} />
                      </button>
                    </div>
                  </>
                )}
              </li>
            ))}
          </ul>
        </div>
      )}

      {hasPermission('manage_positions') && (
        <div className="mb-6">
          <h2 className="text-lg font-semibold mb-3">Gestion des Positions</h2>
          <div className="flex space-x-2 mb-2">
            <input
              type="text"
              value={newPosition}
              onChange={(e) => setNewPosition(e.target.value)}
              placeholder="Nouvelle position"
              className="p-2 border rounded flex-grow text-sm"
            />
            <button onClick={handleAddPosition} className="px-3 py-2 bg-blue-500 text-white text-sm rounded">
              Ajouter
            </button>
          </div>

          <ul className="space-y-1">
            {positions.map((position) => (
              <li key={position.id} className="p-2 border rounded flex justify-between items-center">
                {editingPosition === position.id ? (
                  <input
                    type="text"
                    value={position.name}
                    onChange={(e) => {
                      const updatedPositions = positions.map(p =>
                        p.id === position.id ? { ...p, name: e.target.value } : p
                      );
                      setPositions(updatedPositions);
                    }}
                    onBlur={() => handleEditPosition(position.id, position.name, position.isMultipleAllowed)}
                    className="p-1 border rounded flex-grow text-sm mr-2"
                  />
                ) : (
                  <span className="text-sm">{position.name}</span>
                )}
                <div className="flex space-x-2">
                  <label className="mr-2 flex items-center">
                    <input
                      type="checkbox"
                      checked={position.isMultipleAllowed}
                      onChange={() => toggleMultipleAllowed(position)}
                      className="mr-1"
                    />
                    Multiple
                  </label>
                  <button onClick={() => setEditingPosition(position.id)} className="p-1 bg-yellow-500 text-white text-xs rounded">
                    <Edit size={16} />
                  </button>
                  <button onClick={() => handleDeletePosition(position.id)} className="p-1 bg-red-500 text-white text-xs rounded">
                    <Trash2 size={16} />
                  </button>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}
    </div>
  );
};

export default RolesPositionsManagement;