import React, { useState, useEffect, useMemo, useCallback } from 'react';
import { 
  collection, 
  query, 
  where, 
  getDocs, 
  Timestamp, 
  doc, 
  setDoc, 
  updateDoc, 
  deleteDoc, 
  writeBatch,
  getDoc,
  serverTimestamp,
  onSnapshot
} from 'firebase/firestore';
import { getDatabase, ref, onValue, set, onDisconnect } from 'firebase/database';
import { createUserWithEmailAndPassword, fetchSignInMethodsForEmail, onAuthStateChanged, deleteUser } from 'firebase/auth';
import { ref as storageRef, uploadBytes, getDownloadURL, deleteObject } from 'firebase/storage';
import { Camera, Archive, Edit, Clock, Calendar, Users, EuroIcon, Trash2, CalendarX, Mail, RefreshCcw, Phone } from 'lucide-react';
import { db, storage, auth } from '../firebase';
import { SketchPicker } from 'react-color'; // Import du color picker de react-color
import ForceLogoutButton from './ForceLogoutButton'; // Ajoutez cet import aux autres imports


const colorPalette = [
  // Ajout d'une palette plus grande
  '#FF6B6B', '#4ECDC4', '#45B7D1', '#FFA07A', '#98D8C8', '#F06292', '#AED581',
  '#FFD54F', '#7986CB', '#9575CD', '#4DB6AC', '#FFF176', '#FF8A65', '#A1887F',
  '#90A4AE', '#FFAB91', '#F48FB1', '#81C784', '#64B5F6', '#BA68C8', '#4DD0E1',
  '#DCE775', '#FFB74D', '#E57373', '#F06292', '#BA68C8', '#9575CD', '#7986CB',
  '#64B5F6', '#4FC3F7', '#4DD0E1', '#4DB6AC', '#81C784', '#AED581', '#DCE775',
  '#FFF176', '#FFD54F', '#FFB74D', '#FF8A65', '#A1887F', '#90A4AE', '#D1C4E9',
  '#B39DDB', '#9575CD', '#7E57C2', '#673AB7', '#5C6BC0', '#3F51B5', '#3949AB',
  '#303F9F', '#283593', '#1A237E', '#1976D2', '#1565C0', '#0D47A1', '#0277BD',
  '#01579B', '#00838F', '#006064', '#004D40', '#00695C', '#004C3F', '#00BFA5',
  '#00ACC1', '#0097A7', '#00838F', '#006064', '#FF6B6B', '#4ECDC4', '#45B7D1',
  '#FFD54F', '#7986CB', '#9575CD', '#4DB6AC', '#FFF176', '#FF8A65', '#A1887F',
  '#90A4AE', '#FFAB91', '#F48FB1', '#81C784', '#64B5F6', '#BA68C8', '#4DD0E1',
];


const ConfirmationModal = ({ isOpen, onClose, onConfirm, message }) => {
  if (!isOpen) return null;

  return (
    <div className="fixed inset-0 bg-gray-600 bg-opacity-50 overflow-y-auto h-full w-full z-50">
      <div className="relative top-20 mx-auto p-5 border w-96 shadow-lg rounded-md bg-white">
        <div className="mt-3 text-center">
          <h3 className="text-lg leading-6 font-medium text-gray-900">{message}</h3>
          <div className="mt-2 px-7 py-3">
            <p className="text-sm text-gray-500">
              Cette action est irréversible. Êtes-vous sûr de vouloir continuer ?
            </p>
          </div>
          <div className="items-center px-4 py-3">
            <button
              onClick={onConfirm}
              className="px-4 py-2 bg-red-500 text-white text-base font-medium rounded-md w-full shadow-sm hover:bg-red-700 focus:outline-none focus:ring-2 focus:ring-red-300"
            >
              Confirmer
            </button>
            <button
              onClick={onClose}
              className="mt-3 px-4 py-2 bg-gray-300 text-gray-800 text-base font-medium rounded-md w-full shadow-sm hover:bg-gray-400 focus:outline-none focus:ring-2 focus:ring-gray-300"
            >
              Annuler
            </button>
          </div>
        </div>
      </div>
    </div>
  );
};

const EmployeeManagement = ({ employees: initialEmployees, currentUser }) => {
  const [employees, setEmployees] = useState(initialEmployees || []);
  const [onlineStatus, setOnlineStatus] = useState({});
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showForm, setShowForm] = useState(false);
  const [usedColors, setUsedColors] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [roles, setRoles] = useState([]);
  const [positions, setPositions] = useState([]);
  const [isLoading, setIsLoading] = useState(true);
  const [employeeStats, setEmployeeStats] = useState({});
  const [showDeleteConfirmation, setShowDeleteConfirmation] = useState(false);
  const [employeeToDelete, setEmployeeToDelete] = useState(null);
  const [userPermissions, setUserPermissions] = useState({});
  const [isSuperAdmin, setIsSuperAdmin] = useState(false);

  const allPossiblePermissions = [
    'read_appointments', 'write_appointments',
    'read_clients', 'write_clients',
    'read_contracts', 'write_contracts',
    'read_employees', 'write_employees',
    'read_positions', 'write_positions',
    'read_roles', 'write_roles',
    'read_settings', 'write_settings',
    'read_taskCategories', 'write_taskCategories',
    'read_tasks', 'write_tasks',
    'manage_roles', 'manage_positions'

    // Ajoutez ici toutes les autres permissions possibles
  ];

  useEffect(() => {
    const db = getDatabase();
    const connectedRef = ref(db, '.info/connected');
    const presenceRef = ref(db, 'presence');
  
    // Créer un objet pour stocker les abonnements
    const subscriptions = {};
  
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      if (user) {
        // Pour chaque employé, nous allons vérifier si c'est le user actuel
        employees.forEach(employee => {
          const userStatusRef = ref(db, `presence/${employee.userId}`);
          
          // Si c'est l'utilisateur actuel, on gère sa présence
          if (employee.userId === user.uid) {
            onValue(connectedRef, (snap) => {
              if (snap.val() === true) {
                set(userStatusRef, true);
                onDisconnect(userStatusRef).set(false);
              }
            });
          }
  
          // Écouter les changements de statut pour cet employé
          subscriptions[employee.userId] = onValue(userStatusRef, (snapshot) => {
            setOnlineStatus(prev => ({
              ...prev,
              [employee.userId]: snapshot.val() === true
            }));
          });
        });
      }
    });
  
    // Nettoyage
    return () => {
      unsubscribe();
      // Nettoyer tous les abonnements
      Object.values(subscriptions).forEach(unsubscribe => {
        if (unsubscribe) unsubscribe();
      });
    };
  }, [employees]); // Dépendance à employees pour réagir aux changements de la liste
  
  const fetchUserPermissions = useCallback(async () => {
    if (!db || !currentUser) return;
    try {
      const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRoleId = userData.role;
        const roleDoc = await getDoc(doc(db, 'roles', userRoleId));
        if (roleDoc.exists()) {
          const roleData = roleDoc.data();
          const permissions = roleData.permissions.reduce((acc, perm) => {
            acc[perm] = true;
            return acc;
          }, {});
          setUserPermissions(permissions);
          
          // Vérifier si l'utilisateur a toutes les permissions possibles
          const isSuper = allPossiblePermissions.every(perm => permissions[perm]);
          setIsSuperAdmin(isSuper);
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des permissions de l'utilisateur:", error);
    }
  }, [db, currentUser]);

  useEffect(() => {
    fetchUserPermissions();
  }, [fetchUserPermissions]);

  const hasPermission = (permission) => !!userPermissions[permission];


  
  useEffect(() => {
    const fetchData = async () => {
      setIsLoading(true);
      try {
        const rolesSnapshot = await getDocs(collection(db, "roles"));
        const positionsSnapshot = await getDocs(collection(db, "positions"));
        
        setRoles(rolesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        setPositions(positionsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        
        if (!initialEmployees) {
          const employeesSnapshot = await getDocs(collection(db, "employees"));
          setEmployees(employeesSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() })));
        }
      } catch (error) {
        console.error("Erreur lors du chargement des données:", error);
      } finally {
        setIsLoading(false);
      }
    };

    fetchData();
  }, [initialEmployees]);

  useEffect(() => {
    const colors = employees.map(employee => employee.color).filter(Boolean);
    setUsedColors(colors);
  }, [employees]);

  useEffect(() => {
    const fetchEmployeeStats = async () => {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);
  
      const appointmentsQuery = query(
        collection(db, 'appointments'),
        where('date', '>=', Timestamp.fromDate(startOfMonth)),
        where('date', '<=', Timestamp.fromDate(endOfMonth))
      );
  
      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const appointments = appointmentsSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
  
      const stats = {};
      employees.forEach(employee => {
        stats[employee.id] = {
          totalHours: 0,
          appointmentCount: 0,
          uniqueClients: new Set(),
          revenue: 0
        };
      });
  
      appointments.forEach(appointment => {
        if (stats[appointment.employeeId]) {
          const startTime = new Date(`2000-01-01T${appointment.startTime}`);
          const endTime = new Date(`2000-01-01T${appointment.endTime}`);
          const duration = (endTime - startTime) / 3600000; // Convertir en heures
          
          stats[appointment.employeeId].totalHours += duration;
          stats[appointment.employeeId].appointmentCount += 1;
          stats[appointment.employeeId].uniqueClients.add(appointment.clientId);
          
          const employee = employees.find(e => e.id === appointment.employeeId);
          if (employee?.isAutoEntrepreneur) {
            stats[appointment.employeeId].revenue += duration * (employee.hourlyRate || 0);
          }
        }
      });
  
      Object.keys(stats).forEach(employeeId => {
        stats[employeeId].uniqueClients = stats[employeeId].uniqueClients.size;
      });
  
      setEmployeeStats(stats);
    };
  
    if (employees.length > 0) {
      fetchEmployeeStats();
    }
  }, [employees]);


  // Ajoutez cette fonction pour obtenir l'ID du rôle "utilisateur"
  const getUserRoleId = () => {
    const userRole = roles.find(role => role.name.toLowerCase() === 'utilisateur');
    return userRole ? userRole.id : null;
  };

  const forcePasswordReset = async (employee) => {
    try {
      await updateDoc(doc(db, "employees", employee.id), { needPasswordReset: true });
      setEmployees(prev => prev.map(emp => 
        emp.id === employee.id ? { ...emp, needPasswordReset: true } : emp
      ));
      alert(`Le mot de passe de ${employee.firstName} ${employee.lastName} devra être réinitialisé à la prochaine connexion.`);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut de réinitialisation du mot de passe:", error);
      alert("Une erreur est survenue lors de la mise à jour.");
    }
  };
  
  const forceEmailUpdate = async (employee) => {
    try {
      await updateDoc(doc(db, "employees", employee.id), { needEmailUpdate: true });
      setEmployees(prev => prev.map(emp => 
        emp.id === employee.id ? { ...emp, needEmailUpdate: true } : emp
      ));
      alert(`L'email de ${employee.firstName} ${employee.lastName} devra être mis à jour à la prochaine connexion.`);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du statut de mise à jour de l'email:", error);
      alert("Une erreur est survenue lors de la mise à jour.");
    }
  };

  const addOrUpdateEmployee = async (formData, photoFile, isNewEmployee) => {
    let createdUserId = null;
    const adminUser = auth.currentUser;
  
    try {
      if (isNewEmployee) {
        // Logique pour nouveau employé
        if (!formData.lastName || !formData.firstName) {
          throw new Error("Le nom et le prénom sont requis pour créer un nouveau compte.");
        }
        
        const emailQuery = query(collection(db, "employees"), where("email", "==", formData.email));
        const emailQuerySnapshot = await getDocs(emailQuery);
        if (!emailQuerySnapshot.empty) {
          throw new Error("Un employé avec cet email existe déjà");
        }
  
        const userCredential = await createUserWithEmailAndPassword(auth, formData.email, "motDePasseTemporaire");
        createdUserId = userCredential.user.uid;
        await auth.updateCurrentUser(adminUser);
        
        formData.needPasswordReset = true; // Uniquement pour les nouveaux employés
      }
  
      // Utiliser l'ID approprié
      const userId = isNewEmployee ? createdUserId : formData.id;
      let photoURL = formData.photoURL;
  
      // Gestion de la photo
      if (photoFile) {
        const fileName = `employee-photos/${userId}-${Date.now()}`;
        const photoRef = ref(storage, fileName);
        await uploadBytes(photoRef, photoFile);
        photoURL = await getDownloadURL(photoRef);
      }
  
      // Préparation des données pour mise à jour
      const employeeData = {
        ...formData,
        photoURL: photoURL,
        lastUpdated: serverTimestamp()
      };
  
      // Supprimer les champs qu'on ne veut pas écraser lors d'une mise à jour
      if (!isNewEmployee) {
        delete employeeData.needPasswordReset;
        delete employeeData.createdAt;
      }
  
      // Sauvegarde dans Firestore
      const employeeRef = doc(db, "employees", userId);
      await updateDoc(employeeRef, employeeData);
  
      return {
        id: userId,
        ...employeeData
      };
  
    } catch (error) {
      console.error("Erreur lors de l'ajout/mise à jour de l'employé:", error);
      if (isNewEmployee && createdUserId) {
        try {
          const user = auth.currentUser;
          if (user && user.uid === createdUserId) {
            await deleteUser(user);
          }
        } catch (deleteError) {
          console.error("Erreur lors de la suppression de l'utilisateur:", deleteError);
        }
      }
      throw error;
    }
  };
  
  // Modifier aussi handleSave pour mieux gérer les données existantes
  const handleSave = async (employeeData, photoFile) => {
    try {
      const isNewEmployee = !employeeData.id;
      
      // Supprimer les champs non modifiables si c'est une mise à jour
      if (!isNewEmployee) {
        delete employeeData.createdAt;
        delete employeeData.needPasswordReset;
        delete employeeData.userId;
      }
      
      const updatedEmployee = await addOrUpdateEmployee(employeeData, photoFile, isNewEmployee);
      
      if (isNewEmployee) {
        setEmployees(prev => [...prev, updatedEmployee]);
      } else {
        setEmployees(prev => prev.map(emp => emp.id === updatedEmployee.id ? {...emp, ...updatedEmployee} : emp));
      }
      
      setShowForm(false);
      setSelectedEmployee(null);
    } catch (error) {
      alert(error.message);
    }
  };
  

  const toggleArchiveEmployee = async (employee) => {
    try {
      const newArchiveStatus = !employee.isArchived;
      await updateDoc(doc(db, "employees", employee.id), { isArchived: newArchiveStatus });
      setEmployees(prev => prev.map(emp => emp.id === employee.id ? { ...emp, isArchived: newArchiveStatus } : emp));
    } catch (err) {
      console.error("Erreur lors de l'archivage/désarchivage de l'employé:", err);
    }
  };

  const sortEmployees = (a, b) => {
    if (a.isArchived !== b.isArchived) {
      return a.isArchived ? 1 : -1;
    }
    return `${a.lastName} ${a.firstName}`.localeCompare(`${b.lastName} ${b.firstName}`);
  };

  const sortedAndFilteredEmployees = useMemo(() => {
    return employees
      .filter(employee => {
        if (hasPermission('write_employees')) {
          return showArchived || !employee.isArchived;
        } else {
          return !employee.isArchived;
        }
      })
      .sort(sortEmployees);
  }, [employees, showArchived, hasPermission]);

  const formatHours = (totalHours) => {
    const hours = Math.floor(totalHours);
    const minutes = Math.round((totalHours - hours) * 60);
    return `${hours}h ${minutes.toString().padStart(2, '0')}min`;
  };

  const isLightColor = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness > 155;
  };

  const deleteEmployee = async (employee) => {
    try {
      // Suppression de l'employé de Firestore
      await deleteDoc(doc(db, "employees", employee.id));

      // Suppression de la photo de l'employé du Storage si elle existe
      if (employee.photoURL) {
        const photoRef = ref(storage, employee.photoURL);
        await deleteObject(photoRef);
      }

      // Suppression de l'utilisateur de Firebase Authentication
      // Note: Cela peut nécessiter des règles de sécurité spéciales ou une fonction Cloud
      // car la suppression d'un utilisateur par un autre utilisateur n'est généralement pas autorisée
      // Vous devrez peut-être implémenter cela côté serveur pour plus de sécurité
      // const user = auth.currentUser; // Ceci ne fonctionnera que si l'utilisateur supprime son propre compte
      // await deleteUser(user);

      // Mise à jour de l'état local
      setEmployees(prev => prev.filter(emp => emp.id !== employee.id));
      
      console.log("Employé supprimé avec succès");
    } catch (error) {
      console.error("Erreur lors de la suppression de l'employé:", error);
      throw error;
    }
  };

  const handleDeleteClick = (employee) => {
    if (!isSuperAdmin) {
      alert("Seul un super administrateur peut supprimer un employé.");
      return;
    }
    setEmployeeToDelete(employee);
    setShowDeleteConfirmation(true);
  };

  const handleConfirmDelete = async () => {
    if (employeeToDelete) {
      try {
        await deleteEmployee(employeeToDelete);
        setShowDeleteConfirmation(false);
        setEmployeeToDelete(null);
      } catch (error) {
        alert("Erreur lors de la suppression de l'employé : " + error.message);
      }
    }
  };

  const deleteAllAppointments = async (employeeId) => {
    try {
      const appointmentsRef = collection(db, 'appointments');
      const q = query(appointmentsRef, where('employeeId', '==', employeeId));
      const querySnapshot = await getDocs(q);
      
      const batch = writeBatch(db);
      querySnapshot.forEach((document) => {
        batch.delete(document.ref);
      });
      
      await batch.commit();
      
      console.log(`Tous les rendez-vous de l'employé ${employeeId} ont été supprimés.`);
      // Vous pouvez ajouter ici une mise à jour de l'état local si nécessaire
    } catch (error) {
      console.error("Erreur lors de la suppression des rendez-vous:", error);
      throw error;
    }
  };

  const handleDeleteAllAppointments = async (employee) => {
    if (window.confirm(`Êtes-vous sûr de vouloir supprimer tous les rendez-vous de ${employee.firstName} ${employee.lastName} ?`)) {
      try {
        await deleteAllAppointments(employee.id);
        alert("Tous les rendez-vous ont été supprimés avec succès.");
        // Mettre à jour les statistiques si nécessaire
        setEmployeeStats(prev => ({
          ...prev,
          [employee.id]: { totalHours: 0, appointmentCount: 0, uniqueClients: 0, revenue: 0 }
        }));
      } catch (error) {
        alert("Erreur lors de la suppression des rendez-vous : " + error.message);
      }
    }
  };

  const formatPhoneNumber = (phoneNumber) => {
    // Supprime tous les caractères non numériques
    const cleaned = ('' + phoneNumber).replace(/\D/g, '');
    
    // Format international pour la France (supposé)
    const match = cleaned.match(/^(\d{2})(\d{2})(\d{2})(\d{2})(\d{2})$/);
    if (match) {
      return '+33 ' + match[1] + ' ' + match[2] + ' ' + match[3] + ' ' + match[4] + ' ' + match[5];
    }
    
    // Si le format ne correspond pas, retourne le numéro tel quel
    return phoneNumber;
  };

  const fetchEmployees = useCallback(async () => {
    if (!db) return;
    try {
      const employeesRef = collection(db, 'employees');
      const q = query(employeesRef, where('isArchived', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map(doc => {
        const data = doc.data();
        let lastLogin = data.lastLogin;
        if (lastLogin instanceof Timestamp) {
          lastLogin = lastLogin.toDate();
        } else if (lastLogin && lastLogin.seconds) {
          lastLogin = new Timestamp(lastLogin.seconds, lastLogin.nanoseconds).toDate();
        }
        return {
          userId: doc.id,
          ...data,
          color: data.color || '#808080',
          isAdmin: false,
          lastLogin: lastLogin
        };
      });
      setEmployees(fetchedEmployees);
    } catch (error) {
      console.error("Erreur lors de la récupération des employés:", error);
    }
  }, [db]);

  

  const EmployeeTile = ({ employee, stats, onEdit, onArchive, onDelete, onDeleteAllAppointments, isOnline }) => {
    const backgroundColor = employee.isArchived ? '#A0AEC0' : (employee.color || '#E2E8F0');
    const textColor = isLightColor(backgroundColor) ? 'text-gray-800' : 'text-white';
    const tileOpacity = employee.isArchived ? 'opacity-60' : 'opacity-100';
  

    const formatLastLoginTime = (timestamp) => {
      if (!timestamp) {
        return 'Jamais connecté';
      }
      if (timestamp instanceof Timestamp) {
        timestamp = timestamp.toDate();
      }
      if (!(timestamp instanceof Date) || isNaN(timestamp)) {
        return 'Date de connexion invalide';
      }
      
      const now = new Date();
      const today = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const yesterday = new Date(today);
      yesterday.setDate(yesterday.getDate() - 1);
      
      const loginDate = new Date(timestamp.getFullYear(), timestamp.getMonth(), timestamp.getDate());
  
      if (loginDate.getTime() === today.getTime()) {
        const hours = timestamp.getHours().toString().padStart(2, '0');
        const minutes = timestamp.getMinutes().toString().padStart(2, '0');
        return `Aujourd'hui à ${hours}:${minutes}`;
      } else if (loginDate.getTime() === yesterday.getTime()) {
        const hours = timestamp.getHours().toString().padStart(2, '0');
        const minutes = timestamp.getMinutes().toString().padStart(2, '0');
        return `Hier à ${hours}:${minutes}`;
      } else {
        return timestamp.toLocaleDateString('fr-FR', { 
          day: 'numeric', 
          month: 'long', 
          year: 'numeric',
          hour: '2-digit',
          minute: '2-digit'
        });
      }
    };


    const truncateEmail = (email) => {
      if (email.length > 25) {
        return email.substring(0, 22) + '...';
      }
      return email;
    };

    const formattedPhoneNumber = employee.phoneNumber ? formatPhoneNumber(employee.phoneNumber) : 'Non renseigné';

    const canSeeStats = hasPermission('write_appointments') || (hasPermission('read_appointments') && currentUser.userId === employee.id);

    const statusIndicatorClass = isOnline
    ? 'bg-green-500 shadow-green-glow'
    : 'bg-red-500 shadow-red-glow';

    return (
      <div 
        className={`p-4 rounded-lg shadow-md ${textColor} ${tileOpacity} transition-opacity duration-300 relative overflow-hidden`} 
        style={{ backgroundColor }}
      >
        <div 
          className={`absolute top-2 right-2 w-4 h-4 rounded-full ${statusIndicatorClass} animate-pulse`} 
          title={isOnline ? 'En ligne' : 'Hors ligne'}
          style={{
            boxShadow: isOnline 
              ? '0 0 0 2px #10B981, 0 0 0 4px rgba(16, 185, 129, 0.3), 0 0 10px rgba(16, 185, 129, 0.5)' 
              : '0 0 0 2px #EF4444, 0 0 0 4px rgba(239, 68, 68, 0.3), 0 0 10px rgba(239, 68, 68, 0.5)',
            animation: 'pulse 2s cubic-bezier(0.4, 0, 0.6, 1) infinite'
          }}
        />
        <div className="flex items-center mb-4">
          <div className="w-16 h-16 rounded-full overflow-hidden mr-4 flex-shrink-0">
            {employee.photoURL ? (
              <img src={employee.photoURL} alt={employee.firstName} className="w-full h-full object-cover" />
            ) : (
              <div className="w-full h-full bg-gray-200 flex items-center justify-center">
                <Camera className="text-gray-400" size={32} />
              </div>
            )}
          </div>
          <div className="overflow-hidden">
            <h3 className="text-lg font-semibold truncate">{employee.firstName} {employee.lastName} 
          </h3>
            <p className="text-sm opacity-75 truncate">{truncateEmail(employee.email)}</p>
            <p className="text-sm opacity-75 flex items-center mt-1">
              <Phone className="mr-1 flex-shrink-0" size={14} />
              {employee.phoneNumber ? (
                <a 
                  href={`tel:${employee.phoneNumber}`}
                  className="hover:underline"
                  onClick={(e) => e.stopPropagation()}
                >
                  {formattedPhoneNumber}
                </a>
              ) : (
                <span>Non renseigné</span>
              )}
            </p>
          </div>
        </div>
        <div className="mt-2 text-sm">
          <strong>Rôle:</strong> {employee.role
            ? roles.find(r => r.id === employee.role)?.name
            : 'Aucun rôle'}
        </div>
        <div className="mt-1 text-sm">
          <strong>Positions:</strong> {employee.positionIds && employee.positionIds.length > 0
            ? employee.positionIds.map(positionId => 
                positions.find(p => p.id === positionId)?.name
              ).filter(Boolean).join(', ')
            : 'Aucune position'}
        </div>
        {hasPermission('write_employees') && (
        <>
          <div className="mt-2 text-sm">
            <strong>Dernière connexion:</strong> {formatLastLoginTime(employee.lastLogin)}
          </div>
          
          <div className="mt-2 text-sm flex items-center">
            <strong className="mr-2">Statut:</strong>
            <span className={`px-2 py-1 rounded-full text-xs font-semibold ${isOnline ? 'bg-green-100 text-green-800' : 'bg-red-100 text-red-800'}`}>
              {isOnline ? 'En ligne' : 'Hors ligne'}
            </span>
          </div>
        </>
      )}

        {canSeeStats && (
          <div className="grid grid-cols-2 gap-2 mt-2 text-sm">
            <div className="flex items-center">
              <Clock className="mr-1 flex-shrink-0" size={16} />
              <span className="truncate">{formatHours(stats.totalHours)}</span>
            </div>
            <div className="flex items-center">
              <Calendar className="mr-1 flex-shrink-0" size={16} />
              <span className="truncate">{stats.appointmentCount} RDV</span>
            </div>
            <div className="flex items-center">
              <Users className="mr-1 flex-shrink-0" size={16} />
              <span className="truncate">{stats.uniqueClients} clients</span>
            </div>
            {employee.isAutoEntrepreneur && (
              <div className="flex items-center">
                <span className="mr-1 flex-shrink-0">€</span>
                <span className="truncate">{stats.revenue.toFixed(2)}</span>
              </div>
            )}
          </div>
        )}
        <div className="mt-4 flex justify-end space-x-2">
          {hasPermission('write_employees') && (
            <>
              <button 
                onClick={() => onEdit(employee)}
                className={`${textColor} hover:opacity-75`}
                title="Modifier le collaborateur"
              >
                <Edit size={20} />
              </button>
              <button 
                onClick={() => onArchive(employee)}
                className={`${textColor} hover:opacity-75`}
                title="Archiver le collaborateur"
              >
                <Archive size={20} />
              </button>
            </>
          )}
          {isSuperAdmin && (
            <button 
              onClick={() => onDelete(employee)}
              className={`${textColor} hover:opacity-75`}
              title="Supprimer le collaborateur"
            >
              <Trash2 size={20} />
            </button>
          )}
          {hasPermission('write_appointments') && (
            <button 
              onClick={() => onDeleteAllAppointments(employee)}
              className={`${textColor} hover:opacity-75`}
              title="Supprimer tous les rendez-vous"
            >
              <CalendarX size={20} />
            </button>
          )}
          {hasPermission('write_employees') && (
            <>
              <button 
                onClick={() => forcePasswordReset(employee)}
                className={`${textColor} hover:opacity-75`}
                title="Forcer la réinitialisation du mot de passe"
              >
                <RefreshCcw size={20} />
              </button>
              <button 
                onClick={() => forceEmailUpdate(employee)}
                className={`${textColor} hover:opacity-75`}
                title="Forcer la mise à jour de l'email"
              >
                <Mail size={20} />
              </button>
              <ForceLogoutButton employeeId={employee.id} db={db} />
            </>
          )}
        </div>
      </div>
    );
  };

const EmployeeForm = ({ employee, onSave, onCancel }) => {
  // Dans EmployeeForm, ajouter ces nouveaux états
const [employeeData, setEmployeeData] = useState(() => ({
  firstName: '',
  lastName: '',
  email: '',
  phoneNumber: '',
  photoURL: '',
  birthDate: '', // Ajout du champ
  color: '',
  isAutoEntrepreneur: false,
  hourlyRate: '',
  isArchived: false,
  roles: '',
  positionIds: [],
  address: {
    street: '',
    city: '',
    postalCode: '',
    country: 'France'
  },
  socialSecurity: '',
  bankInfo: {
    iban: '',
    bic: ''
  },
  professionalInfo: {
    contractType: '',
    startDate: '',
    endDate: '',
    weeklyHours: '',
  },
  ...(employee || {})
}));
  const [photoFile, setPhotoFile] = useState(null);
  const [colorPickerVisible, setColorPickerVisible] = useState(false);

  const availableColors = useMemo(() => 
    colorPalette.filter(color => 
      !usedColors.includes(color) || color === employee?.color
    ),
    [usedColors, employee?.color]
  );

  useEffect(() => {
    if (!employee && availableColors.length > 0 && !employeeData.color) {
      setEmployeeData(prev => ({ ...prev, color: availableColors[0] }));
    }
  }, [employee, availableColors, employeeData.color]);


  const handleColorChange = (color) => {
    setEmployeeData(prev => ({ ...prev, color: color.hex }));
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    
    // Gestion des champs imbriqués (avec des points dans le nom)
    if (name.includes('.')) {
      const [parent, child] = name.split('.');
      setEmployeeData(prev => ({
        ...prev,
        [parent]: {
          ...prev[parent],
          [child]: value
        }
      }));
    } else {
      setEmployeeData(prev => ({ 
        ...prev, 
        [name]: type === 'checkbox' ? checked : value 
      }));
    }
  };

    const handlePhotoChange = (e) => {
      if (e.target.files[0]) {
        setPhotoFile(e.target.files[0]);
      }
    };

    const handleRoleChange = (roleId) => {
      setEmployeeData(prev => ({
        ...prev,
        role: roleId // Mise à jour pour un seul rôle
      }));
    };

    const handlePositionChange = (positionId) => {
      setEmployeeData(prev => {
        const currentPositions = prev.positionIds || [];
        const position = positions.find(p => p.id === positionId);
        let newPositions;
        if (currentPositions.includes(positionId)) {
          newPositions = currentPositions.filter(id => id !== positionId);
        } else {
          if (position?.isMultipleAllowed) {
            newPositions = [...currentPositions, positionId];
          } else {
            newPositions = [positionId];
          }
        }
        return { ...prev, positionIds: newPositions };
      });
    };

    const handleSubmit = () => {
      onSave(employeeData, photoFile);
    };

    return (
      <div className="bg-white p-6 rounded-lg shadow-lg">
        <h2 className="text-2xl font-bold text-teal-700 mb-6">
          {employee ? 'Modifier l\'employé' : 'Ajouter un employé'}
        </h2>
        <div className="grid grid-cols-2 gap-6">
          <div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Prénom</label>
              <input
                type="text"
                name="firstName"
                value={employeeData.firstName}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Nom</label>
              <input
                type="text"
                name="lastName"
                value={employeeData.lastName}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Date de naissance</label>
              <input
                type="date"
                name="birthDate"
                value={employeeData.birthDate || ''}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Email</label>
              <input
                type="email"
                name="email"
                value={employeeData.email}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
              <label className="block text-sm font-medium text-gray-700">Numéro de téléphone</label>
              <input
                type="tel"
                name="phoneNumber"
                value={employeeData.phoneNumber}
                onChange={handleInputChange}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
              />
            </div>
            <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Couleur</label>
          <div className="flex items-center mt-1">
            <div 
              className="w-10 h-10 rounded-full mr-2" 
              style={{ backgroundColor: employeeData.color }}
              onClick={() => setColorPickerVisible(!colorPickerVisible)}  // Toggle the color picker visibility
            ></div>
          
          </div>
          {colorPickerVisible && (
            <SketchPicker
              color={employeeData.color}
              onChange={handleColorChange}
              presetColors={availableColors}  // Only available colors are passed here
            />
          )}
        </div>

        <div className="col-span-2">
    <h3 className="text-lg font-semibold mb-4">Informations professionnelles</h3>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Type de contrat</label>
        <select
          name="professionalInfo.contractType"
          value={employeeData.professionalInfo.contractType}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        >
          <option value="">Sélectionner</option>
          <option value="Indépendant">Indépendant</option>
          <option value="Cesu">Cesu</option>
          <option value="CDI">CDI</option>
          <option value="CDD">CDD</option>
          <option value="Interim">Intérim</option>
          <option value="Stage">Stage</option>
        </select>
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Date de début</label>
        <input
          type="date"
          name="professionalInfo.startDate"
          value={employeeData.professionalInfo.startDate}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        />
      </div>
    </div>
  </div>

  <div className="col-span-2">
    <h3 className="text-lg font-semibold mb-4">Coordonnées bancaires</h3>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">IBAN</label>
        <input
          type="text"
          name="bankInfo.iban"
          value={employeeData.bankInfo.iban}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">BIC</label>
        <input
          type="text"
          name="bankInfo.bic"
          value={employeeData.bankInfo.bic}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        />
      </div>
    </div>
  </div>

  <div className="col-span-2">
    <h3 className="text-lg font-semibold mb-4">Sécurité sociale et adresse</h3>
    <div className="grid grid-cols-2 gap-4">
      <div>
        <label className="block text-sm font-medium text-gray-700">Numéro de sécurité sociale</label>
        <input
          type="text"
          name="socialSecurity"
          value={employeeData.socialSecurity}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          maxLength="15"
          placeholder="X XX XX XX XXX XXX XX"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Rue</label>
        <input
          type="text"
          name="address.street"
          value={employeeData.address.street}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Ville</label>
        <input
          type="text"
          name="address.city"
          value={employeeData.address.city}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        />
      </div>
      <div>
        <label className="block text-sm font-medium text-gray-700">Code postal</label>
        <input
          type="text"
          name="address.postalCode"
          value={employeeData.address.postalCode}
          onChange={handleInputChange}
          className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
        />
      </div>
      </div>
      </div>
      
            {isSuperAdmin && (
          <div className="mb-4">
            <label className="block text-sm font-medium text-gray-700">Rôle</label>
            <select
              name="role"
              value={employeeData.role}
              onChange={handleInputChange}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
            >
              <option value="">Sélectionnez un rôle</option>
              {roles.map(role => (
                <option key={role.id} value={role.id}>{role.name}</option>
              ))}
            </select>
          </div>
        )}
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Positions</label>
          <div className="mt-1 space-y-2">
            {positions.map(position => (
              <label key={position.id} className="inline-flex items-center mr-4">
                <input
                  type="checkbox"
                  checked={(employeeData.positionIds || []).includes(position.id)}
                  onChange={() => handlePositionChange(position.id)}
                  className="form-checkbox h-4 w-4 text-indigo-600"
                />
                <span className="ml-2">{position.name}</span>
              </label>
                ))}
              </div>
            </div>
            <div className="mb-4">
              <label className="flex items-center">
                <input
                  type="checkbox"
                  name="isAutoEntrepreneur"
                  checked={employeeData.isAutoEntrepreneur}
                  onChange={handleInputChange}
                  className="rounded border-gray-300 text-indigo-600 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50 mr-2"
                />
                <span className="text-sm font-medium text-gray-700">Auto-entrepreneur</span>
              </label>
            </div>
            {employeeData.isAutoEntrepreneur && (
              <div className="mb-4">
                <label className="block text-sm font-medium text-gray-700">Taux horaire (€)</label>
                <input
                  type="number"
                  name="hourlyRate"
                  value={employeeData.hourlyRate}
                  onChange={handleInputChange}
                  className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
                />
              </div>
            )}
          </div>
          <div className="flex flex-col items-center justify-center">
            <div className="w-40 h-40 bg-gray-200 rounded-full flex items-center justify-center overflow-hidden mb-4">
              {employeeData.photoURL || photoFile ? (
                <img 
                  src={photoFile ? URL.createObjectURL(photoFile) : employeeData.photoURL} 
                  alt="Employee" 
                  className="w-full h-full object-cover"
                />
              ) : (
                <Camera className="text-gray-400" size={48} />
              )}
            </div>
            <input
              type="file"
              accept="image/*"
              onChange={handlePhotoChange}
              className="hidden"
              id="photo-upload"
            />
            <label 
              htmlFor="photo-upload" 
              className="bg-blue-500 text-white px-4 py-2 rounded-full cursor-pointer hover:bg-blue-600 transition duration-300"
            >
              {employeeData.photoURL ? 'Changer la photo' : 'Ajouter une photo'}
            </label>
          </div>
        </div>
        <div className="mt-6 flex justify-end space-x-4">
          <button onClick={onCancel} className="px-4 py-2 border border-gray-300 rounded-md text-sm font-medium text-gray-700 hover:bg-gray-50">
            Annuler
          </button>
          <button onClick={handleSubmit} className="px-4 py-2 border border-transparent rounded-md shadow-sm text-sm font-medium text-white bg-teal-600 hover:bg-teal-700 focus:outline-none focus:ring-2 focus:ring-offset-2 focus:ring-teal-500">
            Sauvegarder
          </button>
        </div>
      </div>
    );
  };

  const handleEditEmployee = (employee) => {
    setSelectedEmployee(employee);
    setShowForm(true);
  };

  const handleArchiveEmployee = (employee) => {
    toggleArchiveEmployee(employee);
  };

  const calculateAge = (birthDate) => {
    if (!birthDate) return null;
    const today = new Date();
    const birth = new Date(birthDate);
    let age = today.getFullYear() - birth.getFullYear();
    const monthDiff = today.getMonth() - birth.getMonth();
    if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
      age--;
    }
    return age;
  };

  if (isLoading) {
    return <div className="flex justify-center items-center h-full">Chargement...</div>;
  }

  return (
    <div className="container mx-auto px-4">
      <h2 className="text-2xl font-bold mb-4">Liste des Collaborateurs</h2>
      {!showForm && (
        <>
          <div className="flex justify-between items-center mb-4">
            {hasPermission('write_employees') && (
              <button 
                onClick={() => {
                  setSelectedEmployee(null);
                  setShowForm(true);
                }}
                className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
              >
                Ajouter un collaborateur
              </button>
            )}
            {hasPermission('write_employees') && (
              <label className="flex items-center">
                <input
                  type="checkbox"
                  checked={showArchived}
                  onChange={() => setShowArchived(!showArchived)}
                  className="mr-2"
                />
                Afficher les collaborateurs archivés
              </label>
            )}
          </div>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-4">
            {sortedAndFilteredEmployees.map(employee => (
              <EmployeeTile 
                key={employee.id} 
                employee={employee} 
                stats={employeeStats[employee.id] || { totalHours: 0, appointmentCount: 0, uniqueClients: 0, revenue: 0 }}
                onEdit={hasPermission('write_employees') ? handleEditEmployee : null}
                onArchive={hasPermission('write_employees') ? handleArchiveEmployee : null}
                onDelete={hasPermission('write_employees') ? handleDeleteClick : null}
                onDeleteAllAppointments={hasPermission('write_appointments') ? handleDeleteAllAppointments : null}
                isOnline={onlineStatus[employee.id] || false}
                hasPermission={hasPermission}  // Ajoutez cette ligne
              />
            ))}
          </div>
          <ConfirmationModal
            isOpen={showDeleteConfirmation}
            onClose={() => setShowDeleteConfirmation(false)}
            onConfirm={handleConfirmDelete}
            message="Êtes-vous sûr de vouloir supprimer ce collaborateur ?"
          />
        </>
      )}
      {showForm && hasPermission('write_employees') && (
        <EmployeeForm 
          employee={selectedEmployee}
          onSave={handleSave}
          onCancel={() => {
            setShowForm(false);
            setSelectedEmployee(null);
          }}
        />
      )}
    </div>
  );
};

export default EmployeeManagement;