import React, { useState, useEffect } from 'react';
import { LineChart, Line, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer } from 'recharts';
import { collection, query, where, getDocs, Timestamp, getDoc, doc } from 'firebase/firestore';
import { ChevronLeft, ChevronRight, Calendar, Clock, EuroIcon } from 'lucide-react';

const AnnualEmployeeChart = ({ db, employeeId }) => {
  const [annualData, setAnnualData] = useState([]);
  const [selectedYear, setSelectedYear] = useState(new Date().getFullYear());
  const [annualTotals, setAnnualTotals] = useState({
    plannedHours: 0,
    completedHours: 0,
    forecastAmount: 0
  });

  useEffect(() => {
    const fetchAnnualData = async () => {
      const startOfYear = new Date(selectedYear, 0, 1);
      const endOfYear = new Date(selectedYear, 11, 31, 23, 59, 59);

      const employeeDoc = await getDoc(doc(db, 'employees', employeeId));
      const employeeData = employeeDoc.exists() ? employeeDoc.data() : null;

      const appointmentsQuery = query(
        collection(db, 'appointments'),
        where('employeeId', '==', employeeId),
        where('date', '>=', Timestamp.fromDate(startOfYear)),
        where('date', '<=', Timestamp.fromDate(endOfYear))
      );

      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const appointments = appointmentsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id,
        date: doc.data().date.toDate()
      }));

      const contractsSnapshot = await getDocs(collection(db, 'contracts'));
      const contracts = {};
      contractsSnapshot.forEach(doc => {
        contracts[doc.id] = doc.data();
      });

      const monthlyData = Array(12).fill().map(() => ({ 
        planned: 0, 
        completed: 0, 
        amount: 0 
      }));

      let totalPlannedHours = 0;
      let totalCompletedHours = 0;
      let totalForecastAmount = 0;

      for (const appointment of appointments) {
        const monthIndex = appointment.date.getMonth();
        const duration = calculateDuration(appointment.startTime, appointment.endTime);
        const durationInHours = duration.hours + duration.minutes / 60;
        
        monthlyData[monthIndex].planned += durationInHours;
        totalPlannedHours += durationInHours;
        
        let hourlyRate;
        if (employeeData && employeeData.isAutoEntrepreneur && employeeData.hourlyRate) {
          hourlyRate = parseFloat(employeeData.hourlyRate);
        } else {
          const clientContract = contracts[appointment.clientId];
          hourlyRate = clientContract ? parseFloat(clientContract.hourlyRate) : 0;
        }

        const appointmentAmount = durationInHours * hourlyRate;
        monthlyData[monthIndex].amount += appointmentAmount;
        totalForecastAmount += appointmentAmount;

        if (appointment.date <= new Date()) {
          monthlyData[monthIndex].completed += durationInHours;
          totalCompletedHours += durationInHours;
        }
      }

      const formattedData = monthlyData.map((data, index) => ({
        name: new Date(selectedYear, index).toLocaleString('default', { month: 'short' }),
        "Heures planifiées": Number(data.planned.toFixed(2)),
        "Heures effectuées": Number(data.completed.toFixed(2)),
        "Montant prévisionnel (€)": Number(data.amount.toFixed(2))
      }));

      setAnnualData(formattedData);
      setAnnualTotals({
        plannedHours: totalPlannedHours,
        completedHours: totalCompletedHours,
        forecastAmount: totalForecastAmount
      });
    };

    if (employeeId) {
      fetchAnnualData();
    }
  }, [db, employeeId, selectedYear]);

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    const diff = (end - start) / (1000 * 60);
    return { hours: Math.floor(diff / 60), minutes: Math.round(diff % 60) };
  };

  const changeYear = (increment) => {
    setSelectedYear(prevYear => prevYear + increment);
  };

  const formatHours = (hours) => {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours}h${minutes.toString().padStart(2, '0')}`;
  };

  return (
    <div className="mb-8">
      <div className="flex items-center justify-between mb-4">
        <h2 className="text-2xl font-bold">Aperçu annuel</h2>
        <div className="flex items-center">
          <button 
            onClick={() => changeYear(-1)} 
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 mr-2"
          >
            <ChevronLeft size={20} />
          </button>
          <span className="text-lg font-semibold">{selectedYear}</span>
          <button 
            onClick={() => changeYear(1)} 
            className="p-2 bg-gray-200 rounded-full hover:bg-gray-300 ml-2"
          >
            <ChevronRight size={20} />
          </button>
        </div>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-3 gap-4 mb-6">
        <div className="bg-blue-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Calendar className="mr-2" size={16} />Total annuel
          </h3>
          <p className="text-2xl font-bold text-blue-600">{formatHours(annualTotals.plannedHours)}</p>
          <p className="text-sm text-gray-600">Heures planifiées</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Clock className="mr-2" size={16} />Heures effectuées
          </h3>
          <p className="text-2xl font-bold text-green-600">{formatHours(annualTotals.completedHours)}</p>
          <p className="text-sm text-gray-600">Sur {formatHours(annualTotals.plannedHours)} planifiées</p>
        </div>
        <div className="bg-yellow-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <EuroIcon className="mr-2" size={16} />Montant prévisionnel
          </h3>
          <p className="text-2xl font-bold text-yellow-600">{annualTotals.forecastAmount.toFixed(2)} €</p>
          <p className="text-sm text-gray-600">Pour l'année {selectedYear}</p>
        </div>
      </div>

      <ResponsiveContainer width="100%" height={300}>
        <LineChart data={annualData}>
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis yAxisId="left" />
          <YAxis yAxisId="right" orientation="right" />
          <Tooltip />
          <Legend />
          <Line yAxisId="left" type="monotone" dataKey="Heures planifiées" stroke="#8884d8" />
          <Line yAxisId="left" type="monotone" dataKey="Heures effectuées" stroke="#82ca9d" />
          <Line yAxisId="right" type="monotone" dataKey="Montant prévisionnel (€)" stroke="#ffc658" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default AnnualEmployeeChart;