import React, { useState, useEffect } from 'react';
import { doc, getDoc, setDoc } from 'firebase/firestore';
import { Check } from 'lucide-react';

const GeneralSettings = ({ db, onSettingsUpdate }) => {
  const [settings, setSettings] = useState({
    organizationName: '',
    timeZone: 'Europe/Paris',
    country: '',
  });
  const [isSaving, setIsSaving] = useState(false);
  const [saveSuccess, setSaveSuccess] = useState(false);

  useEffect(() => {
    const fetchSettings = async () => {
      const settingsDoc = await getDoc(doc(db, 'settings', 'general'));
      if (settingsDoc.exists()) {
        const data = settingsDoc.data();
        setSettings(data);
      }
    };
    fetchSettings();
  }, [db]);

  const handleInputChange = (e) => {
    const { name, value } = e.target;
    setSettings(prevSettings => ({
      ...prevSettings,
      [name]: value
    }));
  };

  const saveSettings = async () => {
    setIsSaving(true);
    try {
      await setDoc(doc(db, 'settings', 'general'), settings);
      setSaveSuccess(true);
      onSettingsUpdate(settings);
      setTimeout(() => setSaveSuccess(false), 2000);
    } catch (error) {
      console.error("Erreur lors de la sauvegarde des paramètres:", error);
    } finally {
      setIsSaving(false);
    }
  };

  return (
    <div className="p-6 bg-white rounded-lg shadow-lg">
      <h2 className="text-2xl font-bold text-teal-700 mb-6">Paramètres généraux</h2>
      
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Nom de l'organisation</label>
          <input
            type="text"
            name="organizationName"
            value={settings.organizationName}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>
  
        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Fuseau horaire</label>
          <select
            name="timeZone"
            value={settings.timeZone}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          >
            <option value="Europe/Paris">Europe/Paris (France)</option>
            <option value="Europe/Brussels">Europe/Brussels (Belgique)</option>
            <option value="Europe/Zurich">Europe/Zurich (Suisse)</option>
            <option value="Europe/Luxembourg">Europe/Luxembourg (Luxembourg)</option>
            <option value="Africa/Abidjan">Africa/Abidjan (Côte d'Ivoire)</option>
            <option value="Africa/Algiers">Africa/Algiers (Algérie)</option>
            <option value="Africa/Dakar">Africa/Dakar (Sénégal)</option>
            <option value="Africa/Casablanca">Africa/Casablanca (Maroc)</option>
            <option value="America/Montreal">America/Montreal (Canada)</option>
            <option value="Indian/Reunion">Indian/Reunion (La Réunion)</option>
            <option value="Pacific/Tahiti">Pacific/Tahiti (Polynésie française)</option>
            {/* Ajoutez d'autres options de fuseau horaire ici */}
          </select>
        </div>

        <div className="mb-4">
          <label className="block text-sm font-medium text-gray-700">Pays</label>
          <input
            type="text"
            name="country"
            value={settings.country}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200 focus:ring-opacity-50"
          />
        </div>

        <button
          onClick={saveSettings}
          disabled={isSaving}
          className="mt-4 px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500 focus:ring-opacity-50 disabled:opacity-50"
      >
          {isSaving ? 'Sauvegarde en cours...' : 'Sauvegarder'}
        </button>

        {saveSuccess && (
          <div className="fixed top-4 right-4 bg-green-100 border border-green-400 text-green-700 px-4 py-2 rounded flex items-center">
            <Check size={18} className="mr-2" />
            Sauvegardé
          </div>
        )}
      </div>
  );
};

export default GeneralSettings;
