import React, { useState, useEffect, useRef } from 'react';
import { X, Check, AlertTriangle } from 'lucide-react';
import { v4 as uuid } from 'uuid';
import { sha256 } from 'js-sha256';


const NFCModal = ({ client, onClose, onSubmit }) => {
  const [step, setStep] = useState(0);
  const [error, setError] = useState(null);
  const [nfcData, setNfcData] = useState(null);
  const [tagSerialNumber, setTagSerialNumber] = useState(null);
  const [isNdefReaderAvailable, setIsNdefReaderAvailable] = useState(false);
  const abortControllerRef = useRef(null);
  const writeCompleteRef = useRef(false);
  const verifyCompleteRef = useRef(false);
  

  const steps = [
    { id: 'init', title: 'Initialisation', description: 'Préparation des données pour l\'enregistrement NFC' },
    { id: 'place', title: 'Placement du tag', description: 'Veuillez placer le tag NFC près de l\'appareil' },
    { id: 'write', title: 'Écriture du tag', description: 'Enregistrement des informations sur le tag' },
    { id: 'verify', title: 'Vérification', description: 'Vérification des données enregistrées' },
    { id: 'complete', title: 'Terminé', description: 'Enregistrement NFC complété avec succès' }
  ];

  const generateNfcData = (serialNumber) => {
    const cid = uuid();
    const secretKey = "Son_of_the_Bitch!"; // À remplacer par une vraie clé secrète
    const hash = sha256(cid + secretKey + client.id);
    const addr = btoa(client.address);
    const exp = new Date(Date.now() + 365 * 24 * 60 * 60 * 1000).toISOString().split('T')[0];
    const url = "https://agenda.omadservices.com";

    return {
      cid,
      clientId: client.id, // Ajout de l'ID du client
      hash: hash.slice(0, 20),
      addr,
      exp,
      serialNumber,
      url
    };
  };

  useEffect(() => {
    setIsNdefReaderAvailable('NDEFReader' in window);
    if (step === 0) {
      setTimeout(() => setStep(1), 1000);
    }
  }, [step]);

  const handleNfcOperation = async () => {
    if (!isNdefReaderAvailable) {
      setError("NFC n'est pas supporté sur cet appareil.");
      return;
    }
  
    try {
      abortControllerRef.current = new AbortController();
      const ndef = new window.NDEFReader();
  
      await ndef.scan({ signal: abortControllerRef.current.signal });
  
      // Ajout de event.preventDefault() et stopImmediatePropagation()
      ndef.addEventListener("reading", async (event) => {
        event.preventDefault();  // Bloque le comportement par défaut
        event.stopImmediatePropagation();  // Stoppe tous les autres gestionnaires d'événements potentiels du navigateur
        console.log("Tag détecté, serial number:", event.serialNumber);
        
        const { message, serialNumber } = event;
        setTagSerialNumber(serialNumber);
  
        if (step === 1 && !writeCompleteRef.current) {
          setStep(2);
          try {
            const dataToWrite = generateNfcData(serialNumber);
            console.log("Données à écrire:", dataToWrite);
        
            // Créer un record pour les données JSON
            const jsonRecord = { recordType: "text", data: JSON.stringify(dataToWrite) };
        
            // Créer un record pour l'URL
            const urlRecord = { recordType: "url", data: dataToWrite.url };
        
            // Écriture NFC avec les deux records
            await ndef.write({ records: [jsonRecord, urlRecord] });
            
            console.log("Écriture réussie");
            setNfcData(dataToWrite);
            writeCompleteRef.current = true;
            setStep(3);
          } catch (writeError) {
            console.error("Erreur d'écriture:", writeError);
            setError("Erreur lors de l'écriture du tag. " + writeError.message);
            abortControllerRef.current.abort();
          }
        } else if (step === 3 && !verifyCompleteRef.current) {
          try {
            if (message && message.records) {
              let verifiedData = null;
              let urlData = null;
        
              for (const record of message.records) {
                // Utiliser 'utf-8' comme encodage par défaut
                const textDecoder = new TextDecoder(record.encoding || 'utf-8');
                const decodedData = textDecoder.decode(record.data);
        
                if (record.recordType === "text") {
                  try {
                    verifiedData = JSON.parse(decodedData);
                  } catch (error) {
                    console.error("Erreur lors du parsing JSON:", error);
                  }
                } else if (record.recordType === "url") {
                  urlData = decodedData;
                }
              }
        
              console.log("Données lues:", verifiedData);
              console.log("URL lue:", urlData);
        
              if (verifiedData && urlData) {
                // Comparer uniquement les champs pertinents
                const relevantFields = ['cid', 'clientId', 'hash', 'addr', 'exp', 'serialNumber'];
                const areDataEqual = relevantFields.every(field => verifiedData[field] === nfcData[field]);
        
                if (areDataEqual && urlData === nfcData.url) {
                  console.log("Vérification réussie");
                  setStep(4);
                  verifyCompleteRef.current = true;
                  abortControllerRef.current.abort();
                } else {
                  console.error("Données non correspondantes");
                  setError("La vérification a échoué. Les données ne correspondent pas.");
                  abortControllerRef.current.abort();
                }
              } else {
                setError("Données manquantes dans le tag NFC.");
                abortControllerRef.current.abort();
              }
            }
          } catch (verifyError) {
            console.error("Erreur de vérification:", verifyError);
            setError("Erreur lors de la vérification du tag. " + verifyError.message);
            abortControllerRef.current.abort();
          }
        }
      }, { once: false }); // Utilisation de once: false pour permettre plusieurs lectures si nécessaire
  
    } catch (error) {
      if (error.name !== 'AbortError') {
        console.error("Erreur NFC:", error);
        setError("Erreur lors de l'accès au NFC. " + error.message);
      }
    }
  };
  
  
  

  useEffect(() => {
    if (step === 1 || step === 3) {
      handleNfcOperation();
    }

    return () => {
      if (abortControllerRef.current) {
        abortControllerRef.current.abort();
      }
    };
  }, [step]);


  const handleSubmit = () => {
    onSubmit({ ...nfcData, tagSerialNumber });
  };

  const renderStepContent = () => {
    const currentStep = steps[step];
    return (
      <div className="text-center">
        <h3 className="text-lg font-semibold mb-2">{currentStep.title}</h3>
        <p>{currentStep.description}</p>
        {error && (
          <div className="mt-4 text-red-500 flex items-center justify-center">
            <AlertTriangle size={20} className="mr-2" />
            {error}
          </div>
        )}
      </div>
    );
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
        <div className="flex justify-between items-center mb-4">
          <h2 className="text-xl font-bold">Configuration NFC pour {client.firstName} {client.lastName}</h2>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={24} />
          </button>
        </div>

        <div className="mb-6">
          {renderStepContent()}
        </div>

        <div className="flex justify-center space-x-2">
          {steps.map((s, index) => (
            <div
              key={s.id}
              className={`w-4 h-4 rounded-full ${
                index === step ? 'bg-blue-500' : 
                index < step ? 'bg-green-500' : 'bg-gray-300'
              }`}
            />
          ))}
        </div>

        {step === 4 && (
          <div className="mt-6 flex justify-center">
            <button
              onClick={handleSubmit}
              className="px-4 py-2 bg-green-500 text-white rounded hover:bg-green-600"
            >
              <Check size={20} className="mr-2 inline" />
              Terminer
            </button>
          </div>
        )}
      </div>
    </div>
  );
};

export default NFCModal;