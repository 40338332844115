import React, { useState, useEffect } from 'react';
import { getAuth, updatePassword, reauthenticateWithCredential, EmailAuthProvider, updateEmail } from 'firebase/auth';
import { doc, updateDoc, getDoc } from 'firebase/firestore';
import { ref, uploadBytes, getDownloadURL } from 'firebase/storage';
import { useNavigate } from 'react-router-dom';
import { User, Mail, Phone, Lock, Camera, ArrowLeft, Home, FileText, Shield, Briefcase, MapPin, Calendar, CheckCircle } from 'lucide-react';
import { db, storage } from '../firebase';

const tabs = [
  { id: 'info', label: 'Infos', longLabel: 'Informations personnelles', icon: User },
  { id: 'security', label: 'Sécurité', longLabel: 'Sécurité', icon: Shield },
  { id: 'professional', label: 'Pro', longLabel: 'Informations professionnelles', icon: Briefcase }
];

const ProfilePage = () => {
  const [activeTab, setActiveTab] = useState('info');
  const [userData, setUserData] = useState(null);
  const [isEditing, setIsEditing] = useState(false);
  const [newPassword, setNewPassword] = useState('');
  const [currentPassword, setCurrentPassword] = useState('');
  const [newEmail, setNewEmail] = useState('');
  const [error, setError] = useState(null);
  const [success, setSuccess] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const [isMobile, setIsMobile] = useState(window.innerWidth < 768);

  useEffect(() => {
    const handleResize = () => {
      setIsMobile(window.innerWidth < 768);
    };

    window.addEventListener('resize', handleResize);
    return () => window.removeEventListener('resize', handleResize);
  }, []);

  // États pour les informations personnelles et professionnelles
  const [address, setAddress] = useState({
    street: '',
    city: '',
    postalCode: '',
    country: 'France',
    birthDate: ''  // Ajout de la date de naissance
  });
  const [socialSecurity, setSocialSecurity] = useState('');
  const [professionalInfo, setProfessionalInfo] = useState({
    contractType: '',
    startDate: '',
    bankInfo: {
      iban: '',
      bic: ''
    }
  });

  useEffect(() => {
    const fetchUserData = async () => {
      setIsLoading(true);
      const auth = getAuth();
      const user = auth.currentUser;
      
      if (user) {
        try {
          const userDoc = await getDoc(doc(db, 'employees', user.uid));
          if (userDoc.exists()) {
            const data = userDoc.data();
            setUserData({ id: userDoc.id, ...data });
            setNewEmail(user.email);
            setAddress(data.address || {
              street: '',
              city: '',
              postalCode: '',
              country: 'France'
            });
            setSocialSecurity(data.socialSecurity || '');
            setProfessionalInfo(data.professionalInfo || {
              department: '',
              position: '',
              startDate: '',
              employeeId: ''
            });
          } else {
            setError("Données utilisateur non trouvées");
          }
        } catch (err) {
          setError("Erreur lors du chargement des données utilisateur");
          console.error(err);
        }
      } else {
        navigate('/login');
      }
      setIsLoading(false);
    };

    fetchUserData();
  }, [navigate]);

  const handleInputChange = (e, section = 'main') => {
    const { name, value } = e.target;
    switch (section) {
      case 'address':
        setAddress(prev => ({ ...prev, [name]: value }));
        break;
      case 'professional':
        if (name.includes('iban') || name.includes('bic')) {
          setProfessionalInfo(prev => ({
            ...prev,
            bankInfo: {
              ...prev.bankInfo,
              [name]: value
            }
          }));
        } else {
          setProfessionalInfo(prev => ({ ...prev, [name]: value }));
        }
        break;
      default:
        setUserData(prev => ({ ...prev, [name]: value }));
    }
  };

  const validateSocialSecurity = (number) => {
    const cleanNumber = number.replace(/\s/g, '');
    const regex = /^[12][0-9]{2}(0[1-9]|1[0-2])(2[AB]|[0-9]{2})[0-9]{8}$/;
    return regex.test(cleanNumber);
  };

  const formatSocialSecurity = (number) => {
    if (!number) return '';
    const cleanNumber = number.replace(/\s/g, '');
    return cleanNumber.replace(/(\d{1})(\d{2})(\d{2})(\d{2})(\d{3})(\d{3})(\d{2})/, '$1 $2 $3 $4 $5 $6 $7');
  };

  const handleSave = async (section) => {
    setIsLoading(true);
    setError(null);
    setSuccess(null);

    if (section === 'professional' && socialSecurity && !validateSocialSecurity(socialSecurity)) {
      setError("Le numéro de sécurité sociale n'est pas valide");
      setIsLoading(false);
      return;
    }

    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const updateData = {};

      switch (section) {
        case 'personal':
          updateData.firstName = userData.firstName;
          updateData.lastName = userData.lastName;
          updateData.phoneNumber = userData.phoneNumber;
          updateData.birthDate = userData.birthDate; // Ajout de la date de naissance
          updateData.address = address;
          break;
        case 'professional':
          updateData.socialSecurity = socialSecurity;
          updateData.professionalInfo = professionalInfo;
          break;
      }

      await updateDoc(doc(db, 'employees', user.uid), updateData);
      setSuccess("Profil mis à jour avec succès");
      setIsEditing(false);
    } catch (err) {
      setError("Erreur lors de la mise à jour du profil");
      console.error(err);
    }
    setIsLoading(false);
  };

  const handlePhotoUpload = async (e) => {
    const file = e.target.files[0];
    if (file) {
      setIsLoading(true);
      setError(null);
      try {
        const auth = getAuth();
        const user = auth.currentUser;
        const storageRef = ref(storage, `profilePhotos/${user.uid}`);
        await uploadBytes(storageRef, file);
        const photoURL = await getDownloadURL(storageRef);
        await updateDoc(doc(db, 'employees', user.uid), { photoURL });
        setUserData(prev => ({ ...prev, photoURL }));
        setSuccess("Photo de profil mise à jour avec succès");
      } catch (err) {
        setError("Erreur lors de la mise à jour de la photo");
        console.error(err);
      }
      setIsLoading(false);
    }
  };

  const handlePasswordChange = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updatePassword(user, newPassword);
      setSuccess("Mot de passe mis à jour avec succès");
      setNewPassword('');
      setCurrentPassword('');
    } catch (err) {
      setError("Erreur lors de la mise à jour du mot de passe");
      console.error(err);
    }
    setIsLoading(false);
  };

  const handleEmailChange = async () => {
    setIsLoading(true);
    setError(null);
    try {
      const auth = getAuth();
      const user = auth.currentUser;
      const credential = EmailAuthProvider.credential(user.email, currentPassword);
      await reauthenticateWithCredential(user, credential);
      await updateEmail(user, newEmail);
      await updateDoc(doc(db, 'employees', user.uid), { email: newEmail });
      setSuccess("Email mis à jour avec succès");
      setCurrentPassword('');
    } catch (err) {
      setError("Erreur lors de la mise à jour de l'email");
      console.error(err);
    }
    setIsLoading(false);
  };

  const renderPersonalInfo = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div>
          <label className="block text-sm font-medium text-gray-700">Prénom</label>
          <input
            type="text"
            name="firstName"
            value={userData?.firstName || ''}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Nom</label>
          <input
            type="text"
            name="lastName"
            value={userData?.lastName || ''}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Date de naissance</label>
          <input
            type="date"
            name="birthDate"
            value={userData?.birthDate || ''}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Téléphone</label>
          <input
            type="tel"
            name="phoneNumber"
            value={userData?.phoneNumber || ''}
            onChange={handleInputChange}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Rue</label>
          <input
            type="text"
            name="street"
            value={address.street}
            onChange={(e) => handleInputChange(e, 'address')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Ville</label>
          <input
            type="text"
            name="city"
            value={address.city}
            onChange={(e) => handleInputChange(e, 'address')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          />
        </div>
        <div>
          <label className="block text-sm font-medium text-gray-700">Code Postal</label>
          <input
            type="text"
            name="postalCode"
            value={address.postalCode}
            onChange={(e) => handleInputChange(e, 'address')}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
          />
        </div>
      </div>
      <div className="flex justify-end">
        <button
          onClick={() => handleSave('personal')}
          disabled={isLoading}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {isLoading ? 'Enregistrement...' : 'Enregistrer'}
        </button>
      </div>
    </div>
  );

  const renderSecurity = () => (
    <div className="space-y-6">
      <div className="bg-white shadow-sm rounded-lg p-6">
        <h3 className="text-lg font-medium mb-4">Changer l'email</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Nouvel email</label>
            <input
              type="email"
              value={newEmail}
              onChange={(e) => setNewEmail(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Mot de passe actuel</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
            />
          </div>
          <button
            onClick={handleEmailChange}
            disabled={isLoading}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Mettre à jour l'email
          </button>
        </div>
      </div>

      <div className="bg-white shadow-sm rounded-lg p-6">
        <h3 className="text-lg font-medium mb-4">Changer le mot de passe</h3>
        <div className="space-y-4">
          <div>
            <label className="block text-sm font-medium text-gray-700">Mot de passe actuel</label>
            <input
              type="password"
              value={currentPassword}
              onChange={(e) => setCurrentPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
            />
          </div>
          <div>
            <label className="block text-sm font-medium text-gray-700">Nouveau mot de passe</label>
            <input
              type="password"
              value={newPassword}
              onChange={(e) => setNewPassword(e.target.value)}
              className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
            />
          </div>
          <button
            onClick={handlePasswordChange}
            disabled={isLoading}
            className="w-full bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
          >
            Changer le mot de passe
          </button>
        </div>
      </div>
    </div>
  );

  const renderProfessionalInfo = () => (
    <div className="space-y-6">
      <div className="grid grid-cols-1 gap-6 md:grid-cols-2">
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700">Numéro de sécurité sociale</label>
          <input
            type="text"
            value={socialSecurity}
            onChange={(e) => setSocialSecurity(e.target.value)}
            className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
            placeholder="X XX XX XX XXX XXX XX"
            maxLength="15"
          />
        </div>
  
        <div className="col-span-2 md:col-span-1">
          <label className="block text-sm font-medium text-gray-700">Type de contrat</label>
          <div className="mt-1 p-2 w-full bg-gray-50 border border-gray-200 rounded-md text-gray-700">
            {userData?.professionalInfo?.contractType || 'Non spécifié'}
          </div>
        </div>
  
        <div className="col-span-2 md:col-span-1">
          <label className="block text-sm font-medium text-gray-700">Date de début</label>
          <div className="mt-1 p-2 w-full bg-gray-50 border border-gray-200 rounded-md text-gray-700">
            {userData?.professionalInfo?.startDate ? 
              new Date(userData.professionalInfo.startDate).toLocaleDateString('fr-FR') : 
              'Non spécifiée'
            }
          </div>
        </div>
  
        <div className="col-span-2">
          <label className="block text-sm font-medium text-gray-700 mb-2">Informations bancaires</label>
          <div className="grid grid-cols-1 md:grid-cols-2 gap-4">
            <div>
              <label className="block text-sm text-gray-600">IBAN</label>
              <input
                type="text"
                name="iban"
                value={professionalInfo.bankInfo?.iban || ''}
                onChange={(e) => handleInputChange(e, 'professional')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
                placeholder="FR76 XXXX XXXX XXXX XXXX XXXX XXX"
              />
            </div>
            <div>
              <label className="block text-sm text-gray-600">BIC</label>
              <input
                type="text"
                name="bic"
                value={professionalInfo.bankInfo?.bic || ''}
                onChange={(e) => handleInputChange(e, 'professional')}
                className="mt-1 block w-full rounded-md border-gray-300 shadow-sm focus:border-indigo-300 focus:ring focus:ring-indigo-200"
                placeholder="XXXXXXPPXXX"
              />
            </div>
          </div>
        </div>
      </div>
  
      <div className="flex justify-end">
        <button
          onClick={() => handleSave('professional')}
          disabled={isLoading}
          className="bg-blue-500 text-white px-4 py-2 rounded hover:bg-blue-600"
        >
          {isLoading ? 'Enregistrement...' : 'Enregistrer'}
        </button>
      </div>
    </div>
  );

  if (isLoading && !userData) {
    return (
      <div className="flex justify-center items-center h-screen">
        <div className="animate-spin rounded-full h-12 w-12 border-b-2 border-blue-500"></div>
      </div>
    );
  }

  return (
    <div className="max-w-4xl mx-auto p-4">
      <div className="flex items-center justify-between mb-6">
        <button 
          onClick={() => navigate(-1)} 
          className="p-2 rounded-full hover:bg-gray-100">
          <ArrowLeft size={24} />
        </button>
        <h1 className="text-xl md:text-2xl font-bold">Mon Profil</h1>
        <div className="w-10"></div>
      </div>

      {userData && (
        <>
          <div className="bg-white shadow rounded-lg p-4 md:p-6 mb-4 md:mb-6">
            <div className="flex flex-col md:flex-row items-center">
              <div className="relative mb-4 md:mb-0">
                {userData.photoURL ? (
                  <img 
                    src={userData.photoURL} 
                    alt="Profile" 
                    className="w-20 h-20 md:w-24 md:h-24 rounded-full object-cover"
                  />
                ) : (
                  <div className="w-20 h-20 md:w-24 md:h-24 rounded-full bg-gray-100 flex items-center justify-center">
                    <User size={40} className="text-gray-400" />
                  </div>
                )}
                <label className="absolute bottom-0 right-0 bg-blue-500 rounded-full p-2 cursor-pointer hover:bg-blue-600 transition-colors">
                  <Camera className="w-4 h-4 text-white" />
                  <input 
                    type="file" 
                    className="hidden" 
                    accept="image/*" 
                    onChange={handlePhotoUpload} 
                  />
                </label>
              </div>
              <div className="text-center md:text-left md:ml-6">
                <h2 className="text-lg md:text-xl font-semibold">
                  {userData.firstName} {userData.lastName}
                </h2>
                <p className="text-gray-600 text-sm md:text-base">{userData.email}</p>
              </div>
            </div>
          </div>

          <div className="flex overflow-x-auto space-x-1 mb-4 md:mb-6 bg-gray-100 p-1 rounded-lg">
            {tabs.map(tab => (
              <button
                key={tab.id}
                onClick={() => setActiveTab(tab.id)}
                className={`flex items-center space-x-2 px-3 md:px-4 py-2 rounded-md whitespace-nowrap flex-1
                         ${activeTab === tab.id ? 'bg-white shadow' : 'hover:bg-gray-50'}`}
              >
                <tab.icon size={18} className={activeTab === tab.id ? 'text-blue-500' : 'text-gray-500'} />
                <span className={`${activeTab === tab.id ? 'text-blue-500' : 'text-gray-700'} text-sm md:text-base`}>
                  {isMobile ? tab.label : tab.longLabel}
                </span>
              </button>
            ))}
          </div>

          <div className="bg-white shadow rounded-lg p-4 md:p-6">
            {activeTab === 'info' && renderPersonalInfo()}
            {activeTab === 'security' && renderSecurity()}
            {activeTab === 'professional' && renderProfessionalInfo()}
          </div>
        </>
      )}

      {error && (
        <div className="fixed bottom-4 right-4 bg-red-100 border-l-4 border-red-500 text-red-700 p-4 rounded shadow-lg">
          {error}
        </div>
      )}
      
      {success && (
        <div className="fixed bottom-4 right-4 bg-green-100 border-l-4 border-green-500 text-green-700 p-4 rounded shadow-lg">
          {success}
        </div>
      )}
    </div>
  );
};

export default ProfilePage;