import React, { useState, useEffect, useCallback } from 'react';
import { MapPin, X, Clock, Calendar, Bell, AlertTriangle, CreditCard, Users, Cake, FileText, RefreshCw, CheckSquare, User, Home, AlertCircle, Newspaper} from 'lucide-react';
import { collection, getDocs, doc, getDoc,  query, where, getFirestore, updateDoc, writeBatch, orderBy, limit, onSnapshot, Timestamp } from 'firebase/firestore';
import { runNormalization } from './normalizeAppointmentsDates'; // Assurez-vous que le chemin est correct
import FinancialSummaryWidget from './FinancialSummaryWidget';
import { useNavigate } from 'react-router-dom';
import { format } from 'date-fns';
import { fr } from 'date-fns/locale';

const Widget = ({ title, children, icon: Icon }) => (
  <div className="bg-white p-5 rounded-lg shadow-sm border border-gray-100">
    <div className="flex justify-between items-center mb-3">
      <h3 className="text-lg font-semibold text-gray-700 flex items-center">
        {Icon && <Icon size={18} className="mr-2 text-gray-400" />}
        {title}
      </h3>
    </div>
    {children}
  </div>
);

const TileWrapper = ({ children, className = "" }) => (
  <div className={`col-span-1 ${className}`}>
    {children}
  </div>
);


const PopupNavigation = ({ isOpen, onClose, address }) => {
  if (!isOpen) return null;

  const handleGoogleMapsNavigate = () => {
    const encodedAddress = encodeURIComponent(address);
    window.open(`https://www.google.com/maps/search/?api=1&query=${encodedAddress}`, '_blank');
  };

  const handleWazeNavigate = () => {
    const encodedAddress = encodeURIComponent(address);
    window.open(`https://www.waze.com/ul?q=${encodedAddress}&navigate=yes`, '_blank');
  };

  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl max-w-sm w-full">
        <div className="flex justify-between items-center mb-4">
          <h3 className="text-lg font-semibold">Navigation</h3>
          <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
            <X size={20} />
          </button>
        </div>
        <p className="mb-4">Adresse du client : {address}</p>
        <div className="space-y-2">
          <button
            onClick={handleGoogleMapsNavigate}
            className="w-full bg-blue-500 text-white py-2 px-4 rounded hover:bg-blue-600 transition-colors"
          >
            Ouvrir dans Google Maps
          </button>
          <button
            onClick={handleWazeNavigate}
            className="w-full bg-green-500 text-white py-2 px-4 rounded hover:bg-green-600 transition-colors"
          >
            Ouvrir dans Waze
          </button>
        </div>
      </div>
    </div>
  );
};

const Tile = ({ title, children, icon: Icon, className = "" }) => (
  <div className={`bg-white p-4 rounded-lg shadow-sm ${className}`}>
    {title && (
      <h2 className="text-lg font-semibold text-gray-700 mb-3 flex items-center">
        {Icon && <Icon size={18} className="mr-2 text-gray-400" />}
        {title}
      </h2>
    )}
    <div>{children}</div>
  </div>
);



const Dashboard = ({ db, currentUser }) => {
  const [currentTime, setCurrentTime] = useState(new Date());
  const [activeEmployees, setActiveEmployees] = useState(0);
  const [normalizationStatus, setNormalizationStatus] = useState('');
  const [updateStatus, setUpdateStatus] = useState('');
  const [appointments, setAppointments] = useState([]);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [todayAppointmentsCount, setTodayAppointmentsCount] = useState(0);
  const [tomorrowAppointmentsCount, setTomorrowAppointmentsCount] = useState(0);
  const navigate = useNavigate();
  const [occupationRate, setOccupationRate] = useState(0);
  const [nextAppointmentTime, setNextAppointmentTime] = useState('');
  const [tilePreferences, setTilePreferences] = useState({});
const [showCustomizeModal, setShowCustomizeModal] = useState(false);

  const formatDate = (date) => {
    const options = { weekday: 'short', year: 'numeric', month: 'long', day: 'numeric' };
    return date.toLocaleDateString('fr-FR', options);
  };

  const formatTime = (date) => {
    return date.toLocaleTimeString('fr-FR', { hour: '2-digit', minute: '2-digit' });
  };

  useEffect(() => {
    const timer = setInterval(() => {
      setCurrentTime(new Date());
    }, 60000);
    return () => clearInterval(timer);
  }, []);

  useEffect(() => {
    const fetchActiveEmployees = async () => {
      const employeesRef = collection(db, "employees");
      const activeEmployeesQuery = query(employeesRef, where("isArchived", "==", false));
      const querySnapshot = await getDocs(activeEmployeesQuery);
      setActiveEmployees(querySnapshot.size);
    };

    fetchActiveEmployees();
  }, [db]);

  const handleNormalization = async () => {
    setNormalizationStatus('En cours...');
    try {
      await runNormalization(db);
      setNormalizationStatus('Normalisation terminée avec succès.');
    } catch (error) {
      console.error("Erreur lors de la normalisation:", error);
      setNormalizationStatus('Erreur lors de la normalisation. Veuillez réessayer.');
    }
  };

  
  // Dans le useEffect de récupération des rendez-vous
  useEffect(() => {
    if (!db || !currentUser?.userId) return;
  
    try {
      const appointmentsRef = collection(db, 'appointments');
      const now = new Date();
      const startOfToday = new Date(now.getFullYear(), now.getMonth(), now.getDate());
      const startOfTomorrow = new Date(startOfToday);
      startOfTomorrow.setDate(startOfTomorrow.getDate() + 1);
      const endOfTomorrow = new Date(startOfTomorrow);
      endOfTomorrow.setHours(23, 59, 59, 999);
  
      const q = query(
        appointmentsRef,
        where('employeeId', '==', currentUser.userId),
        where('date', '>=', Timestamp.fromDate(startOfToday)),
        where('date', '<=', Timestamp.fromDate(endOfTomorrow)),
        orderBy('date', 'asc'),
        orderBy('startTime', 'asc')
      );
  
      const unsubscribe = onSnapshot(q, async (querySnapshot) => {
        const now = new Date();
        const appointmentsWithClientInfo = await Promise.all(
          querySnapshot.docs.map(async (appointmentDoc) => {
            const appointmentData = appointmentDoc.data();
            let clientData = {};
            
            if (appointmentData.clientId) {
              const clientDocRef = doc(db, 'clients', appointmentData.clientId);
              const clientSnap = await getDoc(clientDocRef);
              if (clientSnap.exists()) {
                clientData = clientSnap.data();
              }
            }
      
            // S'assurer que les données de date et d'heure existent
            if (!appointmentData.date || !appointmentData.startTime || !appointmentData.endTime) {
              return null;
            }
      
            const appointmentDate = appointmentData.date?.toDate();
            const [startHours, startMinutes] = appointmentData.startTime.split(':');
            const [endHours, endMinutes] = appointmentData.endTime.split(':');
            
            const startDateTime = new Date(appointmentDate);
            startDateTime.setHours(parseInt(startHours), parseInt(startMinutes), 0);
            
            const endDateTime = new Date(appointmentDate);
            endDateTime.setHours(parseInt(endHours), parseInt(endMinutes), 0);
      
            return {
              id: appointmentDoc.id,
              ...appointmentData,
              date: appointmentDate,
              startDateTime,
              endDateTime,
              clientName: `${clientData.lastName || ''} ${clientData.firstName || ''}`.trim(),
              clientAddress: clientData.address || '',
              addressNumber: clientData.addressNumber || ''
            };
          })
        );
      
        // Ne garder que les rendez-vous en cours ou à venir
        const filteredAppointments = appointmentsWithClientInfo.filter(appointment => {
          if (!appointment) return false;
          const now = new Date();
          // Garder si le rendez-vous est en cours ou n'a pas encore commencé
          return appointment.startDateTime >= now || 
                (now >= appointment.startDateTime && now <= appointment.endDateTime);
        });
      
        // Trier par date et heure
        const sortedAppointments = filteredAppointments.sort((a, b) => {
          return a.startDateTime - b.startDateTime;
        });
      
        // Mise à jour des compteurs
        const todayCount = sortedAppointments.filter(app => {
          const appDate = app.date;
          return (
            appDate.getFullYear() === now.getFullYear() &&
            appDate.getMonth() === now.getMonth() &&
            appDate.getDate() === now.getDate()
          );
        }).length;
      
        const tomorrow = new Date(now);
        tomorrow.setDate(tomorrow.getDate() + 1);
        const tomorrowCount = sortedAppointments.filter(app => {
          const appDate = app.date;
          return (
            appDate.getFullYear() === tomorrow.getFullYear() &&
            appDate.getMonth() === tomorrow.getMonth() &&
            appDate.getDate() === tomorrow.getDate()
          );
        }).length;
      
        setTodayAppointmentsCount(todayCount);
        setTomorrowAppointmentsCount(tomorrowCount);
        setAppointments(sortedAppointments);
      });
      
  
      return () => unsubscribe();
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  }, [db, currentUser]);
  

  // Fonction d'aide pour formater la date
const formatAppointmentDate = (date, startTime) => {
  const today = new Date();
  const tomorrow = new Date(today);
  tomorrow.setDate(tomorrow.getDate() + 1);

  // Comparaison des dates sans tenir compte de l'heure
  const isToday = date.toDateString() === today.toDateString();
  const isTomorrow = date.toDateString() === tomorrow.toDateString();

  if (isToday) {
    return `Aujourd'hui ${startTime}`;
  } else if (isTomorrow) {
    return `Demain ${startTime}`;
  } else {
    // Pour les autres dates, format : "Lun. 6 Nov."
    return format(date, 'EEE d MMM', { locale: fr });
  }
};


  const calculateOccupationRate = useCallback(() => {
    if (appointments.length === 0) return 0;

    const now = new Date();
    const todayStart = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 0, 0, 0);
    const todayEnd = new Date(now.getFullYear(), now.getMonth(), now.getDate(), 23, 59, 59);

    const todayAppointments = appointments.filter(app => 
      app.date >= todayStart && app.date <= todayEnd
    );

    const totalMinutes = (todayEnd - todayStart) / (1000 * 60);
    let occupiedMinutes = 0;

    todayAppointments.forEach(app => {
      const start = new Date(app.date);
      start.setHours(parseInt(app.startTime.split(':')[0]), parseInt(app.startTime.split(':')[1]));
      const end = new Date(app.date);
      end.setHours(parseInt(app.endTime.split(':')[0]), parseInt(app.endTime.split(':')[1]));
      occupiedMinutes += (end - start) / (1000 * 60);
    });

    return Math.round((occupiedMinutes / totalMinutes) * 100);
  }, [appointments]);

  const getNextAppointmentTime = useCallback(() => {
    const now = new Date();
    const nextAppointment = appointments.find(app => {
      const appStart = new Date(app.date);
      appStart.setHours(parseInt(app.startTime.split(':')[0]), parseInt(app.startTime.split(':')[1]));
      return appStart > now;
    });

    if (nextAppointment) {
      return nextAppointment.startTime;
    } else {
      return 'Aucun';
    }
  }, [appointments]);

  useEffect(() => {
    setOccupationRate(calculateOccupationRate());
    setNextAppointmentTime(getNextAppointmentTime());
  }, [appointments, calculateOccupationRate, getNextAppointmentTime]);

  const loadTilePreferences = useCallback(async () => {
    // Vérification plus stricte des paramètres
    if (!db || !currentUser?.id) {
      console.log("Données manquantes pour les préférences:", { db: !!db, id: currentUser?.id });
      setTilePreferences({
        dateTime: true,
        monEspace: true,
        prochainRendezVous: true,
        gestionCollaborateurs: true,
        suiviPresences: true,
        sommairePaie: true,
        anniversaires: true,
        suiviDocuments: true,
        suiviTaches: true,
        suiviFactures: true,
        suiviDevis: true,
        suiviContacts: true
      });
      return;
    }
  
    try {
      const userDoc = await getDoc(doc(db, 'employees', currentUser.id));
      const defaultPreferences = {
        dateTime: true,
        monEspace: true,
        prochainRendezVous: true,
        gestionCollaborateurs: true,
        suiviPresences: true,
        sommairePaie: true,
        anniversaires: true,
        suiviDocuments: true,
        suiviTaches: true,
        suiviFactures: true,
        suiviDevis: true,
        suiviContacts: true
      };
  
      if (userDoc.exists()) {
        const userData = userDoc.data();
        if (userData.tilePreferences) {
          const updatedPreferences = { ...defaultPreferences, ...userData.tilePreferences };
          setTilePreferences(updatedPreferences);
  
          // Mise à jour si nécessaire
          if (Object.keys(updatedPreferences).length !== Object.keys(userData.tilePreferences).length) {
            try {
              await updateDoc(doc(db, 'employees', currentUser.id), { 
                tilePreferences: updatedPreferences 
              });
            } catch (error) {
              console.error("Erreur lors de la mise à jour des préférences:", error);
            }
          }
        } else {
          setTilePreferences(defaultPreferences);
          try {
            await updateDoc(doc(db, 'employees', currentUser.id), { 
              tilePreferences: defaultPreferences 
            });
          } catch (error) {
            console.error("Erreur lors de l'initialisation des préférences:", error);
          }
        }
      } else {
        setTilePreferences(defaultPreferences);
      }
    } catch (error) {
      console.error("Erreur lors du chargement des préférences:", error);
      // Définir les préférences par défaut en cas d'erreur
      setTilePreferences({
        dateTime: true,
        monEspace: true,
        prochainRendezVous: true,
        gestionCollaborateurs: true,
        suiviPresences: true,
        sommairePaie: true,
        anniversaires: true,
        suiviDocuments: true,
        suiviTaches: true,
        suiviFactures: true,
        suiviDevis: true,
        suiviContacts: true
      });
    }
  }, [db, currentUser]);

  useEffect(() => {
    loadTilePreferences();
  }, [loadTilePreferences]);

  const CustomizeModal = ({ isOpen, onClose, preferences, onSave }) => {
    const [localPreferences, setLocalPreferences] = useState(preferences);
  
    const handleToggle = (key) => {
      setLocalPreferences(prev => ({ ...prev, [key]: !prev[key] }));
    };
  
    const handleSave = () => {
      onSave(localPreferences);
      onClose();
    };
  
    if (!isOpen) return null;
  
    return (
      <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
        <div className="bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
          <h2 className="text-xl font-bold mb-4">Personnaliser le tableau de bord</h2>
          {Object.entries(localPreferences).map(([key, value]) => (
            <div key={key} className="flex items-center justify-between py-2">
              <span>{key}</span>
              <input
                type="checkbox"
                checked={value}
                onChange={() => handleToggle(key)}
                className="form-checkbox h-5 w-5 text-blue-600"
              />
            </div>
          ))}
          <div className="flex justify-end mt-4">
            <button onClick={onClose} className="mr-2 px-4 py-2 bg-gray-200 rounded">Annuler</button>
            <button onClick={handleSave} className="px-4 py-2 bg-blue-500 text-white rounded">Enregistrer</button>
          </div>
        </div>
      </div>
    );
  };


  // Fonction d'aide pour formater la date


// Modification de la fonction renderAppointment
const renderAppointment = (appointment) => {
  const now = new Date();
  const isOngoing = now >= appointment.startDateTime && now < appointment.endDateTime;

  const fullAddress = appointment.addressNumber
    ? `${appointment.clientAddress}, ${appointment.addressNumber}`
    : appointment.clientAddress;

  return (
    <div key={appointment.id} className={`flex items-center py-3 border-b border-gray-100 last:border-b-0 ${
      isOngoing ? 'bg-green-50' : ''
    }`}>
      <div className="flex-1">
        <div className="flex items-center">
          <User size={16} className="text-gray-400 mr-2" />
          <div>
            <div className="flex items-center gap-2">
              <span className="font-semibold text-gray-800">{appointment.clientName}</span>
              {isOngoing && (
                <span className="px-2 py-1 text-xs bg-green-100 text-green-800 rounded-full">
                  En cours
                </span>
              )}
            </div>
            <div className="text-sm text-gray-500">{fullAddress}</div>
          </div>
        </div>
      </div>
      
      <div className="flex items-center gap-2">
        <div className="text-right">
          <div className="text-sm text-gray-500">
            {formatAppointmentDate(appointment.date, appointment.startTime)}
          </div>
        </div>
        <button 
          onClick={() => setSelectedAppointment(appointment)}
          className="p-1 hover:bg-gray-100 rounded-full"
        >
          <MapPin size={16} className="text-blue-500" />
        </button>
      </div>
    </div>
  );
};

const calculateRemainingDaysInMonth = () => {
  const today = new Date();
  const lastDayOfMonth = new Date(today.getFullYear(), today.getMonth() + 1, 0).getDate();
  const currentDay = today.getDate();
  return lastDayOfMonth - currentDay;
};

  // Modifier également la fonction saveTilePreferences
const saveTilePreferences = async (newPreferences) => {
  if (!db || !currentUser?.id) return;
  
  try {
    setTilePreferences(newPreferences);
    await updateDoc(doc(db, 'employees', currentUser.id), { 
      tilePreferences: newPreferences 
    });
  } catch (error) {
    console.error("Erreur lors de la sauvegarde des préférences:", error);
  }
};

// Fonction utilitaire pour calculer les jours jusqu'à l'anniversaire
const getDaysUntilBirthday = (birthDate) => {
  if (!birthDate) return null;
  
  const today = new Date();
  const birth = new Date(birthDate);
  const nextBirthday = new Date(today.getFullYear(), birth.getMonth(), birth.getDate());
  
  // Si l'anniversaire est déjà passé cette année, on passe à l'année suivante
  if (nextBirthday < today) {
    nextBirthday.setFullYear(today.getFullYear() + 1);
  }
  
  const diffTime = Math.abs(nextBirthday - today);
  return Math.ceil(diffTime / (1000 * 60 * 60 * 24));
};

// Fonction pour calculer l'âge
const calculateAge = (birthDate) => {
  const today = new Date();
  const birth = new Date(birthDate);
  let age = today.getFullYear() - birth.getFullYear();
  const monthDiff = today.getMonth() - birth.getMonth();
  if (monthDiff < 0 || (monthDiff === 0 && today.getDate() < birth.getDate())) {
    age--;
  }
  return age + 1; // +1 car on veut l'âge qu'ils vont avoir
};

// Dans le composant Dashboard, ajoutons l'état et le useEffect
const [upcomingBirthdays, setUpcomingBirthdays] = useState([]);

useEffect(() => {
  const fetchBirthdays = async () => {
    const employeesSnapshot = await getDocs(collection(db, 'employees'));
    const clientsSnapshot = await getDocs(collection(db, 'clients'));
    
    const birthdays = [];
    
    // Récupérer les anniversaires des employés
    employeesSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.birthDate) {
        const daysUntil = getDaysUntilBirthday(data.birthDate);
        if (daysUntil !== null && daysUntil <= 7) {
          birthdays.push({
            id: doc.id,
            name: `${data.firstName} ${data.lastName}`,
            birthDate: data.birthDate,
            daysUntil,
            type: 'employee',
            age: calculateAge(data.birthDate)
          });
        }
      }
    });
    
    // Récupérer les anniversaires des clients
    clientsSnapshot.forEach((doc) => {
      const data = doc.data();
      if (data.birthDate) {
        const daysUntil = getDaysUntilBirthday(data.birthDate);
        if (daysUntil !== null && daysUntil <= 7) {
          birthdays.push({
            id: doc.id,
            name: `${data.firstName} ${data.lastName}`,
            birthDate: data.birthDate,
            daysUntil,
            type: 'client',
            age: calculateAge(data.birthDate)
          });
        }
      }
    });
    
    // Trier par nombre de jours jusqu'à l'anniversaire
    birthdays.sort((a, b) => a.daysUntil - b.daysUntil);
    setUpcomingBirthdays(birthdays);
  };

  fetchBirthdays();
}, [db]);

const [plannedHours, setPlannedHours] = useState(0);

useEffect(() => {
  const calculatePlannedHours = async () => {
    if (!db || !currentUser) {
      setPlannedHours(0);
      return;
    }

    try {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);

      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('employeeId', '==', currentUser.userId),
        where('date', '>=', Timestamp.fromDate(startOfMonth)),
        where('date', '<=', Timestamp.fromDate(endOfMonth))
      );

      const querySnapshot = await getDocs(q);
      let totalMinutes = 0;

      querySnapshot.docs.forEach(doc => {
        const appointment = doc.data();
        const [startHours, startMinutes] = appointment.startTime.split(':');
        const [endHours, endMinutes] = appointment.endTime.split(':');
        
        const start = parseInt(startHours) * 60 + parseInt(startMinutes);
        const end = parseInt(endHours) * 60 + parseInt(endMinutes);
        
        totalMinutes += end - start;
      });

      setPlannedHours(totalMinutes / 60);

    } catch (error) {
      console.error("Erreur lors du calcul des heures planifiées:", error);
      setPlannedHours(0);
    }
  };

  calculatePlannedHours();
}, [db, currentUser]);


  

const [workedHours, setWorkedHours] = useState(0);

useEffect(() => {
  const calculateWorkedHours = async () => {
    if (!db || !currentUser) {
      setWorkedHours(0);
      return;
    }

    try {
      const now = new Date();
      const startOfMonth = new Date(now.getFullYear(), now.getMonth(), 1, 0, 0, 0);
      const endOfMonth = new Date(now.getFullYear(), now.getMonth() + 1, 0, 23, 59, 59);

      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef,
        where('employeeId', '==', currentUser.userId),
        where('date', '>=', Timestamp.fromDate(startOfMonth)),
        where('date', '<=', Timestamp.fromDate(endOfMonth)),
        where('status', '==', 'completed')
      );

      const querySnapshot = await getDocs(q);
      let totalMinutes = 0;

      querySnapshot.docs.forEach(doc => {
        const appointment = doc.data();
        const [startHours, startMinutes] = appointment.startTime.split(':');
        const [endHours, endMinutes] = appointment.endTime.split(':');
        
        const start = parseInt(startHours) * 60 + parseInt(startMinutes);
        const end = parseInt(endHours) * 60 + parseInt(endMinutes);
        
        totalMinutes += end - start;
      });

      setWorkedHours(totalMinutes / 60);

    } catch (error) {
      console.error("Erreur lors du calcul des heures travaillées:", error);
      setWorkedHours(0);
    }
  };

  calculateWorkedHours();
}, [db, currentUser]);

// Fonction de formatage des heures
const formatHours = (hours) => {
  if (isNaN(hours)) return "0h00";
  const wholeHours = Math.floor(hours);
  const minutes = Math.round((hours - wholeHours) * 60);
  return `${wholeHours}h${minutes.toString().padStart(2, '0')}`;
};


  return (
    <div className="dashboard-container p-4 bg-gray-100">
      <div className="flex justify-between items-center mb-4">
        <h1 className="text-2xl font-bold text-gray-800">Tableau de bord</h1>
        <button 
  onClick={() => setShowCustomizeModal(true)} 
  className="bg-blue-500 text-white px-4 py-2 rounded"
>
  Personnaliser
</button>
  </div>
      
      <div className="grid grid-cols-4 gap-4">
      {tilePreferences.dateTime && (
        <Tile className="col-span-4 sm:col-span-1 lg:col-span-1">
          <div className="text-sm text-gray-500">{formatDate(currentTime)}</div>
          <div className="text-4xl font-bold text-gray-800 mt-2">{formatTime(currentTime)}</div>
        </Tile>
         )}
        

        {tilePreferences.monEspace && (
        <Tile title="Mon espace" icon={Home} className="col-span-4 sm:col-span-3 lg:col-span3 ">
          <div className="grid grid-cols-2 gap-4">
            <div className="flex justify-between">
              <span className="text-gray-600">Rendez-vous restants aujourd'hui</span>
              <span className="font-semibold text-gray-800">{todayAppointmentsCount}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Rendez-vous prévus demain</span>
              <span className="font-semibold text-gray-800">{tomorrowAppointmentsCount}</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Taux d'occupation aujourd'hui</span>
              <span className="font-semibold text-gray-800">{occupationRate}%</span>
            </div>
            <div className="flex justify-between">
              <span className="text-gray-600">Prochain rendez-vous</span>
              <span className="font-semibold text-gray-800">{nextAppointmentTime}</span>
            </div>
          </div>
          <button 
            onClick={() => navigate('/overview')} 
            className="mt-3 text-blue-600 text-sm hover:underline"
          >
            Voir ma vue d'ensemble
          </button>
        </Tile>
        )}
        {tilePreferences.prochainRendezVous && (
        <Tile 
        title="Prochains rendez-vous" 
        icon={Calendar} 
        className="col-span-4 sm:col-span-2 row-span-3 lg:col-span-2 row-span-3"
      >
        <div className="max-h-[500px] overflow-y-auto">
          {appointments.length > 0 ? (
            <div className="space-y-1">
              {appointments.map(renderAppointment)}
            </div>
          ) : (
            <p className="text-center text-gray-500">Aucun rendez-vous à venir</p>
          )}
        </div>
      </Tile>
        )}

        {tilePreferences.gestionCollaborateurs && (
        <Tile title="Gestion des collaborateurs" icon={Users} className="col-span-4 sm:col-span-2 lg:col-span-2">
          <p className="text-gray-700">{activeEmployees} collaborateurs actifs</p>
        </Tile>
        )}

        {tilePreferences.suiviPresences && (
        <Tile title="Suivi des présences" icon={User} className="col-span-4 sm:col-span-1 lg:col-span-1">
          <div className="text-center text-gray-600">
            <RefreshCw className="mx-auto mb-2" size={24} />
            Rien à signaler
          </div>
          <button className="mt-3 text-blue-600 text-sm hover:underline block mx-auto">
            Voir les présences
          </button>
        </Tile>
        )}



{tilePreferences.sommairePaie && (
  <Tile 
    title="Sommaire de la paie" 
    icon={CreditCard} 
    className="col-span-4 sm:col-span-1 lg:col-span-1"
  >
    <div className="text-center">
      <span className="text-4xl font-bold text-blue-600">
        {calculateRemainingDaysInMonth()}
      </span>
      <p className="text-sm text-gray-600 mt-2">
        {calculateRemainingDaysInMonth() > 1 ? 'Jours restants' : 'Jour restant'}
      </p>
      <div className="mt-4 p-2 bg-gray-50 rounded-lg">
        <p className="text-sm text-gray-700">Période de paie</p>
        <p className="font-medium text-gray-900">
          {new Date().toLocaleDateString('fr-FR', { month: 'long', year: 'numeric' })}
        </p>
      </div>
      <div className="mt-4 text-left">
      <div className="flex justify-between items-center text-sm mb-2">
        <span className="text-gray-600">Heures travaillées</span>
        <span className="font-medium">
        {formatHours(workedHours)}
        </span>
      </div>
      <div className="flex justify-between items-center text-sm">
        <span className="text-gray-600">Heures planifiées</span>
        <span className="font-medium">
        {formatHours(plannedHours)}
        </span>
      </div>

        
      <div className="mt-3">
  <div className="w-full bg-gray-200 rounded-full h-2.5">
    <div 
      className="bg-blue-600 h-2.5 rounded-full" 
      style={{ 
        width: `${plannedHours === 0 ? 0 : Math.min((workedHours / plannedHours) * 100, 100)}%` 
      }}
    ></div>
  </div>
  <p className="text-xs text-gray-500 text-center mt-1">
    {plannedHours === 0 ? 0 : Math.round((workedHours / plannedHours) * 100)}% de l'objectif
  </p>
</div>
      </div>
    </div>
  </Tile>
)}

          

        {tilePreferences.suiviTaches && (
        <Tile title="Suivi des tâches" icon={CheckSquare} className="col-span-4 sm:col-span-2 lg:col-span-2">
          <p className="text-gray-700">Aucune tâche à suivre</p>
        </Tile>
        )}

        {tilePreferences.suiviFactures && (
        <Tile title="Suivi des factures" icon={FileText} className="col-span-4 sm:col-span-2 lg:col-span-2">
          <p className="text-gray-700">Aucune facture à suivre</p>
        </Tile>
        )}

        {tilePreferences.suiviDevis && (
        <Tile title="Suivi des devis" icon={FileText} className="col-span-4 sm:col-span-2 lg:col-span-2">
          <p className="text-gray-700">Aucun devis à suivre</p>
        </Tile>
        )}

          {tilePreferences.suiviDocuments && (
        <Tile title="Suivi des documents" icon={FileText} className="col-span-4 sm:col-span-2 lg:col-span-2">
          <p className="text-gray-700">Aucun document à suivre</p>
        </Tile>
        )}

        {tilePreferences.suiviContacts && (
        <Tile title="Suivi des contacts" icon={Users} className="col-span-4 sm:col-span-2 lg:col-span-2">
          <p className="text-gray-700">Aucun contact à suivre</p>
        </Tile>
        )}


{tilePreferences.anniversaires && (
  <Tile title="Anniversaires à venir" icon={Cake} className="col-span-4 sm:col-span-2 lg:col-span-2">
    {upcomingBirthdays.length > 0 ? (
      <div className="space-y-3">
        {upcomingBirthdays.map((birthday) => (
          <div 
            key={birthday.id}
            className="flex items-center justify-between p-2 rounded-lg bg-gray-50 hover:bg-gray-100 transition-colors"
          >
            <div className="flex items-center space-x-3">
              <div className={`w-2 h-2 rounded-full ${birthday.type === 'employee' ? 'bg-blue-500' : 'bg-green-500'}`} />
              <div>
                <p className="font-medium">{birthday.name}</p>
                <p className="text-sm text-gray-500">
                  {birthday.daysUntil === 0 ? (
                    <span className="text-blue-600">Aujourd'hui</span>
                  ) : birthday.daysUntil === 1 ? (
                    <span className="text-purple-600">Demain</span>
                  ) : (
                    <span>Dans {birthday.daysUntil} jours</span>
                  )}
                  <span className="mx-1">•</span>
                  <span>{birthday.age} ans</span>
                </p>
              </div>
            </div>
            <div className={`text-xs font-semibold px-2 py-1 rounded-full ${
              birthday.type === 'employee' ? 'bg-blue-100 text-blue-700' : 'bg-green-100 text-green-700'
            }`}>
              {birthday.type === 'employee' ? 'Employé' : 'Bénéficiaire'}
            </div>
          </div>
        ))}
      </div>
    ) : (
      <p className="text-gray-500">Aucun anniversaire dans les 7 prochains jours</p>
    )}
  </Tile>
)}
      </div>
      <CustomizeModal
      isOpen={showCustomizeModal}
      onClose={() => setShowCustomizeModal(false)}
      preferences={tilePreferences}
      onSave={saveTilePreferences}
    />
      <PopupNavigation
      isOpen={!!selectedAppointment}
      onClose={() => setSelectedAppointment(null)}
      address={selectedAppointment?.clientAddress || "Adresse non disponible"}
    />
    </div>
  );
};



export default Dashboard;