import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, addDoc, updateDoc, doc, query, where, getDocs, Timestamp, getDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { X, Clock,Calendar, User, ChevronLeft, ChevronRight, Plus, AlertTriangle, Copy , RotateCcw, AlertCircle} from 'lucide-react';
import ClientEditAppointmentModal from './ClientEditAppointmentModal'; // Ajoutez cet import

const ClientWeekViewMobile = ({ db, client, currentDate, formatDate, formatTime }) => {
  const [weekDates, setWeekDates] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [currentDayIndex, setCurrentDayIndex] = useState(0);
  const [employees, setEmployees] = useState([]);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(1); // Default to Monday (1)
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [isEditModalOpen, setIsEditModalOpen] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [unassignedAppointments, setUnassignedAppointments] = useState([]);
  const [appointmentsWithColors, setAppointmentsWithColors] = useState([]);
  
  // Ajoutez un nouvel état pour stocker l'employé sélectionné
const [selectedEmployee, setSelectedEmployee] = useState(null);
  
  const isToday = useCallback((date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  }, []);

  const fetchPlanningSettings = useCallback(async () => {
    if (!db) return;
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'planningManagement'));
      if (settingsDoc.exists()) {
        const settings = settingsDoc.data();
        setFirstDayOfWeek(settings.firstDayOfWeek || 1); // Default to Monday if not set
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des paramètres de planification:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchPlanningSettings();
  }, [fetchPlanningSettings]);

  const updateWeekDates = useCallback((date) => {
    const weekStart = new Date(date);
    const day = weekStart.getDay();
    const diff = (day < firstDayOfWeek ? 7 : 0) + day - firstDayOfWeek;
    weekStart.setDate(weekStart.getDate() - diff);
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(weekStart);
      day.setDate(weekStart.getDate() + i);
      dates.push(day);
    }
    setWeekDates(dates);
  }, [firstDayOfWeek]);

  const fetchEmployees = useCallback(async () => {
    if (!db) return;
    try {
      const employeesRef = collection(db, 'employees');
      const q = query(employeesRef, where('isArchived', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map(doc => ({
        userId: doc.id,
        ...doc.data(),
        color: doc.data().color || '#808080' // Couleur par défaut si non définie
      }));
      setEmployees(fetchedEmployees);
      console.log("Employés récupérés:", fetchedEmployees);
    } catch (error) {
      console.error("Erreur lors de la récupération des employés:", error);
    }
  }, [db]);

  const sortAppointments = useCallback((apps) => {
    return apps.sort((a, b) => {
      const dateA = new Date(a.date);
      const dateB = new Date(b.date);
      if (dateA.getTime() !== dateB.getTime()) {
        return dateA.getTime() - dateB.getTime();
      }
      return a.startTime.localeCompare(b.startTime);
    });
  }, []);

  // Modifier la fonction fetchAppointments pour inclure les rendez-vous non assignés
  const fetchAppointments = useCallback(async () => {
    if (!db || !client.id) return;
    try {
      const appointmentsRef = collection(db, 'appointments');
      const q = query(appointmentsRef, where('clientId', '==', client.id));
      const querySnapshot = await getDocs(q);
      const fetchedAppointments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate() || new Date(),
      }));
      
      const sortedAppointments = sortAppointments(fetchedAppointments);
      
      const assigned = sortedAppointments.filter(app => app.status === 'assigne');
      const toFill = sortedAppointments.filter(app => app.status !== 'assigne');
      
      setAppointments(assigned);
      setUnassignedAppointments(toFill);
      
      const withColors = assigned.map(app => ({
        ...app,
        employeeColor: employees.find(emp => emp.userId === app.employeeId)?.color || '#808080'
      }));
      setAppointmentsWithColors(withColors);
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  }, [db, client.id, employees, sortAppointments]);

  useEffect(() => {
    fetchPlanningSettings();
  }, [fetchPlanningSettings]);


  useEffect(() => {
    fetchEmployees();
  }, [fetchEmployees]);


  useEffect(() => {
    if (db && client.id) {
      updateWeekDates(currentDate || new Date());
      fetchAppointments();
    }
  }, [db, client.id, currentDate, fetchEmployees, fetchAppointments, updateWeekDates]);

  

  useEffect(() => {
    // Mettre à jour les couleurs des rendez-vous lorsque les employés changent
    const updatedAppointments = appointments.map(appointment => {
      const employee = employees.find(emp => emp.userId === appointment.employeeId);
      return {
        ...appointment,
        employeeColor: employee?.color || '#808080'
      };
    });
    setAppointments(updatedAppointments);
  }, [employees]);


  const getEmployeeName = (employeeId) => {
    const employee = employees.find(emp => emp.userId === employeeId);
    if (employee) {
      return employee.name || `${employee.firstName} ${employee.lastName}`;
    } else {
      console.warn(`Employé non trouvé pour l'ID: ${employeeId}`);
      return "Employé non trouvé";
    }
  };

  const isSameDay = (date1, date2) => {
    if (!(date1 instanceof Date) || !(date2 instanceof Date)) {
      console.error('Invalid date object:', { date1, date2 });
      return false;
    }
    return date1.getFullYear() === date2.getFullYear() &&
           date1.getMonth() === date2.getMonth() &&
           date1.getDate() === date2.getDate();
  };

  

  

  // Modifiez la fonction handleCellClick
const handleCellClick = (date, employeeId) => {
  setSelectedDate(new Date(date));
  setSelectedEmployee(employeeId);
  setShowAddModal(true);
};

const handleAppointmentDoubleClick = (appointment) => {
  setSelectedAppointment(appointment);
  setIsEditModalOpen(true);
};

const handleCloseEditModal = () => {
  setIsEditModalOpen(false);
  setSelectedAppointment(null);
};

const handleUpdateAppointment = async (updatedAppointment) => {
  try {
    const appointmentRef = doc(db, 'appointments', updatedAppointment.id);
    const updateData = {
      ...updatedAppointment,
      date: Timestamp.fromDate(updatedAppointment.date),
      status: updatedAppointment.employeeId ? 'assigne' : 'libre'
    };

    await updateDoc(appointmentRef, updateData);

    // Mise à jour de l'état local
    setAppointments(prevAppointments => {
      const updatedAppointments = prevAppointments.map(app => 
        app.id === updatedAppointment.id ? updatedAppointment : app
      );
      return sortAppointments(updatedAppointments);
    });

    setAppointmentsWithColors(prevAppointments => {
      const updatedAppointments = prevAppointments.map(app => {
        if (app.id === updatedAppointment.id) {
          const employee = employees.find(emp => emp.userId === updatedAppointment.employeeId);
          return {
            ...updatedAppointment,
            employeeColor: employee?.color || '#808080'
          };
        }
        return app;
      });
      return sortAppointments(updatedAppointments);
    });

    setIsEditModalOpen(false);
    console.log("Rendez-vous mis à jour avec succès:", updatedAppointment);
  } catch (error) {
    console.error("Erreur lors de la mise à jour du rendez-vous:", error);
  }
};

const handleDeleteAppointment = async (appointmentId) => {
  try {
    await deleteDoc(doc(db, 'appointments', appointmentId));
    setAppointments(prevAppointments => 
      prevAppointments.filter(app => app.id !== appointmentId)
    );
    setAppointmentsWithColors(prevAppointments => 
      prevAppointments.filter(app => app.id !== appointmentId)
    );
    setIsEditModalOpen(false);
  } catch (error) {
    console.error("Erreur lors de la suppression du rendez-vous:", error);
  }
};


  



  

  const renderDayHeader = (date) => {
    return (
      <div className="cwvm-day-header">
        <div className="cwvm-day-name">{formatDate(date, 'eee. d MMMM')}</div>
        
      </div>
    );
  };
  
  const renderAppointmentsForDay = (date) => {
    const dayAppointments = appointments.filter(app => 
      app.date.toDateString() === date.toDateString()
    ).sort((a, b) => a.startTime.localeCompare(b.startTime));
  
    return (
      <div className="cwvm-day-column">
        {renderDayHeader(date)}
        <div className="cwvm-appointments">
          {dayAppointments.map((appointment, index) => {
            let borderColor = '#e53e3e'; // Rouge par défaut pour non assigné
            let statusClass = 'non-assigned';
  
            if (appointment.employeeId) {
              const employee = employees.find(emp => emp.userId === appointment.employeeId);
              if (appointment.status === 'assigne') {
                borderColor = employee?.color || '#4a5568'; // Couleur de l'employé ou gris par défaut
                statusClass = 'assigned';
              } else {
                borderColor = '#ed8936'; // Orange pour assigné mais pas encore confirmé
                statusClass = 'pending';
              }
            }
  
            return (
              <div 
                key={index} 
                className={`cwvm-appointment ${statusClass}`}
                style={{ borderLeftColor: borderColor }}
                onDoubleClick={() => handleAppointmentDoubleClick(appointment)}
              >
                <div className="cwvm-appointment-time">
                  <Clock size={14} className="cwvm-icon" />
                  {`${formatTime(appointment.startTime)} - ${formatTime(appointment.endTime)}`}
                </div>
                <div className="cwvm-appointment-employee">
                  <User size={14} className="cwvm-icon" />
                  {appointment.employeeId ? getEmployeeName(appointment.employeeId) : "Non assigné"}
                </div>
                {appointment.status === 'reserve' && (
                  <div className="cwvm-appointment-status">En attente de validation</div>
                )}
              </div>
            );
          })}
        </div>
      </div>
    );
  };
  
  return (
    <div className="cwvm-container">
      <div className="cwvm-week-view">
        {weekDates.map((date, index) => (
          <React.Fragment key={index}>
            {renderAppointmentsForDay(date)}
          </React.Fragment>
        ))}
      </div>
  
      {isEditModalOpen && selectedAppointment && (
        <Modal onClose={handleCloseEditModal}>
          <ClientEditAppointmentModal
            appointment={selectedAppointment}
            employees={employees}
            onSave={handleUpdateAppointment}
            onDelete={handleDeleteAppointment}
            onClose={handleCloseEditModal}
          />
        </Modal>
      )}
    </div>
  );
};


const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="pv-modal w-full max-w-md animate-fade-in-down">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
}; 


export default ClientWeekViewMobile;