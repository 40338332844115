import React, { useState, useEffect } from 'react';
import { doc, getDoc, collection, query, where, getDocs } from 'firebase/firestore';
import { BarChart, Bar, XAxis, YAxis, CartesianGrid, Tooltip, Legend, ResponsiveContainer, PieChart, Pie, Cell } from 'recharts';
import { ChevronDown, ChevronRight, ChevronLeft, Clock, DollarSign, Calendar, Users, EuroIcon, AlertTriangle, Copy } from 'lucide-react';
import AnnualChart from './AnnualChart';
import RevenueDisplay from './RevenueDisplay';

const ClientReport = ({ client, db }) => {
  const [contract, setContract] = useState(null);
  const [monthlyStats, setMonthlyStats] = useState([]);
  const [employeeStats, setEmployeeStats] = useState({});
  const [expandedMonths, setExpandedMonths] = useState({});
  const [employeeNames, setEmployeeNames] = useState({});
  const [selectedMonth, setSelectedMonth] = useState('');
  const [currentDate, setCurrentDate] = useState(new Date());
  const [anomalies, setAnomalies] = useState([]);

  useEffect(() => {
    const fetchData = async () => {
      try {
        // Fetch contract
        const contractDoc = await getDoc(doc(db, 'contracts', client.id));
        let contractData = null;
        if (contractDoc.exists()) {
          contractData = contractDoc.data();
          setContract(contractData);
        }

        // Fetch employee names
        const employeesSnapshot = await getDocs(collection(db, 'employees'));
        const employeeNamesData = {};
        employeesSnapshot.forEach(doc => {
          employeeNamesData[doc.id] = `${doc.data().firstName} ${doc.data().lastName}`;
        });
        setEmployeeNames(employeeNamesData);

        // Set initial month display
        setSelectedMonth(currentDate.toLocaleString('default', { month: 'long', year: 'numeric' }));

        // Fetch initial month's data using the fetched contract data
        await fetchMonthData(currentDate, contractData);
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData();
  }, [client.id, db]);

  const fetchMonthData = async (date, contractData) => {
    try {
      const startOfMonth = new Date(date.getFullYear(), date.getMonth(), 1);
      const endOfMonth = new Date(date.getFullYear(), date.getMonth() + 1, 0);
      const startOfNextMonth = new Date(date.getFullYear(), date.getMonth() + 1, 1);

      const appointmentsQuery = query(
        collection(db, 'appointments'),
        where('clientId', '==', client.id),
        where('date', '>=', startOfMonth),
        where('date', '<', startOfNextMonth)
      );

      const appointmentsSnapshot = await getDocs(appointmentsQuery);
      const appointments = appointmentsSnapshot.docs.map(doc => ({
        ...doc.data(),
        id: doc.id
      }));

      const { monthStats, empStats, anomalies } = processAppointments(appointments, startOfMonth, endOfMonth, contractData);
      setMonthlyStats(monthStats);
      setEmployeeStats(empStats);
      setAnomalies(anomalies);
    } catch (error) {
      console.error("Error fetching month data:", error);
    }
  };

  const changeMonth = (direction) => {
    const newDate = new Date(currentDate);
    newDate.setMonth(newDate.getMonth() + direction);
    setCurrentDate(newDate);
    setSelectedMonth(newDate.toLocaleString('default', { month: 'long', year: 'numeric' }));
    fetchMonthData(newDate, contract);
  };

  const calculateDuration = (startTime, endTime) => {
    const start = new Date(`2000-01-01T${startTime}`);
    const end = new Date(`2000-01-01T${endTime}`);
    const diff = (end - start) / (1000 * 60); // difference in minutes
    const hours = Math.floor(diff / 60);
    const minutes = diff % 60;
    return { hours, minutes };
  };

  const formatDuration = (duration) => {
    const hours = duration.hours;
    const minutes = duration.minutes;
    return `${hours}h${minutes.toString().padStart(2, '0')}`;
  };

  const formatTotalDuration = (hours) => {
    const wholeHours = Math.floor(hours);
    const minutes = Math.round((hours - wholeHours) * 60);
    return `${wholeHours}h${minutes.toString().padStart(2, '0')}`;
  };

  const durationToHours = (duration) => {
    return duration.hours + duration.minutes / 60;
  };

  const processAppointments = (appointments, startDate, endDate, contractData) => {
    const totalDaysInMonth = (endDate.getTime() - startDate.getTime()) / (1000 * 3600 * 24) + 1;
    const totalHoursInMonth = appointments.reduce((total, appointment) => total + durationToHours(calculateDuration(appointment.startTime, appointment.endTime)), 0);
    const averageHoursPerDay = totalHoursInMonth / totalDaysInMonth;
    const scheduledHours = averageHoursPerDay * totalDaysInMonth;

    // Utiliser les heures par mois du contrat comme heures conseillées
    const plannedHours = contractData ? contractData.hoursPerMonth : 0;
    let actualHours = 0;
    const currentDate = new Date();

    appointments.forEach(appointment => {
      const appointmentDate = new Date(appointment.date.toDate());
      const duration = durationToHours(calculateDuration(appointment.startTime, appointment.endTime));

      if (appointmentDate < currentDate) {
        // This appointment has already passed
        actualHours += duration;
      }
    });

    const monthStats = [{
      name: startDate.toLocaleString('default', { month: 'long', year: 'numeric' }),
      planned: formatTotalDuration(plannedHours),
      scheduled: formatTotalDuration(scheduledHours),
      actual: formatTotalDuration(actualHours),
      amount: 0,
      appointments: []
    }];

    const empStats = {};

    appointments.forEach(appointment => {
      const duration = calculateDuration(appointment.startTime, appointment.endTime);
      const durationInHours = durationToHours(duration);

      if (contractData && contractData.hourlyRate) {
        const appointmentAmount = durationInHours * contractData.hourlyRate;
        monthStats[0].amount += appointmentAmount;
      }

      monthStats[0].appointments.push({
        ...appointment,
        duration: formatDuration(duration),
        durationInHours
      });

      if (!empStats[appointment.employeeId]) {
        empStats[appointment.employeeId] = 0;
      }
      empStats[appointment.employeeId] += durationInHours;
    });

    monthStats[0].amount = Number(monthStats[0].amount.toFixed(2));

    const { anomalies } = calculateAnomalies(appointments, startDate, endDate);

    return { monthStats, empStats, anomalies };
  };

  const calculateAnomalies = (appointments, startDate, endDate) => {
    const daysInMonth = {};
    const totalDays = (endDate.getTime() - startDate.getTime()) / (1000 * 60 * 60 * 24) + 1;

    // Initialize all days
    for (let d = new Date(startDate); d <= endDate; d.setDate(d.getDate() + 1)) {
      daysInMonth[d.getDate()] = { count: 0, duration: 0, appointments: [], employeeAppointments: {} };
    }

    // Count appointments and duration for each day
    appointments.forEach(appointment => {
      const date = new Date(appointment.date.toDate());
      const day = date.getDate();
      const duration = durationToHours(calculateDuration(appointment.startTime, appointment.endTime));

      daysInMonth[day].count++;
      daysInMonth[day].duration += duration;
      daysInMonth[day].appointments.push(appointment);

      if (!daysInMonth[day].employeeAppointments[appointment.employeeId]) {
        daysInMonth[day].employeeAppointments[appointment.employeeId] = 0;
      }
      daysInMonth[day].employeeAppointments[appointment.employeeId]++;
    });

    // Calculate average appointments per day per employee
    const employeeAverages = {};
    Object.values(daysInMonth).forEach(day => {
      Object.entries(day.employeeAppointments).forEach(([employeeId, count]) => {
        if (!employeeAverages[employeeId]) {
          employeeAverages[employeeId] = { total: 0, days: 0 };
        }
        employeeAverages[employeeId].total += count;
        employeeAverages[employeeId].days++;
      });
    });

    Object.keys(employeeAverages).forEach(employeeId => {
      employeeAverages[employeeId] = employeeAverages[employeeId].total / employeeAverages[employeeId].days;
    });

    const anomalies = [];

    // Detect anomalies based on employee averages
    Object.entries(daysInMonth).forEach(([day, data]) => {
      Object.entries(data.employeeAppointments).forEach(([employeeId, count]) => {
        const averageForEmployee = employeeAverages[employeeId];
        if (count < averageForEmployee * 0.5) {  // Less than 50% of the average
          anomalies.push({
            type: 'low_appointments_employee',
            day: parseInt(day),
            employeeId,
            count,
            average: averageForEmployee.toFixed(1)
          });
        }
      });

      // Check for duplicate appointments
      if (data.count > 1) {
        const timeSlots = {};
        data.appointments.forEach(appointment => {
          const timeSlot = `${appointment.startTime}-${appointment.endTime}`;
          if (timeSlots[timeSlot]) {
            anomalies.push({
              type: 'duplicate',
              day: parseInt(day),
              timeSlot: timeSlot,
              count: timeSlots[timeSlot] + 1
            });
          } else {
            timeSlots[timeSlot] = 1;
          }
        });
      }
    });

    return { anomalies, employeeAverages };
  };

  const toggleMonth = (month) => {
    setExpandedMonths(prev => ({
      ...prev,
      [month]: !prev[month]
    }));
  };

  const COLORS = ['#0088FE', '#00C49F', '#FFBB28', '#FF8042', '#8884d8', '#82ca9d'];

  const renderEmployeeHoursPieChart = (employeeStatsData) => {
    const data = Object.entries(employeeStatsData).map(([empId, hours]) => ({
      name: employeeNames[empId] || `Employee ${empId}`,
      value: hours
    }));

    return (
      <ResponsiveContainer width="100%" height={200}>
        <PieChart>
          <Pie
            data={data}
            cx="50%"
            cy="50%"
            labelLine={false}
            outerRadius={80}
            fill="#8884d8"
            dataKey="value"
            label={({ name, percent }) => `${name} ${(percent * 100).toFixed(0)}%`}
          >
            {data.map((entry, index) => (
              <Cell key={`cell-${index}`} fill={COLORS[index % COLORS.length]} />
            ))}
          </Pie>
          <Tooltip />
        </PieChart>
      </ResponsiveContainer>
    );
  };

  const renderAnomalies = () => {
    if (anomalies.length === 0) {
      return <p>Aucune anomalie significative détectée.</p>;
    }

    return (
      <div className="space-y-4">
        {anomalies.map((anomaly, index) => (
          <div key={index} className={`border-l-4 p-4 ${
            anomaly.type === 'low_appointments_employee' 
              ? 'bg-yellow-100 border-yellow-500 text-yellow-700'
              : 'bg-orange-100 border-orange-500 text-orange-700'
          }`} role="alert">
            <div className="flex">
              <div className="py-1">
                <AlertTriangle className="h-6 w-6 text-yellow-500 mr-4" />
              </div>
              <div>
                {anomaly.type === 'low_appointments_employee' ? (
                  <>
                    <p className="font-bold">Nombre de rendez-vous inférieur à la moyenne pour un employé</p>
                    <p>
                      {`Le ${anomaly.day} du mois, l'employé ${employeeNames[anomaly.employeeId] || anomaly.employeeId} a ${anomaly.count} rendez-vous`}
                    </p>
                    <p className="text-sm mt-1">
                      Moyenne habituelle : environ {anomaly.average} rendez-vous par jour pour cet employé
                    </p>
                    <p className="text-sm italic mt-1">
                      Note: Vérifiez si cette baisse est prévue ou s'il s'agit d'une anomalie.
                    </p>
                  </>
                ) : (
                  <>
                    <p className="font-bold">Rendez-vous en double détectés</p>
                    <p>
                      {`Le ${anomaly.day} du mois, ${anomaly.count} rendez-vous à ${anomaly.timeSlot}`}
                    </p>
                    <p className="text-sm italic mt-1">
                      Vérifiez s'il s'agit d'une erreur de planification.
                    </p>
                  </>
                )}
              </div>
            </div>
          </div>
        ))}
      </div>
    );
  };

  if (!contract) {
    return <div>Chargement des données...</div>;
  }

  return (
    <div className="bg-white p-6 rounded-lg shadow-md">
      <h1 className="text-2xl font-bold mb-6">Rapport pour {client.firstName} {client.lastName}</h1>


      <AnnualChart client={client} db={db} />

      <div className="mb-6 flex items-center justify-between">
        <button
          onClick={() => changeMonth(-1)}
          className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
        >
          <ChevronLeft size={20} />
        </button>
        <span className="text-lg font-semibold">{selectedMonth}</span>
        <button
          onClick={() => changeMonth(1)}
          className="p-2 bg-gray-200 rounded-full hover:bg-gray-300"
        >
          <ChevronRight size={20} />
        </button>
      </div>

      <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4 mb-6">
        <div className="bg-blue-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Clock className="mr-2" size={16} />
            Tarif horaire
          </h3>
          <p className="text-2xl font-bold text-blue-600">{contract.hourlyRate}€</p>
        </div>
        <div className="bg-green-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Calendar className="mr-2" size={16} />
            Heures conseillées
          </h3>
          <p className="text-2xl font-bold text-green-600">{monthlyStats[0]?.planned || '0h00'}</p>
        </div>
        <div className="bg-orange-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Calendar className="mr-2" size={16} />
            Heures programmées
          </h3>
          <p className="text-2xl font-bold text-orange-600">{monthlyStats[0]?.scheduled || '0h00'}</p>
        </div>
        <div className="bg-pink-100 p-4 rounded-lg shadow">
          <h3 className="text-lg font-semibold mb-2 flex items-center">
            <Clock className="mr-2" size={16} />
            Heures effectuées
          </h3>
          <p className="text-2xl font-bold text-pink-600">{monthlyStats[0]?.actual || '0h00'}</p>
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2 flex items-center">
          <Users className="mr-2" size={20} />
          Heures programmées
        </h3>
        {monthlyStats.map((month, index) => (
          <div key={index} className="mb-2 bg-gray-50 p-3 rounded-lg">
            <div 
              className="flex items-center cursor-pointer"
              onClick={() => toggleMonth(month.name)}
            >
              {expandedMonths[month.name] ? <ChevronDown size={16} /> : <ChevronRight size={16} />}
              <span className="font-semibold">{month.name}: {month.scheduled}</span>
            </div>
            {expandedMonths[month.name] && (
              <ul className="ml-6 mt-2 space-y-1">
                {month.appointments.map((app, appIndex) => (
                  <li key={appIndex} className="text-sm">
                    {new Date(app.date.toDate()).toLocaleDateString()}: {app.duration}
                  </li>
                ))}
              </ul>
            )}
          </div>
        ))}
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2 flex items-center">
          <AlertTriangle className="mr-2" size={20} />
          Anomalies
        </h3>
        {renderAnomalies()}
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Heures totales par employé</h3>
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
          {Object.entries(employeeStats).map(([employeeId, hours]) => {
            const amount = (hours * contract.hourlyRate).toFixed(2);
            return (
              <div key={employeeId} className="bg-purple-100 p-4 rounded-lg shadow relative">
                <h3 className="text-lg font-semibold mb-2">{employeeNames[employeeId] || `Employé ${employeeId}`}</h3>
                <p className="text-2xl font-bold text-purple-600">{formatTotalDuration(hours)}</p>
                <p className="absolute bottom-2 right-2 text-sm text-gray-700">{amount}€</p>
              </div>
            );
          })}
        </div>
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Répartition des heures par employé</h3>
        {renderEmployeeHoursPieChart(employeeStats)}
      </div>

      <div className="mb-6">
        <h3 className="text-xl font-semibold mb-2">Statistiques mensuelles</h3>
        {monthlyStats.map((month, index) => (
          <div key={index} className="mb-6 border-b pb-4">
            <div className="bg-white p-4 rounded-lg shadow">
              <h5 className="text-md font-semibold mb-2">Heures prévues vs effectuées</h5>
              <ResponsiveContainer width="100%" height={200}>
                <BarChart data={[{
                  name: month.name,
                  "Heures prévues": parseFloat(month.planned),
                  "Heures effectuées": parseFloat(month.actual)
                }]}>
                  <CartesianGrid strokeDasharray="3 3" />
                  <XAxis dataKey="name" />
                  <YAxis />
                  <Tooltip />
                  <Legend />
                  <Bar dataKey="Heures prévues" fill="#4299E1" />
                  <Bar dataKey="Heures effectuées" fill="#48BB78" />
                </BarChart>
              </ResponsiveContainer>
            </div>
          </div>
        ))}
      </div>

      <RevenueDisplay monthlyStats={monthlyStats} />
    </div>
  );
};

export default ClientReport;