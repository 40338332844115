import React, { useState, useEffect, useRef } from 'react';
import { Search } from 'lucide-react';

const ClientAutocomplete = ({ clients, value, onChange }) => {
  const [inputValue, setInputValue] = useState(value);
  const [suggestions, setSuggestions] = useState([]);
  const [isOpen, setIsOpen] = useState(false);
  const wrapperRef = useRef(null);

  useEffect(() => {
    const handleClickOutside = (event) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target)) {
        setIsOpen(false);
      }
    };
    document.addEventListener('mousedown', handleClickOutside);
    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, []);

  const handleInputChange = (e) => {
    const value = e.target.value;
    setInputValue(value);

    const filteredSuggestions = clients.filter(client =>
      `${client.lastName} ${client.firstName}`.toLowerCase().includes(value.toLowerCase())
    );
    setSuggestions(filteredSuggestions);
    setIsOpen(true);
  };

  const handleSelectSuggestion = (client) => {
    const clientName = `${client.lastName} ${client.firstName}`;
    setInputValue(clientName);
    onChange(client.id, clientName);
    setIsOpen(false);
  };

  return (
    <div ref={wrapperRef} className="relative w-full">
      <div className="relative">
        <Search className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400" size={20} />
        <input
          type="text"
          value={inputValue}
          onChange={handleInputChange}
          className="w-full pl-10 pr-4 py-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          placeholder="Rechercher un client..."
        />
      </div>
      {isOpen && suggestions.length > 0 && (
        <ul className="absolute z-10 w-full bg-white border rounded-b shadow-lg max-h-60 overflow-auto">
          {suggestions.map((client) => (
            <li
              key={client.id}
              onClick={() => handleSelectSuggestion(client)}
              className="px-4 py-2 hover:bg-gray-100 cursor-pointer"
            >
              {client.lastName} {client.firstName}
            </li>
          ))}
        </ul>
      )}
    </div>
  );
};

export default ClientAutocomplete;