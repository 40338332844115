import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, addDoc, updateDoc, doc, query, getDocs, Timestamp, getDoc, deleteDoc, writeBatch, where, setDoc } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { X, Clock, Calendar, User, ChevronLeft, ChevronRight, Plus, AlertTriangle, Copy, RotateCcw, Search, Shield, Check, XCircle, AlertCircle } from 'lucide-react';
import ClientAutocomplete from './ClientAutocomplete';
import AppointmentDetailsModal from './AppointmentDetailsModal'; 
import EditAppointmentModal from './EditAppointmentModal'; // Nouvelle import

// Hook personnalisé pour détecter la taille de l'écran
const useWindowSize = () => {
  const [windowSize, setWindowSize] = useState({
    width: undefined,
    height: undefined,
  });

  useEffect(() => {
    function handleResize() {
      setWindowSize({
        width: window.innerWidth,
        height: window.innerHeight,
      });
    }
    
    window.addEventListener("resize", handleResize);
    handleResize();
    
    return () => window.removeEventListener("resize", handleResize);
  }, []);

  return windowSize;
}

const WeekView = ({ db, currentDate, currentUser }) => {
  const [weekDates, setWeekDates] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(1); // Default to Monday (1)
  const [showEditModal, setShowEditModal] = useState(false);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [unassignedAppointments, setUnassignedAppointments] = useState([]);
  const [clients, setClients] = useState([]);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [showAddModal, setShowAddModal] = useState(false);
  const [selectedDate, setSelectedDate] = useState(null);
  const [loading, setLoading] = useState(true);
  const [filterText, setFilterText] = useState('');
  const [selectedDay, setSelectedDay] = useState(new Date(currentDate));
  const [isAdmin, setIsAdmin] = useState(false);
  const [adminRoles, setAdminRoles] = useState([]);
  const [userRole, setUserRole] = useState(null);
  const [showBookingModal, setShowBookingModal] = useState(false);
  const [selectedUnassignedAppointment, setSelectedUnassignedAppointment] = useState(null);
  const [showInfoModal, setShowInfoModal] = useState(false);
  const [selectedInfoAppointment, setSelectedInfoAppointment] = useState(null);
  const [isMenuOpen, setIsMenuOpen] = useState(false);
  const [clickTimer, setClickTimer] = useState(null);
  const [showDetailsModal, setShowDetailsModal] = useState(false);
  const [selectedDetailAppointment, setSelectedDetailAppointment] = useState(null);
  const clickDelay = 200; // Délai en millisecondes pour détecter un double-clic
  const [showCompletedAppointments, setShowCompletedAppointments] = useState(false);
  const [userPermissions, setUserPermissions] = useState({
    readAppointments: false,
    writeAppointments: false,
    manageRoles: false
  });
  
  
  const { width } = useWindowSize();
  const isMobile = width < 768; // Considère les écrans de moins de 768px comme mobiles

  

     // Fonction pour récupérer les permissions de l'utilisateur
   const fetchUserPermissions = useCallback(async () => {
    if (!db || !currentUser) return;
    try {
      const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRoleId = userData.role;
        const roleDoc = await getDoc(doc(db, 'roles', userRoleId));
        if (roleDoc.exists()) {
          const roleData = roleDoc.data();
          setUserPermissions({
            readAppointments: roleData.permissions.includes('read_appointments'),
            writeAppointments: roleData.permissions.includes('write_appointments'),
            manageRoles: roleData.permissions.includes('manage_roles')
          });
        }
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des permissions de l'utilisateur:", error);
    }
  }, [db, currentUser]);

  useEffect(() => {
    fetchUserPermissions();
  }, [fetchUserPermissions]);


   // Fonction pour vérifier si l'utilisateur a une permission spécifique
   const hasPermission = (permission) => userPermissions[permission];

  const fetchUserRole = useCallback(async () => {
    if (!db || !currentUser) return;
    try {
      const userDoc = await getDoc(doc(db, 'employees', currentUser.userId));
      if (userDoc.exists()) {
        const userData = userDoc.data();
        const userRoleId = userData.role; // Changé de roles à role
        setUserRole(userRoleId);
        setIsAdmin(adminRoles.includes(userRoleId)); // Vérification pour un seul rôle
      }
    } catch (error) {
      console.error("Erreur lors de la récupération du rôle de l'utilisateur:", error);
    }
  }, [db, currentUser, adminRoles]);

  
  useEffect(() => {
    if (adminRoles.length > 0) {
      fetchUserRole();
    }
  }, [fetchUserRole, adminRoles]);

  const visibleEmployees = useMemo(() => {
    if (isMobile) {
      return employees.filter(emp => emp.userId === currentUser.userId);
    } else if (isAdmin || hasPermission('writeAppointments')) {
      return employees;
    } else {
      return employees.filter(emp => emp.userId === currentUser.userId);
    }
  }, [employees, isAdmin, currentUser, isMobile, hasPermission]);
  
  const isToday = useCallback((date) => {
    const today = new Date();
    return date.getDate() === today.getDate() &&
           date.getMonth() === today.getMonth() &&
           date.getFullYear() === today.getFullYear();
  }, []);

  const fetchPlanningSettings = useCallback(async () => {
    if (!db) return;
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'planningManagement'));
      if (settingsDoc.exists()) {
        const settings = settingsDoc.data();
        setFirstDayOfWeek(settings.firstDayOfWeek || 1); // Default to Monday if not set
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des paramètres de planification:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchPlanningSettings();
  }, [fetchPlanningSettings]);

  const updateWeekDates = useCallback((date) => {
    const weekStart = new Date(date);
    const day = weekStart.getDay();
    const diff = (day < firstDayOfWeek ? 7 : 0) + day - firstDayOfWeek;
    weekStart.setDate(weekStart.getDate() - diff);
    const dates = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(weekStart);
      day.setDate(weekStart.getDate() + i);
      dates.push(day);
    }
    setWeekDates(dates);
  }, [firstDayOfWeek]);

  useEffect(() => {
    const dates = getWeekDates(currentDate);
    setWeekDates(dates);
    setSelectedDay(new Date(currentDate));
  }, [currentDate]);

  const getWeekDates = (date) => {
    const week = [];
    for (let i = 0; i < 7; i++) {
      const day = new Date(date);
      day.setDate(date.getDate() - date.getDay() + i);
      week.push(day);
    }
    return week;
  };
  const navigateDay = (direction) => {
    const newDate = new Date(selectedDay);
    newDate.setDate(selectedDay.getDate() + direction);
    setSelectedDay(newDate);
  };
  const formatDay = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return '';
    }
    const days = ['Dim', 'Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam'];
    return days[date.getDay()];
  };

  const formatDayNumber = (date) => {
    return date.getDate();
  };

  const isCurrentDay = (date) => {
    return date.toDateString() === new Date().toDateString();
  };

  const isSelectedDay = (date) => {
    return date.toDateString() === selectedDay.toDateString();
  };

  const fetchEmployees = useCallback(async () => {
    if (!db) return;
    try {
      const employeesRef = collection(db, 'employees');
      const q = query(employeesRef, where('isArchived', '==', false));
      const querySnapshot = await getDocs(q);
      const fetchedEmployees = querySnapshot.docs.map(doc => ({
        userId: doc.id,
        ...doc.data(),
        color: doc.data().color || '#808080',
        isAdmin: adminRoles.includes(doc.data().role) // Changé pour vérifier un seul rôle
      }));
      setEmployees(fetchedEmployees);
    } catch (error) {
      console.error("Erreur lors de la récupération des employés:", error);
    }
  }, [db, adminRoles]);

  useEffect(() => {
    if (adminRoles.length > 0) {
      fetchEmployees();
    }
  }, [fetchEmployees, adminRoles]);

  const renderEmployeeHeader = (employee) => {
    const isCurrentUser = employee.userId === currentUser.userId;
    const headerText = isCurrentUser ? "Mon planning" : (employee.name || `${employee.firstName} ${employee.lastName}`);
    
    return (
      <h3 className="font-bold text-center mb-4 text-lg flex items-center justify-center">
        {headerText}
        {employee.isAdmin && (
          <Shield className="ml-2 text-blue-500" size={20} title="Droits administratifs" />
        )}
      </h3>
    );
  };

  const filteredEmployees = useMemo(() => {
    if (isAdmin) {
      return employees;
    } else {
      return employees.filter(emp => emp.userId === currentUser.userId);
    }
  }, [employees, isAdmin, currentUser]);

  
  // Mise à jour de la fonction handleUnassignedAppointmentClick
  const handleUnassignedAppointmentClick = (appointment) => {
    if (appointment.status === 'libre' && (hasPermission('writeAppointments') || hasPermission('readAppointments'))) {
      setSelectedUnassignedAppointment(appointment);
      setShowBookingModal(true);
      setShowAddModal(false);
    } else if (appointment.status === 'reserve') {
      setSelectedInfoAppointment(appointment);
      setShowInfoModal(true);
    }
  };

  // Mise à jour de la fonction handleBookAppointment
  const handleBookAppointment = async () => {
    if (!selectedUnassignedAppointment || !currentUser) return;
  
    try {
      const appointmentRef = doc(db, 'appointments', selectedUnassignedAppointment.id);
      await updateDoc(appointmentRef, {
        employeeId: currentUser.userId,
        status: 'reserve'
      });
  
      fetchAppointments();
      setShowBookingModal(false);
    } catch (error) {
      console.error("Erreur lors de la réservation du rendez-vous:", error);
      alert("Une erreur s'est produite lors de la réservation du rendez-vous");
    }
  };


  useEffect(() => {
    const fetchClients = async () => {
      if (!db) return;
      try {
        const clientsSnapshot = await getDocs(collection(db, 'clients'));
        const fetchedClients = clientsSnapshot.docs.map(doc => ({
          id: doc.id,
          ...doc.data()
        }));
        setClients(fetchedClients);
      } catch (error) {
        console.error("Erreur lors de la récupération des clients:", error);
      }
    };

    fetchClients();
  }, [db]);

  const calculateTotalHours = (appointments) => {
    if (!Array.isArray(appointments)) {
      console.error("appointments n'est pas un tableau:", appointments);
      return 0;
    }
  
    return appointments.reduce((total, app) => {
      if (!app.startTime || !app.endTime || app.status === 'reserve') {
        return total; // Ignore les rendez-vous réservés ou invalides
      }
  
      const [startHours, startMinutes] = app.startTime.split(':').map(Number);
      const [endHours, endMinutes] = app.endTime.split(':').map(Number);
      
      const durationInMinutes = (endHours * 60 + endMinutes) - (startHours * 60 + startMinutes);
      return total + durationInMinutes;
    }, 0);
  };

  const formatTotalHours = (totalMinutes) => {
    const hours = Math.floor(totalMinutes / 60);
    const minutes = totalMinutes % 60;
    return `${hours}h${minutes.toString().padStart(2, '0')}`;
  };

  const sortAppointmentsByTime = (appointments) => {
    return appointments.sort((a, b) => {
      // Comparer d'abord les dates
      const dateComparison = a.date.getTime() - b.date.getTime();
      if (dateComparison !== 0) return dateComparison;

      // Si les dates sont les mêmes, comparer les heures de début
      const aTime = a.startTime.split(':').map(Number);
      const bTime = b.startTime.split(':').map(Number);
      return (aTime[0] * 60 + aTime[1]) - (bTime[0] * 60 + bTime[1]);
    });
  };

  const fetchAppointments = useCallback(async () => {
    if (!db) return;
    try {
      const appointmentsRef = collection(db, 'appointments');
      const querySnapshot = await getDocs(appointmentsRef);
      const fetchedAppointments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate() || new Date(),
      }));
    
      const appointmentsWithClientInfo = await Promise.all(fetchedAppointments.map(async appointment => {
        const clientDoc = await getDoc(doc(db, 'clients', appointment.clientId));
        const clientData = clientDoc.data();
        return {
          ...appointment,
          clientName: clientData ? `${clientData.lastName} ${clientData.firstName}` : 'Client non spécifié',
          clientColor: clientData ? clientData.color : '#808080',
          status: appointment.status || 'libre'
        };
      }));
      setAppointments(appointmentsWithClientInfo);
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  }, [db, currentDate, currentUser]);

  
  

  useEffect(() => {
    if (db) {
      updateWeekDates(currentDate || new Date());
      fetchEmployees();
      fetchAppointments();
    }
  }, [db, currentDate, fetchEmployees, fetchAppointments, updateWeekDates, adminRoles, userRole]);

  
  const getAppointmentDisplayColor = (appointment) => {
    if (!appointment.employeeId || appointment.status === 'libre') {
      return '#FF0000'; // Rouge pour les rendez-vous non assignés ou libres
    } else if (appointment.status === 'reserve') {
      return '#FFA500'; // Orange pour "Validation requise"
    } else {
      return appointment.clientColor || '#808080'; // Couleur du client ou gris par défaut
    }
  };

  const appointmentsWithColors = useMemo(() => {
    return appointments.map(appointment => {
      const employee = employees.find(emp => emp.userId === appointment.employeeId);
      return {
        ...appointment,
        employeeColor: employee?.color || '#808080',
        displayColor: getAppointmentDisplayColor(appointment)
      };
    });
  }, [appointments, employees]);

  // Mise à jour de la fonction renderAppointment
  const renderAppointment = (appointment, employee) => {
    let appointmentClass = 'pv-appointment';
    let backgroundColor, textColor, borderColor, statusText;
  
    switch(appointment.status) {
      case 'libre':
        appointmentClass += ' pv-appointment-libre';
        backgroundColor = '#FEE2E2';
        textColor = '#991B1B';
        borderColor = '#EF4444';
        statusText = 'Plage à combler';
        break;
      case 'reserve':
        appointmentClass += ' pv-appointment-reserve';
        backgroundColor = '#FDE68A';
        textColor = '#92400E';
        borderColor = '#FCD34D';
        statusText = 'En attente de validation';
        break;
      case 'in_progress':
        appointmentClass += ' pv-appointment-in-progress';
        backgroundColor = '#FFEDD5';
        textColor = '#9A3412';
        borderColor = '#F97316';
        statusText = 'En cours';
        break;
      case 'completed':
        appointmentClass += ' pv-appointment-completed';
        backgroundColor = 'rgba(156, 163, 175, 0.3)';
        textColor = '#4B5563';
        borderColor = '#9CA3AF';
        statusText = 'Terminé';
        break;
      default:
        appointmentClass += ' pv-appointment-assigne';
        backgroundColor = lightenColor(appointment.clientColor || '#4B5563', 15);
        textColor = isLightColor(backgroundColor) ? '#1F2937' : '#F9FAFB';
        borderColor = appointment.clientColor || '#4B5563';
        statusText = '';
    }

    const getEmployeeName = (employeeId) => {
      const employee = employees.find(emp => emp.userId === employeeId);
      return employee ? `${employee.firstName} ${employee.lastName}` : 'Employé inconnu';
    };
  
    console.log(`Rendering appointment: ${appointment.id}, status: ${appointment.status}`);

    return (
      <div
        className={`${appointmentClass} p-1 mb-1 rounded text-xs shadow-md`}
        style={{ 
          backgroundColor: backgroundColor,
          color: textColor,
          borderLeft: `4px solid ${borderColor}`,
          opacity: appointment.status === 'completed' ? 0.7 : 1
        }}
        onClick={(e) => handleAppointmentClick(appointment, e)}
      >
        <div className="font-bold truncate p-1">
          {!appointment.employeeId || appointment.status === 'libre' ? (
            <>
              <AlertCircle size={12} className="inline-block mr-1 text-red-600" />
              <span>Plage à combler</span>
            </>
          ) : (
            <>
              <User size={12} className="inline-block mr-1" />
              {appointment.clientName}
            </>
          )}
        </div>
        <div className="bg-white text-gray-800 p-1 mt-1 rounded">
          <Clock size={12} className="inline-block mr-1" />
          {`${appointment.startTime} - ${appointment.endTime}`}
        </div>
        {appointment.status === 'reserve' && (
          <div className="text-xs mt-1">
            <User size={12} className="inline-block mr-1" />
            {getEmployeeName(appointment.employeeId)}
          </div>
        )}
        {statusText && <div className="text-xs mt-1 font-semibold">{statusText}</div>}
      </div>
    );
  };

  /// Mise à jour du rendu des cellules pour les employés
  const renderEmployeeCells = () => (
    employees.map(employee => (
      <React.Fragment key={employee.userId}>
        <div className="employee-name p-2 font-bold">
          {employee.name || `${employee.firstName} ${employee.lastName}`}
          <p className="total-hours text-xs text-gray-500">
            {formatTotalHours(calculateTotalHours(groupAppointmentsByEmployee[employee.userId]?.flatMap(day => day.appointments.filter(app => app.status !== 'reserve')) || []))}
          </p>
        </div>
        {weekDates.map((date, dayIndex) => (
          <Droppable key={`${employee.userId}-${dayIndex}`} droppableId={`${employee.userId}-${dayIndex}`}>
            {(provided, snapshot) => (
              <div
                {...provided.droppableProps}
                ref={provided.innerRef}
                className={`day-cell p-1 ${isToday(date) ? 'bg-blue-50' : 'bg-white'} ${
                  snapshot.isDraggingOver ? 'bg-blue-100' : ''
                } min-h-[100px] relative border`}
                onClick={() => hasPermission('writeAppointments') && handleCellClick(date, employee.userId)}
              >
                {sortAndFilterAppointments(filteredAppointments, employee.userId, date)
                  .map((appointment, appIndex) => (
                    hasPermission('writeAppointments') ? (
                      <Draggable key={appointment.id} draggableId={appointment.id} index={appIndex}>
                        {(provided, snapshot) => (
                          <div
                            ref={provided.innerRef}
                            {...provided.draggableProps}
                            {...provided.dragHandleProps}
                            className={`appointment p-1 mb-1 rounded text-xs ${snapshot.isDragging ? 'opacity-50' : ''} 
                                        ${isCtrlPressed ? 'cursor-copy' : 'cursor-move'} shadow-md`}
                            style={{
                              ...provided.draggableProps.style,
                              backgroundColor: getAppointmentDisplayColor(appointment),
                              borderLeft: `4px solid ${employee.color}`
                            }}
                            onClick={(e) => {
                              e.stopPropagation();
                              handleAppointmentClick(appointment);
                            }}
                          >
                            {renderAppointment(appointment, employee)}
                          </div>
                        )}
                      </Draggable>
                    ) : (
                      <div
                        key={appointment.id}
                        className="appointment p-1 mb-1 rounded text-xs shadow-md"
                        style={{
                          backgroundColor: getAppointmentDisplayColor(appointment),
                          borderLeft: `4px solid ${employee.color}`
                        }}
                        onClick={(e) => {
                          e.stopPropagation();
                          hasPermission('readAppointments') && handleAppointmentClick(appointment);
                        }}
                      >
                        {renderAppointment(appointment, employee)}
                      </div>
                    )
                  ))}
                {provided.placeholder}
              </div>
            )}
          </Droppable>
        ))}
      </React.Fragment>
    ))
  );

  // Mise à jour du rendu de la ligne "Plages à combler"
  const renderUnassignedRow = () => (
    <React.Fragment>
      <div className="employee-name non-assigne p-2 font-bold">
        Plages à combler
      </div>
      {weekDates.map((date, dayIndex) => (
        <Droppable key={`unassigned-${dayIndex}`} droppableId={`unassigned-${dayIndex}`}>
          {(provided, snapshot) => (
            <div
              {...provided.droppableProps}
              ref={provided.innerRef}
              className={`day-cell non-assigne p-1 ${isToday(date) ? 'bg-blue-50' : 'bg-white'} ${
                snapshot.isDraggingOver ? 'bg-blue-100' : ''
              } min-h-[100px] relative border`}
              onClick={() => handleCellClick(date, "unassigned")}
            >
              {sortAndFilterAppointments(filteredAppointments, null, date)
                .map((appointment, appIndex) => (
                  <Draggable key={appointment.id} draggableId={appointment.id} index={appIndex}>
                    {(provided, snapshot) => (
                      <div
                        ref={provided.innerRef}
                        {...provided.draggableProps}
                        {...provided.dragHandleProps}
                      >
                        {renderAppointment(appointment)}
                      </div>
                    )}
                  </Draggable>
                ))}
              {provided.placeholder}
            </div>
          )}
        </Droppable>
      ))}
    </React.Fragment>
  );

  useEffect(() => {
    // Mettre à jour les couleurs des rendez-vous lorsque les employés changent
    const updatedAppointments = appointments.map(appointment => {
      const employee = employees.find(emp => emp.userId === appointment.employeeId);
      return {
        ...appointment,
        employeeColor: employee?.color || '#808080'
      };
    });
    setAppointments(updatedAppointments);
  }, [employees]);

    const getEmployeeName = (employeeId) => {
      const employee = employees.find(emp => emp.userId === employeeId);
      if (employee) {
        return employee.name || `${employee.firstName} ${employee.lastName}`;
      } else {
        console.warn(`Employé non trouvé pour l'ID: ${employeeId}`);
        return "Employé non trouvé";
      }
    };

  const isSameDay = (date1, date2) => {
    // Convertir date1 en objet Date si ce n'est pas déjà le cas
    const d1 = date1 instanceof Date ? date1 : new Date(date1);
    // Convertir date2 en objet Date si ce n'est pas déjà le cas
    const d2 = date2 instanceof Date ? date2 : new Date(date2);
  
    // Vérifier si les conversions ont réussi
    if (isNaN(d1.getTime()) || isNaN(d2.getTime())) {
      console.error('Date invalide détectée:', { date1, date2 });
      return false;
    }
  
    return d1.getFullYear() === d2.getFullYear() &&
           d1.getMonth() === d2.getMonth() &&
           d1.getDate() === d2.getDate();
  };

  const filteredAppointments = useMemo(() => {
    console.log("Filtering appointments, showCompletedAppointments:", showCompletedAppointments);
    return appointments.filter(appointment => {
      const lowerFilterText = filterText.toLowerCase();
      const employeeName = getEmployeeName(appointment.employeeId).toLowerCase();
      const clientName = appointment.clientName.toLowerCase();
      const startTime = appointment.startTime.toLowerCase();
      const endTime = appointment.endTime.toLowerCase();
      const matchesFilter = employeeName.includes(lowerFilterText) ||
                            clientName.includes(lowerFilterText) ||
                            startTime.includes(lowerFilterText) ||
                            endTime.includes(lowerFilterText);
      
      const shouldShow = showCompletedAppointments || appointment.status !== 'completed';
      
      console.log(`Appointment ${appointment.id}: status=${appointment.status}, shouldShow=${shouldShow}`);
      
      return matchesFilter && shouldShow;
    });
  }, [appointments, filterText, getEmployeeName, showCompletedAppointments]);

  const sortAndFilterAppointments = useCallback((appointments, employeeId, date) => {
    const compareDate = date instanceof Date ? date : new Date(date);
    if (isNaN(compareDate.getTime())) {
      console.error('Date invalide dans sortAndFilterAppointments:', date);
      return [];
    }
  
    return appointments.filter(app => {
      const appDate = app.date instanceof Date ? app.date : new Date(app.date);
      if (isNaN(appDate.getTime())) {
        console.error('Date invalide dans un rendez-vous:', app);
        return false;
      }
      const sameDay = isSameDay(appDate, compareDate);
      const isAssignedToEmployee = app.employeeId === employeeId;
      const isUnassigned = !app.employeeId || app.status === 'libre' || app.status === 'reserve';
      const isCompleted = app.status === 'completed';
      
      if (employeeId === null || employeeId === 'unassigned') {
        return sameDay && isUnassigned;
      } else {
        return sameDay && (
          isAssignedToEmployee || 
          (isCompleted && showCompletedAppointments && isAssignedToEmployee)
        );
      }
    }).sort((a, b) => {
      const aTime = a.startTime.split(':').map(Number);
      const bTime = b.startTime.split(':').map(Number);
      return (aTime[0] * 60 + aTime[1]) - (bTime[0] * 60 + bTime[1]);
    });
  }, [isSameDay, showCompletedAppointments]);

  const groupAppointmentsByEmployee = useMemo(() => {
    const groupedAppointments = {};
    employees.forEach(employee => {
      groupedAppointments[employee.userId] = weekDates.map(date => ({
        date,
        appointments: sortAppointmentsByTime(appointmentsWithColors.filter(
          app => app.employeeId === employee.userId && isSameDay(app.date, date)
        ))
      }));
    });
    return groupedAppointments;
  }, [appointmentsWithColors, employees, weekDates]);

  const formatDate = (date) => {
    if (!(date instanceof Date) || isNaN(date)) {
      return 'Date invalide';
    }
    const day = date.getDate();
    const month = date.toLocaleString('fr-FR', { month: 'short' });
    return `${day} ${month}`;
  };

  

  const handlePreviousWeek = () => {
    const newDate = new Date(weekDates[0]);
    newDate.setDate(newDate.getDate() - 7);
    updateWeekDates(newDate);
  };

  const handleNextWeek = () => {
    const newDate = new Date(weekDates[0]);
    newDate.setDate(newDate.getDate() + 7);
    updateWeekDates(newDate);
  };

  const handleToday = () => {
    updateWeekDates(new Date());
  };

  const handleCellClick = (date, employeeId) => {
    if (isAdmin && employeeId !== 'NON ASSIGNE') {
      setSelectedDate(new Date(date));
      setShowAddModal(true);
      setSelectedEmployee(employeeId);
    }
    // Pour les cellules "NON ASSIGNE", nous ne faisons rien ici
    // car les rendez-vous non assignés seront gérés par handleUnassignedAppointmentClick
  };

  const handleAppointmentClick = (appointment, event) => {
    event.preventDefault();
    event.stopPropagation();

    if (clickTimer) {
      // Double-clic détecté
      clearTimeout(clickTimer);
      setClickTimer(null);
      handleAppointmentDoubleClick(appointment, event);
    } else {
      // Définir un timer pour le simple clic
      const timer = setTimeout(() => {
        handleSingleClick(appointment);
        setClickTimer(null);
      }, clickDelay);
      setClickTimer(timer);
    }
  };

  const handleSingleClick = (appointment) => {
    if (appointment.status === 'libre') {
      setSelectedUnassignedAppointment(appointment);
      setShowBookingModal(true);
    } else if (appointment.status === 'reserve') {
      setSelectedInfoAppointment(appointment);
      setShowInfoModal(true);
    } else {
      setSelectedDetailAppointment(appointment);
      setShowDetailsModal(true);
    }
  };

  const handleAppointmentDoubleClick = (appointment, event) => {
    if (hasPermission('writeAppointments')) {
      event.preventDefault();
      setSelectedAppointment(appointment);
      setShowEditModal(true);
    }
  };

  const handleAppointmentUpdate = (updatedAppointment) => {
    setAppointments(prevAppointments =>
      prevAppointments.map(app =>
        app.id === updatedAppointment.id ? updatedAppointment : app
      )
    );
  };

  const handleApproveAppointment = async () => {
    if (!selectedInfoAppointment || !hasPermission('writeAppointments')) return;
  
    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'assigne'
      });
  
      setAppointments(prevAppointments => 
        prevAppointments.map(app => 
          app.id === selectedInfoAppointment.id ? {...app, status: 'assigne'} : app
        )
      );
  
      setShowInfoModal(false);
      fetchAppointments();
    } catch (error) {
      console.error("Erreur lors de l'approbation du rendez-vous:", error);
      alert("Une erreur s'est produite lors de l'approbation du rendez-vous");
    }
  };
  
  const handleRejectAppointment = async () => {
    if (!selectedInfoAppointment || !hasPermission('writeAppointments')) return;
  
    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'libre',
        employeeId: null
      });
  
      setAppointments(prevAppointments => 
        prevAppointments.map(app => 
          app.id === selectedInfoAppointment.id ? {...app, status: 'libre', employeeId: null} : app
        )
      );
  
      setShowInfoModal(false);
      fetchAppointments();
    } catch (error) {
      console.error("Erreur lors du refus du rendez-vous:", error);
      alert("Une erreur s'est produite lors du refus du rendez-vous");
    }
  };
  
  const handleCancelReservation = async () => {
    if (!selectedInfoAppointment || selectedInfoAppointment.employeeId !== currentUser.userId) return;
  
    try {
      const appointmentRef = doc(db, 'appointments', selectedInfoAppointment.id);
      await updateDoc(appointmentRef, {
        status: 'libre',
        employeeId: null
      });
  
      setAppointments(prevAppointments => 
        prevAppointments.map(app => 
          app.id === selectedInfoAppointment.id ? {...app, status: 'libre', employeeId: null} : app
        )
      );
  
      setShowInfoModal(false);
      fetchAppointments();
    } catch (error) {
      console.error("Erreur lors de l'annulation de la réservation:", error);
      alert("Une erreur s'est produite lors de l'annulation de la réservation");
    }
  };

  const handleAddAppointment = async (appointmentData) => {
    if (!isAdmin) {
      console.error("Tentative non autorisée d'ajout de rendez-vous");
      return;
    }

    try {
      let appointmentsToAdd;
      if (appointmentData.isRecurring) {
        appointmentsToAdd = generateRecurringAppointments(appointmentData);
      } else {
        appointmentsToAdd = [{
          ...appointmentData,
          isRecurring: false
        }];
      }
  
      const batch = writeBatch(db);
      
      appointmentsToAdd.forEach(appointment => {
        const newAppointmentRef = doc(collection(db, 'appointments'));
        const appointmentDate = new Date(appointment.date);
        appointmentDate.setHours(4, 0, 0, 0);  // Force l'heure à 04:00:00
        batch.set(newAppointmentRef, {
          ...appointment,
          date: Timestamp.fromDate(appointmentDate),
          clientId: appointment.clientId
        });
      });
  
      await batch.commit();
      console.log(`Added ${appointmentsToAdd.length} appointment(s) successfully`);
      
      fetchAppointments();
      setShowAddModal(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout du/des rendez-vous:", error);
    }
  };


  const generateRecurringAppointments = (appointmentData) => {
    console.log("Generating recurring appointments with data:", appointmentData);
    const appointments = [];
    let currentDate = new Date(appointmentData.date);
    const endDate = getRecurrenceEndDate(appointmentData);
    
    while (currentDate <= endDate) {
      if (shouldAddAppointment(currentDate, appointmentData)) {
        appointments.push({
          ...appointmentData,
          date: new Date(currentDate),
          isRecurring: true
        });
      }
      
      if (appointments.length >= appointmentData.recurrenceEndAfter && appointmentData.recurrenceEndType === 'after') {
        break;
      }
      
      currentDate = getNextRecurrenceDate(currentDate, appointmentData);
    }
  
    console.log("Generated appointments:", appointments);
    return appointments;
  };

  const getRecurrenceEndDate = (appointmentData) => {
    const startDate = new Date(appointmentData.date);
    
    switch (appointmentData.recurrenceEndType) {
      case 'never':
        return new Date(startDate.getFullYear() + 2, startDate.getMonth(), startDate.getDate()); // Limit to 2 years in the future
      case 'after':
        return new Date(startDate.getFullYear() + 10, startDate.getMonth(), startDate.getDate()); // Set a far future date
      case 'on':
        return new Date(appointmentData.recurrenceEndDate);
      default:
        return new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate());
    }
  };
  
  const getDateAfterOccurrences = (startDate, appointmentData) => {
    let date = new Date(startDate);
    for (let i = 0; i < appointmentData.recurrenceEndAfter; i++) {
      date = getNextRecurrenceDate(date, appointmentData);
    }
    return date;
  };
  
  const shouldAddAppointment = (date, appointmentData) => {
    if (appointmentData.recurrenceBase === 'weekly') {
      return appointmentData.recurrenceDay.includes(date.getDay());
    } else if (appointmentData.recurrenceBase === 'monthly') {
      if (appointmentData.recurrenceMonthType === 'dayOfWeek') {
        const weekOfMonth = Math.floor((date.getDate() - 1) / 7) + 1;
        return date.getDay() === parseInt(appointmentData.recurrenceMonthWeekday, 10) &&
               weekOfMonth === parseInt(appointmentData.recurrenceMonthWeek, 10);
      } else {
        return date.getDate() === parseInt(appointmentData.recurrenceMonthDay, 10);
      }
    }
    return true;
  };
  
  const getNextRecurrenceDate = (date, appointmentData) => {
    let newDate = new Date(date);
    const frequency = parseInt(appointmentData.recurrenceFrequency, 10);
    
    if (appointmentData.recurrenceBase === 'weekly') {
      newDate.setDate(newDate.getDate() + (7 * frequency));
    } else if (appointmentData.recurrenceBase === 'monthly') {
      newDate.setMonth(newDate.getMonth() + frequency);
      
      if (appointmentData.recurrenceMonthType === 'dayOfWeek') {
        const desiredDay = parseInt(appointmentData.recurrenceMonthWeekday, 10);
        const desiredWeek = parseInt(appointmentData.recurrenceMonthWeek, 10);
        
        newDate.setDate(1); // Go to the first day of the month
        
        // Find the first occurrence of the desired day of the week
        while (newDate.getDay() !== desiredDay) {
          newDate.setDate(newDate.getDate() + 1);
        }
        
        // Move to the desired week
        newDate.setDate(newDate.getDate() + (desiredWeek - 1) * 7);
        
        // If we've gone past the end of the month, go back to the last valid day
        if (newDate.getMonth() > (date.getMonth() + frequency) % 12) {
          newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 0);
        }
      } else {
        // For 'dayOfMonth', adjust to the specific day of the month
        const desiredDay = parseInt(appointmentData.recurrenceMonthDay, 10);
        newDate.setDate(Math.min(desiredDay, new Date(newDate.getFullYear(), newDate.getMonth() + 1, 0).getDate()));
      }
    }
    
    return newDate;
  };

  
  const handleUpdateAppointment = async (updatedAppointment) => {
    try {
      const appointmentRef = doc(db, 'appointments', updatedAppointment.id);
      await updateDoc(appointmentRef, {
        ...updatedAppointment,
        date: Timestamp.fromDate(new Date(updatedAppointment.date))
      });
  
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rendez-vous:", error);
    }
  };

  const handleDeleteAppointment = async (appointmentId) => {
    try {
      await deleteDoc(doc(db, 'appointments', appointmentId));
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la suppression du rendez-vous:", error);
    }
  };

  const handleCloseEditModal = () => {
    setShowEditModal(false);
    setSelectedAppointment(null);
  };

  

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onDragEnd = useCallback(async (result) => {
    if (!result.destination || !hasPermission('writeAppointments')) return;
  
    const { source, destination, draggableId } = result;
    
    const [sourceEmployeeId, sourceDayIndex] = source.droppableId.split('-');
    const [destEmployeeId, destDayIndex] = destination.droppableId.split('-');
    
    let appointment = [...appointments, ...unassignedAppointments].find(app => app.id === draggableId);
    if (!appointment) return;
  
    const sourceDate = weekDates[parseInt(sourceDayIndex)];
    const destinationDate = weekDates[parseInt(destDayIndex)];
    const timeDifference = destinationDate.getTime() - sourceDate.getTime();
  
    const newDate = new Date(appointment.date.getTime() + timeDifference);
    const newEmployeeId = destEmployeeId === 'unassigned' ? null : destEmployeeId;
    const newStatus = destEmployeeId === 'unassigned' ? 'libre' : 'assigne';
  
    try {
      let updatedAppointment = {
        ...appointment,
        date: Timestamp.fromDate(newDate),
        employeeId: newEmployeeId,
        status: newStatus
      };
  
      if (isCtrlPressed) {
        // Copier le rendez-vous
        const newAppointmentRef = doc(collection(db, 'appointments'));
        const newAppointmentId = newAppointmentRef.id; // Générer un nouvel ID
        updatedAppointment = { ...updatedAppointment, id: newAppointmentId };
        await setDoc(newAppointmentRef, updatedAppointment);
      } else {
        // Mettre à jour le rendez-vous existant
        await updateDoc(doc(db, 'appointments', appointment.id), updatedAppointment);
      }
  
      // Mettre à jour l'état local
      if (isCtrlPressed) {
        // Ajouter le nouveau rendez-vous copié à l'état approprié
        if (newStatus === 'assigne') {
          setAppointments(prev => [...prev, updatedAppointment]);
        } else {
          setUnassignedAppointments(prev => [...prev, updatedAppointment]);
        }
      } else {
        // Mettre à jour le rendez-vous existant dans l'état
        setAppointments(prev => prev.map(app => app.id === updatedAppointment.id ? updatedAppointment : app));
        setUnassignedAppointments(prev => prev.filter(app => app.id !== updatedAppointment.id));
      }
  
      // Rafraîchir les données depuis Firestore
      await fetchAppointments();
  
    } catch (error) {
      console.error("Erreur lors de la manipulation du rendez-vous:", error);
    }
  }, [appointments, unassignedAppointments, weekDates, db, isCtrlPressed, fetchAppointments, hasPermission]);

  
  

  const filteredUnassignedAppointments = useMemo(() => {
    const lowerFilterText = filterText.toLowerCase();
    return unassignedAppointments.filter(appointment => {
      const clientName = appointment.clientName.toLowerCase();
      const startTime = appointment.startTime.toLowerCase();
      const endTime = appointment.endTime.toLowerCase();

      return clientName.includes(lowerFilterText) ||
             startTime.includes(lowerFilterText) ||
             endTime.includes(lowerFilterText);
    });
  }, [unassignedAppointments, filterText]);
  
  
  // Fonction utilitaire pour assombrir une couleur
  const darkenColor = (color, percent) => {
    const num = parseInt(color.replace("#",""), 16),
      amt = Math.round(2.55 * percent),
      R = Math.max((num >> 16) - amt, 0),
      G = Math.max((num >> 8 & 0x00FF) - amt, 0),
      B = Math.max((num & 0x0000FF) - amt, 0);
    return "#" + ((0x1000000 + (R * 0x10000) + (G * 0x100) + B).toString(16).slice(1));
  };

  // Fonction utilitaire pour déterminer si une couleur est claire ou foncée
  const isLightColor = (color) => {
    const hex = color.replace('#', '');
    const r = parseInt(hex.substr(0, 2), 16);
    const g = parseInt(hex.substr(2, 2), 16);
    const b = parseInt(hex.substr(4, 2), 16);
    const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
    return brightness > 155;
  };

  return (
    <div className="week-view">
      <div className="filter-bar flex items-center justify-between mb-4">
      <div className="relative">
        <input
          type="text"
          placeholder="Recherche Client, Heure"
          className="pl-8 pr-2 py-1 w-full md:w-64 border-b border-gray-300 focus:border-gray-500 focus:outline-none text-sm bg-transparent"
          value={filterText}
          onChange={(e) => setFilterText(e.target.value)}
        />
        <Search className="absolute left-2 top-1/2 transform -translate-y-1/2 text-gray-400" size={16} />
      </div>
        <div className="flex items-center">
          <input
            type="checkbox"
            id="showCompletedAppointments"
            checked={showCompletedAppointments}
            onChange={(e) => setShowCompletedAppointments(e.target.checked)}
            className="mr-2"
          />
          <label htmlFor="showCompletedAppointments" className="text-sm text-gray-700">
            Afficher les rendez-vous terminés
          </label>
        </div>
      </div>

  

      <DragDropContext onDragEnd={onDragEnd}>
        <div className="week-grid grid" style={{ gridTemplateColumns: 'auto repeat(7, 1fr)' }}>
          {/* En-tête des jours */}
          <div className="employee-header sticky-header"></div>
          {weekDates.map((date, index) => (
            <div 
            key={`header-${index}`} 
            className={`day-header font-bold text-center p-2 sticky-header 
              ${isToday(date) ? 'bg-blue-100 text-blue-600' : 'bg-white'}`}
          >
            <div className={`text-xs ${isToday(date) ? 'text-blue-500' : 'text-gray-500'}`}>
              {formatDay(date)}
            </div>
            <div>{formatDate(date)}</div>
          </div>
          ))}

          {/* Ligne pour les rendez-vous non assignés (visible pour tous les utilisateurs) */}
          {renderUnassignedRow()}


          {/* Lignes des employés */}
          {visibleEmployees.map(employee => (
            <React.Fragment key={employee.userId}>
              <div className="employee-name p-2 font-bold">
                {employee.name || `${employee.firstName} ${employee.lastName}`}
                <p className="total-hours text-xs text-gray-500">
                  {formatTotalHours(calculateTotalHours(groupAppointmentsByEmployee[employee.userId]?.flatMap(day => day.appointments) || []))}
                </p>
              </div>
              {weekDates.map((date, dayIndex) => (
                <Droppable key={`${employee.userId}-${dayIndex}`} droppableId={`${employee.userId}-${dayIndex}`}>
                  {(provided, snapshot) => (
                    <div
                      {...provided.droppableProps}
                      ref={provided.innerRef}
                      className={`day-cell p-1 ${isToday(date) ? 'bg-blue-50' : 'bg-white'} ${
                        snapshot.isDraggingOver ? 'bg-blue-100' : ''
                      } min-h-[100px] relative border`}
                      onClick={() => handleCellClick(date, employee.userId)}
                    >
                      {sortAndFilterAppointments(filteredAppointments, employee.userId, date)
                        .map((appointment, appIndex) => (
                          <Draggable key={appointment.id} draggableId={appointment.id} index={appIndex}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`${snapshot.isDragging ? 'opacity-50' : ''}`}
                                style={{
                                  ...provided.draggableProps.style,
                                }}
                              >
                                {renderAppointment(appointment, employee)}
                              </div>
                            )}
                          </Draggable>
                        ))}
                      {provided.placeholder}
                    </div>
                  )}
                </Droppable>
              ))}
            </React.Fragment>
          ))}
        </div>
      </DragDropContext>
  
      {showBookingModal && selectedUnassignedAppointment && (
      <Modal onClose={() => setShowBookingModal(false)}>
        <div className="booking-modal p-4">
          <h2 className="text-xl font-bold mb-4">Réserver ce rendez-vous</h2>
          <p>Voulez-vous réserver ce rendez-vous ?</p>
          <p>Date : {selectedUnassignedAppointment.date.toLocaleDateString()}</p>
          <p>Heure : {selectedUnassignedAppointment.startTime} - {selectedUnassignedAppointment.endTime}</p>
          <div className="mt-4 flex justify-end space-x-2">
            <button
              onClick={() => setShowBookingModal(false)}
              className="pv-button-action pv-button-cancel-gray"
            >
              Annuler
            </button>
            <button
              onClick={handleBookAppointment}
              className="pv-button-action pv-button-book"
            >
              Réserver
            </button>
          </div>
        </div>
      </Modal>
    )}

    {showInfoModal && selectedInfoAppointment && (
      <Modal onClose={() => setShowInfoModal(false)}>
        <div className="info-modal p-4">
          <h2 className="text-xl font-bold mb-4">Détails du rendez-vous</h2>
          <p>Client : {selectedInfoAppointment.clientName}</p>
          <p>Date : {selectedInfoAppointment.date.toLocaleDateString()}</p>
          <p>Heure : {selectedInfoAppointment.startTime} - {selectedInfoAppointment.endTime}</p>
          <p>Employé : {getEmployeeName(selectedInfoAppointment.employeeId)}</p>
          <p>Statut : {selectedInfoAppointment.status === 'reserve' ? 'En attente de validation' : selectedInfoAppointment.status}</p>
          
          <div className="mt-4 flex justify-end space-x-2">
            {selectedInfoAppointment.status === 'reserve' && (
              <>
                {hasPermission('writeAppointments') && (
                  <>
                    <button
                      onClick={handleApproveAppointment}
                      className="pv-button-action pv-button-approve"
                    >
                      <Check className="mr-1" size={12} />
                      Approuver
                    </button>
                    <button
                      onClick={handleRejectAppointment}
                      className="pv-button-action pv-button-reject"
                    >
                      <XCircle className="mr-1" size={12} />
                      Refuser
                    </button>
                  </>
                )}
                {selectedInfoAppointment.employeeId === currentUser.userId && (
                  <button
                    onClick={handleCancelReservation}
                    className="pv-button-action pv-button-cancel"
                  >
                    <X className="mr-1" size={12} />
                    Annuler
                  </button>
                )}
              </>
            )}
            <button
              onClick={() => setShowInfoModal(false)}
              className="pv-button-action pv-button-gray"
            >
              Fermer
            </button>
          </div>
        </div>
      </Modal>
    )}

      {showAddModal && isAdmin && (
        <Modal onClose={() => setShowAddModal(false)}>
          <AddAppointmentModal
            date={selectedDate}
            employees={employees}
            clients={clients}
            selectedEmployee={selectedEmployee}
            onSave={handleAddAppointment}
            onClose={() => setShowAddModal(false)}
          />
        </Modal>
      )}
  
  {showEditModal && selectedAppointment && (
        <Modal onClose={() => setShowEditModal(false)}>
          <EditAppointmentModal
            appointment={selectedAppointment}
            employees={employees}
            clients={clients}
            onSave={handleUpdateAppointment}
            onClose={() => setShowEditModal(false)}
            onDelete={handleDeleteAppointment}
          />
        </Modal>
        )}
      
      {showDetailsModal && selectedDetailAppointment && (
        <AppointmentDetailsModal
          appointment={selectedDetailAppointment}
          onClose={() => setShowDetailsModal(false)}
          onUpdate={handleAppointmentUpdate}
          db={db}
        />
      )}
    </div>
    
  );
};

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md animate-fade-in-down">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

// Fonction utilitaire pour éclaircir une couleur
const lightenColor = (color, percent) => {
  const num = parseInt(color.replace("#",""), 16),
    amt = Math.round(2.55 * percent),
    R = Math.min((num >> 16) + amt, 255),
    G = Math.min((num >> 8 & 0x00FF) + amt, 255),
    B = Math.min((num & 0x0000FF) + amt, 255);
  return "#" + (0x1000000 + R*0x10000 + G*0x100 + B).toString(16).slice(1);
};

// Fonction utilitaire pour déterminer si une couleur est claire ou foncée
const isLightColor = (color) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness > 155;
};

const AddAppointmentModal = ({ date, employees, clients, selectedEmployee, onSave, onClose }) => {
  const [appointmentData, setAppointmentData] = useState(() => {
    const initialDate = new Date(date);
    initialDate.setMinutes(initialDate.getMinutes() - initialDate.getTimezoneOffset());
    return {
      date: initialDate,
      startTime: '',
      endTime: '',
      employeeId: selectedEmployee || '',
      clientId: '',
      clientName: '',
      isRecurring: false,
      recurrenceBase: 'weekly',
      recurrenceFrequency: 1,
      recurrenceDay: [initialDate.getDay()],
      recurrenceMonthDay: initialDate.getDate(),
      recurrenceMonthType: 'dayOfMonth',
      recurrenceMonthWeek: Math.floor((initialDate.getDate() - 1) / 7) + 1,
      recurrenceMonthWeekday: initialDate.getDay(),
      recurrenceEndType: 'never',
      recurrenceEndAfter: 1,
      recurrenceEndDate: null,
    };
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAppointmentData(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : 
               name === 'recurrenceFrequency' ? parseInt(value, 10) : value
    }));
  };

  const handleClientChange = (clientId, clientName) => {
    setAppointmentData(prev => ({ ...prev, clientId, clientName }));
  };

  const handleDaySelection = (day) => {
    setAppointmentData(prev => ({
      ...prev,
      recurrenceDay: prev.recurrenceDay.includes(day)
        ? prev.recurrenceDay.filter(d => d !== day)
        : [...prev.recurrenceDay, day]
    }));
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    console.log("Submitting appointment data:", appointmentData);
    onSave({
      ...appointmentData,
      date: new Date(appointmentData.date)
    });
  };

  const getDayOfWeekName = (day) => {
    const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    return days[day];
  };

  const getWeekOfMonthName = (week) => {
    const weeks = ['premier', 'deuxième', 'troisième', 'quatrième', 'dernier'];
    return weeks[Math.min(week - 1, 4)];
  };
  
  return (
    <div className="modal bg-white p-6 rounded-lg shadow-xl max-w-md w-full">
      <h2 className="text-2xl font-bold mb-4">Ajouter un rendez-vous</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Calendar className="text-gray-400" size={20} />
          <input 
            type="date" 
            name="date" 
            value={appointmentData.date.toISOString().split('T')[0]} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="text-gray-400" size={20} />
          <input 
            type="time" 
            name="startTime" 
            value={appointmentData.startTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span>à</span>
          <input 
            type="time" 
            name="endTime" 
            value={appointmentData.endTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <select 
            name="employeeId" 
            value={appointmentData.employeeId} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            <option value="">Sélectionner un employé</option>
            {employees.map(emp => (
              <option key={emp.userId} value={emp.userId}>
                {emp.name || `${emp.firstName} ${emp.lastName}`}
              </option>
            ))}
          </select>
        </div>
                
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
          <ClientAutocomplete
            clients={clients}
            value={appointmentData.clientName}
            onChange={handleClientChange}
          />
        </div>

        <div className="flex items-center space-x-2">
          <RotateCcw className="text-gray-400" size={20} />
          <label className="inline-flex items-center">
            <input 
              type="checkbox" 
              name="isRecurring" 
              checked={appointmentData.isRecurring} 
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2">Rendez-vous récurrent</span>
          </label>
        </div>
        {appointmentData.isRecurring && (
          <div className="space-y-4 ml-7">
            <div>
              <select 
                name="recurrenceBase" 
                value={appointmentData.recurrenceBase} 
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="weekly">Hebdomadaire</option>
                <option value="monthly">Mensuel</option>
              </select>
            </div>
            {appointmentData.recurrenceBase === 'weekly' && (
              <div className="flex space-x-2">
                {['Lun', 'Mar', 'Mer', 'Jeu', 'Ven', 'Sam', 'Dim'].map((day, index) => (
                  <button
                    key={day}
                    type="button"
                    onClick={() => handleDaySelection((index + 1) % 7)}
                    className={`p-2 rounded ${
                      appointmentData.recurrenceDay.includes((index + 1) % 7)
                        ? 'bg-blue-500 text-white'
                        : 'bg-gray-200'
                    }`}
                  >
                    {day}
                  </button>
                ))}
              </div>
            )}
            {appointmentData.recurrenceBase === 'monthly' && (
              <>
                <div>
                  <select
                    name="recurrenceMonthType"
                    value={appointmentData.recurrenceMonthType}
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="dayOfMonth">Le même jour du mois</option>
                    <option value="dayOfWeek">Le même jour de la semaine</option>
                  </select>
                </div>
                {appointmentData.recurrenceMonthType === 'dayOfMonth' ? (
                  <div>
                    Répéter le {appointmentData.recurrenceMonthDay} de chaque mois
                  </div>
                ) : (
                  <div>
                    Répéter le {getWeekOfMonthName(appointmentData.recurrenceMonthWeek)} {getDayOfWeekName(appointmentData.recurrenceMonthWeekday)} de chaque mois
                  </div>
                )}
              </>
            )}
            <div className="flex items-center space-x-2">
              <span>Tous les</span>
              <input 
                type="number" 
                name="recurrenceFrequency" 
                value={appointmentData.recurrenceFrequency} 
                onChange={handleChange}
                min="1"
                className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              />
              <span>{appointmentData.recurrenceBase === 'weekly' ? 'semaine(s)' : 'mois'}</span>
            </div>
            <div>
              <select
                name="recurrenceEndType"
                value={appointmentData.recurrenceEndType}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="never">Jamais</option>
                <option value="after">Après un nombre d'occurrences</option>
                <option value="on">À une date spécifique</option>
              </select>
            </div>
            {appointmentData.recurrenceEndType === 'after' && (
              <div className="flex items-center space-x-2">
                <span>Après</span>
                <input 
                  type="number" 
                  name="recurrenceEndAfter" 
                  value={appointmentData.recurrenceEndAfter} 
                  onChange={handleChange}
                  min="1"
                  className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span>occurrences</span>
              </div>
            )}
            {appointmentData.recurrenceEndType === 'on' && (
              <div>
                <input 
                  type="date" 
                  name="recurrenceEndDate" 
                  value={appointmentData.recurrenceEndDate || ''} 
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
          </div>
        )}
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            type="button" 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
};

export default WeekView;