import React, { useState, useEffect, useCallback, useMemo } from 'react';
import { collection, addDoc, updateDoc, doc, query, where, getDocs, Timestamp, getDoc, deleteDoc, writeBatch } from 'firebase/firestore';
import { DragDropContext, Droppable, Draggable } from 'react-beautiful-dnd';
import { X, Clock, Calendar, User, ChevronLeft, ChevronRight, Plus, Copy, Search, RotateCcw, AlertTriangle, Check } from 'lucide-react';
import ClientEditAppointmentModal from './ClientEditAppointmentModal'; // Nouvelle import

// Ajoutez cette fonction d'aide en haut de votre fichier
const isSameDay = (date1, date2) => {
  const d1 = new Date(date1);
  const d2 = new Date(date2);
  return d1.getFullYear() === d2.getFullYear() &&
         d1.getMonth() === d2.getMonth() &&
         d1.getDate() === d2.getDate();
};

const ClientMonthView = ({ 
  db, 
  client, 
  currentDate: initialDate, 
  onClose, 
  generalSettings,
  onAppointmentClick,
  onAddAppointment,
  onUpdateAppointment,
  onDeleteAppointment,
  formatDate,
  formatTime,
  adjustForTimezone
}) => {
  const [currentDate, setCurrentDate] = useState(new Date(initialDate));
  const [monthDates, setMonthDates] = useState([]);
  const [appointments, setAppointments] = useState([]);
  const [employees, setEmployees] = useState([]);
  const [isCtrlPressed, setIsCtrlPressed] = useState(false);
  const [firstDayOfWeek, setFirstDayOfWeek] = useState(0); // Default to Sunday
  const [showAddModal, setShowAddModal] = useState(false);
  const [showEditModal, setShowEditModal] = useState(false);
  const [selectedAppointment, setSelectedAppointment] = useState(null);
  const [selectedDate, setSelectedDate] = useState(null);
  const [selectedEmployee, setSelectedEmployee] = useState(null);
  const [approvedLeaves, setApprovedLeaves] = useState([]);

// Ajouter la fonction de récupération des congés
const fetchApprovedLeaves = useCallback(async () => {
  if (!db) return;
  try {
    const leavesRef = collection(db, 'leaves');
    const q = query(
      leavesRef, 
      where('status', '==', 'approved')
    );
    const querySnapshot = await getDocs(q);
    const leaves = querySnapshot.docs.map(doc => ({
      ...doc.data(),
      id: doc.id,
      startDate: doc.data().startDate.toDate(),
      endDate: doc.data().endDate.toDate()
    }));
    setApprovedLeaves(leaves);
  } catch (error) {
    console.error("Erreur lors de la récupération des congés:", error);
  }
}, [db]);

// Ajouter dans le useEffect principal
useEffect(() => {
  fetchApprovedLeaves();
}, [fetchApprovedLeaves]);

const isDateInLeave = useCallback((date, employeeId) => {
  return approvedLeaves.some(leave => {
    if (leave.employeeId !== employeeId) return false;

    // Conversion en objets Date si nécessaire
    const startDate = leave.startDate instanceof Date ? 
      leave.startDate : new Date(leave.startDate);
    const endDate = leave.endDate instanceof Date ? 
      leave.endDate : new Date(leave.endDate);
    const checkDate = date instanceof Date ? 
      date : new Date(date);
    
    // Normalisation des dates pour comparaison
    const normalizedStart = new Date(startDate);
    normalizedStart.setHours(0, 0, 0, 0);
    const normalizedEnd = new Date(endDate);
    normalizedEnd.setHours(23, 59, 59, 999);
    const normalizedCheck = new Date(checkDate);
    normalizedCheck.setHours(12, 0, 0, 0);

    // Vérification que la date est bien dans la période de congé
    return normalizedCheck >= normalizedStart && normalizedCheck <= normalizedEnd;
  });
}, [approvedLeaves]);

  console.log("ClientMonthView rendered with currentDate:", currentDate);

  // Fetch planning management settings
  const fetchPlanningSettings = useCallback(async () => {
    if (!db) return;
    try {
      const settingsDoc = await getDoc(doc(db, 'settings', 'planningManagement'));
      if (settingsDoc.exists()) {
        const settings = settingsDoc.data();
        setFirstDayOfWeek(settings.firstDayOfWeek || 0);
      }
    } catch (error) {
      console.error("Erreur lors de la récupération des paramètres de planification:", error);
    }
  }, [db]);

  useEffect(() => {
    fetchPlanningSettings();
  }, [fetchPlanningSettings]);

  const updateMonthDates = useCallback((date) => {
    console.log("Updating month dates for:", date);
    const year = date.getFullYear();
    const month = date.getMonth();
    const firstDay = new Date(year, month, 1);
    const lastDay = new Date(year, month + 1, 0);
    
    const dates = [];
    let start = firstDay.getDay() - firstDayOfWeek;
    if (start < 0) start += 7;

    // Ajouter des cases vides pour les jours avant le premier du mois
    for (let i = 0; i < start; i++) {
      dates.push(null);
    }

    // Ajouter tous les jours du mois
    for (let i = 1; i <= lastDay.getDate(); i++) {
      dates.push(new Date(year, month, i));
    }

    setMonthDates(dates);
  }, [firstDayOfWeek]);


  const fetchEmployees = useCallback(async () => {
    if (!db) return;
    try {
      const employeesRef = collection(db, 'employees');
      const querySnapshot = await getDocs(employeesRef);
      const fetchedEmployees = querySnapshot.docs.map(doc => ({
        userId: doc.id,
        ...doc.data(),
        color: doc.data().color || '#808080'
      }));
      setEmployees(fetchedEmployees);
    } catch (error) {
      console.error("Erreur lors de la récupération des employés:", error);
    }
  }, [db]);

 
  const fetchAppointments = useCallback(async () => {
    if (!db || !client.id) return;
    const startOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth(), 1);
    startOfMonth.setHours(0, 0, 0, 0);
    const endOfMonth = new Date(currentDate.getFullYear(), currentDate.getMonth() + 1, 0);
    endOfMonth.setHours(23, 59, 59, 999);

    console.log("Fetching appointments for:", startOfMonth, "to", endOfMonth);
    
    try {
      const appointmentsRef = collection(db, 'appointments');
      const q = query(
        appointmentsRef, 
        where('clientId', '==', client.id),
        where('date', '>=', Timestamp.fromDate(startOfMonth)),
        where('date', '<=', Timestamp.fromDate(endOfMonth))
      );
      const querySnapshot = await getDocs(q);
      const fetchedAppointments = querySnapshot.docs.map(doc => ({
        id: doc.id,
        ...doc.data(),
        date: doc.data().date?.toDate() || new Date(),
      }));
      console.log("Rendez-vous récupérés:", fetchedAppointments);
      setAppointments(fetchedAppointments);
    } catch (error) {
      console.error("Erreur lors de la récupération des rendez-vous:", error);
    }
  }, [db, client.id, currentDate]);

  useEffect(() => {
    console.log("Effect triggered in ClientMonthView");
    updateMonthDates(currentDate);
    fetchAppointments();
    fetchEmployees();
  }, [currentDate, updateMonthDates, fetchAppointments, fetchEmployees, firstDayOfWeek]);

  const sortAppointments = useCallback((apps) => {
    return apps.sort((a, b) => {
      if (a.date.getTime() !== b.date.getTime()) {
        return a.date.getTime() - b.date.getTime();
      }
      return a.startTime.localeCompare(b.startTime);
    });
  }, []);

  const appointmentsWithColors = useMemo(() => {
    const sortedAppointments = sortAppointments([...appointments]);
    return sortedAppointments.map(appointment => {
      const employee = employees.find(emp => emp.userId === appointment.employeeId);
      return {
        ...appointment,
        employeeColor: appointment.status === 'assigne' ? (employee?.color || '#808080') : '#FF0000',
        employeeName: employee ? (employee.name || `${employee.firstName} ${employee.lastName}`) : "Non attribué"
      };
    });
  }, [appointments, employees, sortAppointments]);

  
  const getEmployeeName = (employeeId) => {
    const employee = employees.find(emp => emp.userId === employeeId);
    return employee ? (employee.name || `${employee.firstName} ${employee.lastName}`) : "Employé non trouvé";
  };

  const handlePreviousMonth = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate.getFullYear(), prevDate.getMonth() - 1, 1);
      updateMonthDates(newDate);
      return newDate;
    });
  };

  const handleNextMonth = () => {
    setCurrentDate(prevDate => {
      const newDate = new Date(prevDate.getFullYear(), prevDate.getMonth() + 1, 1);
      updateMonthDates(newDate);
      return newDate;
    });
  };

  const handleToday = () => {
    const today = new Date();
    setCurrentDate(today);
    updateMonthDates(today);
  };

  

  const formatDateRange = () => {
    return currentDate.toLocaleDateString('fr-FR', { year: 'numeric', month: 'long' });
  };


  const handleCellClick = (date, employeeId) => {
    setSelectedDate(new Date(date));
    setSelectedEmployee(employeeId);
    setShowAddModal(true);
  };

  const handleAppointmentClick = (appointment) => {
    setSelectedAppointment(appointment);
    setShowEditModal(true);
  };

  const handleAddAppointment = async (appointmentData) => {
    try {
      let appointmentsToAdd = [];
      if (appointmentData.isRecurring) {
        appointmentsToAdd = generateRecurringAppointments(appointmentData);
      } else {
        appointmentsToAdd = [{
          ...appointmentData,
          isRecurring: false,
          status: appointmentData.employeeId ? 'assigne' : 'libre'
        }];
      }

      const batch = writeBatch(db);
      
      appointmentsToAdd.forEach(appointment => {
        const newAppointmentRef = doc(collection(db, 'appointments'));
        batch.set(newAppointmentRef, {
          ...appointment,
          clientId: client.id,
          date: Timestamp.fromDate(appointment.date)
        });
      });

      await batch.commit();
      console.log(`Added ${appointmentsToAdd.length} appointment(s) successfully`);
      
      fetchAppointments();
      setShowAddModal(false);
    } catch (error) {
      console.error("Erreur lors de l'ajout du/des rendez-vous:", error);
    }
  };

  const handleUpdateAppointment = async (updatedAppointment) => {
    try {
      const appointmentRef = doc(db, 'appointments', updatedAppointment.id);
      const updateData = {
        ...updatedAppointment,
        date: Timestamp.fromDate(updatedAppointment.date),
        status: updatedAppointment.employeeId ? 'assigne' : 'libre'
      };
  
      await updateDoc(appointmentRef, updateData);
  
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la mise à jour du rendez-vous:", error);
    }
  };

  const handleDeleteAppointment = async (appointmentId) => {
    try {
      await deleteDoc(doc(db, 'appointments', appointmentId));
      fetchAppointments();
      setShowEditModal(false);
    } catch (error) {
      console.error("Erreur lors de la suppression du rendez-vous:", error);
    }
  };

  const generateRecurringAppointments = (appointmentData) => {
    const appointments = [];
    let currentDate = new Date(appointmentData.date);
    const endDate = getRecurrenceEndDate(appointmentData);
    
    let occurrenceCount = 0;
    const maxOccurrences = appointmentData.recurrenceEndType === 'after' ? appointmentData.recurrenceEndAfter : Infinity;

    while (currentDate <= endDate && occurrenceCount < maxOccurrences) {
      if (appointmentData.recurrenceBase === 'daily') {
        appointments.push({
          ...appointmentData,
          date: new Date(currentDate),
          isRecurring: true
        });
        occurrenceCount++;
        currentDate.setDate(currentDate.getDate() + appointmentData.recurrenceInterval);
      } else if (appointmentData.recurrenceBase === 'weekly') {
        if (appointmentData.recurrenceDay.includes(currentDate.getDay())) {
          appointments.push({
            ...appointmentData,
            date: new Date(currentDate),
            isRecurring: true
          });
          occurrenceCount++;
        }
        currentDate.setDate(currentDate.getDate() + 1);
        if (currentDate.getDay() === 0) {
          currentDate.setDate(currentDate.getDate() + (7 * (appointmentData.recurrenceFrequency - 1)));
        }
      } else if (appointmentData.recurrenceBase === 'monthly') {
        if (shouldAddAppointment(currentDate, appointmentData)) {
          appointments.push({
            ...appointmentData,
            date: new Date(currentDate),
            isRecurring: true
          });
          occurrenceCount++;
        }
        currentDate = getNextRecurrenceDate(currentDate, appointmentData);
      }
    }

    return appointments;
  };

  const getRecurrenceEndDate = (appointmentData) => {
    if (appointmentData.recurrenceEndType === 'on' && appointmentData.recurrenceEndDate) {
      const endDate = new Date(appointmentData.recurrenceEndDate);
      endDate.setHours(23, 59, 59, 999);
      return endDate;
    } else if (appointmentData.recurrenceEndType === 'after') {
      const startDate = new Date(appointmentData.date);
      const endDate = new Date(startDate);
      if (appointmentData.recurrenceBase === 'daily') {
        endDate.setDate(startDate.getDate() + (appointmentData.recurrenceEndAfter - 1) * appointmentData.recurrenceInterval);
      } else if (appointmentData.recurrenceBase === 'weekly') {
        endDate.setDate(startDate.getDate() + (appointmentData.recurrenceEndAfter - 1) * 7 * appointmentData.recurrenceFrequency);
      } else if (appointmentData.recurrenceBase === 'monthly') {
        endDate.setMonth(startDate.getMonth() + (appointmentData.recurrenceEndAfter - 1) * appointmentData.recurrenceFrequency);
      }
      return endDate;
    } else {
      const startDate = new Date(appointmentData.date);
      return new Date(startDate.getFullYear() + 1, startDate.getMonth(), startDate.getDate());
    }
  };

  const shouldAddAppointment = (date, appointmentData) => {
    if (appointmentData.recurrenceBase === 'monthly') {
      if (appointmentData.recurrenceMonthType === 'dayOfWeek') {
        const weekOfMonth = Math.floor((date.getDate() - 1) / 7) + 1;
        return date.getDay() === parseInt(appointmentData.recurrenceMonthWeekday, 10) &&
               weekOfMonth === parseInt(appointmentData.recurrenceMonthWeek, 10);
      } else {
        return date.getDate() === parseInt(appointmentData.recurrenceMonthDay, 10);
      }
    }
    return true;
  };

  
  const getNextRecurrenceDate = (date, appointmentData) => {
    let newDate = new Date(date);
    const frequency = parseInt(appointmentData.recurrenceFrequency, 10);
    
    if (appointmentData.recurrenceBase === 'monthly') {
      newDate.setMonth(newDate.getMonth() + frequency);
      
      if (appointmentData.recurrenceMonthType === 'dayOfWeek') {
        const desiredDay = parseInt(appointmentData.recurrenceMonthWeekday, 10);
        const desiredWeek = parseInt(appointmentData.recurrenceMonthWeek, 10);
        
        newDate.setDate(1);
        
        while (newDate.getDay() !== desiredDay) {
          newDate.setDate(newDate.getDate() + 1);
        }
        
        newDate.setDate(newDate.getDate() + (desiredWeek - 1) * 7);
        
        if (newDate.getMonth() > (date.getMonth() + frequency) % 12) {
          newDate = new Date(newDate.getFullYear(), newDate.getMonth(), 0);
        }
      } else {
        newDate.setDate(parseInt(appointmentData.recurrenceMonthDay, 10));
      }
    } else if (appointmentData.recurrenceBase === 'weekly') {
      newDate.setDate(newDate.getDate() + (7 * frequency));
    }
    
    return newDate;
  };

  useEffect(() => {
    const handleKeyDown = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(true);
      }
    };
    const handleKeyUp = (e) => {
      if (e.key === 'Control') {
        setIsCtrlPressed(false);
      }
    };
    window.addEventListener('keydown', handleKeyDown);
    window.addEventListener('keyup', handleKeyUp);
    return () => {
      window.removeEventListener('keydown', handleKeyDown);
      window.removeEventListener('keyup', handleKeyUp);
    };
  }, []);

  const onDragEnd = useCallback(async (result) => {
    if (!result.destination) return;

    const { source, destination, draggableId } = result;
    
    if (source.droppableId !== destination.droppableId) {
      const appointment = appointmentsWithColors.find(app => app.id === draggableId);
      if (appointment) {
        const sourceDate = new Date(parseInt(source.droppableId));
        const destinationDate = new Date(parseInt(destination.droppableId));
        const timeDifference = destinationDate.getTime() - sourceDate.getTime();

        const newDate = new Date(appointment.date.getTime() + timeDifference);

        try {
          if (isCtrlPressed) {
            // Dupliquer le rendez-vous
            const newAppointment = {
              ...appointment,
              date: Timestamp.fromDate(newDate),
              clientId: client.id
            };
            delete newAppointment.id;
            const docRef = await addDoc(collection(db, 'appointments'), newAppointment);
            const addedAppointment = { ...newAppointment, id: docRef.id, date: newDate };
            setAppointments(prev => [...prev, addedAppointment]);
          } else {
            // Déplacer le rendez-vous
            await updateDoc(doc(db, 'appointments', appointment.id), {
              date: Timestamp.fromDate(newDate)
            });
            setAppointments(prev => prev.map(app =>
              app.id === appointment.id ? { ...app, date: newDate } : app
            ));
          }
        } catch (error) {
          console.error("Erreur lors de la manipulation du rendez-vous:", error);
        }
      }
    }
  }, [appointmentsWithColors, db, isCtrlPressed, client.id]);


  const getDayNames = () => {
    const dayNames = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    return [...dayNames.slice(firstDayOfWeek), ...dayNames.slice(0, firstDayOfWeek)];
  };


  const getAppointmentStyle = (appointment) => {
    let backgroundColor, borderColor, textColor, statusText, icon;
  
    switch(appointment.status) {
      case 'libre':
        backgroundColor = '#FEE2E2'; // Rouge clair
        borderColor = '#EF4444';
        textColor = '#991B1B';
        statusText = 'Plage à combler';
        icon = <AlertTriangle size={12} className="inline-block mr-1 text-red-600" />;
        break;
      case 'reserve':
        backgroundColor = '#FDE68A'; // Jaune
        borderColor = '#F59E0B';
        textColor = '#92400E';
        statusText = 'En attente de validation';
        icon = <Clock size={12} className="inline-block mr-1 animate-spin" />;
        break;
      case 'assigne':
        backgroundColor = appointment.employeeColor || '#808080';
        borderColor = appointment.employeeColor || '#808080';
        textColor = isLightColor(backgroundColor) ? '#1F2937' : '#F9FAFB';
        statusText = appointment.employeeName;
        icon = <User size={12} className="inline-block mr-1" />;
        break;
      case 'in_progress':
        backgroundColor = '#FFEDD5'; // Orange clair
        borderColor = '#F97316';
        textColor = '#9A3412';
        statusText = 'En cours';
        icon = <Clock size={12} className="inline-block mr-1" />;
        break;
      case 'completed':
        backgroundColor = 'rgba(156, 163, 175, 0.3)'; // Gris transparent
        borderColor = '#9CA3AF';
        textColor = '#4B5563';
        statusText = 'Terminé';
        icon = <Check size={12} className="inline-block mr-1 text-green-500" />;
        break;
      default:
        backgroundColor = '#E5E7EB';
        borderColor = '#9CA3AF';
        textColor = '#4B5563';
        statusText = 'Statut inconnu';
        icon = null;
    }
  
    return { backgroundColor, borderColor, textColor, statusText, icon };
  };
  
  return (
    <div className="pv-container pv-month-view">
      <div className="pv-month-header">
        
        <div className="pv-day-names">
          {getDayNames().map((day, index) => (
            <div key={index} className="pv-day-name">{day}</div>
          ))}
        </div>
      </div>
      <DragDropContext onDragEnd={onDragEnd}>
        <div className="month-grid grid grid-cols-7 gap-1">
          {/* ... en-têtes des jours ... */}
          {monthDates.map((date, index) => (
            <Droppable key={date ? date.getTime() : `empty-${index}`} droppableId={date ? date.getTime().toString() : `empty-${index}`}>
              {(provided, snapshot) => (
                <div
                  ref={provided.innerRef}
                  {...provided.droppableProps}
                  className={`pv-day-cell ${date ? (date.getMonth() === currentDate.getMonth() ? 'pv-current-month' : 'pv-other-month') : 'pv-empty-cell'} 
                              ${snapshot.isDraggingOver ? 'pv-dragging-over' : ''}`}
                  onClick={() => date && handleCellClick(date)}
                >
                  {date && (
                    <>
                      <div className={`pv-date-number ${isSameDay(date, new Date()) ? 'pv-today' : ''}`}>
                        {date.getDate()}
                      </div>
                      {/* Ajout de l'indicateur de congés */}
                      {employees.map(employee => {
                        if (isDateInLeave(date, employee.userId)) {
                          return (
                            <div 
                              key={`employee-offday-${employee.userId}`}
                              className="employee-offday-indicator"
                            >
                              <div className="employee-offday-content">
                                <Clock size={14} className="employee-offday-icon" />
                                <span className="employee-offday-text">
                                  {employee.firstName} absent
                                </span>
                              </div>
                            </div>
                          );
                        }
                        return null;
                      })}
                      <div className="pv-appointments-container">
                      {appointmentsWithColors
                      .filter(app => isSameDay(app.date, date))
                      .map((appointment, appIndex) => {
                        const { backgroundColor, borderColor, textColor, statusText, icon } = getAppointmentStyle(appointment);
                        return (
                          <Draggable key={appointment.id} draggableId={appointment.id} index={appIndex}>
                            {(provided, snapshot) => (
                              <div
                                ref={provided.innerRef}
                                {...provided.draggableProps}
                                {...provided.dragHandleProps}
                                className={`pv-appointment ${snapshot.isDragging ? 'pv-dragging' : ''}`}
                                style={{
                                  ...provided.draggableProps.style,
                                  backgroundColor: backgroundColor,
                                  borderLeft: `4px solid ${borderColor}`,
                                  color: textColor
                                }}
                                onClick={(e) => {
                                  e.stopPropagation();
                                  handleAppointmentClick(appointment);
                                }}
                              >
                                <div className="pv-appointment-title">
                                  {icon}
                                  {statusText}
                                </div>
                                <div className="pv-appointment-time bg-white text-gray-800 p-1 mt-1 rounded">
                                  <Clock className="inline-block mr-1" size={12} />
                                  {`${appointment.startTime} - ${appointment.endTime}`}
                                </div>
                                {isCtrlPressed && snapshot.isDragging && (
                                  <div className="pv-copy-icon-wrapper">
                                    <Copy size={16} className="pv-copy-icon" />
                                  </div>
                                )}
                              </div>
                            )}
                          </Draggable>
                        );
                      })}
                      </div>
                    </>
                  )}
                  {provided.placeholder}
                </div>
              )}
            </Droppable>
          ))}
        </div>
      </DragDropContext>

      {showAddModal && (
        <Modal onClose={() => setShowAddModal(false)}>
          <AddAppointmentModal
            date={selectedDate}
            employees={employees}
            onSave={handleAddAppointment}
            onClose={() => setShowAddModal(false)}
            initialEmployeeId={selectedEmployee}
          />
        </Modal>
      )}

{showEditModal && selectedAppointment && (
        <Modal onClose={() => setShowEditModal(false)}>
          <ClientEditAppointmentModal
            appointment={selectedAppointment}
            employees={employees}
            onSave={handleUpdateAppointment}
            onClose={() => setShowEditModal(false)}
            onDelete={handleDeleteAppointment}
          />
        </Modal>
        )}
    </div>
  );
};

const Modal = ({ children, onClose }) => {
  return (
    <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
      <div className="bg-white p-6 rounded-lg shadow-xl w-full max-w-md">
        <button onClick={onClose} className="absolute top-2 right-2 text-gray-500 hover:text-gray-700">
          <X size={24} />
        </button>
        {children}
      </div>
    </div>
  );
};

const lightenColor = (color, percent) => {
  const num = parseInt(color.replace("#",""), 16),
    amt = Math.round(2.55 * percent),
    R = Math.min((num >> 16) + amt, 255),
    G = Math.min((num >> 8 & 0x00FF) + amt, 255),
    B = Math.min((num & 0x0000FF) + amt, 255);
  return "#" + (0x1000000 + R*0x10000 + G*0x100 + B).toString(16).slice(1);
};

const isLightColor = (color) => {
  const hex = color.replace('#', '');
  const r = parseInt(hex.substr(0, 2), 16);
  const g = parseInt(hex.substr(2, 2), 16);
  const b = parseInt(hex.substr(4, 2), 16);
  const brightness = ((r * 299) + (g * 587) + (b * 114)) / 1000;
  return brightness > 155;
};

const AddAppointmentModal = ({ date, employees, onSave, onClose, initialEmployeeId }) => {
  const [appointmentData, setAppointmentData] = useState(() => {
    const initialDate = new Date(date);
    initialDate.setMinutes(initialDate.getMinutes() - initialDate.getTimezoneOffset());
    return {
      date: initialDate,
      startTime: '',
      endTime: '',
      employeeId: initialEmployeeId || '',
      status: initialEmployeeId ? 'assigne' : 'libre',
      isRecurring: false,
      recurrenceBase: 'weekly',
      recurrenceFrequency: 1,
      recurrenceDay: [initialDate.getDay()],
      recurrenceMonthDay: initialDate.getDate(),
      recurrenceMonthType: 'dayOfMonth',
      recurrenceMonthWeek: Math.floor((initialDate.getDate() - 1) / 7) + 1,
      recurrenceMonthWeekday: initialDate.getDay(),
      recurrenceEndType: 'never',
      recurrenceEndAfter: 1,
      recurrenceEndDate: null,
      recurrenceInterval: 1,
      recurrenceMonthFrequency: 1,
    };
  });

  const handleChange = (e) => {
    const { name, value, type, checked } = e.target;
    setAppointmentData(prev => {
      const newData = { ...prev };
      if (name === 'date') {
        const newDate = new Date(value);
        newData[name] = isNaN(newDate.getTime()) ? prev.date : newDate;
      } else if (name === 'employeeId') {
        newData[name] = value;
        newData.status = value ? 'assigne' : 'libre';
      } else {
        newData[name] = type === 'checkbox' ? checked : value;
      }
      return newData;
    });
  };

  const handleSubmit = (e) => {
    e.preventDefault();
    onSave(appointmentData);
  };

  const getDayOfWeekName = (day) => {
    const days = ['Dimanche', 'Lundi', 'Mardi', 'Mercredi', 'Jeudi', 'Vendredi', 'Samedi'];
    return days[day];
  };

  const getWeekOfMonthName = (week) => {
    const weeks = ['premier', 'deuxième', 'troisième', 'quatrième', 'dernier'];
    return weeks[Math.min(week - 1, 4)];
  };
  

  return (
    <div className="modal">
      <h2 className="text-2xl font-bold mb-4">Ajouter un rendez-vous</h2>
      <form onSubmit={handleSubmit} className="space-y-4">
        <div className="flex items-center space-x-2">
          <Calendar className="text-gray-400" size={20} />
          <input 
            type="date" 
            name="date" 
            value={appointmentData.date instanceof Date && !isNaN(appointmentData.date) 
              ? appointmentData.date.toISOString().split('T')[0] 
              : ''}
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>
        <div className="flex items-center space-x-2">
          <Clock className="text-gray-400" size={20} />
          <input 
            type="time" 
            name="startTime" 
            value={appointmentData.startTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
          <span>à</span>
          <input 
            type="time" 
            name="endTime" 
            value={appointmentData.endTime} 
            onChange={handleChange}
            className="flex-grow p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
          />
        </div>       
        <div className="flex items-center space-x-2">
          <User className="text-gray-400" size={20} />
        <select name="employeeId" value={appointmentData.employeeId} onChange={handleChange}>
          <option value="">Sélectionner un employé</option>
          {employees.map(emp => (
            <option key={emp.userId} value={emp.userId}>
            {emp.name || `${emp.firstName} ${emp.lastName}`}
          </option>
          ))}
        </select>
        </div>
        <div className="flex items-center space-x-2">
          <RotateCcw className="text-gray-400" size={20} />
          <label className="inline-flex items-center">
            <input 
              type="checkbox" 
              name="isRecurring" 
              checked={appointmentData.isRecurring} 
              onChange={handleChange}
              className="form-checkbox h-5 w-5 text-blue-600"
            />
            <span className="ml-2">Rendez-vous récurrent</span>
          </label>
        </div>

        {appointmentData.isRecurring && (
            <div className="space-y-4 ml-7">
              <div>
                <select 
                  name="recurrenceBase" 
                  value={appointmentData.recurrenceBase} 
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                >
                  <option value="daily">Journalière</option>
                  <option value="weekly">Hebdomadaire</option>
                  <option value="monthly">Mensuelle</option>
                </select>
              </div>

              {appointmentData.recurrenceBase === 'daily' && (
                <div className="flex items-center space-x-2">
                  <span>Tous les</span>
                  <input 
                    type="number" 
                    name="recurrenceInterval"
                    value={appointmentData.recurrenceInterval || 1} 
                    onChange={handleChange}
                    min="1"
                    className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span>jour(s)</span>
                </div>
              )}

              {appointmentData.recurrenceBase === 'weekly' && (
                <>
                  
                  <div className="flex items-center space-x-2">
                    <span>Tous les</span>
                    <input 
                      type="number" 
                      name="recurrenceFrequency" 
                      value={appointmentData.recurrenceFrequency} 
                      onChange={handleChange}
                      min="1"
                      className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                    />
                    <span>semaine(s)</span>
                  </div>
                </>
              )}

              {appointmentData.recurrenceBase === 'monthly' && (
              <>
                <div>
                  <select
                    name="recurrenceMonthType"
                    value={appointmentData.recurrenceMonthType}
                    onChange={handleChange}
                    className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  >
                    <option value="dayOfMonth">Le même jour du mois</option>
                    <option value="dayOfWeek">Le même jour de la semaine</option>
                  </select>
                </div>
                {appointmentData.recurrenceMonthType === 'dayOfMonth' ? (
                  <div>
                    Répéter le {appointmentData.recurrenceMonthDay} de chaque mois
                  </div>
                ) : (
                  <div>
                    Répéter le {getWeekOfMonthName(appointmentData.recurrenceMonthWeek)} {getDayOfWeekName(appointmentData.recurrenceMonthWeekday)} de chaque mois
                  </div>
                )}
                <div className="flex items-center space-x-2">
                  <span>Tous les</span>
                  <input 
                    type="number" 
                    name="recurrenceMonthFrequency" 
                    value={appointmentData.recurrenceMonthFrequency} 
                    onChange={handleChange}
                    min="1"
                    className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                  />
                  <span>mois</span>
                </div>
              </>
            )}
            <div>
              <select
                name="recurrenceEndType"
                value={appointmentData.recurrenceEndType}
                onChange={handleChange}
                className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
              >
                <option value="never">Jamais</option>
                <option value="after">Après un nombre d'occurrences</option>
                <option value="on">À une date spécifique</option>
              </select>
            </div>
            {appointmentData.recurrenceEndType === 'after' && (
              <div className="flex items-center space-x-2">
                <span>Après</span>
                <input 
                  type="number" 
                  name="recurrenceEndAfter" 
                  value={appointmentData.recurrenceEndAfter} 
                  onChange={handleChange}
                  min="1"
                  className="w-16 p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
                <span>occurrences</span>
              </div>
            )}
            {appointmentData.recurrenceEndType === 'on' && (
              <div>
                <input 
                  type="date" 
                  name="recurrenceEndDate" 
                  value={appointmentData.recurrenceEndDate || ''} 
                  onChange={handleChange}
                  className="w-full p-2 border rounded focus:outline-none focus:ring-2 focus:ring-blue-500"
                />
              </div>
            )}
          </div>
        )}
        <div className="flex justify-end space-x-2 mt-6">
          <button 
            type="button" 
            onClick={onClose}
            className="px-4 py-2 bg-gray-200 text-gray-800 rounded hover:bg-gray-300 focus:outline-none focus:ring-2 focus:ring-gray-500"
          >
            Annuler
          </button>
          <button 
            type="submit"
            className="px-4 py-2 bg-blue-500 text-white rounded hover:bg-blue-600 focus:outline-none focus:ring-2 focus:ring-blue-500"
          >
            Ajouter
          </button>
        </div>
      </form>
    </div>
  );
};

export default ClientMonthView;