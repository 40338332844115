import React from 'react';
import { LogOut } from 'lucide-react';
import { getDatabase, ref, set } from 'firebase/database';
import { doc, updateDoc } from 'firebase/firestore';

const ForceLogoutButton = ({ employeeId, db, textColor }) => {
  const handleForceLogout = async () => {
    if (window.confirm("Êtes-vous sûr de vouloir forcer la déconnexion de cet utilisateur ?")) {
      try {
        const rtdb = getDatabase();
        const userStatusRef = ref(rtdb, `presence/${employeeId}`);
        
        await set(userStatusRef, false);
        
        await updateDoc(doc(db, 'employees', employeeId), {
          isOnline: false,
          lastForceLogout: new Date()
        });
        
        console.log("Déconnexion forcée effectuée avec succès");
      } catch (error) {
        console.error("Erreur lors de la déconnexion forcée:", error);
        alert("Une erreur est survenue lors de la déconnexion forcée");
      }
    }
  };

  return (
    <button 
      onClick={handleForceLogout}
      className={`${textColor} hover:opacity-75`}
      title="Forcer la déconnexion"
    >
      <LogOut size={20} />
    </button>
  );
};

export default ForceLogoutButton;