import React, { useState, useEffect } from 'react';
import { doc, setDoc, getDoc, collection, getDocs } from 'firebase/firestore';

const TIME_SLOTS = [
  { id: 'morning', label: 'Matin', timeRange: '04:00 - 12:00' },
  { id: 'noon', label: 'Midi', timeRange: '12:00 - 14:00' },
  { id: 'afternoon', label: 'Après-midi', timeRange: '14:00 - 17:30' },
  { id: 'evening', label: 'Soir', timeRange: '17:30 - 22:00' },
];

const ClientContract = ({ client, db }) => {
  const [contract, setContract] = useState({
    startDate: '',
    endDate: '',
    isOpenEnded: false,
    hourlyRate: '',
    hoursPerMonth: '',
    tasksPerPeriod: {
      morning: [],
      noon: [],
      afternoon: [],
      evening: []
    },
    customTasks: '',
  });
  const [categories, setCategories] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [expandedCategories, setExpandedCategories] = useState({});

  useEffect(() => {
    fetchContract();
    fetchCategories();
    fetchTasks();
  }, [client.id, db]);

  const fetchContract = async () => {
    const contractDoc = await getDoc(doc(db, 'contracts', client.id));
    if (contractDoc.exists()) {
      const data = contractDoc.data();
      setContract({
        ...data,
        tasksPerPeriod: data.tasksPerPeriod || { morning: [], noon: [], afternoon: [], evening: [] }
      });
    }
  };

  const fetchCategories = async () => {
    const categorySnapshot = await getDocs(collection(db, 'taskCategories'));
    const fetchedCategories = categorySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCategories(fetchedCategories);
  };

  const fetchTasks = async () => {
    const taskSnapshot = await getDocs(collection(db, 'tasks'));
    const fetchedTasks = taskSnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTasks(fetchedTasks);
  };

  const handleInputChange = (e) => {
    const { name, value, type, checked } = e.target;
    setContract(prev => ({
      ...prev,
      [name]: type === 'checkbox' ? checked : value
    }));
  };

  const handleTaskChange = (taskId, period) => {
    setContract(prev => {
      const newSelectedTasks = new Set(prev.tasksPerPeriod[period]);
      if (newSelectedTasks.has(taskId)) {
        newSelectedTasks.delete(taskId);
      } else {
        newSelectedTasks.add(taskId);
      }
      return {
        ...prev,
        tasksPerPeriod: {
          ...prev.tasksPerPeriod,
          [period]: Array.from(newSelectedTasks)
        }
      };
    });
  };

  const handleCategoryExpand = (categoryId) => {
    setExpandedCategories(prev => ({
      ...prev,
      [categoryId]: !prev[categoryId]
    }));
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    await setDoc(doc(db, 'contracts', client.id), contract);
  };

  // Function to render tasks by time slot
  const renderTasksByTimeSlot = (timeSlot) => (
    <div className="space-y-2">
      {categories.map(category => (
        <div key={category.id} className="border rounded p-2">
          <div className="flex justify-between items-center">
            <h4 className="text-sm font-medium">{category.name}</h4>
            <button
              type="button"
              onClick={() => handleCategoryExpand(category.id)}
              className="text-xs text-blue-500 hover:text-blue-700"
            >
              {expandedCategories[category.id] ? 'Réduire' : 'Voir'}
            </button>
          </div>
          {expandedCategories[category.id] && (
            <div className="pl-2">
              {tasks
                .filter(task => task.category === category.id)
                .map(task => (
                  <label key={task.id} className="flex items-center text-sm">
                    <input
                      type="checkbox"
                      checked={contract.tasksPerPeriod[timeSlot.id].includes(task.id)}
                      onChange={() => handleTaskChange(task.id, timeSlot.id)}
                      className="mr-1"
                    />
                    {task.name}
                  </label>
                ))}
            </div>
          )}
        </div>
      ))}
    </div>
  );

  // Nouvelle fonction pour afficher les tâches cochées
  const renderCheckedTasks = () => {
    const allCheckedTasks = TIME_SLOTS.flatMap(slot => 
      contract.tasksPerPeriod[slot.id].map(taskId => {
        const task = tasks.find(t => t.id === taskId);
        return task ? { ...task, timeSlot: slot.label } : null;
      })
    ).filter(Boolean);

    return (
      <div className="mt-4">
        <h3 className="text-md font-semibold mb-2">Tâches sélectionnées</h3>
        <ul className="list-disc pl-5">
          {allCheckedTasks.map((task, index) => (
            <li key={index} className="text-sm">
              {task.name} - {task.timeSlot}
            </li>
          ))}
        </ul>
      </div>
    );
  };

  return (
    <div className="bg-white p-4 rounded-lg shadow-md max-w-5xl mx-auto">
      <h2 className="text-lg font-bold mb-4">Contrat de {client.firstName} {client.lastName}</h2>
      <form onSubmit={handleSubmit} className="space-y-3">
        <div className="grid grid-cols-1 md:grid-cols-2 gap-2">
          <div className="md:col-span-2 grid grid-cols-1 md:grid-cols-2 gap-2 items-end">
            <div>
              <label className="block text-sm mb-1">Date de début</label>
              <input
                type="date"
                name="startDate"
                value={contract.startDate}
                onChange={handleInputChange}
                className="w-full p-2 border rounded text-sm"
                required
              />
            </div>
            {!contract.isOpenEnded && (
              <div>
                <label className="block text-sm mb-1">Date de fin</label>
                <input
                  type="date"
                  name="endDate"
                  value={contract.endDate}
                  onChange={handleInputChange}
                  className="w-full p-2 border rounded text-sm"
                />
              </div>
            )}
          </div>
          <div className="md:col-span-2">
            <label className="flex items-center text-sm">
              <input
                type="checkbox"
                name="isOpenEnded"
                checked={contract.isOpenEnded}
                onChange={handleInputChange}
                className="mr-2"
              />
              Contrat sans fin
            </label>
          </div>
          <div>
            <label className="block text-sm mb-1">Tarif horaire (€)</label>
            <input
              type="number"
              name="hourlyRate"
              value={contract.hourlyRate}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-sm"
              step="0.01"
              required
            />
          </div>
          <div>
            <label className="block text-sm mb-1">Heures par mois</label>
            <input
              type="number"
              name="hoursPerMonth"
              value={contract.hoursPerMonth}
              onChange={handleInputChange}
              className="w-full p-2 border rounded text-sm"
              required
            />
          </div>
        </div>
        

        {/* Tâches groupées par créneaux horaires */}
        <div>
          <h3 className="text-md font-semibold mb-2">Tâches à accomplir par créneau horaire</h3>
          <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-4">
            {TIME_SLOTS.map((slot) => (
              <div key={slot.id}>
                <h4 className="font-bold text-sm">{slot.label} ({slot.timeRange})</h4>
                {renderTasksByTimeSlot(slot)}
              </div>
            ))}
          </div>
        </div>

        {/* Affichage des tâches cochées */}
        {renderCheckedTasks()}

        

        <button type="submit" className="bg-blue-500 text-white px-3 py-2 rounded text-sm hover:bg-blue-600">
          Sauvegarder le contrat
        </button>
      </form>
    </div>
  );
};

export default ClientContract;
