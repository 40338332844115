import { useEffect } from 'react';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { ref, onDisconnect, set, serverTimestamp } from 'firebase/database';
import { rtdb } from '../firebase'; // Assurez-vous que ce chemin est correct

const usePresence = () => {
  useEffect(() => {
    console.log("usePresence hook initialized");
    const auth = getAuth();

    const unsubscribe = onAuthStateChanged(auth, (user) => {
      console.log("Auth state changed", user);
      if (user) {
        const userStatusRef = ref(rtdb, `presence/${user.uid}`);
        console.log("User status ref:", userStatusRef);

        const updatePresence = (state) => {
          console.log("Updating presence state to", state);
          set(userStatusRef, {
            state: state,
            last_changed: serverTimestamp(),
          }).then(() => {
            console.log("Presence updated successfully");
          }).catch((error) => {
            console.error("Error updating presence:", error);
          });
        };

        updatePresence('online');

        onDisconnect(userStatusRef).set({
          state: 'offline',
          last_changed: serverTimestamp(),
        }).then(() => {
          console.log("Disconnect handler set successfully");
        }).catch((error) => {
          console.error("Error setting disconnect handler:", error);
        });
      }
    });

    return () => {
      console.log("Cleaning up usePresence hook");
      unsubscribe();
    };
  }, []);
};

export default usePresence;