import React, { useState, useEffect } from 'react';
import { BrowserRouter as Router, Route, Routes, Navigate } from 'react-router-dom';
import { getAuth, onAuthStateChanged } from 'firebase/auth';
import { getFirestore } from 'firebase/firestore';
import Login from './components/Login';
import Register from './components/Register';
import ForgotPassword from './components/ForgotPassword';
import MainLayout from './components/MainLayout';
import ResetPassword from './components/ResetPassword';
import UpdateEmail from './components/UpdateEmail';
import ProfilePage from './components/ProfilePage';
import ClientManagement from './components/ClientManagement';
import ClientDetails from './components/ClientDetails';
import ClientContract from './components/ClientContract';
import ClientSummary from './components/ClientSummary';
import OverviewPage from './components/OverviewPage';

import './App.css';

function App() {
  const [user, setUser] = useState(null);
  const [loading, setLoading] = useState(true);
  const db = getFirestore();

  useEffect(() => {
    const auth = getAuth();
    const unsubscribe = onAuthStateChanged(auth, (user) => {
      setUser(user);
      setLoading(false);
    });

    return () => unsubscribe();
  }, []);

  if (loading) {
    return <div className="flex items-center justify-center h-screen">Chargement...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/login" element={user ? <Navigate to="/dashboard" /> : <Login />} />
        <Route path="/register" element={user ? <Navigate to="/dashboard" /> : <Register />} />
        <Route path="/forgot-password" element={<ForgotPassword />} />
        <Route path="/reset-password" element={<ResetPassword />} />
        <Route path="/update-email" element={<UpdateEmail />} />
        <Route path="/profile" element={<ProfilePage />} />
        <Route path="/client-management" element={<ClientManagement />} />
        <Route path="/client-details/:clientId" element={<ClientDetails />} />
        <Route path="/client-contract/:clientId" element={<ClientContract />} />
        <Route path="/client-summary/:clientId" element={<ClientSummary />} />
        <Route path="/overview" element={user ? <OverviewPage db={db} currentUser={user} /> : <Navigate to="/login" />} />
        <Route
          path="/*"
          element={user ? <MainLayout /> : <Navigate to="/login" />}
        />
      </Routes>
    </Router>
  );
}

export default App;