import React, { useState, useEffect } from 'react';
import { collection, query, getDocs, addDoc, updateDoc, deleteDoc, doc } from 'firebase/firestore';
import { Edit, Trash2, X } from 'lucide-react';

const TaskManager = ({ db }) => {
  const [categories, setCategories] = useState([]);
  const [tasks, setTasks] = useState([]);
  const [newCategory, setNewCategory] = useState('');
  const [newTask, setNewTask] = useState({ name: '', category: '' });
  const [isEditing, setIsEditing] = useState(false);
  const [taskToEdit, setTaskToEdit] = useState(null);
  const [editingCategory, setEditingCategory] = useState(null);
  const [editedCategoryName, setEditedCategoryName] = useState('');

  useEffect(() => {
    fetchCategories();
    fetchTasks();
  }, []);

  // Fetch categories from Firestore
  const fetchCategories = async () => {
    const q = query(collection(db, 'taskCategories'));
    const querySnapshot = await getDocs(q);
    const fetchedCategories = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setCategories(fetchedCategories);
  };

  // Fetch tasks from Firestore
  const fetchTasks = async () => {
    const q = query(collection(db, 'tasks'));
    const querySnapshot = await getDocs(q);
    const fetchedTasks = querySnapshot.docs.map(doc => ({ id: doc.id, ...doc.data() }));
    setTasks(fetchedTasks);
  };

  // Add new task or update existing task
  const handleSaveTask = async () => {
    if (newTask.name && newTask.category) {
      if (isEditing && taskToEdit) {
        await updateDoc(doc(db, 'tasks', taskToEdit.id), newTask);
        setIsEditing(false);
        setTaskToEdit(null);
      } else {
        await addDoc(collection(db, 'tasks'), newTask);
      }
      setNewTask({ name: '', category: '' });
      fetchTasks();
    }
  };

  // Set task to be edited
  const handleEditTask = (task) => {
    setIsEditing(true);
    setTaskToEdit(task);
    setNewTask({ name: task.name, category: task.category });
  };

  // Delete task
  const handleDeleteTask = async (id) => {
    await deleteDoc(doc(db, 'tasks', id));
    fetchTasks();
  };

  // Add new category
  const handleAddCategory = async () => {
    if (newCategory) {
      await addDoc(collection(db, 'taskCategories'), { name: newCategory });
      setNewCategory('');
      fetchCategories();
    }
  };

  // Update existing category
  const handleUpdateCategory = async (id, updatedName) => {
    if (updatedName) {
      await updateDoc(doc(db, 'taskCategories', id), { name: updatedName });
      fetchCategories();
    }
  };

  // Delete category
  const handleDeleteCategory = async (id) => {
    await deleteDoc(doc(db, 'taskCategories', id));
    fetchCategories();
  };

  // Group tasks by category
  const getTasksByCategory = (categoryId) => {
    return tasks.filter(task => task.category === categoryId);
  };

  const handleEditCategory = (category) => {
    setEditingCategory(category);
    setEditedCategoryName(category.name);
  };

  const handleCancelEditCategory = () => {
    setEditingCategory(null);
    setEditedCategoryName('');
  };

  const handleSaveCategory = async () => {
    if (editingCategory && editedCategoryName) {
      await updateDoc(doc(db, 'taskCategories', editingCategory.id), { name: editedCategoryName });
      setEditingCategory(null);
      setEditedCategoryName('');
      fetchCategories();
    }
  };

  return (
    <div className="p-4">
      {/* Categories Management */}
      <div className="mb-6">
        <h2 className="text-lg font-semibold mb-3">Catégories de tâches</h2>
        <div className="flex space-x-2 mb-2">
          <input
            type="text"
            value={newCategory}
            onChange={(e) => setNewCategory(e.target.value)}
            placeholder="Nouvelle catégorie"
            className="p-2 border rounded flex-grow text-sm"
          />
          <button onClick={handleAddCategory} className="px-3 py-2 bg-blue-500 text-white text-sm rounded">
            Ajouter
          </button>
        </div>
        <ul className="space-y-1">
          {categories.map((category) => (
            <li key={category.id} className="p-2 border rounded flex justify-between items-center">
              {editingCategory && editingCategory.id === category.id ? (
                <>
                  <input
                    type="text"
                    value={editedCategoryName}
                    onChange={(e) => setEditedCategoryName(e.target.value)}
                    className="p-1 border rounded flex-grow text-sm mr-2"
                  />
                  <div className="flex space-x-2">
                    <button onClick={handleSaveCategory} className="p-1 bg-green-500 text-white text-xs rounded">
                      Sauvegarder
                    </button>
                    <button onClick={handleCancelEditCategory} className="p-1 bg-gray-500 text-white text-xs rounded">
                      <X size={16} />
                    </button>
                  </div>
                </>
              ) : (
                <>
                  <span className="text-sm">{category.name}</span>
                  <div className="flex space-x-2">
                    <button
                      onClick={() => handleEditCategory(category)}
                      className="p-1 bg-yellow-500 text-white text-xs rounded"
                    >
                      <Edit size={16} />
                    </button>
                    <button onClick={() => handleDeleteCategory(category.id)} className="p-1 bg-red-500 text-white text-xs rounded">
                      <Trash2 size={16} />
                    </button>
                  </div>
                </>
              )}
            </li>
          ))}
        </ul>
      </div>

      {/* Tasks Management */}
      <div>
        <h2 className="text-lg font-semibold mb-3">{isEditing ? 'Modifier la tâche' : 'Ajouter une tâche'}</h2>
        <div className="flex space-x-2 mb-2">
          <input
            type="text"
            value={newTask.name}
            onChange={(e) => setNewTask({ ...newTask, name: e.target.value })}
            placeholder="Nom de la tâche"
            className="p-2 border rounded flex-grow text-sm"
          />
          <select
            value={newTask.category}
            onChange={(e) => setNewTask({ ...newTask, category: e.target.value })}
            className="p-2 border rounded flex-grow text-sm"
          >
            <option value="">Sélectionner une catégorie</option>
            {categories.map((category) => (
              <option key={category.id} value={category.id}>{category.name}</option>
            ))}
          </select>
          <button onClick={handleSaveTask} className="px-3 py-2 bg-blue-500 text-white text-sm rounded">
            {isEditing ? 'Sauvegarder' : 'Ajouter'}
          </button>
        </div>

        {/* Tasks grouped by category */}
        <div className="space-y-4">
          {categories.map((category) => (
            <div key={category.id}>
              <h3 className="font-semibold text-base mb-2">{category.name}</h3>
              <ul className="space-y-1">
                {getTasksByCategory(category.id).length > 0 ? (
                  getTasksByCategory(category.id).map((task) => (
                    <li key={task.id} className="p-2 border rounded flex justify-between items-center">
                      <span className="text-sm">{task.name}</span>
                      <div className="flex space-x-2">
                        <button onClick={() => handleEditTask(task)} className="p-1 bg-yellow-500 text-white text-xs rounded">
                          <Edit size={16} />
                        </button>
                        <button onClick={() => handleDeleteTask(task.id)} className="p-1 bg-red-500 text-white text-xs rounded">
                          <Trash2 size={16} />
                        </button>
                      </div>
                    </li>
                  ))
                ) : (
                  <li className="text-sm text-gray-500">Aucune tâche dans cette catégorie</li>
                )}
              </ul>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};

export default TaskManager;
